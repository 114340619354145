import React, { useState, useEffect } from "react";
import Navbar from "../../../components/Navbar";
import Brackground from "../../../components/Background";
import Container from "../../../components/Container";
import Header from "../../../components/Header";
import style from "./CustosNavio.module.css";
import Input from "../../../components/Input";
import SubmitButton from "../../../components/Button";
import Axios from "axios";
import InputMask from "react-input-mask";
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import Navio from "./navio.png";


import { SnackbarProvider, useSnackbar } from "notistack";

const CustosNavio = () => {
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();
  const [navios, setNavios] = useState([]);
  const [selectedNavio, setSelectedNavio] = useState("");
  const [periodo, setPeriodo] = useState([]);
  const [raps, setRaps] = useState([]);
  const [selectedRap, setSelectedRap] = useState("");
  const [combustivel, setCombustivel] = useState("");
  const [combustivelLista, setCombustivelLista] = useState([]);
  const [chavedeouro, setChaveDeOuro] = useState("");
  const [chavedeouroLista, setChaveDeOuroLista] = useState([]);

  const [showDetails, setShowDetails] = useState(false);


  const valorTotal = combustivelLista.reduce((total, item) => total + item.VALOR, 0);

  const usuario = JSON.parse(localStorage.getItem("user_token")).id;

  // Função para mostrar alertas
  const showAlert = (message, variant) => {
    enqueueSnackbar(message, { variant: variant });
  };

  const onSelectNavio = (e) => {
    const selectedValue = e.target.value;
    setSelectedNavio(selectedValue);
    getRapByOperacao(selectedValue);
  }

  const onSelectRap = (e) => {
    const codOperacao = e.target.value;
    setSelectedRap(codOperacao);
    getDadosCombustivel(codOperacao);
    getDadosChaveDeOuro(codOperacao);
  }

  const getPeriodo = (id) => {
    Axios.get(`https://hml.operacao.orion-op.com.br/api/periodos/gerais/${id}`).then((response) => {
      setPeriodo(response.data);
    });
  }

  const getRapByOperacao = (codNavio) => {
    Axios.get(`https://hml.operacao.orion-op.com.br/api/operacao/navio/${codNavio}`) // Ajustado para corresponder ao endpoint do backend
      .then((response) => {
        setRaps(response.data);
      })
      .catch((error) => {
        console.error("Erro ao buscar os RAPs: ", error);
        showAlert("Erro ao buscar os RAPs", "error");
      });
  }

  useEffect(() => {
    Axios.get("https://hml.operacao.orion-op.com.br/api/navio")
      .then((response) => {
        setNavios(response.data);
      })
      .catch((error) => {
        console.error("Erro ao buscar os navios: ", error);
        showAlert("Erro ao buscar os navios", "error");
      });
  }, []);

  const getDadosCombustivel = (codOperacao) => {
    Axios.get(`https://hml.operacao.orion-op.com.br/api/combustivel/operacao/${codOperacao}`)
      .then((response) => {
        setCombustivelLista(response.data);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados de combustível: ", error);
        showAlert("Erro ao buscar dados de combustível", "error");
      });
  }

  const getDadosChaveDeOuro = (codOperacao) => {
    Axios.get(`https://hml.operacao.orion-op.com.br/api/chavedeouro/${codOperacao}`)
      .then((response) => {
        setChaveDeOuroLista(response.data);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da Chave De Ouro: ", error);
        showAlert("Erro ao buscar dados da Chave De Ouro", "error");
      });
  }

  return (
    <>
      <Navbar veiculos />
      <Header />
      <Brackground />
      <Container>
        <div className={style.content}>

          <div className={style.nav}>
            <div className={style.navItem} onClick={() => navigate('/custeio/vigias')}>
              Vigias
            </div>

            <div className={style.navItem} onClick={() => navigate('/custeio/puxadas')}>
              Puxadas
            </div>

            <div className={style.active}>
              Custos de Navio
            </div>
          </div>

          <div className={style.columns}>
            <select
              className={style.navioDropdown}
              value={selectedNavio}
              onChange={onSelectNavio}
            >
              <option value="">Selecione um Navio</option>
              {navios.map((val, key) => (
                <option key={key} value={val.COD_NAVIO}>{val.NOME_NAVIO || "-"}</option>
              ))}
            </select>

            <select
              className={style.navioDropdown}
              value={selectedRap}
              onChange={onSelectRap}
            >
              <option value="">Selecione o RAP</option>
              {raps.map((rap, key) => (
                <option key={key} value={rap.COD_OPERACAO}>{rap.RAP}</option>
              ))}
            </select>

          </div>


          <div className={style.mainContent}>

            <div className={style.newColumn}>
              <div className={style.tableHeader}>
                <h2 className={style.tableTitle}>Combustível</h2>
                Valor Total: {valorTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
              </div>
              <table className={style.table}>
                <thead>
                  <tr>
                    <th>Cupom</th>
                    <th>Data</th>
                    <th>Litros</th>
                    <th>Gerador</th>
                    <th>Valor</th>
                  </tr>
                </thead>
                <tbody>
                  {combustivelLista.map((item, index) => (
                    <tr key={index}>
                      <td>{item.CUPOM}</td>
                      <td>{moment(item.DATA).format('DD/MM/YYYY')}</td>
                      <td>{item.LITROS} L</td>
                      <td>{item.DESC_EQUIPAMENTO_GERADOR.replace("GERADOR", "")}</td>
                      <td>{item.VALOR.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                    </tr>
                  ))}
                </tbody>


              </table>

              <h2 className={style.tableTitle}>OGMO</h2>
              <table className={style.table}>
                <thead>
                  <tr>
                    <th>Fornecedor</th>
                    <th>Data Início</th>
                    <th>Navio</th>
                    <th>Local</th>
                    <th>Moega</th>
                  </tr>
                </thead>
                <tbody>

                </tbody>

              </table>

              <h2 className={style.tableTitle}>Puxadas</h2>
              <table className={style.table}>
                <thead>
                  <tr>
                    <th>Fornecedor</th>
                    <th>Data Início</th>
                    <th>Local</th>
                    <th>Valor</th>
                  </tr>
                </thead>
                <tbody>

                </tbody>

              </table>
              <div className={style.tableHeader}>
                <h2 className={style.tableTitle}>Carregamento <button className={style.addButton} onClick={() => setShowDetails(!showDetails)}>
                  {showDetails ? "-" : "+"}
                </button></h2>
                Valor Total: {chavedeouroLista.reduce((acc, item) => acc + (item.TICKET_CHAVE_01 && item.TICKET_CHAVE_02 ? 60 : 30), 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
              </div>

              <table className={style.table}>
                <thead>
                  <tr>
                    <th>Tiquete 01</th>
                    <th>Tiquete 02</th>
                    <th>Placa CAVALO</th>
                    <th>Data e Hora</th>
                    <th>Valor</th>
                  </tr>
                </thead>
                {/* Visão resumida */}
                {!showDetails && (
                  <tbody>
                    <tr>
                      <td colSpan="5">
                        <strong>Tickets: </strong>{chavedeouroLista.length * 2} {/* Assumindo 2 tickets por carregamento */}
                        <strong> Carregamentos: </strong>{chavedeouroLista.length} Autos
                      </td>
                    </tr>
                  </tbody>
                )}

                {/* Visão detalhada */}
                {showDetails && (
                  <tbody>
                    {chavedeouroLista.map((item, index) => (
                      <tr key={index}>
                        <td>{item.TICKET_CHAVE_01 || '-'}</td>
                        <td>{item.TICKET_CHAVE_02 || '-'}</td>
                        <td>{item.PLACA_CAVALO || '-'}</td>
                        <td>{item.DATA_CARREGAMENTO ? moment(item.DATA_CARREGAMENTO).format('DD/MM/YY HH:mm') : '-'}</td>
                        <td>{
                          (item.TICKET_CHAVE_01 && item.TICKET_CHAVE_02) ? 'R$ 60,00' :
                            (item.TICKET_CHAVE_01 || item.TICKET_CHAVE_02) ? 'R$ 30,00' :
                              'R$ 0,00'
                        }</td>
                      </tr>
                    ))}
                  </tbody>
                )}

              </table>

              <h2 className={style.tableTitle}>Insumos</h2>
              <table className={style.table}>
                <thead>
                  <tr>
                    <th>Tipo</th>
                    <th>Data da Compra</th>
                    <th>Quantidade</th>
                    <th>Valor</th>
                  </tr>
                </thead>
                <tbody>

                </tbody>

              </table>
            </div>

            <div className={style.custos}>
              <img src={Navio} alt="Logo" className={style.logoImage} />
            </div>

          </div>
        </div>

      </Container>
    </>
  );
};

export default function IntegrationNotistack() {
  return (
    <SnackbarProvider
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      maxSnack={3}
      autoHideDuration={2500}
    >
      <CustosNavio />
    </SnackbarProvider>
  );
}
