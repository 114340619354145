import React from "react";
import { useEffect, useState } from 'react';
import Axios from 'axios';
import { Navigate, useNavigate } from "react-router-dom";
import { SnackbarProvider, useSnackbar } from 'notistack';
import Navbar from "../../../components/Navbar";
import Brackground from "../../../components/Background";
import Container from "../../../components/Container";
import Header from "../../../components/Header";
import Input from "../../../components/Input";
import SubmitButton from "../../../components/Button";
import style from "./CadastroNavio.module.css";
import MaskedInput from "../../../components/InputMask";

const CadastroNavio = () => {

  const navigate = useNavigate();

  const [nome, setNome] = useState("");
  const [imo, setImo] = useState("");
  const [bandeira, setBandeira] = useState("");
  const [loa, setLoa] = useState("");
  const [callSign, setCallSign] = useState("");
  const [aisTransponder, setAisTransponder] = useState("");
  const [bridge, setBridge] = useState("");
  const [detailedVesselType, setDetailedVesselType] = useState("");
  const [cargoHolds, setCargoHolds] = useState("");
  const [gear, setGear] = useState("");


  const usuario = JSON.parse(localStorage.getItem("user_token")).id;

  const [values, setValues] = useState({});
  function handleChange(event) {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  }


  const addNavio = () => {
    const payload = {
      nome: nome,
      imo: imo,
      bandeira: bandeira,
      status: 'AGUARDANDO INÍCIO DA VIAGEM',
      usuario: usuario,
    };

    // Add fields to payload only if they are provided
    if (loa) payload.loa = loa;
    if (callSign) payload.callSign = callSign.toUpperCase(); // Assuming you want to ensure uppercase
    if (aisTransponder) payload.aisTransponder = aisTransponder.toUpperCase(); // Assuming you want to ensure uppercase
    if (bridge) payload.bridge = bridge;
    if (detailedVesselType) payload.detailedVesselType = detailedVesselType;
    if (cargoHolds) payload.cargoHolds = parseInt(cargoHolds, 10);
    if (gear) payload.gear = gear;

    Axios.post('https://hml.operacao.orion-op.com.br/api/navio/criar', payload)
      .then(function (res) {
        console.log(res);
        res.data.sqlMessage ?
          showAlert(res.data.sqlMessage, 'error') :
          showAlert('Navio cadastrado com sucesso!', 'success');
        setTimeout(() => {
          navigate("/navios");
        }, 2000);
      });
  };

  const { enqueueSnackbar } = useSnackbar();
  const showAlert = (txt, variant) => {
    enqueueSnackbar(txt, { variant: variant });
  }

  const validaDados = () => {
    if (!nome | !imo | !bandeira) {
      showAlert('Preencha todos os campos!', 'error')
      return;
    }
    if (imo.length < 7) {
      showAlert('Imo deve conter 8 digitos!', 'error')
      return;
    }
    addNavio();
  }

  return (
    <>
      <Navbar navios />
      <Header />
      <Brackground />
      <Container>
        <div className={style.content}>
          <div className={style.nav}>
            <div onClick={() => navigate("/navios")}>
              Navios
            </div>
            <div className={style.active}>
              Cadastrar Navio
            </div>
          </div>

          <div className={style.columns}>

            <div className={style.column3}>
              <Input
                type={"text"}
                text={"Nome do navio"}
                onChange={(e) => [setNome(e.target.value.toUpperCase())]}
              />
            </div>
            <div className={style.column3}>
              <MaskedInput
                text={'IMO'}
                name={'IMO'}
                mask={'99999999'}
                value={values.Imo}
                placeholder={'0000000'}
                onChange={(e) => [setImo(e.target.value.toUpperCase())]}
              />
            </div>

            <div className={style.column3}>
              <Input
                type={"text"}
                text={"Bandeira"}
                placeholder={"ex: China"}
                onChange={(e) => [setBandeira(e.target.value.toUpperCase())]}
              />
            </div>

            <div className={style.column3}>
              <Input
                type={"text"}
                text={"LOA (m)"}
                placeholder={"Comprimento total"}
                onChange={(e) => [setLoa(e.target.value.replace(',', '.'))]}
              />
            </div>

            <div className={style.column3}>
              <Input
                type={"text"}
                text={"Call Sign"}
                placeholder={"Enter Call Sign"}
                onChange={(e) => setCallSign(e.target.value.toUpperCase())} // Assuming you want the call sign in uppercase
              />
            </div>

          </div>
          <div className={style.columns}>

            <div className={style.column3}>
              <Input
                type={"text"}
                text={"AIS Transponder"}
                placeholder={"Enter AIS Transponder Code"}
                onChange={(e) => setAisTransponder(e.target.value.toUpperCase())} // Assuming you might want this in uppercase too
              />
            </div>

            <div className={style.column3}>
              <Input
                type={"text"}
                text={"Bridge (m)"}
                placeholder={"Enter Bridge Height"}
                onChange={(e) => setBridge(e.target.value.replace(',', '.'))}
              />
            </div>

            <div className={style.column3}>
              <label>Detailed Vessel Type</label>
              <div className="select is-fullwidth">
                <select
                  value={detailedVesselType}
                  onChange={(e) => setDetailedVesselType(e.target.value)}
                >
                  <option value="">Selecione Vessel Type</option>
                  <option value="Container Ship">Container Ship</option>
                  <option value="Bulk Carrier">Bulk Carrier</option>
                  <option value="Tanker">Tanker</option>
                  <option value="Gas Carrier">Gas Carrier</option>
                  <option value="Chemical Tanker">Chemical Tanker</option>
                  <option value="Outros">Outros</option>
                </select>
              </div>
            </div>

            <div className={style.column3}>
              <Input
                type="number"
                text="Cargo Holds"
                placeholder="Enter number of Cargo Holds"
                min="0" // Assuming zero is an acceptable value; adjust as needed
                onChange={(e) => setCargoHolds(e.target.value)}
              />
            </div>

            <div className={style.column3}>
              <Input
                type="number"
                text="Gear"
                onChange={(e) => setGear(e.target.checked)}
              />
            </div>

          </div>
          <div className="columns">
            <div className="column is-5">
              <SubmitButton text={"Cadastrar"} onClick={validaDados} />
            </div>
          </div>
        </div>

      </Container>
    </>
  );
};

export default function IntegrationNotistack() {
  return (
    <SnackbarProvider
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      maxSnack={3}
      autoHideDuration={2500}>
      <CadastroNavio />
    </SnackbarProvider >
  );
}