import { BrowserRouter, Route, Routes } from "react-router-dom";

import AberturaPeriodo from "../pages/operacao/AberturaPeriodo";
import BuscarMotorista from "../pages/veiculos/BuscarMotorista";
import MotivacaoMotorista from "../pages/veiculos/MotivacaoMotorista";
import BuscarMotoristaAgendamento from "../pages/Agendamento/BuscarMotoristaAgendamento";
import BuscarMotoristaPlaca from "../pages/veiculos/BuscarMotoristaPlaca";
import Motivados from "../pages/motivacao/Motivados";
import Forms from "../pages/SegTrabalho/Forms";
import PageSeg from "../pages/SegTrabalho/PageSeg";
import LaudosEquipamentos from "../pages/SegTrabalho/LaudosEquipamentos";
import CestoDeResgate from "../pages/SegTrabalho/CestoDeResgate";
import FluxogramaAtendimento from "../pages/SegTrabalho/FluxogramaAtendimento";
import HistoricoForms from "../pages/SegTrabalho/HistoricoForms";
import PosicaoNavios from "../pages/LineUp/PosicaoNavios";
import Vigias from "../pages/Custeio/Vigias";
import CustosNavio from "../pages/Custeio/CustosNavio";
import Combustivel from "../pages/Suprimentos/Combustivel";
import DashboardSuprimentos from "../pages/Suprimentos/DashboardSuprimentos";
import Cotacao from "../pages/Suprimentos/Cotacao";
import FichaRegistro from "../pages/RecursosHumanos/FichaRegistro";
import Puxadas from "../pages/Custeio/Puxadas";
import Equipamentos from "../pages/manutencao/Equipamentos";
import RelatarEquipamentos from "../pages/manutencao/RelatarEquipamentos";
import CadastroCarga from "../pages/cargas/CadastroCarga";
import CadastroMotorista from "../pages/veiculos/CadastroMotorista";
import CadastroMotoristaAgendamento from "../pages/Agendamento/CadastroMotoristaAgendamento";
import CadastroMotivado from "../pages/motivacao/CadastroMotivado";
import HistoricoMotivacao from "../pages/motivacao/HistoricoMotivacao";
import CadastroNavio from "../pages/navios/CadastroNavio";
import CadastroOperacao from "../pages/navios/CadastroOperacao";
import Cargas from "../pages/cargas/Cargas";
import Dashboard from "../pages/Dashboard";
import EmAndamento from "../pages/operacao/EmAndamento";
import { Fragment } from "react";
import GraficoDocs from "../pages/operacao/GraficoDocs";
import Login from "../pages/Login";
import Navios from "../pages/navios/Navios";
import Operacao from "../pages/operacao/Operacao";
import PesagemFinal from "../pages/operacao/PesagemFinal";
import PesagemInicial from "../pages/veiculos/PesagemInicial";
import AgendamentoPesagem from "../pages/Agendamento/AgendamentoPesagem";
import DashboardAgendamento from "../pages/Agendamento/DashboardAgendamento";
import RelatorioPeriodo from "../pages/operacao/RelatorioPeriodo"
import RelatorioSetPort from "../pages/operacao/RelatorioSetPort"
import AnaliseOperacional from "../pages/operacao/AnaliseOperacional"
import IDFA from "../pages/operacao/IDFA"
import RelatorioOperacao from "../pages/RelatorioOperacao"
import Relatorios from "../pages/Relatorios";
import Administrador from "../pages/Administrador";
import Yara from "../pages/Yara";
import Integracao from "../pages/Integracao";
import Fertigran from "../pages/Fertigran";
import Terrena from "../pages/Terrena";
import Adufertil from "../pages/Adufertil";
import Cibrafertil from "../pages/Cibrafertil";
import Iterum from "../pages/Iterum";
import Araguaia from "../pages/Araguaia";
import Suporte from "../pages/Suporte";
import useAuth from "../hooks/useAuth";

//recebe item, no caso Home
const Private = ({ Item }) => {
    const { signed } = useAuth();

    //verifica se esta logado encaminha para o item que passou no parametro, no caso Home
    return signed > 0 ? <Item /> : <Login />;
};

const RoutesApp = () => {
    return (
        <BrowserRouter>
            <Fragment>
                <Routes>
                    <Route exact path="/navios" element={<Private Item={Navios} />} />
                    <Route exact path="/navios/cadastro" element={<Private Item={CadastroNavio} />} />
                    <Route exact path="/operacao/cadastro/:nome/:id" element={<Private Item={CadastroOperacao} />} />
                    <Route exact path="/cargas/cadastro/:nome/:id" element={<Private Item={CadastroCarga} />} />
                    <Route exact path="/cargas" element={<Private Item={Cargas} />} />
                    <Route exact path="/operacoes" element={<Private Item={EmAndamento} />} />
                    <Route exact path="/operacao/:id" element={<Private Item={Operacao} />} />
                    <Route exact path="/operacao/:id/aberturaperiodo" element={<Private Item={AberturaPeriodo} />} />
                    <Route exact path="/relatorios/:id" element={<Private Item={RelatorioPeriodo} />} />
                    <Route exact path="/relatorios/analiseoperacional/:id" element={<Private Item={AnaliseOperacional} />} />
                    <Route exact path="/relatorios/idfa/:id" element={<Private Item={IDFA} />} />
                    <Route exact path="/relatorios/operacao/:id" element={<Private Item={RelatorioOperacao} />} />
                    <Route exact path="/relatoriossetport/:id" element={<Private Item={RelatorioSetPort} />} />
                    <Route exact path="/operacao/GraficoDocs/:id" element={<Private Item={GraficoDocs} />} />
                    <Route exact path="/dashboard/:id" element={<Private Item={Dashboard} />} />
                    <Route exact path="/relatorios" element={<Private Item={Relatorios} />} />
                    <Route exact path="/administrador" element={<Private Item={Administrador} />} />
                    <Route exact path="/yara" element={<Private Item={Yara} />} />
                    <Route exact path="/integracao" element={<Private Item={Integracao} />} />
                    <Route exact path="/fertigran" element={<Private Item={Fertigran} />} />
                    <Route exact path="/terrena" element={<Private Item={Terrena} />} />
                    <Route exact path="/Cibrafertil" element={<Private Item={Cibrafertil} />} />
                    <Route exact path="/Adufertil" element={<Private Item={Adufertil} />} />
                    <Route exact path="/Iterum" element={<Private Item={Iterum} />} />
                    <Route exact path="/Araguaia" element={<Private Item={Araguaia} />} />
                    <Route exact path="/suporte" element={<Private Item={Suporte} />} />
                    <Route exact path="/veiculos/" element={<Private Item={CadastroMotorista} />} />
                    <Route exact path="/agendamento/cadastromotoristaagendamento" element={<Private Item={CadastroMotoristaAgendamento} />} />
                    <Route exact path="/agendamento/dashboardagendamento" element={<Private Item={DashboardAgendamento} />} />
                    <Route exact path="/motivacao/cadastro" element={<Private Item={CadastroMotivado} />} />
                    <Route exact path="/segtrabalho/forms" element={<Private Item={Forms} />} />
                    <Route exact path="/segtrabalho/historicoforms" element={<Private Item={HistoricoForms} />} />
                    <Route exact path="/segtrabalho/pageseg" element={<Private Item={PageSeg} />} />
                    <Route exact path="/segtrabalho/laudosequipamentos" element={<Private Item={LaudosEquipamentos} />} />
                    <Route exact path="/segtrabalho/cestoderesgate" element={<Private Item={CestoDeResgate} />} />
                    <Route exact path="/segtrabalho/fluxogramaatendimento" element={<Private Item={FluxogramaAtendimento} />} />
                    <Route exact path="/motivacao/historicomotivacao" element={<Private Item={HistoricoMotivacao} />} />
                    <Route exact path="/veiculos/pesageminicial/:nome/:cpf/:cnh/:id" element={<Private Item={PesagemInicial} />} />
                    <Route exact path="/Agendamento/AgendamentoPesagem/:nome/:cpf/:cnh/:id" element={<Private Item={AgendamentoPesagem} />} />
                    <Route exact path="/operacao/pesagemfinal/:id" element={<Private Item={PesagemFinal} />} />
                    <Route exact path="/veiculos/buscarmotorista" element={<Private Item={BuscarMotorista} />} />
                    <Route exact path="/veiculos/motivacaomotorista" element={<Private Item={MotivacaoMotorista} />} />
                    <Route exact path="/agendamento/buscarmotoristaagendamento" element={<Private Item={BuscarMotoristaAgendamento} />} />
                    <Route exact path="/veiculos/buscarmotoristaplaca" element={<Private Item={BuscarMotoristaPlaca} />} />
                    <Route exact path="/manutencao/equipamentos" element={<Private Item={Equipamentos} />} />
                    <Route exact path="/manutencao/relatarequipamentos" element={<Private Item={RelatarEquipamentos} />} />                                    
                    <Route exact path="/motivacao/motivados" element={<Private Item={Motivados} />} />
                    <Route exact path="/lineup/posicaonavios" element={<Private Item={PosicaoNavios} />} />
                    <Route exact path="/custeio/vigias" element={<Private Item={Vigias} />} />
                    <Route exact path="/recursoshumanos/ficharegistro" element={<Private Item={FichaRegistro} />} />    
                    <Route exact path="/custeio/puxadas" element={<Private Item={Puxadas} />} />
                    <Route exact path="/custeio/custosnavio" element={<Private Item={CustosNavio} />} />   
                    <Route exact path="/suprimentos/combustivel" element={<Private Item={Combustivel} />} />
                    <Route exact path="/suprimentos/dashboardsuprimentos" element={<Private Item={DashboardSuprimentos} />} />
                    <Route exact path="/suprimentos/cotacao" element={<Private Item={Cotacao} />} />         
                    <Route path="/" element={<Private Item={Dashboard} />} />
                    <Route path="*" element={<Private Item={Dashboard} />} />
                </Routes>
            </Fragment>
        </BrowserRouter>
    );
};

export default RoutesApp;