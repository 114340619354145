import React, { useEffect, useState } from "react";
import Axios from 'axios';
import Navbar from "../../../components/Navbar";
import { SnackbarProvider, useSnackbar } from 'notistack';
import Background from "../../../components/Background";
import Container from "../../../components/Container";
import SubmitButton from "../../../components/Button";
import Header from "../../../components/Header";
import Dialog from '@mui/material/Dialog';
import Input from "../../../components/Input";
import { useNavigate } from "react-router-dom";
import style from "./Cargas.module.css";
import modal from "./Modal.module.css";
import modal2 from "./Modal2.module.css";
import confirm from "./Confirm.module.css";
import { format } from 'date-fns';
import Popa from './Popa.png';
import Proa from './Proa.png';
import { AiFillEdit } from 'react-icons/ai'; // Importa o ícone de lápis


const Cargas = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [operacoesList, setOperacoesList] = useState([]);
  const [cargas, setCargas] = useState([]);
  const [pedidos, setPedidos] = useState([]);
  const [i, setI] = useState(0);
  const [date, setDate] = useState("");
  const [ideta, setIdeta] = useState("");
  const [idet, setIdet] = useState("");
  const [dataeta, setDataeta] = useState("");
  const [activeTab, setActiveTab] = useState(1); // Inicialize com 1 para começar com a aba "Cargas"
  const [cargasRelacionadas, setCargasRelacionadas] = useState([]);
  const [selectedCarga, setSelectedCarga] = useState('');
  const [selectedPedido, setSelectedPedido] = useState('');
  const [nrPedido, setNrPedido] = useState('');
  const [ManifestadoPedido, setManifestadoPedido] = useState(''); // Para a aba "Pedidos"
  const [kgDescarregadoBD, setKgDescarregadoBD] = useState('');
  const [nrTransportadora, setNrTransportadora] = useState('');
  const [pedidoData, setPedidoData] = useState([]); // Estado para armazenar os dados do pedido
  const [showPedidoData, setShowPedidoData] = useState(false);
  const [selectedNavio, setSelectedNavio] = useState("");
  const [numeroPoroes, setNumeroPoroes] = useState(0);
  const [selectedImportador, setSelectedImportador] = useState('');
  const [destinos, setDestinos] = useState([]);
  const [selectedDestino, setSelectedDestino] = useState('');
  const [pedidosPorCarga, setPedidosPorCarga] = useState([]);
  const [selectedCargaDetails, setSelectedCargaDetails] = useState(null);
  const [colorHex, setColorHex] = useState('#ffffff'); // Inicializa com branco ou qualquer outra cor padrão
  const [editingIndex, setEditingIndex] = useState(null);
  const [newDescarregadoBD, setNewDescarregadoBD] = useState('');
  const [file, setFile] = useState(null); // Estado para armazenar o arquivo PDF
  const [listaDocumentoDoc, setListaDocumentoDoc] = useState([]); // Estado para armazenar os dados do Break Down
  const [selectedDocumento, setSelectedDocumento] = useState(''); // Estado para o produto selecionado
  const [listaDocumento, setListaDocumento] = useState([]); // Estado para armazenar a Lista de Documentos
  const [isIntegrating, setIsIntegrating] = useState(false);
  const [countdown, setCountdown] = useState(30);

  const [editingDates, setEditingDates] = useState({
    EOSP: false,
    NOR_TENDERED: false,
    PILOT_ON_BOARD_FOR_BERTHING: false,
    COMPLETED_DISCHARGE_OPERATIONS: false,
    FIRST_LINE_ASHORE: false,
    NOR_ACCEPTED: false,
    GANGWAY_SECURED_ASHORE: false,
    COMMENCED_DISCHARGE_OPERATION: false,
  });

  // Estado para armazenar as datas editáveis
  const [editedDates, setEditedDates] = useState({
    EOSP: cargas[0]?.EOSP || '',
    NOR_TENDERED: cargas[0]?.NOR_TENDERED || '',
    PILOT_ON_BOARD_FOR_BERTHING: cargas[0]?.PILOT_ON_BOARD_FOR_BERTHING || '',
    COMPLETED_DISCHARGE_OPERATIONS: cargas[0]?.COMPLETED_DISCHARGE_OPERATIONS || '',
    FIRST_LINE_ASHORE: cargas[0]?.FIRST_LINE_ASHORE || '',
    NOR_ACCEPTED: cargas[0]?.NOR_ACCEPTED || '',
    GANGWAY_SECURED_ASHORE: cargas[0]?.GANGWAY_SECURED_ASHORE || '',
    COMMENCED_DISCHARGE_OPERATION: cargas[0]?.COMMENCED_DISCHARGE_OPERATION || ''
  });

  const usuario = JSON.parse(localStorage.getItem("user_token")).id;

  const handlePoroesChange = (e) => {
    setNumeroPoroes(Number(e.target.value));
  };

  const handleTabChange = (newValue) => {
    setActiveTab(newValue);
    if (newValue === 4) { // Se a aba "Break Down" for selecionada
      getBreakDownData(i.COD_OPERACAO);
    } else if (newValue === 5) { // Se a aba "Documentos" for selecionada
      getListaDocumentoDoc(i.COD_OPERACAO);
    }
  };

  useEffect(() => {
    getOperacoes();
    getDestinos();
    getOperadoresPortuarios();
    getClientes();
    getProdutos();
    getListaDocumento();
  }, []);

  const getOperacoes = () => {
    Axios.get('https://hml.operacao.orion-op.com.br/api/operacao').then((response) => {
      setOperacoesList(response.data);
    });
  }

  const redirectToViagem = () => {
    if (selectedNavio) {
      navigate(`/operacao/${selectedNavio}`);
    } else {
      showAlert("Por favor, selecione um navio primeiro", "error");
    }
  };

  const handlePedidoChange = (e) => {
    const selectedCarga = e.target.value;
    setSelectedPedido(selectedPedido);

    if (selectedPedido) {
      loadTransportadoraData(selectedPedido);
      setShowPedidoData(true);
    } else {
      setShowPedidoData(false);
    }
  };

  const getCargas = (id) => {
    Axios.get(`https://hml.operacao.orion-op.com.br/api/carga/busca/${id}`).then(function (res) {
      console.log(res.data);
      setCargas(res.data);
      setIdeta(id);
    });
  }

  const getPedidos = (id) => {
    Axios.get(`https://hml.operacao.orion-op.com.br/api/carga/pedido/${id}`).then(function (res) {
      console.log(res.data);
      setPedidos(res.data);
      setIdet(id);
    });
  }

  const validaEta = async () => {
    await Axios.put('https://hml.operacao.orion-op.com.br/api/alterar/eta', {
      id: ideta,
      eta: dataeta,
    }).then(function (res) {
      res.data.sqlMessage
        ? showAlert(res.data.sqlMessage, 'error')
        : showAlert('Data ETA alterada com sucesso!', 'success');
    });
  }

  const [openA, setOpenA] = useState(false);

  const DetalhesOp = () => {
    setOpenA(true);
  };

  const FecharDetalhesOp = () => {
    setOpenA(false);
  };

  const DetalharOp = (index) => {
    setI(operacoesList[index]);
    setPedidoData([]); // Limpar o estado da lista de pedidos
  };

  const [openB, setOpenB] = useState(false);

  const AbrirConfirm = () => {
    FecharDetalhesOp();
    setOpenB(true);
  };

  const FecharConfirm = () => {
    setOpenB(false);
  };

  const showAlert = (txt, variant) => {
    enqueueSnackbar(txt, { variant: variant });
  };

  const registrarAtracacao = () => {
    if (date === "") {
      showAlert("Preencha a data e horário!", 'error');
      return;
    }
    Axios.put('https://hml.operacao.orion-op.com.br/api/operacao/registrar/atracacao', {
      id: i.COD_OPERACAO,
      date: date,
    }).then(function (res) {
      res.data.sqlMessage
        ? showAlert(res.data.sqlMessage, 'error')
        : showAlert('Atracação registrada com sucesso!', 'success');
      FecharConfirm();
      getOperacoes();
      setDate("");
    });
  };

  const handleItemClick = () => {
    setActiveTab(1);
  };

  const handlePedidoSubmit = async (e) => {
    e.preventDefault();

    if (!selectedCarga || !nrPedido || !selectedDestino) {
      showAlert("Preencha todos os campos antes de adicionar um pedido.", 'error');
      return;
    }

    // Faça uma solicitação para adicionar o pedido ao banco de dados
    try {
      const response = await Axios.post('https://hml.operacao.orion-op.com.br/api/cargas/pedido', {
        codOperacao: i.COD_OPERACAO, // Suponho que você deseje usar o COD_OPERACAO da operação atual
        nrPedido: nrPedido,
        ManifestadoPedido: ManifestadoPedido,
        codCarga: selectedCarga,
        colorHex: colorHex,
        codDestino: selectedDestino,
      });

      if (response.status === 200) {
        showAlert('Pedido adicionado com sucesso!', 'success');
        // Limpar campos ou fazer outras ações necessárias após a inserção
        setNrPedido('');
        setManifestadoPedido('');
        setSelectedCarga('');
        setSelectedDestino(''); // Reseta a seleção do destino
        setColorHex('#ffffff'); // Reseta a seleção da cor
        loadPedidoData(selectedCarga); // Carregar dados do pedido com base no COD_OPERACAO e COD_CARGA
      } else {
        showAlert('Erro ao adicionar o pedido.', 'error');
      }
    } catch (error) {
      showAlert('Erro ao adicionar o pedido.', 'error');
      console.error(error);
    }
  };

  const handleTransportadoraSubmit = async (e) => {
    e.preventDefault();

    if (!selectedCarga || !nrPedido) {
      showAlert("Preencha todos os campos antes de adicionar um pedido.", 'error');
      return;
    }

    // Faça uma solicitação para adicionar o pedido ao banco de dados
    try {
      const response = await Axios.post('https://hml.operacao.orion-op.com.br/api/cargas/pedido', {
        codOperacao: i.COD_OPERACAO, // Suponho que você deseje usar o COD_OPERACAO da operação atual
        nrPedido: nrPedido,
        codCarga: selectedCarga,
      });

      if (response.status === 200) {
        showAlert('Pedido adicionado com sucesso!', 'success');
        // Limpar campos ou fazer outras ações necessárias após a inserção
        setNrPedido('');
        setManifestadoPedido('');
        setSelectedCarga('');
        loadPedidoData(selectedCarga); // Carregar dados do pedido com base no COD_OPERACAO e COD_CARGA
      } else {
        showAlert('Erro ao adicionar o pedido.', 'error');
      }
    } catch (error) {
      showAlert('Erro ao adicionar o pedido.', 'error');
      console.error(error);
    }
  };

  // Função para carregar dados do pedido com base no COD_OPERACAO e COD_CARGA
  const loadPedidoData = async (codCarga) => {
    try {
      const response = await Axios.get(`https://hml.operacao.orion-op.com.br/api/cargas/pedido/${codCarga}`);
      if (response.status === 200) {
        setPedidoData(response.data);
      } else {
        showAlert('Erro ao carregar os dados do pedido.', 'error');
      }
    } catch (error) {
      showAlert('Erro ao carregar os dados do pedido.', 'error');
      console.error(error);
    }
  };

  // Função para carregar dados do pedido com base no COD_OPERACAO e COD_CARGA
  const loadTransportadoraData = async (codCarga) => {
    try {
      const response = await Axios.get(`https://hml.operacao.orion-op.com.br/api/cargas/pedido/${codCarga}`);
      if (response.status === 200) {
        setPedidoData(response.data);
      } else {
        showAlert('Erro ao carregar os dados do pedido.', 'error');
      }
    } catch (error) {
      showAlert('Erro ao carregar os dados do pedido.', 'error');
      console.error(error);
    }
  };

  const handleNavioChange = (e) => {
    setSelectedNavio(e.target.value);
  };

  const handleDocViagemClick = () => {
    const selectedOperationIndex = operacoesList.findIndex(op => String(op.COD_OPERACAO) === selectedNavio);

    if (selectedOperationIndex === -1) {
      showAlert("Por favor, selecione um navio válido", "error");
      return;
    }

    const selectedOperation = operacoesList[selectedOperationIndex];
    getCargoHolds(selectedOperation.COD_OPERACAO); // Chama a função para obter o número de porões
    DetalhesOp();
    DetalharOp(selectedOperationIndex);
    getCargas(selectedOperation.COD_OPERACAO);
    getPedidos(selectedOperation.COD_OPERACAO);
    setActiveTab(1);
  };


  const getDestinos = () => {
    Axios.get('https://hml.operacao.orion-op.com.br/api/destinos')
      .then((response) => {
        setDestinos(response.data);
      })
      .catch((error) => {
        console.error('Erro ao buscar destinos:', error);
        showAlert('Erro ao carregar destinos.', 'error');
      });
  };

  const getPedidosPorCarga = (idCarga) => {
    Axios.get(`https://hml.operacao.orion-op.com.br/api/pedido/carga/${idCarga}`)
      .then((response) => {
        setPedidosPorCarga(response.data);
      })
      .catch((error) => {
        console.error('Erro ao buscar pedidos por carga:', error);
        showAlert('Erro ao carregar pedidos por carga.', 'error');
      });
  };

  const handleCargaChange = (e) => {
    const selectedCargaId = e.target.value;
    setSelectedCarga(selectedCargaId);

    const selectedCargaObj = cargas.find(carga => carga.COD_CARGA.toString() === selectedCargaId);
    if (selectedCargaObj) {
      setSelectedCargaDetails(selectedCargaObj);
      setSelectedImportador(selectedCargaObj.IMPORTADOR);
      loadPedidoData(selectedCargaId);
      setShowPedidoData(true);
    } else {
      setShowPedidoData(false);
      setSelectedImportador('');
      setSelectedCargaDetails(null);
    }

    getPedidosPorCarga(selectedCargaId);
  };

  const [poraoData, setPoraoData] = useState([]); // Inicializa como um array vazio

  const getPoraoData = (codOperacao) => {
    Axios.get(`https://hml.operacao.orion-op.com.br/api/porao/${codOperacao}`)
      .then((response) => {
        if (response.data) {
          console.log('Dados recebidos do porão:', response.data); // Adiciona o log aqui
          setPoraoData(response.data[0]); // Armazena os dados no estado poraoData (note que estou pegando o primeiro item do array)
        }
      })
      .catch((error) => {
        console.error('Erro ao buscar dados do Porão:', error);
      });
  };

  const [poraoDescarregado, setPoraoDescarregado] = useState([]); // Inicializa como um array vazio

  // Função para buscar dados de descarregamento por porão no backend
  const getPoraoDescarregado = (codOperacao) => {
    Axios.get(`https://hml.operacao.orion-op.com.br/api/porao/descarregado/${codOperacao}`)
      .then((response) => {
        if (response.data) {
          console.log('Dados recebidos do porão:', response.data); // Verifique se os dados estão sendo recebidos corretamente
          setPoraoDescarregado(response.data); // Salve os dados recebidos no estado
        }
      })
      .catch((error) => {
        console.error('Erro ao buscar dados do Porão:', error);
      });
  };


  useEffect(() => {
    if (i.COD_OPERACAO) {
      getPoraoDescarregado(i.COD_OPERACAO); // Passa o código da operação para buscar os dados ao carregar o componente
    }
  }, [i.COD_OPERACAO]);


  useEffect(() => {
    if (i.COD_OPERACAO) {
      getPoraoData(i.COD_OPERACAO); // Passa o código da operação para buscar os dados ao carregar o componente
    }
  }, [i.COD_OPERACAO]);

  const [breakDownData, setBreakDownData] = useState([]); // Estado para armazenar os dados do Break Down

  const getBreakDownData = (codOperacao) => {
    Axios.get(`https://hml.operacao.orion-op.com.br/api/breakdown/operacao/${codOperacao}`)
      .then((response) => {
        setBreakDownData(response.data);
      })
      .catch((error) => {
        console.error('Erro ao buscar dados do Break Down:', error);
        showAlert('Erro ao carregar dados do Break Down.', 'error');
      });
  };

  const [operadoresPortuarios, setOperadoresPortuarios] = useState([]); // Estado para armazenar os operadores portuários

  const getOperadoresPortuarios = () => {
    Axios.get('https://hml.operacao.orion-op.com.br/api/operadorportuario').then((response) => {
      setOperadoresPortuarios(response.data);
    }).catch((error) => {
      console.error('Erro ao buscar operadores portuários:', error);
      showAlert('Erro ao carregar operadores portuários.', 'error');
    });
  };

  const [selectedOperadorPortuario, setSelectedOperadorPortuario] = useState(''); // Estado para o operador portuário selecionado

  const handleOperadorPortuarioChange = (e) => {
    setSelectedOperadorPortuario(e.target.value);
  };

  const [clientes, setClientes] = useState([]); // Estado para armazenar os clientes

  const getClientes = () => {
    Axios.get('https://hml.operacao.orion-op.com.br/api/clientes').then((response) => {
      setClientes(response.data);
    }).catch((error) => {
      console.error('Erro ao buscar clientes:', error);
      showAlert('Erro ao carregar clientes.', 'error');
    });
  };

  const [selectedCliente, setSelectedCliente] = useState(''); // Estado para o cliente selecionado

  const handleClienteChange = (e) => {
    setSelectedCliente(e.target.value);
  };

  const [produtos, setProdutos] = useState([]); // Estado para armazenar os produtos

  const getProdutos = () => {
    Axios.get('https://hml.operacao.orion-op.com.br/api/produtos').then((response) => {
      setProdutos(response.data);
    }).catch((error) => {
      console.error('Erro ao buscar produtos:', error);
      showAlert('Erro ao carregar produtos.', 'error');
    });
  };

  const [selectedProduto, setSelectedProduto] = useState(''); // Estado para o produto selecionado
  const [produtoSelecionado, setProdutoSelecionado] = useState([]); // Estado para armazenar o produto selecionado em cada coluna

  // Função para lidar com a mudança de seleção de produtos
  const handleProdutoChange = (e, poraoIndex) => {
    const novoProdutoSelecionado = [...produtoSelecionado];
    novoProdutoSelecionado[poraoIndex] = e.target.value;
    setProdutoSelecionado(novoProdutoSelecionado);
  };

  const [kgManifestadoBD, setKgManifestadoBD] = useState(''); // Para a aba "Break Down"

  const handleBreakDownSubmit = async (e) => {
    e.preventDefault();

    if (!selectedOperadorPortuario || !selectedCliente || !selectedProduto || !kgManifestadoBD || !kgDescarregadoBD) {
      showAlert("Preencha todos os campos antes de adicionar um Break Down.", 'error');
      return;
    }

    try {
      const response = await Axios.post('https://hml.operacao.orion-op.com.br/api/breakdown/adicionar', {
        codOperacao: i.COD_OPERACAO,
        codOperadorPortuario: selectedOperadorPortuario,
        codCliente: selectedCliente,
        codProduto: selectedProduto,
        qtdeManifestadaBD: kgManifestadoBD,
        qtdeDescarregadaBD: kgDescarregadoBD
      });

      if (response.status === 200) {
        showAlert('Break Down adicionado com sucesso!', 'success');
        // Limpar campos após a inserção
        setSelectedOperadorPortuario('');
        setSelectedCliente('');
        setSelectedProduto('');
        setKgManifestadoBD('');
        setKgDescarregadoBD('');
        getBreakDownData(i.COD_OPERACAO); // Atualizar os dados do Break Down
      } else {
        showAlert('Erro ao adicionar o Break Down.', 'error');
      }
    } catch (error) {
      showAlert('Erro ao adicionar o Break Down.', 'error');
      console.error(error);
    }
  };

  const handleEditClick = (index, currentDescarregadoBD) => {
    setEditingIndex(index);
    setNewDescarregadoBD(currentDescarregadoBD);
  };

  const handleSaveClick = async (index, breakDown) => {
    try {
      const response = await Axios.put('https://hml.operacao.orion-op.com.br/api/breakdown/atualizar', {
        id: breakDown.COD_BREAK_DOWN,
        qtdeDescarregadaBD: newDescarregadoBD,
      });

      if (response.status === 200) {
        showAlert('Quantidade descarregada atualizada com sucesso!', 'success');
        setEditingIndex(null);
        getBreakDownData(i.COD_OPERACAO);
      } else {
        showAlert('Erro ao atualizar a quantidade descarregada.', 'error');
      }
    } catch (error) {
      showAlert('Erro ao atualizar a quantidade descarregada.', 'error');
      console.error(error);
    }
  };

  const handleCancelEdit = () => {
    setEditingIndex(null);
    setNewDescarregadoBD('');
  };

  const getListaDocumentoDoc = (codOperacao) => {
    Axios.get(`https://hml.operacao.orion-op.com.br/api/carga/listadocumento/${codOperacao}`)
      .then((response) => {
        setListaDocumentoDoc(response.data);
      })
      .catch((error) => {
        console.error('Erro ao buscar dados da lista de documento:', error);
        showAlert('Erro ao carregar dados da lista de documento.', 'error');
      });
  };

  const handleDocumentoChange = (e) => {
    setSelectedDocumento(e.target.value);
  };

  const getListaDocumento = () => {
    Axios.get('https://hml.operacao.orion-op.com.br/api/listadocumento').then((response) => {
      setListaDocumento(response.data);
    }).catch((error) => {
      console.error('Erro ao buscar a lista de documentos:', error);
      showAlert('Erro ao carregar a lista de documentos.', 'error');
    });
  };

  const handleDownload = (nomeArquivo) => {
    const url = `https://hml.operacao.orion-op.com.br/api/carga/documento/download/${nomeArquivo}`;

    fetch(url)
      .then((response) => {
        if (response.ok) return response.blob();
        throw new Error('Falha ao baixar o arquivo.');
      })
      .then((blob) => {
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', nomeArquivo);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(downloadUrl);
      })
      .catch((error) => {
        console.error('Erro no download:', error.message);
      });
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleDocumentSubmit = async (e) => {
    e.preventDefault();

    if (!selectedDocumento || !file) {
      showAlert("Preencha todos os campos antes de adicionar um documento.", 'error');
      return;
    }

    const usuario = JSON.parse(localStorage.getItem("user_token")).id; // Obtenha o usuário do localStorage
    const formData = new FormData();
    formData.append('file', file);
    formData.append('codListaDocumento', selectedDocumento);
    formData.append('codOperacao', i.COD_OPERACAO); // Use o valor correto de COD_OPERACAO
    formData.append('nomeNavio', i.NOME_NAVIO); // Use o valor correto de NOME_NAVIO
    formData.append('selectedDocumento', selectedDocumento); // Adiciona o nome do documento
    formData.append('usuarioDocumento', usuario); // Adiciona o usuário real ao FormData

    try {
      const response = await Axios.post('https://hml.operacao.orion-op.com.br/api/documento/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        showAlert('Documento adicionado com sucesso!', 'success');
        getListaDocumentoDoc(i.COD_OPERACAO);
      } else {
        showAlert('Erro ao adicionar o documento.', 'error');
      }
    } catch (error) {
      showAlert('Erro ao adicionar o documento.', 'error');
      console.error(error);
    }
  };


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return isNaN(date) ? 'Data inválida' : format(date, 'dd/MM/yyyy HH:mm');
  };

  const getCargoHolds = async (codOperacao) => {
    try {
      const response = await Axios.get(`https://hml.operacao.orion-op.com.br/api/operacao/${codOperacao}/cargo-holds`);
      if (response.status === 200) {
        const { CARGO_HOLDS, NOME_NAVIO } = response.data;
        setNumeroPoroes(CARGO_HOLDS);
        setSelectedNavio(NOME_NAVIO);
      } else {
        showAlert('Erro ao carregar informações do navio.', 'error');
      }
    } catch (error) {
      showAlert('Erro ao carregar informações do navio.', 'error');
      console.error(error);
    }
  };

  useEffect(() => {
    // Função para buscar produtos do backend
    const fetchProdutos = async () => {
      try {
        const response = await Axios.get('https://hml.operacao.orion-op.com.br/api/produtos');
        setProdutos(response.data);
      } catch (error) {
        console.error('Erro ao buscar produtos:', error);
      }
    };

    fetchProdutos();
  }, []);

  const handleIntegrarMicSistemas = () => {
    setIsIntegrating(true);
    setCountdown(75); // Inicia a contagem regressiva de 45 segundos
    localStorage.setItem('isIntegrating', true);
    localStorage.setItem('countdown', 45);

    // Iniciar a contagem regressiva imediatamente após o clique
    const interval = setInterval(() => {
      setCountdown((prevCountdown) => {
        const newCountdown = prevCountdown - 1;
        localStorage.setItem('countdown', newCountdown);

        if (newCountdown <= 0) {
          clearInterval(interval);
          localStorage.removeItem('isIntegrating');
          localStorage.removeItem('countdown');
          window.location.reload(); // Atualiza a página após a contagem regressiva
          return 0;
        }
        return newCountdown;
      });
    }, 1000);

    // Executar a integração com o MIC Sistemas
    Axios.post('https://hml.operacao.orion-op.com.br/api/puppeteer/micsistemas', {
      codOperacao: i.COD_OPERACAO, // Envie o COD_OPERACAO atual
    })
      .then((response) => {
        if (response.status === 200) {
          showAlert('Integração com MIC Sistemas iniciada com sucesso!', 'success');
          clearInterval(interval); // Para a contagem regressiva se a integração for bem-sucedida
          localStorage.removeItem('isIntegrating');
          localStorage.removeItem('countdown');
          window.location.reload(); // Atualiza a página imediatamente após a integração ser bem-sucedida
        } else {
          showAlert('Erro ao integrar com MIC Sistemas.', 'error');
        }
      })
      .catch((error) => {
        showAlert('Erro ao integrar com MIC Sistemas.', 'error');
        console.error(error);
      });
  };

  useEffect(() => {
    const savedIsIntegrating = localStorage.getItem('isIntegrating') === 'true';
    const savedCountdown = parseInt(localStorage.getItem('countdown'), 10);

    if (savedIsIntegrating && savedCountdown > 0) {
      setIsIntegrating(true);
      setCountdown(savedCountdown);

      const interval = setInterval(() => {
        setCountdown((prevCountdown) => {
          const newCountdown = prevCountdown - 1;
          localStorage.setItem('countdown', newCountdown);

          if (newCountdown <= 0) {
            clearInterval(interval);
            localStorage.removeItem('isIntegrating');
            localStorage.removeItem('countdown');
            window.location.reload(); // Atualiza a página após a contagem regressiva
            return 0;
          }
          return newCountdown;
        });
      }, 1000);

      return () => clearInterval(interval); // Limpa o intervalo ao desmontar o componente
    }
  }, []);

  const handleEditToggle = (field) => {
    setEditingDates({ ...editingDates, [field]: !editingDates[field] });
  };

  const handleInputChange = (e, field) => {
    // Atualiza o valor da data no estado 'editedDates'
    setEditedDates({ ...editedDates, [field]: e.target.value });
  };

  const handleSaveDate = (field) => {
    // Obtém a data do estado
    const dateValue = editedDates[field];

    // Ajusta o valor do fuso horário subtraindo as 3 horas
    const adjustedDate = new Date(dateValue);
    adjustedDate.setHours(adjustedDate.getHours() - 3);

    // Converte para um formato adequado para envio ao backend (YYYY-MM-DD HH:MM:SS)
    const formattedDate = adjustedDate.toISOString().slice(0, 19).replace('T', ' ');

    // Verifica se a data foi corretamente formatada
    if (!formattedDate) {
      showAlert('Data inválida. Por favor, revise o valor.', 'error');
      return;
    }

    console.log('Data sendo enviada:', formattedDate);

    // Faz a requisição para salvar a data
    Axios.put(`https://hml.operacao.orion-op.com.br/api/operacao/atualizar/${i.COD_OPERACAO}`, { field, value: formattedDate })
      .then(response => {
        showAlert('Data atualizada com sucesso!', 'success');
        setEditingDates({ ...editingDates, [field]: false }); // Volta para o modo de exibição

        // Atualizar as cargas para refletir a nova data localmente
        setCargas(cargas.map(carga => carga.COD_OPERACAO === i.COD_OPERACAO ? { ...carga, [field]: formattedDate } : carga));
      })
      .catch(error => {
        showAlert('Erro ao atualizar a data', 'error');
        console.error('Erro ao atualizar a data:', error);
      });
  };




  return (
    <>
      <Navbar cargas />
      <Header />
      <Background />
      <Container>
        <div className={style.content}>
          <div className={style.nav}>
            <div className={style.active}>DOCS VIAGEM</div>
          </div>

          <div className={style.table}>
            <div className={style.table_outer_container}>

            </div>
          </div>

          <div className={style.dropdownContainer}>
            <select
              className={style.navioDropdown}
              value={selectedNavio}
              onChange={handleNavioChange}
            >
              <option value="">Selecione um Navio</option>
              {operacoesList.map((val, key) => (
                <option key={key} value={val.COD_OPERACAO}>
                  {val.NOME_NAVIO || "-"}
                </option>
              ))}
            </select>
            <button className={style.btnRelatorio} onClick={handleDocViagemClick}>
              DOC VIAGEM
            </button>
          </div>
        </div>
      </Container>

      <Dialog
        open={openA}
        onClose={FecharDetalhesOp}
        fullWidth
        maxWidth="lg"
        PaperProps={{
          style: {
            maxHeight: '90%',
            width: '90%',
          },
        }}
      >
        <div className={modal.modal}>
          <div className={modal.modalcontent}>
            <div className={modal.navButtons}>
              <button className={activeTab === 1 ? 'active' : ''} onClick={() => handleTabChange(1)}>Cargas</button>
              <button className={activeTab === 2 ? 'active' : ''} onClick={() => handleTabChange(2)}>Pedidos</button>
              <button className={activeTab === 3 ? 'active' : ''} onClick={() => handleTabChange(3)}>Transportadoras</button>
              <button className={activeTab === 4 ? 'active' : ''} onClick={() => handleTabChange(4)}>Break Down</button>
              <button className={activeTab === 5 ? 'active' : ''} onClick={() => handleTabChange(5)}>Documentos</button>
              <button className={activeTab === 6 ? 'active' : ''} onClick={() => handleTabChange(6)}>Porão</button>
            </div>

            <div className={modal.abas}>
              {activeTab === 1 && (
                <div className={modal.confirmar}>
                  <div className={modal.center}>
                    <div className={modal.status}><i className="fa fa-ship icon"></i>&nbsp;&nbsp;{i.STATUS_OPERACAO} - <b>Navio:</b> {i.NOME_NAVIO} - <b>Berço:</b> {i.NOME_BERCO}</div>

                  </div>
                  {cargas.length > 0 && (
                    <div className={modal.detalhebox}>
                      {/* EOSP */}
                      <div className={modal.inlineContainer}>
                        <label>EOSP:</label>
                        {editingDates.EOSP ? (
                          <>
                            <input
                              type="datetime-local"
                              value={editedDates.EOSP}
                              onChange={(e) => handleInputChange(e, 'EOSP')}
                            />
                            <button onClick={() => handleSaveDate('EOSP')}>✔</button>
                          </>
                        ) : (
                          <>
                            {cargas[0].EOSP ? format(new Date(cargas[0].EOSP), 'dd/MM/yyyy HH:mm') : 'Não informado'}
                            <AiFillEdit onClick={() => handleEditToggle('EOSP')} className="edit-icon" />
                          </>
                        )}
                      </div>

                      {/* NOR Tendered */}
                      <div className={modal.inlineContainer}>
                        <label>NOR Tendered:</label>
                        {editingDates.NOR_TENDERED ? (
                          <>
                            <input
                              type="datetime-local"
                              value={editedDates.NOR_TENDERED}
                              onChange={(e) => handleInputChange(e, 'NOR_TENDERED')}
                            />
                            <button onClick={() => handleSaveDate('NOR_TENDERED')}>✔</button>
                          </>
                        ) : (
                          <>
                            {cargas[0].NOR_TENDERED ? format(new Date(cargas[0].NOR_TENDERED), 'dd/MM/yyyy HH:mm') : 'Não informado'}
                            <AiFillEdit onClick={() => handleEditToggle('NOR_TENDERED')} className="edit-icon" />
                          </>
                        )}
                      </div>

                      {/* Pilot On Board */}
                      <div>
                        <label>Pilot On Board:</label>
                        {editingDates.PILOT_ON_BOARD_FOR_BERTHING ? (
                          <>
                            <input
                              type="datetime-local"
                              value={editedDates.PILOT_ON_BOARD_FOR_BERTHING}
                              onChange={(e) => handleInputChange(e, 'PILOT_ON_BOARD_FOR_BERTHING')}
                            />
                            <button onClick={() => handleSaveDate('PILOT_ON_BOARD_FOR_BERTHING')}>✔</button>
                          </>
                        ) : (
                          <>
                            {cargas[0].PILOT_ON_BOARD_FOR_BERTHING ? format(new Date(cargas[0].PILOT_ON_BOARD_FOR_BERTHING), 'dd/MM/yyyy HH:mm') : 'Não informado'}
                            <AiFillEdit onClick={() => handleEditToggle('PILOT_ON_BOARD_FOR_BERTHING')} className="edit-icon" />
                          </>
                        )}
                      </div>

                      {/* Completed Discharge */}
                      <div>
                        <label>Completed Discharge:</label>
                        {editingDates.COMPLETED_DISCHARGE_OPERATIONS ? (
                          <>
                            <input
                              type="datetime-local"
                              value={editedDates.COMPLETED_DISCHARGE_OPERATIONS}
                              onChange={(e) => handleInputChange(e, 'COMPLETED_DISCHARGE_OPERATIONS')}
                            />
                            <button onClick={() => handleSaveDate('COMPLETED_DISCHARGE_OPERATIONS')}>✔</button>
                          </>
                        ) : (
                          <>
                            {cargas[0].COMPLETED_DISCHARGE_OPERATIONS ? format(new Date(cargas[0].COMPLETED_DISCHARGE_OPERATIONS), 'dd/MM/yyyy HH:mm') : 'Não informado'}
                            <AiFillEdit onClick={() => handleEditToggle('COMPLETED_DISCHARGE_OPERATIONS')} className="edit-icon" />
                          </>
                        )}
                      </div>

                      {/* First Line Ashore */}
                      <div>
                        <label>First Line Ashore:</label>
                        {editingDates.FIRST_LINE_ASHORE ? (
                          <>
                            <input
                              type="datetime-local"
                              value={editedDates.FIRST_LINE_ASHORE}
                              onChange={(e) => handleInputChange(e, 'FIRST_LINE_ASHORE')}
                            />
                            <button onClick={() => handleSaveDate('FIRST_LINE_ASHORE')}>✔</button>
                          </>
                        ) : (
                          <>
                            {cargas[0].FIRST_LINE_ASHORE ? format(new Date(cargas[0].FIRST_LINE_ASHORE), 'dd/MM/yyyy HH:mm') : 'Não informado'}
                            <AiFillEdit onClick={() => handleEditToggle('FIRST_LINE_ASHORE')} className="edit-icon" />
                          </>
                        )}
                      </div>

                      {/* NOR Accepted */}
                      <div>
                        <label>NOR Accepted:</label>
                        {editingDates.NOR_ACCEPTED ? (
                          <>
                            <input
                              type="text"
                              value={editedDates.NOR_ACCEPTED}
                              onChange={(e) => handleInputChange(e, 'NOR_ACCEPTED')}
                            />
                            <button onClick={() => handleSaveDate('NOR_ACCEPTED')}>✔</button>
                          </>
                        ) : (
                          <>
                            {cargas[0].NOR_ACCEPTED && cargas[0].NOR_ACCEPTED.toLowerCase() !== 'as per charter party'
                              ? format(new Date(cargas[0].NOR_ACCEPTED), 'dd/MM/yyyy HH:mm')
                              : cargas[0].NOR_ACCEPTED || 'Não informado'}
                            <AiFillEdit onClick={() => handleEditToggle('NOR_ACCEPTED')} className="edit-icon" />
                          </>
                        )}
                      </div>

                      {/* Gangway Secured */}
                      <div className={modal.detalhebox}>
                        <label>Gangway Secured:</label>
                        {editingDates.GANGWAY_SECURED_ASHORE ? (
                          <div className={modal.inlineContainer}>
                            <input
                              type="datetime-local"
                              value={editedDates.GANGWAY_SECURED_ASHORE}
                              onChange={(e) => handleInputChange(e, 'GANGWAY_SECURED_ASHORE')}
                            />
                            <button onClick={() => handleSaveDate('GANGWAY_SECURED_ASHORE')}>✔</button>
                          </div>
                        ) : (
                          <div className={modal.inlineContainer}>
                            {cargas[0].GANGWAY_SECURED_ASHORE
                              ? format(new Date(cargas[0].GANGWAY_SECURED_ASHORE), 'dd/MM/yyyy HH:mm')
                              : 'Não informado'}
                            <AiFillEdit
                              onClick={() => handleEditToggle('GANGWAY_SECURED_ASHORE')}
                              className="edit-icon"
                            />
                          </div>
                        )}
                      </div>


                      {/* Commenced Discharge */}
                      <div>
                        <label>Commenced Discharge:</label>
                        {editingDates.COMMENCED_DISCHARGE_OPERATION ? (
                          <>
                            <input
                              type="datetime-local"
                              value={editedDates.COMMENCED_DISCHARGE_OPERATION}
                              onChange={(e) => handleInputChange(e, 'COMMENCED_DISCHARGE_OPERATION')}
                            />
                            <button onClick={() => handleSaveDate('COMMENCED_DISCHARGE_OPERATION')}>✔</button>
                          </>
                        ) : (
                          <>
                            {cargas[0].COMMENCED_DISCHARGE_OPERATION ? format(new Date(cargas[0].COMMENCED_DISCHARGE_OPERATION), 'dd/MM/yyyy HH:mm') : 'Não informado'}
                            <AiFillEdit onClick={() => handleEditToggle('COMMENCED_DISCHARGE_OPERATION')} className="edit-icon" />
                          </>
                        )}
                      </div>
                    </div>
                  )}


                  <div className={modal.center}>
                    <div className={modal.cargas}>
                      <table className={modal.table}>

                        <thead>
                          <tr>
                            <th></th>
                            <th>CÓDIGO</th>
                            <th>IMPORTADOR</th>
                            <th>PRODUTO</th>
                            <th>MANIFESTADO</th>
                          </tr>
                        </thead>
                        <tbody>
                          {cargas.length === 0 ? (
                            <tr>
                              <td colSpan="6">Nenhuma carga identificada</td>
                            </tr>
                          ) : (
                            cargas.map((val) => (
                              <tr key={val.COD_CARGA}>
                                <td>{val.TIPO}</td>
                                <td>{val.NUMERO}</td>
                                <td>{val.IMPORTADOR}</td>
                                <td>{val.PRODUTO}</td>
                                <td>{(val.QTDE_MANIFESTADA / 1000).toLocaleString(undefined, { maximumFractionDigits: 2 })} TONS</td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className={modal.center}>
                    {i.STATUS_OPERACAO === 'AGUARDANDO DI/BL' ? (
                      <button className={modal.finalizar}
                        onClick={() => navigate(`/cargas/cadastro/${i.NOME_NAVIO}/${i.COD_OPERACAO}`)}>
                        EDITAR CARGA
                      </button>
                    ) : (
                      <div className={modal.center}></div>
                    )}

                    {i.STATUS_OPERACAO === 'AGUARDANDO ATRACAÇÃO' ? (
                      <button className={modal.finalizar}
                        onClick={AbrirConfirm}>
                        REGISTRAR ATRACAÇÃO
                      </button>
                    ) : ""}
                  </div>
                </div>
              )}

              {activeTab === 2 && (
                <div className={modal.confirmar}>
                  <div className={modal.navioNome}>
                    <i className={`fas fa-ship ${modal.navioIcone}`}></i>
                    <div><b>Navio:</b> {i.NOME_NAVIO}</div>
                  </div>
                  <div className={modal.formRow}>
                    <div className={modal.formManifestado}>
                      {selectedCargaDetails && (
                        <div className={modal.progressContainer}>
                          <label className={modal.progressLabel}>Quantidade Manifestada: {(selectedCargaDetails.QTDE_MANIFESTADA / 1000).toLocaleString('pt-BR')} TONS</label>
                          <div className={modal.progressBar} style={{ display: 'flex', height: '20px', backgroundColor: '#e0e0e0' }}>
                            {pedidoData.map((pedido, index) => {
                              const porcentagemManifestada = (pedido.QTDE_MANIFESTADA_PEDIDO / selectedCargaDetails.QTDE_MANIFESTADA) * 100;
                              return (
                                <div
                                  key={index}
                                  style={{
                                    width: `${porcentagemManifestada}%`,
                                    backgroundColor: pedido.COR_HEX || '#90caf9',
                                    display: 'inline-block',
                                  }}
                                  title={`${pedido.NR_PEDIDO}: ${porcentagemManifestada.toFixed(2)}%`}
                                ></div>
                              );
                            })}
                          </div>
                          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '5px' }}>
                            {pedidoData.map((pedido, index) => {
                              const porcentagemManifestada = (pedido.QTDE_MANIFESTADA_PEDIDO / selectedCargaDetails.QTDE_MANIFESTADA) * 100;
                              return (
                                <span key={index} style={{ width: `${porcentagemManifestada}%`, textAlign: 'center', display: 'inline-block' }}>
                                  {porcentagemManifestada >= 5 ? `${porcentagemManifestada.toFixed(2)}%` : ''}
                                </span>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className={modal.importadorDisplay}>
                      <label>Importador:</label>
                      <div>{selectedImportador || ''}</div>
                    </div>
                  </div>

                  <form onSubmit={handlePedidoSubmit}>
                    <div className={modal.formRow}>
                      <div className={modal.formColumn}>
                        <label htmlFor="cargasDropdown">DI ou BL</label>
                        <select id="cargasDropdown" value={selectedCarga} onChange={handleCargaChange}>
                          <option value="">Selecione a carga</option>
                          {cargas.map((carga) => (
                            <option key={carga.COD_CARGA} value={carga.COD_CARGA}>
                              {carga.NUMERO}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className={modal.formColumn}>
                        <label>PEDIDO</label>
                        <input
                          type="text"
                          id="nrPedido"
                          value={nrPedido}
                          onChange={(e) => setNrPedido(e.target.value)}
                        />
                      </div>

                      <div className={modal.formColumn}>
                        <label htmlFor="destinoSelect">DESTINO</label>
                        <select
                          id="destinoSelect"
                          value={selectedDestino}
                          onChange={(e) => setSelectedDestino(e.target.value)}
                          className={modal.selectDropdown}
                        >
                          <option value="">Selecionar destino</option>
                          {destinos.map((destino) => (
                            <option key={destino.COD_DESTINO} value={destino.COD_DESTINO}>
                              {destino.NOME_DESTINO}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className={modal.formColumn}>
                        <label>MANIFESTADO (KG)</label>
                        <input
                          type="text"
                          id="ManifestadoPedido"
                          value={ManifestadoPedido}
                          onChange={(e) => setManifestadoPedido(e.target.value)}
                        />
                      </div>

                      <div className={modal.formColumn}>
                        <label htmlFor="colorPicker">Cor:</label>
                        <div className={modal.colorPickerContainer} style={{ color: colorHex }}>
                          <input
                            type="color"
                            id="colorPicker"
                            value={colorHex}
                            onChange={(e) => setColorHex(e.target.value)}
                            style={{ width: '100%', height: '40px', opacity: 0 }}
                          />
                        </div>
                      </div>
                    </div>

                    {showPedidoData && (
                      <div className={modal.tableContainer}>
                        <table className={modal.table}>
                          <thead>
                            <tr>
                              <th>DI ou BL</th>
                              <th>Pedido</th>
                              <th>Destino</th>
                              <th>Manifestado - PEDIDO</th>
                              <th>COR</th>
                            </tr>
                          </thead>
                          <tbody>
                            {pedidoData.map((pedido, index) => (
                              <tr key={index}>
                                <td>{pedido.NUMERO_DOC}</td>
                                <td>{pedido.NR_PEDIDO}</td>
                                <td>{pedido.NOME_DESTINO ? pedido.NOME_DESTINO : "Sem Destino"}</td>
                                <td>{pedido.QTDE_MANIFESTADA_PEDIDO ? `${(pedido.QTDE_MANIFESTADA_PEDIDO / 1000).toLocaleString('pt-BR')} TONS` : "0 TONS"}</td>
                                <td style={{ backgroundColor: pedido.COR_HEX || "transparent" }}>{pedido.COR_HEX ? "" : "N/A"}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                    <div className={modal.micsistemas}>
                      <button
                        type="button"
                        className={modal.finalizar}
                        onClick={!isIntegrating ? handleIntegrarMicSistemas : null}
                        disabled={isIntegrating} // Desabilita o botão enquanto está integrando
                      >
                        {isIntegrating ? `Aguarde... (${countdown}s)` : "INTEGRAR MIC SISTEMAS"}
                      </button>
                      <button type="submit" className={modal.finalizar}>
                        ADICIONAR PEDIDO
                      </button>
                    </div>
                  </form>
                </div>
              )}

              {activeTab === 3 && (
                <div>
                  <div className={modal.detalhebox}>
                    <div><b>Navio:</b> {i.NOME_NAVIO}</div>
                  </div>
                  <form onSubmit={handleTransportadoraSubmit}>
                    <label htmlFor="cargasDropdown">Escolha um pedido:  </label>
                    <select id="cargasDropdown" onChange={handlePedidoChange}>
                      <option value=""></option>
                      {pedidos.map((pedido) => (
                        <option key={pedido.ID_PEDIDO} value={pedido.ID_PEDIDO}>
                          {pedido.NR_PEDIDO}
                        </option>
                      ))}
                    </select>
                    <div className={modal.detalhebox}>
                      <label htmlFor="nrTransportadora">Transportadora:  </label>
                      <input
                        type="text"
                        id="nrransportadora"
                        value={nrTransportadora}
                        onChange={(e) => setNrTransportadora(e.target.value)}
                      />
                    </div>

                    {showPedidoData && (
                      <div className={modal.tableContainer}>
                        <table className={modal.table}>
                          <thead>
                            <tr>
                              <th>Pedido</th>
                              <th>Transportadora</th>
                            </tr>
                          </thead>
                          <tbody>
                            {pedidoData.map((pedido, index) => (
                              <tr key={index}>
                                <td>{pedido.NUMERO_DOC}</td>
                                <td>{pedido.NR_PEDIDO}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                    <button type="submit" className={modal.finalizar}>
                      ADICIONAR TRANSPORTADORA
                    </button>
                  </form>
                </div>
              )}

              {activeTab === 4 && (
                <div className={modal.confirmar}>
                  <div className={modal.navioNome}>
                    <i className={`fas fa-ship ${modal.navioIcone}`}></i>
                    <div><b>Navio:</b> {i.NOME_NAVIO}</div>
                  </div>

                  <form onSubmit={handleBreakDownSubmit}>
                    <div className={modal.formRow}>
                      <div className={modal.formColumn}>
                        <label htmlFor="operador">OPERADOR</label>
                        <select id="operador" value={selectedOperadorPortuario} onChange={handleOperadorPortuarioChange}>
                          <option value="">Selecione</option>
                          {operadoresPortuarios.map((operador) => (
                            <option key={operador.COD_OPERADOR_PORTUARIO} value={operador.COD_OPERADOR_PORTUARIO}>
                              {operador.NOME_REDUZIDO_OPERADOR_PORTUARIO}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className={modal.formColumn}>
                        <label htmlFor="cliente">CLIENTE</label>
                        <select id="cliente" value={selectedCliente} onChange={handleClienteChange}>
                          <option value="">Selecione</option>
                          {clientes.map((cliente) => (
                            <option key={cliente.COD_CLIENTE} value={cliente.COD_CLIENTE}>
                              {cliente.NOME_REDUZIDO}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className={modal.formColumn}>
                        <label htmlFor="produto">PRODUTO</label>
                        <select id="produto" value={selectedProduto} onChange={handleProdutoChange}>
                          <option value="">Selecione</option>
                          {produtos.map((produto) => (
                            <option key={produto.COD_PRODUTO} value={produto.COD_PRODUTO}>
                              {produto.PRODUTO}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className={modal.formRow}>
                      <div className={modal.formColumn}>
                        <label>MANIFESTADO (KG)</label>
                        <input
                          type="text"
                          id="kgManifestadoBD"
                          value={kgManifestadoBD}
                          onChange={(e) => setKgManifestadoBD(e.target.value)}
                        />
                      </div>

                      <div className={modal.formColumn}>
                        <label>DESCARREGADO (KG)</label>
                        <input
                          type="text"
                          id="kgDescarregadoBD"
                          value={kgDescarregadoBD}
                          onChange={(e) => setKgDescarregadoBD(e.target.value)}
                        />
                      </div>

                      <button type="submit" className={modal.finalizar}>
                        ADICIONAR
                      </button>
                    </div>
                  </form>

                  <div className={modal.tableContainer}>
                    <table className={modal.table}>
                      <thead>
                        <tr>
                          <th>OPERADOR</th>
                          <th>CLIENTE</th>
                          <th>PRODUTO</th>
                          <th>MANIFESTADO</th>
                          <th>DESCARREGADO</th>
                          <th>SALDO</th>
                        </tr>
                      </thead>
                      <tbody>
                        {breakDownData.map((breakDown, index) => (
                          <tr key={index}>
                            <td>{breakDown.NOME_REDUZIDO_OPERADOR_PORTUARIO}</td>
                            <td>{breakDown.NOME_REDUZIDO_CLIENTE}</td>
                            <td>{breakDown.PRODUTO}</td>
                            <td>{breakDown.QTDE_MANIFESTADA ? `${(breakDown.QTDE_MANIFESTADA / 1000).toLocaleString('pt-BR', { maximumFractionDigits: 2 })} TONS` : "0 TONS"}</td>
                            <td>
                              {editingIndex === index ? (
                                <input
                                  type="text"
                                  value={newDescarregadoBD}
                                  onChange={(e) => setNewDescarregadoBD(e.target.value)}
                                />
                              ) : (
                                breakDown.DESCARREGADO_BD ? `${(breakDown.DESCARREGADO_BD / 1000).toLocaleString('pt-BR', { maximumFractionDigits: 2 })} TONS` : "0 TONS"
                              )}
                              {editingIndex === index ? (
                                <>
                                  <span onClick={() => handleSaveClick(index, breakDown)}>✅</span>
                                  <span onClick={handleCancelEdit}>❌</span>
                                </>
                              ) : (
                                <span onClick={() => handleEditClick(index, breakDown.DESCARREGADO_BD)}>✏️</span>
                              )}
                            </td>
                            <td>{breakDown.QTDE_MANIFESTADA && breakDown.DESCARREGADO_BD ? `${((breakDown.QTDE_MANIFESTADA - breakDown.DESCARREGADO_BD) / 1000).toLocaleString('pt-BR', { maximumFractionDigits: 2 })} TONS` : "0 TONS"}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}

              {activeTab === 5 && (
                <div className={modal.confirmar}>
                  <div className={modal.navioNome}>
                    <i className={`fas fa-ship ${modal.navioIcone}`}></i>
                    <div><b>Navio:</b> {i.NOME_NAVIO}</div>
                  </div>

                  <form onSubmit={handleDocumentSubmit}>
                    <div className={modal.formRow}>
                      <div className={modal.formColumn}>
                        <label htmlFor="listadocumento">NOME DOCUMENTO</label>
                        <select id="listadocumento" value={selectedDocumento} onChange={handleDocumentoChange}>
                          <option value="">Selecione</option>
                          {listaDocumento.map((documento) => (
                            <option key={documento.COD_LISTA_DOCUMENTO} value={documento.COD_LISTA_DOCUMENTO}>
                              {documento.NOME_DOCUMENTO}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className={modal.formColumn}>
                        <label>ANEXO</label>
                        <input
                          type="file"
                          accept=".pdf"
                          onChange={handleFileChange}
                        />
                      </div>
                    </div>

                    <div className={modal.formRow}>
                      <button type="submit" className={modal.finalizar}>
                        ADICIONAR
                      </button>
                    </div>
                  </form>

                  <div className={modal.tableContainer}>
                    <table className={modal.table}>
                      <thead>
                        <tr>
                          <th>DOCUMENTO</th>
                          <th>USUÁRIO</th>
                          <th>DATA E HORA</th>
                          <th>PDF</th>
                        </tr>
                      </thead>
                      <tbody>
                        {listaDocumentoDoc.map((doclist, index) => (
                          <tr key={index}>
                            <td>{doclist.NOME_DOCUMENTO}</td>
                            <td>{doclist.USUARIO_DOCUMENTO}</td>
                            <td>{formatDate(doclist.DATA_DOCUMENTO)}</td>

                            <td>
                              {doclist.NOME_ARQUIVO && (
                                <i
                                  onClick={() => handleDownload(doclist.NOME_ARQUIVO)}
                                  style={{ color: "blue" }}
                                  className="fa fa-file-pdf icon"
                                  aria-hidden="true"
                                />
                              )}
                            </td>

                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}

              {activeTab === 6 && (
                <div className={modal2.confirmar}>
                  <div className={modal2.navioNome}>
                    <i className={`fas fa-ship ${modal2.navioIcone}`}></i>
                    <div><b>Navio:</b> {i.NOME_NAVIO}</div>
                  </div>

                  <div className={modal2.layoutContainer}>
                    <div className={modal2.imageWrapperleft}>
                      <img src={Popa} alt="Popa" className={modal2.shipImage} />
                      <table className={modal2.breakdownTable2}>
                        <tbody>
                          <tr><td>MANIFESTADO</td></tr>
                          <tr><td>DESCARREGADO</td></tr>
                          <tr><td>SALDO</td></tr>
                        </tbody>
                      </table>
                    </div>

                    <div className={modal2.tableWrapper}>
                      <table className={modal2.breakdownTable}>
                        <thead>
                          <tr>
                            {[...Array(numeroPoroes)].map((_, index) => (
                              <th key={index}>Porão {index + 1}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {/* Linha de produtos */}
                          <tr>
                            {[...Array(numeroPoroes)].map((_, index) => (
                              <td key={index}>
                                {poraoData && poraoData[`PRODUTO_0${index + 1}`] ? poraoData[`PRODUTO_0${index + 1}`] : 'N/A'}
                              </td>
                            ))}
                          </tr>
                          {/* Linha de manifestado */}
                          <tr>
                            {[...Array(numeroPoroes)].map((_, index) => (
                              <td key={index}>
                                {poraoData && poraoData[`PORAO_0${index + 1}_MANIFESTADO`]
                                  ? `${(poraoData[`PORAO_0${index + 1}_MANIFESTADO`] / 1000).toLocaleString('pt-BR', { minimumFractionDigits: 3, maximumFractionDigits: 3 })} Tons`
                                  : '0,000 Tons'}
                              </td>
                            ))}
                          </tr>
                          {/* Linha de descarregado */}
                          <tr>
                            {[...Array(numeroPoroes)].map((_, index) => {
                              // Encontre o porão correspondente nos dados recebidos
                              const porao = poraoDescarregado.find(p => p.PORAO === (index + 1).toString());

                              return (
                                <td key={index}>
                                  {/* Exiba o valor do peso líquido total se o porão existir, caso contrário, exiba '0,000 tons' */}
                                  {porao ? `${(porao.PESO_LIQUIDO_TOTAL / 1000).toLocaleString('pt-BR', { minimumFractionDigits: 3, maximumFractionDigits: 3 })} Tons` : '0,000 Tons'}
                                </td>
                              );
                            })}
                          </tr>
                          {/* Linha de saldo */}
                          <tr>
                            {[...Array(numeroPoroes)].map((_, index) => {
                              const manifestado = poraoData && poraoData[`PORAO_0${index + 1}_MANIFESTADO`]
                                ? poraoData[`PORAO_0${index + 1}_MANIFESTADO`]
                                : 0;

                              const descarregado = poraoDescarregado.find(p => p.PORAO === (index + 1).toString())
                                ? poraoDescarregado.find(p => p.PORAO === (index + 1).toString()).PESO_LIQUIDO_TOTAL
                                : 0;

                              const saldo = (manifestado - descarregado).toFixed(3);

                              return (
                                <td key={index}>
                                  {`${(saldo / 1000).toLocaleString('pt-BR', { minimumFractionDigits: 3, maximumFractionDigits: 3 })} Tons`}
                                </td>
                              );
                            })}
                          </tr>

                        </tbody>
                      </table>
                    </div>

                    <div className={modal2.imageWrapperright}>
                      <img src={Proa} alt="Proa" className={modal2.shipImage} />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Dialog>

      <Dialog open={openB} onClose={FecharConfirm} fullWidth>
        <div className={confirm.modal}>
          <div className={confirm.nav}>
            <div onClick={FecharConfirm}>Voltar</div>
          </div>
          <div className={confirm.center}>
            Deseja registrar a Atracação desta Operação?
            <br />
            <div>ao confirmar o Dashboard será liberado!</div>
          </div>

          <div className={confirm.inputbox}>
            <Input
              type={"datetime-local"}
              text={"Data e hora da Atracação"}
              onChange={(e) => [setDate(e.target.value)]}
            />
          </div>
          <div className={confirm.flex}>
            <button className={confirm.cancelar} onClick={FecharConfirm}>CANCELAR</button>
            <button className={confirm.confirmar} onClick={registrarAtracacao}>CONFIRMAR</button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default function IntegrationNotistack() {
  return (
    <SnackbarProvider
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      maxSnack={3}
      autoHideDuration={2500}>
      <Cargas />
    </SnackbarProvider>
  );
}
