import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { SnackbarProvider, useSnackbar } from 'notistack';
import Navbar from '../../../components/Navbar';
import Background from '../../../components/Background';
import Container from '../../../components/Container';
import Header from '../../../components/Header';
import SubmitButton from '../../../components/Button';
import Input from '../../../components/Input';
import style from './MotivacaoMotorista.module.css';
import InputMask from 'react-input-mask';
import moment from 'moment';

const MotivacaoMotorista = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [bercospa, setBercoSPA] = useState([]);
  const [navios, setNavios] = useState([]);
  const [cpfList, setCpfList] = useState(['']); // Inicializa com um único campo de CPF
  const [bercoSelecionado, setBercoSelecionado] = useState('');
  const [diasSelecionado, setDiasSelecionado] = useState('1');
  const [operacao, setOperacao1] = useState('');
  const usuario = JSON.parse(localStorage.getItem("user_token")).id;
  const [mostrarNavios1, setMostrarNavios1] = useState(false); // Inicializa com false
  const [motivacoes, setMotivacoes] = useState([]);  // Adiciona estado para armazenar as motivações
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const showAlert = (message, variant) => {
    enqueueSnackbar(message, { variant });
  };

  const validaTecla = (e) => {
    if (e.key === 'Enter') {
      validaDados(); // Chama a função de validação
    }
  };

  // Função para remover os caracteres '.' e '-' do CPF
  const removerFormatacaoCPF = (cpf) => {
    return cpf.replace(/[.-]/g, ''); // Remove os pontos e traços
  };

  const validaDados = () => {
    if (!diasSelecionado) {
      showAlert('Selecione um período!', 'error');
      return;
    }
    if (!bercoSelecionado) {
      showAlert('Selecione um berço!', 'error');
      return;
    }
    if (!cpfList[0]) {
      showAlert('Preencha pelo menos o primeiro CPF', 'error');
      return;
    }
    motivar();
  };

  // Buscar os dados de Berços SPA e Navios
  useEffect(() => {
    Axios.get(`https://hml.operacao.orion-op.com.br/api/bercospa`)
      .then(response => setBercoSPA(response.data))
      .catch(error => showAlert('Erro ao buscar berços', 'error'));

    Axios.get(`https://hml.operacao.orion-op.com.br/api/operacao/operando`)
      .then(response => setNavios(response.data))
      .catch(error => showAlert('Erro ao buscar navios', 'error'));
  }, []);

  const motivar = () => {
    setIsButtonDisabled(true); // Desabilita o botão imediatamente quando a função é chamada
  
    const dataInicial = new Date();
    const dias = parseInt(diasSelecionado, 10);
    const dataFinal = new Date(dataInicial);
    dataFinal.setDate(dataFinal.getDate() + dias);
  
    const dataInicialFormatada = dataInicial.toISOString().split('T')[0];
    const dataFinalFormatada = dataFinal.toISOString().split('T')[0];
  
    const cpfsSemFormatacao = cpfList.map(cpf => removerFormatacaoCPF(cpf));
    const [cpf1, cpf2, cpf3, cpf4, cpf5] = [...cpfsSemFormatacao, '', '', '', '', ''].slice(0, 5);
  
    const dadosMotivacao = {
      codBercoSpa: bercoSelecionado,
      cpf1,
      cpf2,
      cpf3,
      cpf4,
      cpf5,
      dataInicial: dataInicialFormatada,
      dataFinal: dataFinalFormatada,
      usuarioCadastro: usuario,
      operacao
    };
  
    Axios.post('https://hml.operacao.orion-op.com.br/api/motivacao/motorista/registrar', dadosMotivacao)
      .then(() => {
        // Exibe o showAlert de sucesso
        showAlert('Ação de motivar realizada com sucesso!', 'success');
        
        // Mantém o botão desabilitado e recarrega a página após 2 segundos
        setTimeout(() => {
          window.location.reload(); // Atualiza a página após 2 segundos
        }, 2000);
      })
      .catch(() => {
        // Exibe o showAlert de erro
        showAlert('Erro ao registrar motivação', 'error');
        
        // Reabilita o botão após o erro
        setIsButtonDisabled(false);
      })
  };
  

  const addNewCpfField = () => {
    if (cpfList.length < 5) {
      setCpfList([...cpfList, '']);
    } else {
      showAlert('Limite de 5 CPFs atingido', 'warning');
    }
  };

  const removeCpfField = (index) => {
    const updatedCpfList = cpfList.filter((_, i) => i !== index);
    setCpfList(updatedCpfList);
  };

  const handleCpfChange = (index, value) => {
    const updatedCpfList = [...cpfList];
    updatedCpfList[index] = value;
    setCpfList(updatedCpfList);
  };



  useEffect(() => {
    Axios.get("https://hml.operacao.orion-op.com.br/api/motorista/motivacao")
      .then((response) => {
        setMotivacoes(response.data);  // Atualize o estado com as motivações retornadas
      })
      .catch((error) => {
        console.error("Erro ao buscar as motivações: ", error);
        showAlert("Erro ao buscar as motivações", "error");
      });
  }, []);
  
  const handleCopy = async (event, idMotivacao) => {
    event.preventDefault();
    
    try {
      const response = await Axios.get(`https://hml.operacao.orion-op.com.br/api/motivacao/motorista/conteudo/${idMotivacao}`);
      const textToCopy = response.data.content;
      if (navigator.clipboard) {
        navigator.clipboard.writeText(textToCopy).then(() => {
          showAlert("Texto copiado com sucesso!", "success");
        }, (err) => {
          console.error("Erro ao copiar usando a API da área de transferência: ", err);
          // Usar método de fallback
          copyToClipboardFallback(textToCopy);
        });
      } else {
        // Navegador não suporta navigator.clipboard, usar fallback
        copyToClipboardFallback(textToCopy);
      }
    } catch (error) {
      console.error('Erro ao tentar copiar o texto: ', error);
      showAlert("Erro ao copiar o texto.", "error");
    }
  };

  const copyToClipboardFallback = (textToCopy) => {
    const textArea = document.createElement('textarea');
    textArea.value = textToCopy;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    showAlert("Texto copiado com sucesso!", "success");
  };
  
  return (
    <>
      <Navbar motivados />
      <Header />
      <Background />
      <Container>
        <div className={style.content}>
          <div className={style.nav}>
            <div onClick={() => navigate('/veiculos/BuscarMotorista')}>Motorista</div>
            <div onClick={() => navigate('/veiculos')}>Cadastrar Motorista</div>
            <div className={style.active}>Motivação Motorista</div>
          </div>

          <div className={style.filtersContainer}>
            {cpfList.map((cpf, index) => (
              <div key={index} className={style.cpfField}>
                <InputMask
                  mask="999.999.999-99"
                  id={`cpfInput-${index}`}
                  className={style.inputCPF}
                  value={cpf}
                  onChange={(e) => handleCpfChange(index, e.target.value)}
                  placeholder="000.000.000-00"
                />
              </div>
            ))}

            <div className={style.buttonGroup}>
              {cpfList.length < 5 && (
                <button className={style.addButton} onClick={addNewCpfField}>+</button>
              )}
              {cpfList.length > 1 && (
                <button className={style.addButton} onClick={() => removeCpfField(cpfList.length - 1)}>-</button>
              )}
            </div>
          </div>

          <div className={style.filtersContainer}>
            <select className={style.selectEmpresa} onChange={(e) => { setBercoSelecionado(e.target.value) }}>
              <option value="">Escolha uma opção</option>
              {bercospa?.map((bercospa) => {
                return (
                  <option value={bercospa.COD_BERCO_SPA}>{bercospa.DESC_BERCO_SPA}</option>
                );
              })}
            </select>

            <div className={style.selectButtonGroup}>
              <select className={style.selectEmpresa} onChange={(e) => { setOperacao1(e.target.value) }}>
                <option value="">Escolha uma opção</option>
                {navios?.map((navios) => {
                  return (
                    <option value={navios.COD_OPERACAO}>{navios.NOME_NAVIO}</option> // O valor será o COD_OPERACAO
                  );
                })}
              </select>

            </div>

            <div className={style.submitButton}>
  <button
    onClick={validaDados}
    onKeyPress={(e) => validaTecla(e)}
    disabled={isButtonDisabled}
    className={`${style.submitButton} ${isButtonDisabled ? style.submitButtonDisabled : ''}`}
  >
    Motivar
  </button>
</div>


          </div>

          <div className={style.tableContainer}>
  <table className={style.table}>
    <thead>
      <tr>
        <th>ID</th>
        <th>BERÇO</th>
        <th>INÍCIO</th>
        <th>TÉRMINO</th>
        <th>USUÁRIO CADASTRO</th>
        <th>CADASTRO</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {motivacoes.map((motivacao) => (
        <tr key={motivacao.COD_MOTIVACAO_MOTORISTA}>
          <td>{motivacao.COD_MOTIVACAO_MOTORISTA}</td>
          <td>{motivacao.DESC_BERCO_SPA}</td>
          <td>{moment(motivacao.DATA_INICIAL).format('DD/MM/YY')}</td>
          <td>{motivacao.DATA_FINAL ? moment(motivacao.DATA_FINAL).format('DD/MM/YY') : 'N/A'}</td>
          <td>{motivacao.USUARIO_MOTIVACAO_MOTORISTA}</td>
          <td>{moment(motivacao.DAT_CADASTRO).format('DD/MM/YY HH:mm')}</td>
          <td>
            <div className={style.buttonContainer}>
              <button onClick={(event) => handleCopy(event, motivacao.COD_MOTIVACAO_MOTORISTA)} className={style.copyButton}>
                Copiar
              </button>
            </div>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
</div>

        </div>
      </Container>
    </>
  );
};

export default function IntegrationNotistack() {
  return (
    <SnackbarProvider
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      maxSnack={3}
      autoHideDuration={2500}
    >
      <MotivacaoMotorista />
    </SnackbarProvider>
  );
}
