import React, { useEffect, useRef, useState } from 'react';
import './PosicaoNavios.css';
import Header from '../../../components/Header';
import Navbar from '../../../components/Navbar';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaEdit } from 'react-icons/fa';
import logoSetPort from './logo-setport.png';
import logoZPort from './logo-zport.png';
import logoRodrimar from './logo-rodrimar.png';
import logoReliance from './logo-reliance.png';
import logoOrion from './logo-orion.png';
import Dialog from '@mui/material/Dialog';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { FaTrash } from 'react-icons/fa'; // Importe o ícone de lixeira

const generateRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; 6 > i; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};

const generateDateRange = (startDate, endDate) => {
    const dates = [];
    const currentDate = new Date(startDate);
    const end = new Date(endDate);
    while (currentDate <= end) {
        dates.push(formatDate(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
};

const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear()).slice(-2);
    return `${day}/${month}/${year}`;
};

const getOperatorLogo = (operator) => {
    switch (operator) {
        case 'SET PORT':
            return logoSetPort;
        case 'ZPORT':
            return logoZPort;
        case 'RODRIMAR':
            return logoRodrimar;
        case 'RELIANCE':
            return logoReliance;
        case 'ORION':
            return logoOrion;
        default:
            return null;
    }
};

const PosicaoNavios = () => {
    const [tasksData, setTasksData] = useState([]);
    const [chartValues, setChartValues] = useState([]);
    const [selectedShip, setSelectedShip] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [showBercoSelect, setShowBercoSelect] = useState(false);
    const [bercos, setBercos] = useState([]);
    const [openA, setOpenA] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [navios, setNavios] = useState([]); // Estado para armazenar os navios
    const [agentes, setAgentes] = useState([]);
    const [operadores, setOperadores] = useState([]);

    const chartWrapperRef = useRef(null);
    const picColors = {};

    const { enqueueSnackbar } = useSnackbar();

    const showAlert = (txt, variant) => {
        enqueueSnackbar(txt, { variant });
    };

    const toggleForm = () => {
        setShowForm(!showForm);
    };

    useEffect(() => {
        const fetchNavios = async () => {
            try {
                const response = await fetch('https://hml.operacao.orion-op.com.br/api/navio');
                const data = await response.json();
                setNavios(data);
            } catch (error) {
                console.error('Erro ao buscar navios:', error);
                showAlert('Erro ao buscar navios!', 'error');
            }
        };

        fetchNavios();
    }, []);

    useEffect(() => {
        // Buscar agentes do backend
        const fetchAgentes = async () => {
            try {
                const response = await fetch('https://hml.operacao.orion-op.com.br/api/agentes');
                const data = await response.json();
                setAgentes(data);
            } catch (error) {
                console.error('Erro ao buscar agentes:', error);
            }
        };

        fetchAgentes();
    }, []);

    useEffect(() => {
        // Buscar agentes do backend
        const fetchOperadores = async () => {
            try {
                const response = await fetch('https://hml.operacao.orion-op.com.br/api/operadorportuario');
                const data = await response.json();
                setOperadores(data);
            } catch (error) {
                console.error('Erro ao buscar operadores:', error);
            }
        };

        fetchOperadores();
    }, []);



    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://hml.operacao.orion-op.com.br/api/line-up-details');
                const data = await response.json();
                const formattedData = data.map(item => ({
                    id: item.ID_LINE_UP,
                    berth: item.NOME_BERCO,
                    PIC: item.NOME_AGENTE,
                    name: item.NOME_NAVIO,
                    startDate: new Date(item.DATA_INICIO),
                    endDate: new Date(item.DATA_TERMINO),
                    operator1: item.NOME_REDUZIDO_OPERADOR_PORTUARIO_01,
                    operator2: item.NOME_REDUZIDO_OPERADOR_PORTUARIO_02,
                    details: item
                }));
                const allStartDates = formattedData.map(task => task.startDate);
                const allEndDates = formattedData.map(task => task.endDate);
                const minDate = new Date(Math.min(...allStartDates));
                const maxDate = new Date(Math.max(...allEndDates));
                const dates = generateDateRange(minDate, maxDate);
                setChartValues(dates);
                setTasksData(formattedData);
            } catch (error) {
                console.error('Error fetching data:', error);
                showAlert('Erro ao buscar dados!', 'error');
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const fetchBercos = async () => {
            try {
                const response = await fetch('https://hml.operacao.orion-op.com.br/api/bercos');
                const data = await response.json();
                setBercos(data);
            } catch (error) {
                console.error('Erro ao buscar os berços:', error);
                showAlert('Erro ao buscar os berços!', 'error');
            }
        };

        fetchBercos();
    }, []);

    const AbrirDetalhesOp = (ship) => {
        setSelectedShip({ ...ship, id: ship.ID_LINE_UP });
        setStartDate(ship.startDate);
        setEndDate(ship.endDate);
        setOpenA(true);
    };

    const FecharDetalhesOp = () => {
        setOpenA(false);
        setSelectedShip(null);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await fetch(`https://hml.operacao.orion-op.com.br/api/update-line-up/${selectedShip.id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    startDate: startDate.toISOString(),
                    endDate: endDate.toISOString(),
                }),
            });

            if (response.ok) {
                showAlert('Datas atualizadas com sucesso!', 'success');
                const updatedTasks = tasksData.map(task =>
                    task.id === selectedShip.id
                        ? { ...task, startDate, endDate }
                        : task
                );
                setTasksData(updatedTasks);
                FecharDetalhesOp();
            } else {
                showAlert('Falha ao atualizar as datas.', 'error');
            }
        } catch (error) {
            console.error('Erro ao atualizar as datas:', error);
            showAlert('Erro ao atualizar as datas.', 'error');
        }
    };

    const handleEditBerco = async (e) => {
        const newBercoId = e.target.value;
        try {
            const response = await fetch(`https://hml.operacao.orion-op.com.br/api/update-line-up-berco/${selectedShip?.id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ COD_BERCO: newBercoId }),
            });

            if (response.ok) {
                showAlert('Berço atualizado com sucesso!', 'success');
                const updatedTasks = tasksData.map(task =>
                    task.id === selectedShip?.id
                        ? { ...task, COD_BERCO: newBercoId }
                        : task
                );
                setTasksData(updatedTasks);
                setShowBercoSelect(false);
                window.location.reload(); // Adicionar esta linha para recarregar a página
            } else {
                showAlert('Falha ao atualizar o berço.', 'error');
            }
        } catch (error) {
            console.error('Erro ao atualizar o berço:', error);
            showAlert('Erro ao atualizar o berço.', 'error');
        }
    };

    useEffect(() => {
        const createChart = () => {
            const days = chartWrapperRef.current.querySelectorAll(".chart-values li");
            const daysArray = Array.from(days);

            const tasksByBerth = {};
            tasksData.forEach(task => {
                const berth = task.berth || 'default';
                if (!tasksByBerth[berth]) {
                    tasksByBerth[berth] = [];
                }
                tasksByBerth[berth].push(task);
            });

            const chartGroups = chartWrapperRef.current.querySelector('.chart-groups');
            const chartGroupSeparators = chartWrapperRef.current.querySelector('.chart-group-separators');
            chartGroups.innerHTML = '';
            chartGroupSeparators.innerHTML = '';

            const berthPositions = {};
            const separatorElements = [];

            Object.keys(tasksByBerth).forEach((berth, index) => {
                const li = document.createElement('li');
                li.textContent = berth;
                li.style.fontWeight = 'bold';
                li.style.backgroundColor = '#fff';
                chartGroups.appendChild(li);
                berthPositions[berth] = index * 40;

                const separator = document.createElement('div');
                separator.className = 'chart-group-separator';
                separator.style.top = `${40 + index * 40}px`;
                separatorElements.push(separator);
            });

            separatorElements.forEach(separator => chartGroupSeparators.appendChild(separator));

            tasksData.forEach(task => {
                if (!picColors[task.PIC]) {
                    picColors[task.PIC] = generateRandomColor();
                }
            });

            Object.entries(tasksByBerth).forEach(([berth, group]) => {
                const topPosition = berthPositions[berth];

                group.forEach(task => {
                    const startDay = formatDate(task.startDate);
                    const endDay = formatDate(task.endDate);
                    let left = 0,
                        width = 0;

                    const startIndex = daysArray.findIndex(day => day.textContent === startDay);
                    const endIndex = daysArray.findIndex(day => day.textContent === endDay);

                    if (startIndex !== -1) {
                        left = daysArray[startIndex].offsetLeft;
                    }
                    if (endIndex !== -1) {
                        width = daysArray[endIndex].offsetLeft + daysArray[endIndex].offsetWidth - left;
                    }

                    const color = picColors[task.PIC] || '#808080';

                    const bar = document.createElement('li');
                    bar.style.left = `${left + 3}px`;
                    bar.style.width = `${width - 6}px`;
                    bar.style.backgroundColor = color;
                    bar.style.opacity = 1;
                    bar.style.top = `${topPosition + 8}px`;
                    bar.style.display = 'flex';
                    bar.style.alignItems = 'center';
                    bar.style.justifyContent = 'space-between';
                    bar.style.cursor = 'pointer';

                    bar.addEventListener('click', (e) => {
                        e.stopPropagation();
                        AbrirDetalhesOp(task.details);
                    });

                    const shipName = document.createElement('span');
                    shipName.textContent = task.name;
                    bar.appendChild(shipName);

                    const operatorLogos = document.createElement('div');
                    operatorLogos.className = 'operator-logos';

                    const operatorLogo1 = getOperatorLogo(task.operator1);
                    if (operatorLogo1) {
                        const logo1 = document.createElement('img');
                        logo1.src = operatorLogo1;
                        logo1.alt = task.operator1;
                        operatorLogos.appendChild(logo1);
                    }

                    const operatorLogo2 = getOperatorLogo(task.operator2);
                    if (operatorLogo2) {
                        const logo2 = document.createElement('img');
                        logo2.src = operatorLogo2;
                        logo2.alt = task.operator2;
                        operatorLogos.appendChild(logo2);
                    }

                    bar.appendChild(operatorLogos);

                    chartWrapperRef.current.querySelector('.chart-bars').appendChild(bar);
                });
            });

            const chartGroupsLis = chartGroups.querySelectorAll('li');
            Object.keys(tasksByBerth).forEach((berth, index) => {
                chartGroupsLis[index].style.top = `${berthPositions[berth]}px`;
            });
        };

        createChart();

        window.addEventListener("resize", createChart);
        return () => {
            window.removeEventListener("resize", createChart);
        };
    }, [tasksData]);

    const orderedBercos = [
        "CS2",
        "CS3",
        "ARM 31",
        "ARM 32",
        "ARM 33",
        "ARM 33/34",
        "ARM 35P1",
        "ARM 35P2"
    ];

    const handleDelete = async () => {
        if (selectedShip && selectedShip.id) {
            try {
                const response = await fetch(`https://hml.operacao.orion-op.com.br/api/delete-line-up/${selectedShip.id}`, {
                    method: 'DELETE',
                });

                if (response.ok) {
                    showAlert('Navio excluído com sucesso!', 'success');

                    // Aguarda 2 segundos antes de atualizar a página
                    setTimeout(() => {
                        setTasksData(prevTasks => prevTasks.filter(task => task.id !== selectedShip.id));
                        FecharDetalhesOp(); // Fecha o diálogo após a exclusão
                        window.location.reload(); // Atualiza a página
                    }, 1000);
                } else {
                    showAlert('Falha ao excluir o navio.', 'error');
                }
            } catch (error) {
                console.error('Erro ao excluir o navio:', error);
                showAlert('Erro ao excluir o navio.', 'error');
            }
        }
    };


    return (
        <>
            <Header />
            <Navbar />
            <div className="rel-container">
                <div className="nav">
                    <div className="navItem">
                        Line-Up
                    </div>
                </div>
                <div className="chart-wrapper" ref={chartWrapperRef}>
                    <ul className="chart-groups"></ul>
                    <div className="chart-group-separators"></div>
                    <div className="chart-container">
                        <ul className="chart-values">
                            {chartValues.map((date, index) => (
                                <li key={index}>{date}</li>
                            ))}
                        </ul>
                        <ul className="chart-bars"></ul>
                    </div>
                </div>

                <button className="addButton" onClick={toggleForm}>
                    {showForm ? '-' : '+'}
                </button>

                {showForm && (
                    <div className="addForm">
                        <form>
                            <div>
                                <label>Navio:</label>
                                <select name="navio" required>
                                    <option value="" disabled selected>
                                        Selecione um Navio
                                    </option>
                                    {navios.map((navio) => (
                                        <option key={navio.COD_NAVIO} value={navio.COD_NAVIO}>
                                            {navio.NOME_NAVIO}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <label>Agente:</label>
                                <select name="agente" required>
                                    <option value="" disabled selected>
                                        Selecione um Agente
                                    </option>
                                    {agentes.map((agente) => (
                                        <option key={agente.COD_AGENTE} value={agente.COD_AGENTE}>
                                            {agente.NOME_AGENTE}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div>
                                <label>Operador:</label>
                                <select name="operador" required>
                                    <option value="" disabled selected>
                                        Selecione um Operador
                                    </option>
                                    {operadores.map((operador) => (
                                        <option key={operador.COD_OPERADOR_PORTUARIO} value={operador.COD_OPERADOR_PORTUARIO}>
                                            {operador.NOME_REDUZIDO_OPERADOR_PORTUARIO}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <button type="submit">Adicionar Navio</button>
                        </form>
                    </div>
                )}
                <Dialog
                    open={openA}
                    onClose={FecharDetalhesOp}
                    fullWidth
                    maxWidth="lg"
                    PaperProps={{
                        style: {
                            maxHeight: '70%',
                            width: '50%',
                        },
                    }}
                >
                    {selectedShip && (
                        <div className="ship-details">
                            <h3>Detalhes do Navio</h3>
                            <p><strong>Navio:</strong> MV {selectedShip.NOME_NAVIO}</p>
                            <p><strong>Agente:</strong> {selectedShip.NOME_AGENTE}</p>
                            <p>
                                <strong>Berço: </strong>
                                {showBercoSelect ? (
                                    <select className="custom-select" onChange={handleEditBerco} value={selectedShip.COD_BERCO}>
                                        {orderedBercos
                                            .map(orderBerco => {
                                                const berco = bercos.find(berco => berco.NOME_BERCO === orderBerco);
                                                return berco ? (
                                                    <option key={berco.COD_BERCO} value={berco.COD_BERCO}>
                                                        {berco.NOME_BERCO}
                                                    </option>
                                                ) : null;
                                            })
                                        }
                                    </select>
                                ) : (
                                    <>
                                        {selectedShip.NOME_BERCO}
                                        <FaEdit onClick={() => setShowBercoSelect(true)} style={{ cursor: 'pointer', marginLeft: '8px' }} />
                                    </>
                                )}
                            </p>

                            <div style={{ marginTop: '10px', textAlign: 'left' }}>
                                <p><strong>OPERADOR - 1:</strong> {selectedShip?.NOME_REDUZIDO_OPERADOR_PORTUARIO_01 || 'N/A'}</p>
                                <p><strong>OPERADOR - 2:</strong> {selectedShip?.NOME_REDUZIDO_OPERADOR_PORTUARIO_02 || 'N/A'}</p>
                                <p><strong>OPERADOR - 3:</strong> {selectedShip?.NOME_REDUZIDO_OPERADOR_PORTUARIO_03 || 'N/A'}</p>
                            </div>

                            <p style={{ display: 'flex', alignItems: 'center' }}>
                                <strong>Período:</strong>
                                <form onSubmit={handleSubmit} style={{ marginLeft: '10px' }}>
                                    <div className="datePickerContainer">
                                        <div className="dateRangeLabel" onClick={() => setShowDatePicker(true)}>
                                            {startDate && endDate
                                                ? `${startDate.toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit' })} - ${endDate.toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit' })}`
                                                : `${new Date().toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit' })} - ${new Date().toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit' })}`
                                            }
                                        </div>

                                        {showDatePicker && (
                                            <div className="datePickerWrapper">
                                                <DatePicker
                                                    selected={startDate}
                                                    onChange={(dates) => {
                                                        const [start, end] = dates;
                                                        setStartDate(start);
                                                        setEndDate(end);
                                                        if (start && end) {
                                                            setShowDatePicker(false);
                                                        }
                                                    }}
                                                    startDate={startDate}
                                                    endDate={endDate}
                                                    selectsRange
                                                    inline
                                                    monthsShown={1}
                                                    locale="pt-BR"
                                                    dateFormat="dd/MM/yy"
                                                    placeholderText="Selecione o período"
                                                />
                                            </div>
                                        )}
                                    </div>
                                </form>

                            </p>
                            <FaTrash
                                onClick={handleDelete}
                                style={{
                                    position: 'absolute',
                                    bottom: '20px',
                                    right: '20px',
                                    cursor: 'pointer',
                                    color: 'red'
                                }}
                                size={24}
                            />

                        </div>
                    )}
                </Dialog>
            </div>
        </>
    );
};

export default function IntegrationNotistack() {
    return (
        <SnackbarProvider
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            maxSnack={3}
            autoHideDuration={2500}>
            <PosicaoNavios />
        </SnackbarProvider>
    );
}
