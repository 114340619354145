import { Navigate, useNavigate, useParams } from "react-router-dom";
import { SnackbarProvider, useSnackbar } from 'notistack';
import { useEffect, useState, } from 'react';
import Axios from "axios";
import Brackground from "../../../components/Background";
import Confirm from '@mui/material/Dialog';
import Container from "../../../components/Container";
import Header from "../../../components/Header";
import Input from "../../../components/Input";
import Navbar from "../../../components/Navbar";
import Paralisacao from '@mui/material/Dialog';
import ParalisacaoFim from '@mui/material/Dialog';
import Pesagem from '@mui/material/Dialog';
import React from "react";
import confirm from "./Confirm.module.css";
import modal from "./Modal.module.css";
import moment from 'moment';
import style from "./Operacao.module.css";
import { PersonRemoveSharp } from "@mui/icons-material";


const Operacao = () => {

  useEffect(() => {
    DadosDashboard();
    getVeiculos();
    getQtDescarregado();
    getAlerta();
    getQtDescarregadom();
    getQtDescarregadoh();
    getSaldom();
    getMotivos();
    getTotalSaldo();
    VerificaParalisacao();
    getComplementos();
    getCargas();
    getTipoveiculo()
    getTransportadora()
    getDocdibl()
    getDocumentos();
    getDocs();
    getPedido();
  }, [])



  useEffect(() => {
    const interval_1 = setInterval(() => {
      DadosDashboard();
      getVeiculos();
      getQtDescarregado();
      getQtDescarregadom();
      getQtDescarregadoh();
      getSaldom();
      getTotalSaldo();
      getMotivos();
      VerificaParalisacao();
      getComplementos();
    }, 1500)

    return () => clearInterval(interval_1); //This is important

  }, [])

  const navigate = useNavigate();
  let { id } = useParams();

  const [busca, setBusca] = useState("");

  const [existeParalisacao, setExisteParalisacao] = useState(0);
  const [dadosDash, setDadosDash] = useState([]);
  const [veiculos, setVeiculos] = useState([]);
  const [paralisacoes, setParalisacoes] = useState([]);
  const [descarregado, setDescarregado] = useState([]);
  const [alerta, setAlerta] = useState([]);
  const [descarregadoh, setDescarregadoh] = useState([]);
  const [saldo, setSaldo] = useState([]);
  const [descarregadom, setDescarregadom] = useState([]);
  const [saldom, setSaldom] = useState([]);
  const [docs, setDocs] = useState([]);
  const [doc, setDoc] = useState('');
  const [mostrarProvisorio, setMostrarProvisorio] = useState(false);
  const [pesoProvisorio, setPesoProvisorio] = useState('');
  const [dataProvisorio, setDataProvisorio] = useState('');


  const [porao, setPorao] = useState(null);
  const [motivos, setMotivos] = useState([]);
  const [complementos, setComplementos] = useState([]);
  const [motivo, setMotivo] = useState("");
  const [complemento, setComplemento] = useState("");
  const [dtinicio, setDtinicio] = useState("");
  const [obs, setObs] = useState("");
  const usuario = JSON.parse(localStorage.getItem("user_token")).id;

  const [i, setI] = useState({});
  const [peso2, setPeso2] = useState("");
  const [ticket, setTicket] = useState("");
  const [data, setData] = useState();
  const [obsPesagem, setObsPesagem] = useState("");

  const [dataFim, setDataFim] = useState("");
  const [dataFimPara, setDataFimPara] = useState("");

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isConfirmDisabled, setIsConfirmDisabled] = useState(false);

  const [mostaInput1, setMostaInput1] = useState(false);
  const [mostaInput2, setMostaInput2] = useState(false);
  const [mostaInput3, setMostaInput3] = useState(false);
  const [mostaInput4, setMostaInput4] = useState(false);
  const [mostaInput5, setMostaInput5] = useState(false);
  const [mostaInput6, setMostaInput6] = useState(false);
  const [mostaInput7, setMostaInput7] = useState(false);
  const [mostaInput8, setMostaInput8] = useState(false);
  const [mostaInput9, setMostaInput9] = useState(false);
  const [mostaInput10, setMostaInput10] = useState(false);
  const [viewMode, setViewMode] = useState('MOEGA');

  const [mostrarPesoProvisorio, setMostrarPesoProvisorio] = useState(false);
  const [pesoProvisorioTemp, setPesoProvisorioTemp] = useState(pesoProvisorio);
  const [mostrarDataProvisorioPY, setMostrarDataProvisorioPY] = useState(false);
  const [dataProvisorioPYTemp, setDataProvisorioPYTemp] = useState(dataProvisorio);

  const [dataPy, setDataPy] = useState([{ PESO_CARREGADO_PY: "", DATA_CARREGAMENTO_PY: "", USUARIO_CARREG_PY: "" }]);
  const [pedidos, setPedidos] = useState([]);
  const [pedido, setPedido] = useState('')
  const [documento, setDocumento] = useState([])
  const [documentos, setDocumentos] = useState([])
  const [placaCavalo, setPlacaCavalo] = useState('')
  const [placa1, setPlaca1] = useState('')
  const [placa2, setPlaca2] = useState('')
  const [placa3, setPlaca3] = useState('')
  const [tara, setTara] = useState('')
  const [dataTara, setDataTara] = useState('')
  const [tipoveiculos, setTipoveiculos] = useState([])
  const [transportadora, setTransportadora] = useState([])
  const [transportadoras, setTransportadoras] = useState([])
  const [tipoveiculo, setTipoveiculo] = useState([])
  const [docdibl, setDocdibl] = useState([])
  const [docsdibl, setDocsdibl] = useState([])

  //modal de 2 pesagem
  const [openA, setOpenA] = useState(false);
  const AbrirPesagem = () => {
    setOpenA(true);
  };
  const FecharPesagem = () => {
    setOpenA(false);
    setData("");
    setPeso2("");
  };

  const [openC, setOpenC] = useState(false);
  const AbrirConfirm = () => {
    setOpenC(true);
  };
  const FecharConfirm = () => {
    setOpenC(false);
  };

  const [MostaInput, setMostaInput] = useState(false);

  const divClick = () => {
    setMostaInput(true);
  };

  const toggleProvisorio = (id) => {
    setMostrarProvisorio((prevProvisorio) => ({
      ...prevProvisorio,
      [id]: !prevProvisorio[id],
    }));
  };

  //modal de paralisacao
  const [openB, setOpenB] = useState(false);
  const AbrirParalisacao = () => {
    setOpenB(true);
  };
  const FecharParalisacao = () => {
    setOpenB(false);
  };

  const [openD, setOpenD] = useState(false);
  const AbrirParalisacaoFim = () => {
    setOpenD(true);
  };
  const FecharParalisacaoFim = () => {
    setOpenD(false);
    setDataFimPara("")
  };

  const editarPesoProvisorio = () => {
    setMostrarPesoProvisorio(true);
  };

  const salvarPesoProvisorio = () => {
    setPesoProvisorio(pesoProvisorioTemp);
    setMostrarPesoProvisorio(false);
    // Adicione aqui a lógica para salvar o valor no backend se necessário
  };

  const cancelarEdicaoPesoProvisorio = () => {
    setPesoProvisorioTemp(pesoProvisorio); // Restaura o valor original
    setMostrarPesoProvisorio(false);
  };

  const editarDataProvisorioPY = () => {
    setMostrarDataProvisorioPY(true);
  };

  const salvarDataProvisorioPY = () => {
    setDataProvisorio(dataProvisorioPYTemp);
    setMostrarDataProvisorioPY(false);
    // Adicione aqui a lógica para salvar o valor no backend se necessário
  };

  const cancelarEdicaoDataProvisorioPY = () => {
    setDataProvisorioPYTemp(dataProvisorio); // Restaura o valor original
    setMostrarDataProvisorioPY(false);
  };


  const getTipoveiculo = () => {
    Axios.get(`https://hml.operacao.orion-op.com.br/api/tipoveiculo`,)
      .then(function (res) {
        setTipoveiculos(res.data);
      });
  }

  const getTransportadora = () => {
    Axios.get(`https://hml.operacao.orion-op.com.br/api/transportadora`,)
      .then(function (res) {
        setTransportadoras(res.data);
      });
  }

  const getDocdibl = () => {
    const url = window.location.href; // Obtém a URL atual do navegador
    const parts = url.split("/"); // Divide a URL em partes usando a barra como delimitador
    const idOperacao = parts[parts.length - 1]; // Obtém a parte final da URL (o ID da operação)

    Axios.get(`https://hml.operacao.orion-op.com.br/api/carga/${idOperacao}`)
      .then(function (res) {
        setDocsdibl(res.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  }


  const getDocumentos = () => {
    Axios.get(`https://hml.operacao.orion-op.com.br/api/documentos/${id}`,)
      .then(function (res) {
        setDocumentos(res.data);
      });
  }

  const getCargas = () => {
    Axios.get(`https://hml.operacao.orion-op.com.br/api/carga/busca/${id}`,)
      .then(function (res) {
        setDocs(res.data);
      });
  }

  const getDate = () => {
    const date = new Date()
    date.setHours(date.getHours() - 3)
    return (date.toISOString().slice(0, 19).replace('T', ' '))
  }

  const VerificaParalisacao = () => {
    Axios.get(`https://hml.operacao.orion-op.com.br/api/verifica/paralisacao/${id}`,)
      .then(function (res) {
        setExisteParalisacao(res.data)
      })
  }

  const [dis, setDis] = useState([])
  const getDocs = () => {
    Axios.get(`https://hml.operacao.orion-op.com.br/api/carga/busca/${id}`,)
      .then(function (res) {
        setDis(res.data)
      });
  }

  const getPedido = () => {
    Axios.get(`https://hml.operacao.orion-op.com.br/api/buscar/pedidos/${id}`,)
      .then(function (res) {
        setPedidos(res.data);
      });
  }

  const DadosDashboard = () => {
    Axios.get(`https://hml.operacao.orion-op.com.br/api/periodo/dashboard/${id}`,)
      .then(function (res) {
        setDadosDash(res.data[0])
        Axios.get(`https://hml.operacao.orion-op.com.br/api/paralisacao/periodo/${res.data[0].SEQ_PERIODO_OP}`,)
          .then(function (res) {
            setParalisacoes(res.data)

          })
      })
  }
  const getVeiculos = () => {
    Axios.get(`https://hml.operacao.orion-op.com.br/api/dashboard/veiculos/${id}`,)
      .then(function (res) {
        setVeiculos(res.data)
      })
  }

  const getVeiculoAtual = () => {
    return veiculos.find(item => item.ID_CARREGAMENTO === i.ID_CARREGAMENTO) || i
  }

  const getQtDescarregado = () => {
    Axios.get(`https://hml.operacao.orion-op.com.br/api/dashboard/descarregado/${id}`,)
      .then(function (res) {
        setDescarregado(res.data[0].DESCARREGADO)
      })
  }

  const getQtDescarregadoh = () => {
    Axios.get(`https://hml.operacao.orion-op.com.br/api/dashboard/descarregadoh/${id}`,)
      .then(function (res) {
        setDescarregadoh(res.data[0].DESCARREGADOH)
      })
  }

  const getAlerta = () => {
    Axios.get(`https://hml.operacao.orion-op.com.br/api/operacao/alerta/${id}`)
      .then(function (res) {
        setAlerta(res.data);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const getQtDescarregadom = () => {
    Axios.get(`https://hml.operacao.orion-op.com.br/api/dashboard/descarregadom/${id}`,)
      .then(function (res) {
        setDescarregadom(res.data[0].DESCARREGADOM)
      })
  }

  const getSaldom = () => {
    Axios.get(`https://hml.operacao.orion-op.com.br/api/dashboard/saldom/${id}`,)
      .then(function (res) {
        setSaldom(res.data[0].SALDOM)
      })
  }

  const getTotalSaldo = () => {
    Axios.get(`https://hml.operacao.orion-op.com.br/api/dashboard/saldo/${id}`,)
      .then(function (res) {
        setSaldo(res.data[0].SALDO)
      })
  }

  const getMotivos = () => {
    Axios.get(`https://hml.operacao.orion-op.com.br/api/motivos`,)
      .then(function (res) {
        setMotivos(res.data)
      })
  }
  const getComplementos = () => {
    Axios.get(`https://hml.operacao.orion-op.com.br/api/complementos`,)
      .then(function (res) {
        setComplementos(res.data)
      })
  }

  const addParalisacao = () => {
    Axios.post('https://hml.operacao.orion-op.com.br/api/paralisacao/criar', {
      operacao: id,
      periodo: dadosDash.SEQ_PERIODO_OP,
      motivo: motivo,
      obs: obs,
      dtinicio: dtinicio,
      usuario: usuario,
      dtcadastro: getDate()
    }).then(function (res) {
      if (res.data.sqlMessage)
        showAlert(res.data.sqlMessage, 'error')
      else {
        showAlert('Paralisação adicionada!', 'success');
      }
    })
    FecharParalisacao()
    dadosDash()
  }

  const [inputs, setInputs] = useState([
    { name: 'Nome do motorista:', id: 1, value: `${i.NOME_MOTORISTA}`, show: false },
    { name: 'Placa do calvalo: ', id: 2, value: `${i.PLACA_CAVALO}`, show: false },
    { name: 'Placa da carreta 1: ', id: 3, value: `${i.PLACA_CARRETA}`, show: false },
    { name: 'Placa da carreta 2:', id: 4, value: `${i.PLACA_CARRETA2}`, show: false },
    { name: 'Placa da carreta 3:', id: 5, value: `${i.PLACA_CARRETA3}`, show: false },
    { name: '1º peso (tara):', id: 6, value: `${i.PESO_TARA}`, show: false },
    { name: 'Pedido MIC:', id: 7, value: `${i.PEDIDO_MIC}`, show: false },
  ]);

  const handleDivClick = (id) => {
    const newInputs = [...inputs];
    const index = newInputs.findIndex((input) => input.id === id);
    newInputs[index].show = !newInputs[index].show;
    setInputs(newInputs);
  };

  const divClick2 = () => {
    setMostaInput2(true);
  };
  const divClick3 = () => {
    setMostaInput3(true);
  };
  const divClick4 = () => {
    setMostaInput4(true);
  };
  const divClick5 = () => {
    setMostaInput5(true);
  };
  const divClick6 = () => {
    setMostaInput6(true);
  };
  const divClick7 = () => {
    setMostaInput7(true);
  };
  const divClick8 = () => {
    setMostaInput8(true);
  };
  const divClick9 = () => {
    setMostaInput9(true);
  };
  const divClick10 = () => {
    setMostaInput10(true);
  };



  const toggleView = (mode) => {
    setViewMode(mode);
  };

  const copyToClipboardFallback = text => {
    if (!text) return;

    const textarea = document.createElement('textarea');
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    try {
      const successful = document.execCommand('copy');
      if (successful) {
        showAlert('Texto copiado com sucesso!', 'success');
      } else {
        showAlert('Falha ao copiar texto.', 'error');
      }
    } catch (err) {
      console.error('Erro ao copiar texto: ', err);
      showAlert('Erro ao copiar texto.', 'error');
    }
    document.body.removeChild(textarea);
  };

  const validaPara = () => {
    let d1 = moment(dtinicio, "YYYY-MM-DD HH:mm:ss");
    let d2 = moment(dataFimPara, "YYYY-MM-DDTHH:mm:ss");

    if (d1.isAfter(d2)) {
      return showAlert('Data de início da paralisação não pode ser posterior à data de término', 'error');
    } else {
      // Verificar a diferença total em minutos entre as duas datas
      let diffMinutes = d2.diff(d1, 'minutes');

      // Se a diferença for maior que 7 horas (420 minutos), exibir erro
      if (diffMinutes > 420) {
        return showAlert('A paralisação não pode durar mais de 7 horas', 'error');
      } else {
        encerrarParalisacao();
      }
    }
  }

  const encerrarParalisacao = () => {
    if (!dataFimPara) {
      showAlert('Preencha todos os campos', 'error')
      return;
    }

    Axios.put('https://hml.operacao.orion-op.com.br/api/encerrar/paralisacao',
      {
        id: paralisacoes[0].SEQ_PARALISACAO,
        data: dataFimPara,
      }).then(function (res) {
        res.data.sqlMessage ?
          showAlert(res.data.sqlMessage, 'error') :
          showAlert('paralisacao encerrada!', 'success');
        FecharParalisacaoFim()
      });
  }

  const validaplaca3 = async () => {
    await Axios.put('https://hml.operacao.orion-op.com.br/api/alterar/carreta3',
      {
        id: i.ID_CARREGAMENTO,
        placa: placa3,
      }).then(function (res) {
        res.data.sqlMessage ?
          showAlert(res.data.sqlMessage, 'error') :
          showAlert('Placa 3 alterada com sucesso!', 'success');
        setMostaInput5(false)
        setOpenA(false);
      });
  }

  // Função chamada quando o usuário clica em "Reportar"
  const reportProblemas = async () => {
    try {
      const response = await Axios.put('https://hml.operacao.orion-op.com.br/api/segundapesagem/problemas', {
        id: i.ID_CARREGAMENTO,
        pesoProvisorio: pesoProvisorioTemp,
        dataProvisorioPY: dataProvisorioPYTemp,
      });

      if (response.data === "sucesso") {
        showAlert('Dados atualizados com sucesso!', 'success');
        // Atualize o estado conforme necessário para refletir os novos valores na UI
      } else {
        showAlert('Erro ao atualizar os dados.', 'error');
      }
    } catch (error) {
      console.error('Erro ao reportar problemas:', error);
      showAlert('Erro ao conectar ao servidor.', 'error');
    }
  };

  const validaVeiculo = async () => {
    await Axios.put('https://hml.operacao.orion-op.com.br/api/veiculo/atualiza',
      {
        tipoveiculo: tipoveiculo,
        id: i.ID_CARREGAMENTO
      }).then(function (res) {
        res.data.sqlMessage ?

          showAlert(res.data.sqlMessage, 'error') :
          showAlert('Veiculo alterado com sucesso!', 'success');

        setMostaInput7(false)
        setOpenA(false);
      });
  }

  const validaTransportadora = async () => {
    await Axios.put('https://hml.operacao.orion-op.com.br/api/transportadora/atualiza',
      {
        transportadora: transportadora,
        id: i.ID_CARREGAMENTO
      }).then(function (res) {
        res.data.sqlMessage ?

          showAlert(res.data.sqlMessage, 'error') :
          showAlert('Transportadora alterada com sucesso!', 'success');

        setMostaInput9(false)
        setOpenA(false);
      });
  }

  const validaDocdibl = async () => {
    await Axios.put('https://hml.operacao.orion-op.com.br/api/docsdibl/atualiza',
      {
        docdibl: docdibl,
        id: i.ID_CARREGAMENTO
      }).then(function (res) {
        res.data.sqlMessage ?

          showAlert(res.data.sqlMessage, 'error') :
          showAlert('Carga alterada com sucesso!', 'success');

        setMostaInput10(false)
        setOpenA(false);
      });
  }

  const validaplaca2 = async () => {
    await Axios.put('https://hml.operacao.orion-op.com.br/api/alterar/carreta2',
      {
        id: i.ID_CARREGAMENTO,
        placa: placa2,
      }).then(function (res) {
        res.data.sqlMessage ?
          showAlert(res.data.sqlMessage, 'error') :
          showAlert('Placa 2 alterada com sucesso!', 'success');
        setMostaInput4(false)
        setOpenA(false);
      });
  }

  const validaPlaca1 = () => {
    if (!placa1) {
      showAlert('Placa 1 deve conter 7 dígitos!', 'error');
      return;
    }

    atualizaPlaca1()
  }

  const atualizaPlaca1 = async () => {
    await Axios.put('https://hml.operacao.orion-op.com.br/api/alterar/carreta1',
      {
        id: i.ID_CARREGAMENTO,
        placa: placa1,
      }).then(function (res) {
        res.data.sqlMessage ?
          showAlert(res.data.sqlMessage, 'error') :
          showAlert('Placa 1 alterada com sucesso!', 'success');
        setMostaInput3(false)
        setOpenA(false);
      });
  }

  const validaDoc = async () => {

    await Axios.put('https://hml.operacao.orion-op.com.br/api/documentos/atualiza',
      {
        documento: parseInt(documento),
        id: i.ID_CARREGAMENTO,
      }).then(function (res) {
        res.data.sqlMessage ?
          showAlert(res.data.sqlMessage, 'error') :
          showAlert('Pedido alterado com sucesso!', 'success');
        setMostaInput8(false)
        setOpenA(false);
      });
  }

  const validaCavalo = () => {
    if (!placaCavalo) {
      showAlert('Placa Cavalo deve conter 7 dígitos!', 'error');
      return;
    }

    atualizaCavalo()
  }

  const atualizaCavalo = async () => {
    await Axios.put('https://hml.operacao.orion-op.com.br/api/alterar/cavalo',
      {
        id: i.ID_CARREGAMENTO,
        placa: placaCavalo,
      }).then(function (res) {
        res.data.sqlMessage ?
          showAlert(res.data.sqlMessage, 'error') :
          showAlert('Placa Cavalo alterada com sucesso!', 'success');
        setMostaInput2(false)
        setOpenA(false);
      });
  }

  const validaTara = () => {
    if (!tara) {
      showAlert('Para atualizar a tara é necessário ter o peso!', 'error');
      return;
    }
    if (!dataTara) {
      showAlert('Para atualizar a tara é necessário ter a data!', 'error');
      return;
    }
    atualizaTara()
  }

  const atualizaTara = async () => {
    await Axios.put('https://hml.operacao.orion-op.com.br/api/alterar/tara',
      {
        tara: tara,
        data: dataTara,
        id: i.ID_CARREGAMENTO,
        usuario: usuario
      }).then(function (res) {
        res.data.sqlMessage ?
          showAlert(res.data.sqlMessage, 'error') :
          showAlert('Tara alterada com sucesso!', 'success');
        setMostaInput6(false)
        setOpenA(false);
      });
  }

  const validaDados1 = () => {
    if (!motivo | !complemento | !dtinicio) {
      showAlert('Preencha todos os campos', 'error')
      return;
    }
    addParalisacao();
  }

  const validaDados2 = () => {
    if (i.NOME_TRANSPORTADORA == "NÃO INFORMADA") {
      showAlert('Escolha a transportadora da MINUTA', 'error')
      return;
    }
    if (!porao) {
      showAlert('Selecione o porão', 'error')
      return;
    }
    if (!peso2 | !data) {
      showAlert('Faça a INTEGRAÇÃO para computar a 2° Pesagem e a Data.', 'error')
      return;
    }
    if (
      (i.PESO_TARA >= 0 && i.PESO_TARA <= 999) || (i.PESO_TARA >= 1001 && i.PESO_TARA <= 7999) || i.PESO_TARA == null) {
      showAlert('Registre o peso TARA corretamente', 'error');
      return;
    }
    if (ticket !== String(i.ID_CARREGAMENTO)) {
      showAlert('O Ticket não corresponde ao ID de Carregamento', 'error')
      return;
    }
    if (i.DESC_TIPO_VEICULO == "AGUARDANDO MODELO") {
      showAlert('É obrigatório a escolha do tipo do veículo', 'error')
      return;
    }
    if (peso2 > 55000000) {
      showAlert('Peso excedido!', 'error')
      return;
    }

    SegundaPesagem();
  }

  const puxarPY = () => {
    Axios.get(`https://hml.operacao.orion-op.com.br/api/puxarpy/${i.ID_CARREGAMENTO}`,)
      .then(function (res) {
        if (!res.data[0].DATA_CARREGAMENTO_PY) {
          showAlert('Peso de integração ainda não computado!', 'error')
        }
        console.log(new Date(res.data[0].DATA_CARREGAMENTO_PY).toISOString());
        setData(res.data[0].DATA_CARREGAMENTO_PY.slice(0, -2))
        setPeso2(res.data[0].PESO_CARREGADO_PY)
      });
  }

  //setI
  const validaDados3 = () => {
    if (!dataFim) {
      showAlert('Preencha a data!', 'error')
      return;
    }
    encerrarPeriodo();
  }

  const GerarNotaMIC = async () => {
    if (getVeiculoAtual().STATUS_NOTA_MIC == 4)
      return

    const preparaPlaca = (placa) => {
      if (placa == undefined || placa == '')
        return ''

      return placa.replace(/\s/g, '').slice(0, 3) + ' ' + placa.replace(/\s/g, '').slice(3)
    }

    const input_data = {
      placa1: preparaPlaca(i.PLACA_CARRETA),
      placa2: preparaPlaca(i.PLACA_CARRETA2),
      placa3: preparaPlaca(i.PLACA_CARRETA3),
      placaCavalo: preparaPlaca(i.PLACA_CAVALO),
      num_DI: i.NUMERO_DOC,
      pedido_mic: i.PEDIDO_MIC,
      peso_bruto: parseFloat(i.PESO_TARA) + parseFloat(i.PESO_CARREGADO || peso2),
      peso_liquido: i.PESO_CARREGADO || peso2,
      tara: 1.0,
      codTiquete: i.TICKET || ticket,
      data: i.DATA_CARREGAMENTO || data
    };

    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `https://hml.operacao.orion-op.com.br/api/gerarnotamic/${i.ID_CARREGAMENTO}`,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      data: input_data
    };

    await Axios.request(config)
    FecharPesagem()
  }

  const EntregarNotaMIC = async () => {
    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `https://hml.operacao.orion-op.com.br/api/entregarnotamic/${i.ID_CARREGAMENTO}`,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    };

    await Axios.request(config)
    FecharPesagem()
    showAlert("Nota entregue!", 'success')
  }

  const datetimeLocal = (datetime) => {
    if (datetime == undefined)
      return ''

    const dt = new Date(datetime);
    dt.setMinutes(dt.getMinutes() - dt.getTimezoneOffset());
    return dt.toISOString().slice(0, 16).replace("T", " ");
  }

  const DownloadNota = async () => {
    const input_data = {
      idCarregamento: i.ID_CARREGAMENTO
    };

    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `https://hml.operacao.orion-op.com.br/api/baixarnota`,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      data: input_data
    };

    Axios.request(config)
      .then((res) => {
        var byteCharacters = atob(res.data.pdf.split(',')[res.data.pdf.split(',').length - 1]);
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        var file = new Blob([byteArray], { type: res.data.pdf.split(',')[0].split(":")[1] });

        const url = URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = url;
        link.download = res.data.filename
        link.click();
        URL.revokeObjectURL(url);
      })
      .catch((err) => {
        showAlert('Erro ao obter PDF', 'error')
      })
  }

  const SegundaPesagem = () => {
    Axios.put('https://hml.operacao.orion-op.com.br/api/segundapesagem',
      {
        peso2: peso2,
        data: data,
        usuario: usuario,
        ticket: ticket,
        porao: porao,
        id: i.ID_CARREGAMENTO,
      }).then(function (res) {
        if (res.data.sqlMessage)
          showAlert(res.data.sqlMessage, 'error')
        else {
          showAlert('Veiculo pesado com sucesso!', 'success');

          if (i.PESO_TARA == 1000)
            GerarNotaMIC()

          FecharPesagem()
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const { enqueueSnackbar } = useSnackbar();
  const showAlert = (txt, variant) => {
    enqueueSnackbar(txt, { variant: variant });
  }

  const encerrarPeriodo = () => {
    Axios.put('https://hml.operacao.orion-op.com.br/api/periodo/finalizar',
      {
        id: dadosDash.SEQ_PERIODO_OP,
        id_operacao: dadosDash.COD_OPERACAO,
        data: dataFim
      }).then(function (res) {
        res.data.sqlMessage ?
          showAlert(res.data.sqlMessage, 'error') :
          showAlert('Período finalizado com sucesso!', 'success');
        FecharConfirm();
      });
  }

  const [currentAlertIndex, setCurrentAlertIndex] = useState(0);
  useEffect(() => {
    if (alerta.length > 0) {
      const interval = setInterval(() => {
        setCurrentAlertIndex(prevIndex => (prevIndex + 1) % alerta.length);
      }, 4000); // Alterna a cada 4 segundos

      return () => clearInterval(interval); // Limpa o intervalo ao desmontar o componente
    }
  }, [alerta]);

  return (
    <>
      <Navbar operacao />
      <Header />
      <Brackground />
      <Container>
        <div className={style.content}>
          <div className={style.nav}>
            <div onClick={() => navigate(`/operacoes`)}>
              Viagens
            </div>
            <div className={style.active} >
              2° Pesagem
            </div>
            <div onClick={() => navigate(`/operacao/pesagemfinal/${id}`)}>
              3° Pesagem
            </div>
            <div onClick={() => navigate(`/operacao/GraficoDocs/${id}`)}>
              DashBoard
            </div>
            <div onClick={() => navigate(`/operacao/${id}/AberturaPeriodo`)}>
              Abertura de Período
            </div>
          </div>
          {!dadosDash.SEQ_PERIODO_OP ?
            <div>
              <div className={style.notform}>DASHBOARD INDISPONÍVEL</div>
            </div>
            :
            <div>
              <div className={style.flex}>
                <div className={style.periodo}>
                  {dadosDash.DEN_PERIODO || "--/--"}
                  <div className={style.data}>
                    {/* 02/01/2023 */}
                    {moment(dadosDash.INI_PERIODO).format("DD/MM/YYYY") || "--/--"}
                  </div>
                </div>
                <div>
                </div>
                <div className={style.status}>
                  <div className={`${style[dadosDash.STATUS_OPERACAO]}`}>
                    <i className="fa fa-truck"></i>&nbsp;&nbsp;{dadosDash.STATUS_OPERACAO || "--"}
                  </div>
                </div>
              </div>
              <div className={style.flex}>
                {dadosDash.STATUS_OPERACAO !== 'PARALISADO' ? (
                  <div className={style.tara}>
                    <div className={style.taratitulo}>
                      1º Pesagem (Tara)
                      <div>
                        <input type="text" onChange={(e) => { setBusca(e.target.value) }} />
                        <i className="fa fa-search"></i>
                      </div>
                    </div>
                    <div className={style.sumario}>
                      <div>NOME</div>
                      <div>CAVALO</div>
                      <div>DATA | HORA </div>
                    </div>
                    <div className={style.lista}>
                      {veiculos.filter((val) => {
                        let nome = val.NOME_MOTORISTA.trim().split(' ')[0]
                        let horario = moment(val.DATA_TARA).format(' DD/MM HH:mm')

                        val.COR = 'item_status_' + (val.STATUS_NOTA_MIC == 1 ? 'def' : val.STATUS_NOTA_MIC)

                        if (busca == "") {
                          return val
                        } else if (nome.toLowerCase().includes(busca.toLowerCase()) || val.PLACA_CAVALO.toLowerCase().includes(busca.toLowerCase()) || horario.toLowerCase().includes(busca.toLowerCase())) {
                          return val
                        }
                      }).map((val, key) => {
                        return (
                          <div className={style.item + ' ' + style[val.COR]} onClick={() => [AbrirPesagem(), setI(val)]}>
                            <div className={style.item_cell}>{val.NOME_MOTORISTA.trim().split(' ')[0] || "-"}</div>
                            <div className={style.item_cell}>{val.PLACA_CAVALO || "-"}</div>
                            <div className={style.item_cell}>{moment(val.DATA_TARA).format('DD/MM | HH:mm') || "-"}</div>
                          </div>
                        )
                      })

                      }
                    </div>
                  </div>
                ) : (
                  <div className={style.disabledMessage}>
                    A seção de pesagem está desabilitada enquanto a operação estiver paralisada.
                  </div>
                )}
                <div className={style.colunadireita}>
                  <div className={style.paralisacao}>
                    <div className={style.sumariob}>
                      <div className={style.motivobox}>MOTIVO</div>
                      <div className={style.duracaobox}>DURAÇÃO</div>
                    </div>

                    <div className={style.flex}>
                      <div className={style.conteudobox}>

                        <div className={style.listab}>
                          {!paralisacoes.length ?
                            <div>--</div>
                            :
                            paralisacoes.map((val) => {
                              return (
                                <div className={style.itemb} >
                                  <div>{val.DESC_MOTIVO || "-"}</div>
                                  <div>{val.DURACAO || "-"} min</div>
                                </div>
                              )
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={style.flexPorao}>
                    <div className={style.pesos2}>
                      <input type="radio" id="porao1" name="porao" onChange={() => setPorao('1')} />
                      <label htmlFor="porao1">PORÃO #1</label>

                      <input type="radio" id="porao2" name="porao" onChange={() => setPorao('2')} />
                      <label htmlFor="porao2">PORÃO #2</label>

                      <input type="radio" id="porao3" name="porao" onChange={() => setPorao('3')} />
                      <label htmlFor="porao3">PORÃO #3</label>

                      <input type="radio" id="porao4" name="porao" onChange={() => setPorao('4')} />
                      <label htmlFor="porao4">PORÃO #4</label>

                      <input type="radio" id="porao5" name="porao" onChange={() => setPorao('5')} />
                      <label htmlFor="porao5">PORÃO #5</label>

                      <input type="radio" id="porao6" name="porao" onChange={() => setPorao('6')} />
                      <label htmlFor="porao6">PORÃO #6</label>


                    </div>
                  </div>
                </div>
              </div>

              .
              <div className={style.flex}>
                <div className={style.tara}>
                  <div className={style.flexContainer}>
                    <div className={style.dataBox}>
                      <span>MANIFESTADO</span>
                      <span>
                        {dadosDash.MANIFESTADO
                          ? (dadosDash.MANIFESTADO / 1000).toLocaleString('pt-BR', { minimumFractionDigits: 3, maximumFractionDigits: 3 })
                          : "--"
                        } TONS
                      </span>
                    </div>
                    <div className={style.dataBox}>
                      <span>DESCARREGADO</span>
                      <span>
                        {descarregadoh
                          ? (descarregadoh / 1000).toLocaleString('pt-BR', { minimumFractionDigits: 3, maximumFractionDigits: 3 })
                          : "0"
                        } TONS
                      </span>
                    </div>
                    <div className={`${style.dataBox} ${dadosDash.MANIFESTADO && descarregadoh && ((dadosDash.MANIFESTADO - descarregadoh) / 1000) < 100 ? style.saldoAlerta2 : ''}`}>
                      <span>SALDO</span>
                      <span>
                        {dadosDash.MANIFESTADO && descarregadoh
                          ? ((dadosDash.MANIFESTADO - descarregadoh) / 1000).toLocaleString('pt-BR', { minimumFractionDigits: 3, maximumFractionDigits: 3 })
                          : "--"
                        } TONS
                      </span>
                    </div>

                  </div>
                </div>

                {alerta.length > 0 && (
                  <>

                    {alerta.length > 0 && alerta.map((pedido, index) => {
                      if (index === currentAlertIndex) {
                        const saldoManifestado = ((pedido.QTDE_MANIFESTADA_PEDIDO / 1000) - (pedido.PESO_LIQUIDO_POR_PEDIDO / 1000));
                        if (saldoManifestado < 100) {
                          return (
                            <div className={`${style.taraAlerta} ${style.blink}`} key={pedido.PEDIDO_MIC_ALERTA}>
                              <div><i className="fa fa-exclamation-circle" aria-hidden="true"></i> ALERTA - PEDIDO</div>
                              <span>PEDIDO: {pedido.PEDIDO_MIC_ALERTA} - SALDO: {saldoManifestado.toLocaleString('pt-BR')} TONS</span>
                            </div>
                          );
                        }
                      }
                      return null; // Retorna null para pedidos que não atendem ao critério de alerta
                    })}
                  </>
                )}

              </div>

              <div className={style.flex}>
                <button
                  className={style.abrirp}
                  onClick={dadosDash.STATUS_OPERACAO == 'OPERANDO' ? AbrirParalisacao : AbrirParalisacaoFim}>
                  {dadosDash.STATUS_OPERACAO == 'OPERANDO' ? "ABRIR PARALISAÇÃO" : "ENCERRAR PARALISAÇÃO"}
                </button>
                <div className={style.navio}><i className="fa fa-ship icon"></i>&nbsp;&nbsp;&nbsp;{dadosDash.NOME_NAVIO || "--"}</div>
                <button
                  className={style.finalizar}
                  disabled={isButtonDisabled}  // Desabilita o botão se o estado for true
                  onClick={() => {
                    if (dadosDash.STATUS_OPERACAO == 'PARALISADO') {
                      showAlert('É necessário ENCERRAR A PARALISAÇÃO para finalizar este período', 'error');
                    } else {
                      AbrirConfirm();
                      setIsButtonDisabled(true);  // Desabilita o botão
                      setTimeout(() => {
                        setIsButtonDisabled(false);  // Reabilita o botão após 10 segundos
                      }, 30000);  // 10 segundos
                    }
                  }}
                >
                  FINALIZAR ESTE PERÍODO
                </button>
              </div>
            </div>}
        </div>
      </Container>
      <Pesagem open={openA} onClose={FecharPesagem} fullWidth>
        <div className={modal.modal}>
          <div className={modal.nav}>
            <div onClick={FecharPesagem}>Voltar</div>
            <div className={modal.active}>2º Pesagem </div>
          </div>
          <div className={modal.flex}>
            <div className={modal.periodo}>
              {dadosDash.DEN_PERIODO || "--/--"}
              <div className={modal.data}>
                {moment(dadosDash.INI_PERIODO).format("DD/MM/YYYY") || "--/--"}
              </div>
              {getVeiculoAtual().PESO_TARA == 1000 && getVeiculoAtual().STATUS_CARREG >= 3 && (
                <div className={modal.nota}>
                  <h2>MIC Sistemas</h2>
                  {getVeiculoAtual().STATUS_NOTA_MIC == 4 &&
                    <div className={modal.gera_nota} onClick={getVeiculoAtual().STATUS_NOTA_MIC == 4 ? DownloadNota : undefined} style={getVeiculoAtual().STATUS_NOTA_MIC == 4 ? { "cursor": "pointer" } : { "cursor": "auto" }}>
                      <i className="fa fa-file-pdf icon"></i>
                      <h3>BAIXAR Nota Fiscal</h3>
                    </div>}
                  {![2, 4].includes(getVeiculoAtual().STATUS_NOTA_MIC) &&
                    <div className={modal.gera_nota} onClick={GerarNotaMIC} style={getVeiculoAtual().STATUS_NOTA_MIC == 4 ? { "cursor": "auto" } : { "cursor": "pointer" }}>
                      <i className="fa fa-file-pdf icon"></i>
                      <h3>GERAR nota fiscal</h3>
                    </div>}
                  {getVeiculoAtual().STATUS_NOTA_MIC != 4 &&
                    (<label className={modal['obs_nota_status_' + (getVeiculoAtual().STATUS_NOTA_MIC < 3 ? 'def' : getVeiculoAtual().STATUS_NOTA_MIC)]}>
                      {getVeiculoAtual().OBS_NOTA}
                    </label>)}
                  {getVeiculoAtual().STATUS_NOTA_MIC == 4 &&
                    (<label>
                      <input onClick={EntregarNotaMIC} type="checkbox" style={{ "width": "auto", "marginRight": "5px" }} value="1" />
                      Entregue ao Motorista
                    </label>)}

                </div>
              )}

            </div>

            <div className={modal.motorista}>
              <div className={modal.motoristaid}>
                <div className={style.line}>
                  <b>ID de Carregamento: </b>
                  <span onClick={() => copyToClipboardFallback(i.ID_CARREGAMENTO)} style={{ cursor: 'pointer' }}>
                    {i.ID_CARREGAMENTO}
                  </span>
                </div>


                <div className={style.line}>
                  <b>Motorista: </b>{i.NOME_MOTORISTA}
                </div>
              </div>

              <div className={modal.motoristaid}>
                <div className={style.line}>
                  <b>Cavalo: </b>{mostaInput2 &&
                    < >
                      <input onChange={(e) => { setPlacaCavalo(e.target.value.toUpperCase()) }} placeholder="Placa do cavalo" className={style.inputline} type="text" />
                      <button onClick={validaCavalo} className={style.buttontline} type="submit"><i class="fa fa-check" aria-hidden="true"></i></button>
                      <button className={style.buttontlinecancel} onClick={() => setMostaInput2(false)}><i class="fa fa-times" aria-hidden="true"></i></button>
                    </> || i.PLACA_CAVALO}
                  <span><i onClick={divClick2} class="fa fa-pencil-square-o" aria-hidden="true"></i></span>
                </div>



                <div className={style.line}>
                  <b className={style.title}>1° Carreta: </b>{mostaInput3 &&
                    <>
                      <input onChange={(e) => { setPlaca1(e.target.value.toUpperCase()) }} placeholder="Placa 1" className={style.inputline} type="text" />
                      <button onClick={validaPlaca1} className={style.buttontline}><i class="fa fa-check" aria-hidden="true"></i></button>
                      <button className={style.buttontlinecancel} onClick={() => setMostaInput3(false)}><i class="fa fa-times" aria-hidden="true"></i></button>
                    </> || i.PLACA_CARRETA}<span><i onClick={divClick3} class="fa fa-pencil-square-o" aria-hidden="true"></i></span>
                </div>



                <div className={style.line}>
                  <b>2° Carreta: </b>{mostaInput4 &&
                    <>
                      <input onChange={(e) => { setPlaca2(e.target.value.toUpperCase()) }} placeholder="Placa 2" className={style.inputline} type="text" />
                      <button onClick={validaplaca2} className={style.buttontline}><i class="fa fa-check" aria-hidden="true"></i></button>
                      <button className={style.buttontlinecancel} onClick={() => setMostaInput4(false)}><i class="fa fa-times" aria-hidden="true"></i></button>
                    </> || i.PLACA_CARRETA2 || "não registrado"}<span><i onClick={divClick4} class="fa fa-pencil-square-o" aria-hidden="true"></i></span>
                </div>


                <div className={style.line}>
                  <b>3° Carreta: </b>{mostaInput5 &&
                    <>
                      <input placeholder="Placa 3" className={style.inputline} type="text" onChange={(e) => { setPlaca3(e.target.value.toUpperCase()) }} />
                      <button onClick={validaplaca3} className={style.buttontline}><i class="fa fa-check" aria-hidden="true"></i></button>
                      <button className={style.buttontlinecancel} onClick={() => setMostaInput5(false)}><i class="fa fa-times" aria-hidden="true"></i></button>
                    </> || i.PLACA_CARRETA3 || "não registrado"}<span><i onClick={divClick5} class="fa fa-pencil-square-o" aria-hidden="true"></i></span>
                </div>
              </div>
              <div className={modal.motoristaid}>
                <div className={style.line}>
                  <b>AUTO: </b>{mostaInput7 &&
                    <>
                      <select className={style.inputline} onChange={(e) => { setTipoveiculo(e.target.value) }}>
                        <option disabled selected>Selecione uma opção</option>
                        {tipoveiculos?.map((val) => {
                          return (
                            <option value={val.COD_TIPO}>{val.DESC_TIPO_VEICULO}</option>
                          )
                        })}
                      </select>

                      <button onClick={validaVeiculo} className={style.buttontline}><i class="fa fa-check" aria-hidden="true"></i></button>
                      <button className={style.buttontlinecancel} onClick={() => setMostaInput7(false)}><i class="fa fa-times" aria-hidden="true"></i></button>

                    </> || i.DESC_TIPO_VEICULO || "não registrado"}<span><i onClick={divClick7} class="fa fa-pencil-square-o" aria-hidden="true"></i></span>

                </div>

                <div className={style.line}>
                  <b>1º Pesagem (tara): </b>{mostaInput6 &&
                    <><div>
                      <input onChange={(e) => { setTara(e.target.value) }} placeholder="Peso da TARA" className={style.inputline} type="text" />
                      <input onChange={(e) => { setDataTara(e.target.value) }} placeholder="Peso da TARA" className={style.inputlinedate} type="datetime-local" /></div>
                      <button onClick={validaTara} className={style.buttontline}><i class="fa fa-check" aria-hidden="true"></i></button>

                      <button className={style.buttontlinecancel} onClick={() => setMostaInput6(false)}><i class="fa fa-times" aria-hidden="true"></i></button>
                    </> || `${i.PESO_TARA} KG`} <span><i onClick={divClick6} class="fa fa-pencil-square-o" aria-hidden="true"></i></span>
                </div>

                <div className={style.line}>
                  <b>Pedido MIC: </b>{mostaInput8 &&
                    <>
                      <select className={style.inputline} onChange={(e) => { setDocumento(e.target.value) }}>
                        <option disabled selected>Selecione uma opção</option>
                        {pedidos?.map((val) => {
                          return (
                            <option value={val.NR_PEDIDO}>{val.NR_PEDIDO}</option>
                          )
                        })}
                      </select>

                      <button onClick={validaDoc} className={style.buttontline}><i class="fa fa-check" aria-hidden="true"></i></button>
                      <button className={style.buttontlinecancel} onClick={() => setMostaInput8(false)}><i class="fa fa-times" aria-hidden="true"></i></button>

                    </> || i.PEDIDO_MIC || "Não Registrado"}<span><i onClick={divClick8} class="fa fa-pencil-square-o" aria-hidden="true"></i></span>

                </div>


                <div className={style.line}>
                  <b>N° DI/BL: </b>{mostaInput10 &&
                    <>
                      <select className={style.inputline} onChange={(e) => { setDocdibl(e.target.value) }}>
                        <option disabled selected>Selecione uma opção</option>
                        {docsdibl?.map((val) => {
                          return (
                            <option value={val.COD_CARGA}>{val.NUMERO_DOC}</option>
                          )
                        })}
                      </select>

                      <button onClick={validaDocdibl} className={style.buttontline}><i class="fa fa-check" aria-hidden="true"></i></button>
                      <button className={style.buttontlinecancel} onClick={() => setMostaInput10(false)}><i class="fa fa-times" aria-hidden="true"></i></button>

                    </> || i.NUMERO_DOC}<span><i onClick={divClick10} class="fa fa-pencil-square-o" aria-hidden="true"></i></span>
                </div>
                <div className={style.line}>
                  <b>Transportadora: </b>{mostaInput9 &&
                    <>
                      <select className={style.inputline} onChange={(e) => { setTransportadora(e.target.value) }}>
                        <option disabled selected>Selecione uma opção</option>
                        {transportadoras?.map((val) => {
                          return (
                            <option value={val.COD_TRANSP}>{val.NOME_REDUZIDO}</option>
                          )
                        })}
                      </select>

                      <button onClick={validaTransportadora} className={style.buttontline}><i class="fa fa-check" aria-hidden="true"></i></button>
                      <button className={style.buttontlinecancel} onClick={() => setMostaInput9(false)}><i class="fa fa-times" aria-hidden="true"></i></button>

                    </> || i.NOME_TRANSPORTADORA}<span><i onClick={divClick9} class="fa fa-pencil-square-o" aria-hidden="true"></i></span>
                </div>
              </div>
            </div>
          </div>
          <div className={modal.flex}>
            <div className={modal.inputbox_pesagem}>
              2º Pesagem
              <input type="number" onChange={(e) => { setPeso2(e.target.value) }} value={peso2} disabled={true} />
            </div>
            <div className={modal.inputbox_ticket}>
              Ticket
              <input type="text" onChange={(e) => { setTicket(e.target.value) }} placeholder={getVeiculoAtual().TICKET} disabled={getVeiculoAtual().STATUS_CARREG == 3} />
            </div>
            <div className={modal.inputbox}>
              Data
              <input type={getVeiculoAtual().STATUS_CARREG == 3 ? "text" : "datetime-local"} onChange={(e) => { setData(e.target.value) }} value={data} disabled={true} />
            </div>
          </div>


          <div className={modal.problemas}>
            <div className={modal.flex}>
              <div className={modal.inputbox_pesoprovisorio}>
                <button onClick={() => setMostrarProvisorio(!mostrarProvisorio)} className={modal.button_as_text}>
                  {mostrarProvisorio ? 'Ocultar' : 'Problemas?'}
                </button>

              </div>
              {mostrarProvisorio && (
                <div className={modal.flex}>
                  <div className={modal.inputbox_ticket}>
                    <span>Peso Provisório: {pesoProvisorioTemp}</span>
                  </div>
                  <div className={modal.inputbox_dataprovisorio}>
                    <span>Data Provisória: {dataProvisorioPYTemp}</span>
                  </div>
                  <button onClick={reportProblemas} className={modal.reportarProblemaButton}>
                    Reportar
                  </button>
                </div>
              )}
            </div>
          </div>



          <div className={modal.flex}>
            <button className={style.finalizar} onClick={puxarPY}>INTEGRAÇÃO</button>
            <div className={style.navio}><i className="fa fa-ship icon"></i>&nbsp;&nbsp;&nbsp;{dadosDash.NOME_NAVIO || "--"}</div>
            <button className={style.finalizar} onClick={validaDados2} disabled={getVeiculoAtual().STATUS_CARREG == 3}>REGISTRAR</button>
          </div>
        </div>
      </Pesagem>
      <Paralisacao open={openB} onClose={FecharParalisacao} fullWidth>
        <div className={modal.modal}>
          <div className={modal.nav}>
            <div onClick={FecharParalisacao}>Voltar</div>
            <div className={modal.active}>Abertura de Paralisação</div>
          </div>
          <div className={modal.flex}>
            <div className={modal.periodo}>
              {dadosDash.DEN_PERIODO || "--/--"}
              <div className={modal.data}>
                {/* 02/01/2023 */}
                {moment(dadosDash.INI_PERIODO).format("DD/MM/YYYY") || "--/--"}
              </div>
            </div>
            <div className={modal.inputbox}>
              Início
              <input type="datetime-local" onChange={(e) => { setDtinicio(e.target.value) }} />
            </div>
          </div>
          <div className={modal.selectbox}>
            <label>Motivo:</label>
            <select onChange={(e) => { setMotivo(e.target.value) }}>
              <option disabled selected>Selecione uma opção</option>
              {motivos?.map((val) => {
                return (
                  <option value={val.COD_MOTIVO}>{val.DESC_MOTIVO} </option>
                )
              })}
            </select>
          </div>
          <div className={modal.selectbox}>
            <label>Complemento:</label>
            <select onChange={(e) => { setComplemento(e.target.value) }}>
              <option disabled selected>Selecione uma opção</option>
              {complementos?.map((val) => {
                return (
                  <option value={val.COD_COMPL}>{val.DESC_COMPL}</option>
                )
              })}
            </select>
          </div>
          <div className={modal.flex}>
            <div className={modal.textbox}>
              Observação
              <textarea rows="4" onChange={(e) => setObs(e.target.value)}></textarea>
            </div>
          </div>

          <div className={modal.flex}>
            <div className={style.navio}><i className="fa fa-ship icon"></i>&nbsp;&nbsp;&nbsp;{dadosDash.NOME_NAVIO || "--"}</div>
            <button className={style.finalizar} onClick={validaDados1}>REGISTRAR PARALISAÇÃO</button>
          </div>
        </div>
      </Paralisacao>
      <Confirm open={openC} onClose={FecharConfirm} fullWidth>
    <div className={confirm.modal}>
      <div className={confirm.nav}>
        <div onClick={FecharConfirm}>Voltar</div>
      </div>
      <div className={confirm.center}>
        Deseja finalizar o período atual?
        <br />
        <div>Ao finalizar não será mais possível acessar Dashboard!</div>
      </div>
      <div className={confirm.center}>
        <div className={confirm.inputbox}>
          horário:
          <input type="datetime-local" onChange={(e) => { setDataFim(e.target.value); }} />
        </div>
      </div><br></br>
      <div className={confirm.flex}>
        <div className={confirm.navio}><i className="fa fa-ship icon"></i>&nbsp;&nbsp;&nbsp;{dadosDash.NOME_NAVIO || "--"}</div>
        <button 
          className={confirm.confirmar} 
          onClick={() => {
            validaDados3();
            setIsConfirmDisabled(true);  // Desabilita o botão
            setTimeout(() => {
              setIsConfirmDisabled(false);  // Reabilita o botão após 10 segundos
            }, 20000);  // 20 segundos
          }}
          disabled={isConfirmDisabled}  // Controla o estado de desabilitação
        >
          CONFIRMAR
        </button>
      </div>
    </div>
  </Confirm>
      <ParalisacaoFim open={openD} onClose={FecharParalisacaoFim} fullWidth>
        <div className={confirm.modal}>
          <div className={confirm.nav}>
            <div onClick={FecharParalisacaoFim}>Voltar</div>
          </div><br />
          <div className={confirm.center}>
            Deseja finalizar paralisação atual?
            <br />
          </div>
          <div className={confirm.center}>
            <div className={confirm.inputbox}>
              horário:
              <input type="datetime-local" onChange={(e) => { setDataFimPara(e.target.value) }} />
            </div>
          </div><br></br>
          <div className={confirm.flex}>
            <button className={confirm.cancelar} onClick={FecharParalisacaoFim}>CANCELAR</button>
            <div className={confirm.navio}><i className="fa fa-ship icon"></i>&nbsp;&nbsp;&nbsp;{dadosDash.NOME_NAVIO || "--"}</div>
            <button className={confirm.confirmar} onClick={validaPara}>CONFIRMAR</button>
          </div>
        </div>
      </ParalisacaoFim>
    </>
  );

};

export default function IntegrationNotistack() {
  return (
    <SnackbarProvider
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      maxSnack={3}
      autoHideDuration={3500}>
      <Operacao />
    </SnackbarProvider >
  );
}