import React, { useState, useEffect } from "react";
import Navbar from "../../../components/Navbar";
import Brackground from "../../../components/Background";
import Container from "../../../components/Container";
import Header from "../../../components/Header";
import style from "./DashboardSuprimentos.module.css";
import Input from "../../../components/Input";
import SubmitButton from "../../../components/Button";
import Axios from "axios";
import InputMask from "react-input-mask";
import { useNavigate } from 'react-router-dom';
import moment from 'moment';


import { SnackbarProvider, useSnackbar } from "notistack";

const DashboardSuprimentos = () => {
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const [isFormVisible, setIsFormVisible] = useState(false); // Novo estado para controle de visibilidade
  const toggleFormVisibility = () => setIsFormVisible(!isFormVisible); // Função para alternar a visibilidade

  const [descricao, setDescricao] = useState(""); // Descrição da tarefa

  const [centroCusto, setCentroCusto] = useState(""); // Código do centro de custo
  const [centroCustos, setCentroCustos] = useState([]); // Código do centro de custo

  const [dataInicio, setDataInicio] = useState(""); // Data de início da tarefa

  const [dataTermino, setDataTermino] = useState(""); // Data de término da tarefa (opcional)

  const [responsavel, setResponsavel] = useState(""); // Responsável pela tarefa

  const [status, setStatus] = useState("PENDENTE"); // Status inicial da tarefa

  const [prioridade, setPrioridade] = useState("BAIXA"); // Prioridade da tarefa

  const [tarefas, setTarefas] = useState([]);

  const [isModalDataTerminoVisivel, setIsModalDataTerminoVisivel] = useState(false);
  const [tarefaAtual, setTarefaAtual] = useState(null); // Armazena a tarefa atual para a qual o modal de data de término será aplicado

  const [exibirInputDataTermino, setExibirInputDataTermino] = useState(false);

  const [idTarefaConcluir, setIdTarefaConcluir] = useState(null); // Armazena o ID da tarefa selecionada para conclusão

  // Função para mostrar alertas
  const showAlert = (message, variant) => {
    enqueueSnackbar(message, { variant: variant });
  };

  // Ajustando para cadastrar uma nova tarefa
  const cadastrarTarefa = async () => {
    const tarefaData = {
      descricao: descricao,
      codCentroCusto: centroCusto,
      dataInicio: dataInicio,
      responsavel: responsavel,
      status: status,
      prioridade: prioridade,
      usuarioCadastro: JSON.parse(localStorage.getItem("user_token")).id,
    };

    try {
      const response = await Axios.post(
        "https://hml.operacao.orion-op.com.br/api/dashboardsuprimentos/cadastrar",
        tarefaData, // Envio os dados diretamente como JSON
        {
          headers: {
            'Content-Type': 'application/json', // Ajuste importante aqui
          }
        }
      );

      showAlert("Tarefa cadastrada com sucesso!", "success");
      // Aguarde 2 segundos (2000 milissegundos) antes de recarregar a página
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      console.error("Erro ao cadastrar tarefa:", error.response ? error.response.data.message : error.message);
      showAlert("Erro ao cadastrar tarefa: " + (error.response ? error.response.data.message : error.message), "error");
    }
  };

  const exibirModalDataTermino = (tarefaSelecionada) => {
    setIdTarefaConcluir(tarefaSelecionada.ID_TAREFAS_SUPRIMENTOS); // Armazena o ID da tarefa
    setTarefaAtual(tarefaSelecionada);
    setDataTermino(""); // Resetar a data de término ao abrir
  };

  const concluirTarefaComData = async () => {
    if (!tarefaAtual || !dataTermino) {
      showAlert("Selecione uma data válida.", "error");
      return;
    }

    try {
      await Axios.put(`https://hml.operacao.orion-op.com.br/api/tarefas/suprimentos/${tarefaAtual.ID_TAREFAS_SUPRIMENTOS}`, {
        status: 'CONCLUÍDA',
        dataTermino: dataTermino,
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      showAlert('Tarefa concluída com sucesso!', 'success');
      setIdTarefaConcluir(null); // Esconde o input após a tarefa ser concluída

      setExibirInputDataTermino(false); // Esconde o input de data após a atualização
      setDataTermino(''); // Limpa a data selecionada
      fetchTarefas(); // Atualiza a lista de tarefas
    } catch (error) {
      console.error("Erro ao concluir tarefa com data de término", error);
      showAlert('Erro ao concluir a tarefa', 'error');
    }
  };

  useEffect(() => {
    Axios.get("https://hml.operacao.orion-op.com.br/api/centrocusto")
      .then((response) => {
        setCentroCustos(response.data);
      })
      .catch((error) => {
        console.error("Erro ao buscar os centros de custos: ", error);
        showAlert("Erro ao buscar os centros de custos", "error");
      });
  }, []);

  // Função para buscar tarefas
  const fetchTarefas = async () => {
    try {
      const response = await Axios.get("https://hml.operacao.orion-op.com.br/api/tarefas/suprimentos");
      setTarefas(response.data);
    } catch (error) {
      console.error("Erro ao buscar tarefas:", error);
      showAlert("Erro ao buscar tarefas", "error");
    }
  };

  useEffect(() => {
    fetchTarefas();
  }, []);

  const moverTarefa = async (idTarefa, novoStatus) => {
    try {
      const response = await Axios.put(`https://hml.operacao.orion-op.com.br/api/tarefas/suprimentos/${idTarefa}`, {
        status: novoStatus,
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      showAlert('Status da tarefa atualizado com sucesso!', 'success');
      // Recarrega as tarefas para atualizar a UI
      await fetchTarefas();
    } catch (error) {
      console.error("Erro ao mover tarefa:", error);
      showAlert('Erro ao atualizar o status da tarefa', 'error');
    }
  };

  const getPriorityColor = (priority) => {
    switch (priority) {
      case 'Baixa':
        return 'green';
      case 'Média':
        return 'yellow';
      case 'Alta':
        return 'red';
      default:
        return 'grey';
    }
  };


  return (
    <>
      <Navbar veiculos />
      <Header />
      <Brackground />
      <Container>
        <div className={style.content}>

          <div className={style.nav}>
            <div className={style.active}>
              Dashboard
            </div>

            <div className={style.navItem} onClick={() => navigate('/suprimentos/combustivel')}>
              Combustível
            </div>

            <div className={style.navItem} onClick={() => navigate('/suprimentos/cotacao')}>
              Cotação
            </div>
          </div>

          <div className={style.kanbanContainer}>
            <div className={style.kanbanColumn}>
              <h2>Pendentes</h2>
              {tarefas.filter(tarefa => tarefa.STATUS === "PENDENTE" || tarefa.STATUS === "AGUARDANDO APROVAÇÃO DO DIRETOR" || tarefa.STATUS === "EM COTAÇÃO").map((tarefa) => (
                <div className={`${style.kanbanItem} ${tarefa.PRIORIDADE === 'ALTA' ? style.kanbanItemHighPriority : tarefa.PRIORIDADE === 'MÉDIA' ? style.kanbanItemMediaPriority : tarefa.PRIORIDADE === 'BAIXA' ? style.kanbanItemBaixaPriority : ''}`}>
                  <div className={style.headerContainer}>
                    <h3>{tarefa.DESCRICAO}</h3>
                    <div className={style.responsavel}>
                      {tarefa.RESPONSAVEL}
                    </div>
                  </div>
                  <div className={style.dataContainer}> {/* Usa a nova classe aqui */}
                    <div className={style.dataItem}>
                      <i className="fa fa-clock-o" aria-hidden="true"></i>
                      <strong> Início:</strong> {moment(tarefa.DATA_INICIO).format('DD/MM/YYYY')}
                    </div>
                    <div className={style.dataItem}>
                      <i className={`fa fa-clock-o ${style.icon}`} aria-hidden="true"></i>
                      <strong> Término:</strong> {idTarefaConcluir === tarefa.ID_TAREFAS_SUPRIMENTOS && (
                        <>
                          <input
                            type="date"
                            value={dataTermino}
                            onChange={(e) => setDataTermino(e.target.value)}
                          />
                          <button className={style.concluir} onClick={() => concluirTarefaComData()}>Salvar</button>
                        </>
                      )}
                    </div>

                  </div>
                  <div className={style.dataContainer}>
                    <div className={style.dataItem}>
                      <strong>Departamento: {tarefa.DESCRICAO_CENTROCUSTO}</strong>
                    </div>
                    <div className={style.dataItem}>
                      <strong>Status: {tarefa.STATUS}</strong>
                    </div>
                  </div>
                  <div className={style.actionContainer}>
                    <div className={style.prioridade}>
                      <i className="fa fa-flag" aria-hidden="true" style={{ color: getPriorityColor(tarefa.PRIORIDADE) }}></i>
                      {tarefa.PRIORIDADE}
                    </div>
                    <div className={style.buttonsContainer}>
                      <button className={style.actionButton}>Editar</button>
                      {tarefa.STATUS === "PENDENTE" || tarefa.STATUS === "AGUARDANDO APROVAÇÃO DO DIRETOR" || tarefa.STATUS === "EM COTAÇÃO" ? (
                        <button className={style.actionButton} onClick={() => exibirModalDataTermino(tarefa)}>Concluir</button>
                      ) : null}
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className={style.kanbanColumn}>
              <h2>Concluídas</h2>
              {tarefas.filter(tarefa => tarefa.STATUS === "CONCLUÍDA").map((tarefa) => (
                <div key={tarefa.ID_TAREFAS_SUPRIMENTOS} className={style.kanbanItem}>
                  <div className={style.headerContainer}>
                    <h3>{tarefa.DESCRICAO}</h3>
                    <div className={style.responsavel}>
                      {tarefa.RESPONSAVEL}
                    </div>
                  </div>
                  <div className={style.dataContainer}> {/* Usa a nova classe aqui */}
                    <div className={style.dataItem}>
                      <i className="fa fa-clock-o" aria-hidden="true"></i>
                      <strong> Início:</strong> {moment(tarefa.DATA_INICIO).format('DD/MM/YYYY')}
                    </div>
                    <div className={style.dataItem}>
                      <i className={`fa fa-clock-o ${style.icon}`} aria-hidden="true"></i>
                      <strong> Término:</strong> {tarefa.DATA_TERMINO ? moment(tarefa.DATA_TERMINO).format('DD/MM/YYYY') : 'N/A'}
                    </div>
                  </div>
                  <div className={style.dataContainer}>
                    <div className={style.dataItem}>
                      <strong>Departamento: {tarefa.DESCRICAO_CENTROCUSTO}</strong>
                    </div>
                    <div className={style.dataItem}>
                      <strong>Status: {tarefa.STATUS}</strong>
                    </div>
                  </div>
                  <div className={style.actionContainer}>
                    <div className={style.prioridade}>
                      <i className="fa fa-flag" aria-hidden="true" style={{ color: getPriorityColor(tarefa.PRIORIDADE) }}></i>
                      {tarefa.PRIORIDADE}
                    </div>
                    <div className={style.buttonsContainer}>
                      <button className={style.actionButton}>Editar</button>
                      <button className={style.actionButton} onClick={() => moverTarefa(tarefa.ID_TAREFAS_SUPRIMENTOS, 'PENDENTE')}>Reabrir</button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>


          <button onClick={toggleFormVisibility} className={style.addButton}>
            {isFormVisible ? '-' : '+'} {/* Texto do botão muda com o estado */}
          </button>

          {isFormVisible && (
            <div className={style.columns}>
              <div className={style.itens}>
                <label>DESCRIÇÃO</label>
                <input
                  type="text"
                  value={descricao}
                  onChange={(e) => setDescricao(e.target.value)}
                />
              </div>

              <div className={style.itens}>
                <label>CENTRO DE CUSTO</label>
                <select onChange={(e) => { setCentroCusto(e.target.value) }}>
                  <option disabled selected>Escolha uma opção</option>
                  {centroCustos?.map((centroCusto) => {
                    return (
                      <option value={centroCusto.COD_CENTROCUSTO}>{centroCusto.DESCRICAO}</option>
                    )
                  })}
                </select>
              </div>

              <div className={style.cnpj}>
                <label>DATA DE INÍCIO</label>
                <input
                  type="datetime-local"
                  value={dataInicio}
                  onChange={(e) => setDataInicio(e.target.value)}
                />
              </div>

              <div className={style.itens}>
                <label>RESPONSÁVEL</label>
                <input
                  type="text"
                  value={responsavel}
                  onChange={(e) => setResponsavel(e.target.value)}
                />
              </div>

              <div className={style.itens}>
                <label>STATUS</label>
                <select
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option value="PENDENTE">Pendente</option>
                  <option value="EM COTAÇÃO">Em Cotação</option>
                  <option value="CONCLUÍDA">Concluída</option>
                  <option value="REPROVADO">Reprovado</option>
                  <option value="AGUARDANDO APROVAÇÃO DO DIRETOR">Aguardando Aprovação do Diretor</option>
                </select>
              </div>

              <div className={style.itens}>
                <label>PRIORIDADE</label>
                <select
                  value={prioridade}
                  onChange={(e) => setPrioridade(e.target.value)}
                >
                  <option value="BAIXA">Baixa</option>
                  <option value="MÉDIA">Média</option>
                  <option value="ALTA">Alta</option>
                </select>
              </div>
              <SubmitButton text="Cadastrar" onClick={cadastrarTarefa} />
            </div>
          )}

        </div>

      </Container>
    </>
  );
};

export default function IntegrationNotistack() {
  return (
    <SnackbarProvider
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      maxSnack={3}
      autoHideDuration={2500}
    >
      <DashboardSuprimentos />
    </SnackbarProvider>
  );
}
