import React, { useEffect } from "react";
import Navbar from "../../components/Navbar";
import Background from "../../components/Background";
import Container from "../../components/Container";
import Header from "../../components/Header";
import style from "./Suporte.module.css";
import Moega from "./DJI_0197.JPG"; // Importando a imagem

const Suporte = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://player.twitch.tv/js/embed/v1.js";
    script.async = true;
    script.onload = () => {
      // Inicializa o primeiro player do Twitch
      new window.Twitch.Player("twitch-embed1", {
        channel: "camera02orion",
        parent: ["amorion.ddns.net"], // Substitua pelo seu domínio
        width: "620",
        height: "400",
      });
      // Inicializa o segundo player do Twitch
      new window.Twitch.Player("twitch-embed2", {
        channel: "camera02orion",
        parent: ["amorion.ddns.net"], // Substitua pelo seu domínio
        width: "620",
        height: "400",
      });
      // Inicializa o terceiro player do Twitch
      new window.Twitch.Player("twitch-embed3", {
        channel: "camera02orion",
        parent: ["amorion.ddns.net"], // Substitua pelo seu domínio
        width: "620",
        height: "400",
      });
      // Inicializa o quarto player do Twitch
      new window.Twitch.Player("twitch-embed4", {
        channel: "camera02orion",
        parent: ["localhost"], // Substitua pelo seu domínio
        width: "620",
        height: "400",
      });
    };
    document.body.appendChild(script);
  }, []);

  return (
    <>
      <Navbar suporte />
      <Header />
      <Background />
      <Container>
        <div className={style.container}>
          <div className={style.nav}>
            <div className={style.active}>ACOMPANHAMENTO DE DISCARGA EM TEMPO REAL</div>
          </div>
          <div className={style.content}>
            <div className={style.imageWrapper}>
              <img src={Moega} alt="Imagem de suporte" className={style.image} />
            </div>
            <div className={style.gridContainer}>
              <div className={style.camera}>
                <div id="twitch-embed1" className={style.video}></div>
                <div className={style.cameraTitle}>
                  <div className={style.cameraNumber}>1</div>
                  Grelha
                </div>
              </div>
              <div className={style.camera}>
                <div id="twitch-embed2" className={style.video}></div>
                <div className={style.cameraTitle}>
                  <div className={style.cameraNumber}>2</div>
                  Balança
                </div>
              </div>
              <div className={style.camera}>
                <div id="twitch-embed3" className={style.video}></div>
                <div className={style.cameraTitle}>
                  <div className={style.cameraNumber}>3</div>
                  Carregamento
                </div>
              </div>
              <div className={style.camera}>
                <div id="twitch-embed4" className={style.video}></div>
                <div className={style.cameraTitle}>
                  <div className={style.cameraNumber}>4</div>
                  Placa
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Suporte;
