// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Container_container__VVOCq {\n    z-index: -1;\n    margin: 50px 20px 0 80px;\n/* From https://css.glass \n    background: #ffffff1c;\n    border-radius: 16px;\n    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);\n    backdrop-filter: blur(2.2px);\n    -webkit-backdrop-filter: blur(2.2px);*/\n    border: 1px solid #00000007\n}", "",{"version":3,"sources":["webpack://./src/components/Container/Container.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,wBAAwB;AAC5B;;;;;0CAK0C;IACtC;AACJ","sourcesContent":[".container {\n    z-index: -1;\n    margin: 50px 20px 0 80px;\n/* From https://css.glass \n    background: #ffffff1c;\n    border-radius: 16px;\n    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);\n    backdrop-filter: blur(2.2px);\n    -webkit-backdrop-filter: blur(2.2px);*/\n    border: 1px solid #00000007\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Container_container__VVOCq"
};
export default ___CSS_LOADER_EXPORT___;
