import { SnackbarProvider, useSnackbar } from 'notistack';
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Axios from "axios";
import Brackground from "../../../components/Background";
import Container from "../../../components/Container";
import Header from "../../../components/Header";
import Input from "../../../components/Input";
import Navbar from "../../../components/Navbar";
import React from "react";
import Select from "../../../components/select";
import SubmitButton from "../../../components/Button";
import moment from "moment";
import style from "./IDFA.module.css"
import { useStepContext } from "@mui/material";
import Logo from './logo.png'; // Importe o logo aqui

const IDFA = () => {

    useEffect(() => {
        getPeriodo();
        getOp();
        getIDFA();
        getCargaData();
        DadosDashboard();
        VerificaParalisacao();
    }, [])

    const { id } = useParams();
    const [navioslist, setNaviosList] = useState([])
    const [periodo, setPeriodo] = useState([]);
    const [qtdeManifestadaTotal, setQtdeManifestadaTotal] = useState(0);
    const [operacoes, setOperacoes] = useState([]);
    const [autos, setAutos] = useState([]);
    const [atracacao, setAtracacao] = useState("");
    const [nomeCliente, setNomeCliente] = useState("");
    const [documentos, setDocumentos] = useState([]);
    const [codMoega, setCodMoega] = useState("");
    const [idfaData, setIdfaData] = useState([]);
    const [nomeNavio, setNomeNavio] = useState("");
    const [rap, setRap] = useState("");
    const [nomeBerco, setNomeBerco] = useState("");
    const [eta, setEta] = useState("");
    const [cargaData, setCargaData] = useState([]);
    const [busca, setBusca] = useState("");
    const [existeParalisacao, setExisteParalisacao] = useState(0);
    const [dadosDash, setDadosDash] = useState([]);
    const [paralisacoes, setParalisacoes] = useState([]);
    const [relatorios, setRelatorios] = useState([]);
    const [nomesClientes, setNomesClientes] = useState([]);

    const navigate = useNavigate();

    const getOp = () => {
        Axios.get('https://hml.operacao.orion-op.com.br/api/relatorios/operacoes').then((response) => {
            setNaviosList(response.data)
        });
    }

    const getIDFA = () => {
        Axios.get(`https://hml.operacao.orion-op.com.br/api/relatorio/idfa/navio/${id}`)
            .then((response) => {
                setIdfaData(response.data);
                if (response.data.length > 0) {
                    setNomeNavio(response.data[0].NOME_NAVIO);
                    setRap(response.data[0].RAP);
                    setNomeBerco(response.data[0].NOME_BERCO);
                    setEta(response.data[0].ETA);
                }
            })
            .catch((err) => {
                console.error("Erro ao buscar dados do IDFA:", err);
            });
    }

    const getCargaData = () => {
        Axios.get(`https://hml.operacao.orion-op.com.br/api/relatorio/idfa/carga/${id}`)
            .then((response) => {
                setCargaData(response.data);
            })
            .catch((err) => {
                console.error("Erro ao buscar dados da carga:", err);
            });
    }

    const getDate = () => {
        const date = new Date()
        date.setHours(date.getHours() - 3)
        return (date.toISOString().slice(0, 19).replace('T', ' '))
    }

    const getPeriodo = () => {
        Axios.get(`https://hml.operacao.orion-op.com.br/api/operacao/gerais/${id}`).then((response) => {
            setPeriodo(response.data)
        });
    }

    const getOperacoes = () => {
        Axios.post(`https://hml.operacao.orion-op.com.br/api/operacao/paralisacao/${id}`, {
            data: relatorios
        }).then((response) => {
            setOperacoes(response.data);

            Axios.get(`https://hml.operacao.orion-op.com.br/api/relatorio/IDFA/navio/${id}`)
                .then((navioResponse) => {
                    const data = navioResponse.data;

                    // Extract unique client names
                    const uniqueNomesClientes = new Set();
                    // Extract unique COD_MOEGA
                    const uniqueCodMoega = new Set();

                    data.forEach(item => {
                        if (item.NOME_REDUZIDO) {
                            uniqueNomesClientes.add(item.NOME_REDUZIDO);
                        }
                        if (item.COD_MOEGA !== undefined) {
                            uniqueCodMoega.add(item.COD_MOEGA);
                        }
                    });

                    setNomesClientes([...uniqueNomesClientes]);
                    setCodMoega([...uniqueCodMoega]);

                    // Set other states if needed
                    if (data.length > 0) {
                        setNomeNavio(data[0].NOME_NAVIO);
                        setQtdeManifestadaTotal(data[0].QTDE_MANIFESTADA_TOTAL);
                        setAtracacao(data[0].ATRACACAO);
                    }
                })
                .catch((err) => {
                    console.error("Error fetching navio data:", err);
                });
        }).catch((error) => {
            console.error("Error fetching operacoes:", error);
        });
    }

    const VerificaParalisacao = () => {
        Axios.get(`https://hml.operacao.orion-op.com.br/api/verifica/paralisacao/${id}`,)
            .then(function (res) {
                setExisteParalisacao(res.data)
            })
    }
    const DadosDashboard = () => {
        Axios.get(`https://hml.operacao.orion-op.com.br/api/periodo/dashboard/${id}`,)
            .then(function (res) {
                setDadosDash(res.data[0])
                Axios.get(`https://hml.operacao.orion-op.com.br/api/paralisacao/periodo/${res.data[0].SEQ_PERIODO_OP}`,)
                    .then(function (res) {
                        setParalisacoes(res.data)
                    })
            })
    }

    // Obter a data atual e formatá-la
    const today = new Date().toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
    });
    console.log(today);

    return (
        <>
            <Navbar relatorios />
            <Header />
            <Brackground />
            <Container>
                <div className={style.content}>
                    <div className={style.nav}>
                        <div onClick={() => navigate(`/relatorios`)}>
                            Selecione um navio
                        </div>
                        <div className={style.nav}>
                            <div onClick={() => navigate(`/relatorios/${id}`)}>
                                Relatório por Período
                            </div>
                        </div>
                        <div onClick={() => navigate(`/relatorios/operacao/${id}`)}>
                            Relatório por Viagem
                        </div>
                        <div className={style.active}>
                            Análise Operacional
                        </div>

                        <div onClick={() => navigate(`/relatorios/idfa/${id}`)}>
                            IDFA
                        </div>
                    </div>

                    <div className={style.columnsContainer}>
                        <div className={style.column}>
                            <div className={style.logoContainer}>
                                <img src={Logo} alt="Logo" className={style.logo} />
                            </div>
                        </div>
                        <div className={style.column}>
                            <strong>IDFA -  IDENTIFICAÇÃO DE FALTAS/ACRESCIMOS</strong>
                        </div>
                        <div className={style.column}>
                            ({today})
                        </div>
                    </div>

                    <div className={style.columnsContainer4}>
                        <div className={style.column4}>
                            <div>NAVIO</div>
                            <div>{nomeNavio}</div>
                        </div>
                        <div className={style.column4}>
                            <div>VIAGEM</div>
                            <div>{rap}</div>
                        </div>
                        <div className={style.column4}>
                            <div>LOCAL ATRACAÇÃO</div>
                            <div>{nomeBerco}</div>
                        </div>
                        <div className={style.column4}>
                            <div>DATA ATRACAÇÃO</div>
                            <div>{moment(eta).format('DD/MM/YYYY')}</div>
                        </div>
                    </div>

                    <div className={style.dateContainer}>

                    </div>

                    <table className={style.table}>
                        <thead>
                            <tr>
                                <th>MERCADORIA</th>
                                <th>PORTO</th>
                                <th>PAÍS</th>
                                <th>IMPORTADOR</th>
                                <th>DI</th>
                                <th>BL</th>
                                <th>P.MANIFESTADO(TON)</th>
                                <th>P.APURADOR(TON)</th>
                                <th>F/A</th>
                                <th>F/A(TON)</th>
                                <th>F/A(%)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {cargaData.map((item, index) => {
                                const qtdeManifestadaTon = item.QTDE_MANIFESTADA / 1000;
                                const descarregadoTon = item.DESCARREGADOH / 1000;
                                const fa = descarregadoTon < qtdeManifestadaTon ? "F" : "A";
                                const faTon = (descarregadoTon - qtdeManifestadaTon).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                                const faPercent = ((descarregadoTon - qtdeManifestadaTon) / qtdeManifestadaTon * 100).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

                                return (
                                    <tr key={index}>
                                        <td>{item.PRODUTO}</td>
                                        <td></td>
                                        <td></td>
                                        <td>{item.NOME_REDUZIDO}</td>
                                        <td>{item.NUMERO_DOC}</td>
                                        <td></td>
                                        <td>{qtdeManifestadaTon.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} Tons</td>
                                        <td>{descarregadoTon.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} Tons</td>
                                        <td>{fa}</td>
                                        <td>{faTon} Tons</td>
                                        <td>{faPercent} %</td>
                                    </tr>
                                );
                            })}
                        </tbody>

                    </table>

                </div>

            </Container>
        </>
    );
};

export default function IntegrationNotistack() {
    return (
        <SnackbarProvider
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            maxSnack={3}
            autoHideDuration={2500}>
            <IDFA />
        </SnackbarProvider >
    );
}