import React, { useState, useEffect } from "react";
import Navbar from "../../../components/Navbar";
import Brackground from "../../../components/Background";
import Container from "../../../components/Container";
import Header from "../../../components/Header";
import style from "./HistoricoMotivacao.module.css";
import SubmitButton from '../../../components/Button';
import Axios from "axios";
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { SnackbarProvider, useSnackbar } from "notistack";

const HistoricoMotivacao = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [motivacoes, setMotivacoes] = useState([]);

  // Função para mostrar alertas
  const showAlert = (message, variant) => {
    enqueueSnackbar(message, { variant: variant });
  };

  useEffect(() => {
    Axios.get("https://hml.operacao.orion-op.com.br/api/motivacao")
      .then((response) => {
        setMotivacoes(response.data);
      })
      .catch((error) => {
        console.error("Erro ao buscar as motivações: ", error);
        showAlert("Erro ao buscar as motivações", "error");
      });
  }, []);

  const copyToClipboard = (text) => {
    console.log("Tentando copiar: ", text); // Adicione isso para depuração
    navigator.clipboard.writeText(text).then(() => {
      showAlert("Texto copiado com sucesso!", "success");
    }).catch(err => {
      console.error('Failed to copy: ', err);
      showAlert("Erro ao copiar o texto.", "error");
    });
  };

  const copyToClipboardFallback = (text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
  
    try {
      document.execCommand('copy');
      showAlert("Texto copiado com sucesso!", "success");
    } catch (err) {
      showAlert("Erro ao copiar o texto.", "error");
      console.error("Falha ao copiar: ", err);
    }
  
    document.body.removeChild(textArea);
  };
  
  
  
  const handleCopy = async (event, idMotivacao) => {
    event.preventDefault();
    
    try {
      const response = await Axios.get(`https://hml.operacao.orion-op.com.br/api/motivacao/conteudo/${idMotivacao}`);
      const textToCopy = response.data.content;
      if (navigator.clipboard) {
        navigator.clipboard.writeText(textToCopy).then(() => {
          showAlert("Texto copiado com sucesso!", "success");
        }, (err) => {
          console.error("Erro ao copiar usando a API da área de transferência: ", err);
          // Usar método de fallback
          copyToClipboardFallback(textToCopy);
        });
      } else {
        // Navegador não suporta navigator.clipboard, usar fallback
        copyToClipboardFallback(textToCopy);
      }
    } catch (error) {
      console.error('Erro ao tentar copiar o texto: ', error);
      showAlert("Erro ao copiar o texto.", "error");
    }
  };
  
  
  
  const handleCancel = (idMotivacao) => {
    // Aqui você pode adicionar a lógica para cancelar a motivação
    console.log(`Cancelar a motivação ${idMotivacao}`);
    showAlert(`O Lucas ainda está desenvolvendo para cancelar o ${idMotivacao}.`, "info");
  };

  return (
    <>
      <Navbar veiculos />
      <Header />
      <Brackground />
      <Container>
        <div className={style.content}>
          <div className={style.nav}>
            <div onClick={() => navigate("/motivacao/motivados")}>
              Motivado
            </div>
            <div className={`${style.navItem} ${style.active}`}>
              Motivação
            </div>
            <div className={style.navItem} onClick={() => navigate('/motivacao/cadastro')}>
              Cadastrar Motivado
            </div>
          </div>
          <table className={style.table}>
            <thead>
              <tr>
                <th>ID</th>
                <th>BERÇO</th>
                <th>INÍCIO</th>
                <th>TÉRMINO</th>
                <th>USUÁRIO CADASTRO</th>
                <th>CADASTRO</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {motivacoes.map((motivacao) => (
                <tr key={motivacao.ID_MOTIVACAO}>
                  <td>{motivacao.ID_MOTIVACAO}</td>
                  <td>{motivacao.DESC_BERCO_SPA}</td>
                  <td>{moment(motivacao.DATA_INICIO).format('DD/MM/YY')}</td>
                  <td>{motivacao.DATA_TERMINO ? moment(motivacao.DATA_TERMINO).format('DD/MM/YY') : 'N/A'}</td>
                  <td>{motivacao.USUARIO_CADASTRO}</td>
                  <td>{moment(motivacao.DATA_CADASTRO).format('DD/MM/YY HH:mm')}</td>
                  <td>
                    <div className={style.buttonContainer}>
                    <button onClick={(event) => handleCopy(event, motivacao.ID_MOTIVACAO)} className={style.copyButton}>
  Copiar
</button>


                      <button onClick={() => handleCancel(motivacao.ID_MOTIVACAO)} className={style.cancelButton}>
                        Cancelar
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Container>
    </>
  );
};

export default function IntegrationNotistack() {
  return (
    <SnackbarProvider
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      maxSnack={3}
      autoHideDuration={2500}
    >
      <HistoricoMotivacao />
    </SnackbarProvider>
  );
}
