import React from 'react';
import { useNavigate } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import Navbar from '../../../components/Navbar';
import Background from '../../../components/Background';
import Container from '../../../components/Container';
import Header from '../../../components/Header';
import style from './FluxogramaAtendimento.module.css';
import CorpoDeBombeiros from './Corpo_De_Bombeiros.png'; // Caminho da imagem
import GuardaPortuaria from './Guarda_Portuaria.png'; // Caminho da imagem

const FluxogramaAtendimento = () => {
  const navigate = useNavigate();

  return (
    <>
      <Navbar motivados />
      <Header />
      <Background />
      <Container>
        <div className={style.content}>
          <div className={style.nav}>
            <div onClick={() => navigate("")}>
              Segurança do Trabalho &gt;&gt; FLUXOGRAMA DE ATENDIMENTO A EMERGÊNCIA
            </div>
          </div>

          <div className={style.fluxograma}>
            <h2>Fluxograma para Comunicação em Caso de Ocorrência</h2>

            {/* Passo 1: Acidente Com/Sem Vítima */}
            <div className={style.step}>
              <div className={style.stepNumber}>1</div>
              <div className={style.stepContent}>
                <center><h3>Acidente Com/Sem Vítima - SEM necessidade de remoção para hospital</h3></center>
                <p><strong>Gerente:</strong>
                  <a href="tel:13997611390" className={style.acionarBtn}>
                    José Rodrigues - (13) 99761-1390
                  </a></p>
                <p><strong>Segurança do Trabalho:</strong>
                  <a href="tel:13997505463" className={style.acionarBtn}>
                    Natália Lopes - (13) 99750-5463
                  </a></p>
              </div>

            </div>

            {/* Passo 2: Acidente Ambiental */}
            <div className={style.step}>
              <div className={style.stepNumber}>2</div>
              <div className={style.stepContent}>
                <center><h3>Acidente Com Vítima - Necessidade de remoção para hospital </h3></center>
                <p><strong>Ambulância:</strong>
                  <a href="tel:08009415455" className={style.acionarBtn}>
                    MEDICAR - 0800.941.5455
                  </a></p>
                <p><strong>O código de Identificação é (ID): 002326810</strong></p>

                <p>Em caso de acidente que necessite de apoio do Corpo de bombeiros
                  para remoção/movimentação do colaborador ou equipamento – 193</p>

                {/* Botão para acionar o Corpo de Bombeiros */}
                <div className={style.bombeiros}>
                  <img src={CorpoDeBombeiros} alt="Corpo de Bombeiros" className={style.bombeirosLogo} />
                  <a href="tel:193" className={style.acionarBtn}>
                    Acionar Corpo de Bombeiros
                  </a>
                </div>
                <p><center><strong>Em caso de ocorrência no porão, solicitar a manutenção ou
                  tripulação a conexão do cesto de resgate</strong></center></p>


              </div>
            </div>

            {/* Passo 3: Incêndio */}
            <div className={style.step}>
              <div className={style.stepNumber}>3</div>
              <div className={style.stepContent}>
                <center><h3><strong>Informar ao Plantão</strong> </h3></center>
                <p>
                  Após solicitar apoio médico,
                  destinar um colaborador para informar o plantão da <strong>Guarda
                  Portuaria (13) 3202.6570</strong> e o <strong>GATE </strong>mais próximo sobre a chegada da
                  ambulância, importante que essa pessoa organize a via de acesso se
                  necessário.</p>
                  <div className={style.bombeiros}>
                  <img src={GuardaPortuaria} alt="Guarda Portuaria" className={style.bombeirosLogo} />
                  <a href="tel:1332026570" className={style.acionarBtn}>
                    Acionar Guarda Portuaria
                  </a>
                </div>
              </div>
            </div>

            {/* Contatos finais */}
            <div className={style.step}>
              <div className={style.stepNumber}>4</div>
              <div className={style.stepContent}>
              <p><strong>Gerente:</strong>
                  <a href="tel:13997611390" className={style.acionarBtn}>
                    José Rodrigues - (13) 99761-1390
                  </a></p>
                <p><strong>Segurança do Trabalho:</strong>
                  <a href="tel:13997505463" className={style.acionarBtn}>
                    Natália Lopes - (13) 99750-5463
                  </a></p>
              </div>
            </div>

            {/* Acidente Ambiental e Incêndio (Two columns with icons) */}
<div className={style.twoColumns}>
  {/* Acidente Ambiental */}
  <div className={style.column}>
    <div className={style.columnHeader}>
      <i className="fas fa-leaf"> Acidente Ambiental</i> {/* FontAwesome icon for environmental issues */}
      
    </div>
    <p>1º Passo: Vazamento de óleo/diesel no Costado, utilizar o Kit Ambiental, registrar a ocorrência com fotos e enviar para José Rodrigues e Natália Lopes.</p>
    <p>2º Passo: Vazamento de óleo/produto no mar, acionar José Rodrigues e Natália Lopes.</p>
  </div>

  {/* Incêndio */}
  <div className={style.columnIncendio}>
    <div className={style.columnHeaderIncendio}>
      <i className="fas fa-fire"> Incêndio</i> {/* FontAwesome icon for fire incidents */}
    </div>
    <p>1º Passo: Iniciar o combate ao foco do princípio de incêndio com os meios de intervenção existentes.</p>
    <p>2º Passo: Se não tiver sucesso, acionar imediatamente o Corpo de Bombeiros (193) e PAM do Porto (3202-6565).</p>
    <p>3º Passo: Evacuar o local e encaminhar os ocupantes para o ponto de encontro (GATE mais próximo).</p>
  </div>
</div>

          </div>
        </div>
      </Container>
    </>
  );
};

export default function IntegrationNotistack() {
  return (
    <SnackbarProvider
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      maxSnack={3}
      autoHideDuration={2500}
    >
      <FluxogramaAtendimento />
    </SnackbarProvider>
  );
}
