import { SnackbarProvider, useSnackbar } from 'notistack';
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Axios from "axios";
import Brackground from "../../../components/Background";
import Container from "../../../components/Container";
import Header from "../../../components/Header";
import Input from "../../../components/Input";
import Navbar from "../../../components/Navbar";
import React from "react";
import Select from "../../../components/select";
import SubmitButton from "../../../components/Button";
import moment from "moment";
import style from "./RelatorioPeriodo.module.css"
import { useStepContext } from "@mui/material";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import LogoOrion from './LOGO_ORION.png';
import Logo_Orion from './Logo-Orion.png';
import { format } from 'date-fns';
import {
    Modal,
    Box,
    Button,
    Typography,
} from "@mui/material";

const RelatorioPeriodo = () => {

    useEffect(() => {
        getEquipamentos();
        getMoegas();
        getPeriodos();
        getBercos();
        VerificaPeriodo();
        getOp()
        getPeriodo()
        DadosDashboard();
        getVeiculos();
        VerificaParalisacao();
    }, [])

    const { id } = useParams();
    const [busca, setBusca] = useState("");
    const [existeParalisacao, setExisteParalisacao] = useState(0);
    const [dadosDash, setDadosDash] = useState([]);
    const [veiculos, setVeiculos] = useState([]);
    const [paralisacoes, setParalisacoes] = useState([]);
    const [descarregado, setDescarregado] = useState([]);
    const [relatorios, setRelatorios] = useState([]);
    const [i, setI] = useState({});
    const [peso2, setPeso2] = useState("");
    const [data, setData] = useState("");
    const [obsPesagem, setObsPesagem] = useState("");
    const [pesobruto, setPesoBruto] = useState("");
    const [databruto, setDataBruto] = useState("");
    const [navioslist, setNaviosList] = useState([])
    const [equipamento, setEquipamento] = useState("");
    const [periodo, setPeriodo] = useState([]);
    const [berco, setBerco] = useState("");
    const [qtbordo, setQtBordo] = useState("");
    const [qtterra, setQtTerra] = useState("");
    const [gerador, setGerador] = useState("");
    const [grab, setGrab] = useState("");
    const [porao, setPorao] = useState("");
    const [requisicao, setRequisicao] = useState("");
    const [conexo, setConexo] = useState("");
    const [inicio, setInicio] = useState("");
    const [existePeriodo, setExistePeriodo] = useState(0);
    const usuario = JSON.parse(localStorage.getItem("user_token")).id;
    const [operacoes, setOperacoes] = useState([]);
    const [equipamentos, setEquipamentos] = useState([]);
    const [periodos, setPeriodos] = useState('');
    const [autos, setAutos] = useState([]);
    const [documentos, setDocumentos] = useState([]);
    const [moegas, setMoegas] = useState([]);
    const [hora, setHora] = useState([]);
    const [paralisacao, setParalisacao] = useState([]);
    const [moega, setMoega] = useState([]);
    const [bercos, setBercos] = useState([])
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [buttonText, setButtonText] = useState('Cadastrar');
    const [filterType, setFilterType] = useState("NOME_MOTORISTA");
    const [openDDSModal, setOpenDDSModal] = useState(false);
    const handleOpenDDSModal = () => setOpenDDSModal(true);
    const handleCloseDDSModal = () => setOpenDDSModal(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [file, setFile] = useState(null);
    const [filter, setFilter] = useState("");
    const [seqPeriodoOp, setSeqPeriodoOp] = useState(null);
    const [selectedSeqPeriodoOp, setSelectedSeqPeriodoOp] = useState(null);


    // Estado para controlar a exibição do modal
    const [openModal, setOpenModal] = useState(false);
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);

    // Função para lidar com o envio de dados para SUPERVIA
    const handleSendToSupervia = () => {
        // Desabilitar o botão SUPERVIA ao clicar em "Sim"
        setIsButtonDisabled(true);

        // Primeiro, realizar a requisição para pegar os resultados necessários
        Axios.post(`https://hml.operacao.orion-op.com.br/api/periodo/supervia/${id}`, {
            data: relatorios // Passando 'relatorios' como 'data' conforme especificado
        })
            .then((response) => {
                // Armazenar os dados do resultado
                const resultado = response.data;

                // Verifique se há resultado
                if (!resultado || resultado.length === 0) {
                    enqueueSnackbar('Nenhum dado encontrado para o período especificado.', { variant: 'warning' });
                    setIsButtonDisabled(false); // Reativar o botão caso não haja resultado
                    return;
                }

                // Armazenar os dados necessários
                const corteOutroOperador = resultado.some((item) => item.CORTE_OUTRO_OPERADOR === 'S');
                const nomeNavio = resultado[0]?.NOME_NAVIO;
                const iniPeriodo = resultado[0]?.INI_PERIODO;
                const fimPeriodo = resultado[0]?.FIM_PERIODO;
                const seqPeriodoOP = resultado[0]?.SEQ_PERIODO_OP;
                const codOperacao = resultado[0]?.COD_OPERACAO;
                const dataIniPeriodo = resultado[0]?.DAT_INI_PERIODO;
                const dataFimPeriodo = resultado[0]?.DAT_FIM_PERIODO;
                const usuario = JSON.parse(localStorage.getItem("user_token")).id; // Obter o ID do usuário

                // Verificar se há CORTE_OUTRO_OPERADOR = 'S' e, se sim, acionar a rota de corte operador
                if (corteOutroOperador) {
                    if (!nomeNavio || !iniPeriodo || !fimPeriodo || !seqPeriodoOP || !codOperacao) {
                        enqueueSnackbar('Erro: Dados insuficientes para acionar o corte do operador.', { variant: 'error' });
                        setIsButtonDisabled(false); // Reativar o botão caso ocorra erro
                        return;
                    }

                    // Chamar a rota /supervia/corteoperador com o nome do navio e outras informações necessárias
                    Axios.post(`https://hml.operacao.orion-op.com.br/api/supervia/corteoperador`, {
                        NOME_NAVIO: nomeNavio,
                        INI_PERIODO: iniPeriodo,
                        FIM_PERIODO: fimPeriodo,
                        SEQ_PERIODO_OP: seqPeriodoOP,
                        COD_OPERACAO: codOperacao,
                        DAT_INI_PERIODO: dataIniPeriodo,
                        DAT_FIM_PERIODO: dataFimPeriodo,
                        DATA: relatorios, // Passar também o período de data, se necessário
                        USUARIO: usuario // Adicionar o campo de usuário
                    })
                        .then(() => {
                            enqueueSnackbar('Corte por outro operador enviado para SUPERVIA com sucesso', { variant: 'success' });
                        })
                        .catch((error) => {
                            console.error("Erro ao enviar para /supervia/corteoperador:", error);
                            enqueueSnackbar('Erro ao enviar corte por outro operador', { variant: 'error' });
                            setIsButtonDisabled(false); // Reativar o botão caso ocorra erro
                        });
                } else {
                    // Se não houver corte por outro operador, considerar o envio bem-sucedido
                    enqueueSnackbar('Dados enviados para SUPERVIA com sucesso', { variant: 'success' });
                }
            })
            .catch((error) => {
                console.error("Erro ao obter dados de /periodo/supervia:", error);
                enqueueSnackbar('Erro ao obter dados para envio', { variant: 'error' });
                setIsButtonDisabled(false); // Reativar o botão caso ocorra erro
            })
            .finally(() => {
                handleCloseModal(); // Fecha o modal após o clique em "Sim", mesmo que ocorra algum erro
            });
    };


    // Função para exibir o modal de confirmação de envio para SUPERVIA
    const showModal = () => {
        handleOpenModal();
    }

    const navigate = useNavigate();

    const getOp = () => {
        Axios.get('https://hml.operacao.orion-op.com.br/api/relatorios/operacoes').then((response) => {
            setNaviosList(response.data)
        });
    }

    const getDate = () => {
        const date = new Date()
        date.setHours(date.getHours() - 3)
        return (date.toISOString().slice(0, 19).replace('T', ' '))
    }

    const getEquipamentos = () => {
        Axios.get('https://hml.operacao.orion-op.com.br/api/equipamentos').then((response) => {
            setEquipamentos(response.data)
        });
    }

    const getMoegas = () => {
        Axios.get(`https://hml.operacao.orion-op.com.br/api/periodo/moega/${id}`, {
            data: relatorios
        }).then((response) => {
            console.log(response.data);
            setMoegas(response.data);
        });
    }

    const getPeriodos = () => {
        Axios.get('https://hml.operacao.orion-op.com.br/api/periodos/horarios').then((response) => {
            setPeriodos(response.data)
        });
    }

    const getBercos = () => {
        Axios.get('https://hml.operacao.orion-op.com.br/api/bercos').then((response) => {
            setBercos(response.data);
        });
    }

    const { enqueueSnackbar } = useSnackbar();

    const showAlert = (txt, variant) => {
        enqueueSnackbar(txt, { variant: variant });
    }

    const VerificaPeriodo = () => {
        Axios.get(`https://hml.operacao.orion-op.com.br/api/periodo/busca/${id}`,)
            .then(function (res) {
                setExistePeriodo(res.data[0].EXISTE)
            })
    }

    const getOperacoes = () => {
        getAutos()
        getDocumentos()
        getMoegas();
        getHora()
        getParalisacao()
        getMoega()
        console.log(autos)
        console.log(documentos)
        Axios.post(`https://hml.operacao.orion-op.com.br/api/periodo/carregamentos/${id}`, {
            data: relatorios
        }).then((response) => {
            console.log(response.data);
            setOperacoes(response.data);
        });
    }

    const getAutos = () => {
        Axios.post(`https://hml.operacao.orion-op.com.br/api/periodo/autos/${id}`, {
            data: relatorios
        }).then((response) => {
            console.log(response.data);
            setAutos(response.data);
        });
    }

    const getDocumentos = () => {
        Axios.post(`https://hml.operacao.orion-op.com.br/api/periodo/documentos/${id}`, {
            data: relatorios
        }).then((response) => {
            console.log(response.data);
            setDocumentos(response.data);
        });
    }

    const getHora = () => {
        Axios.post(`https://hml.operacao.orion-op.com.br/api/periodo/horahora/${id}`, {
            data: relatorios
        }).then((response) => {
            console.log(response.data);
            setHora(response.data);
        });
    }

    const getParalisacao = () => {
        Axios.post(`https://hml.operacao.orion-op.com.br/api/periodo/paralisacao/${id}`, {
            data: relatorios
        }).then((response) => {
            console.log(response.data);
            setParalisacao(response.data);
        });
    }

    const getMoega = () => {
        Axios.post(`https://hml.operacao.orion-op.com.br/api/periodo/moega/${id}`, {
            data: relatorios
        }).then((response) => {
            console.log(response.data);
            setMoega(response.data);
        });
    }

    const getListaDocumentoDoc = async (id) => {
        try {
            const response = await Axios.get(`https://operacao.orion-op.com.br/api/documento/lista/${id}`);
            if (response.status === 200) {
                // Supondo que você tenha um estado para armazenar a lista de documentos
                setDocumentos(response.data); // Atualiza o estado documentos com os dados da resposta
            } else {
                showAlert('Erro ao obter a lista de documentos.', 'error');
            }
        } catch (error) {
            console.error('Erro ao obter a lista de documentos:', error);
            showAlert('Erro ao obter a lista de documentos.', 'error');
        }
    };
    

    const addPeriodo = async () => {
        await Axios.post('https://hml.operacao.orion-op.com.br/api/periodo/criar', {
            operacao: id,
            periodo: periodo,
            inicio: inicio,
            berco: berco,
            qtbordo: qtbordo,
            qtterra: qtterra,
            porao: porao,
            moega: equipamento,
            conexo: conexo,
            requisicao: requisicao,
            gerador: gerador,
            grab: grab,
            usuario: usuario,
            dtcadastro: getDate()
        })
            .then(function (res) {
                console.log(res);
                res.data.sqlMessage ?
                    showAlert(res.data.sqlMessage, 'error') :
                    showAlert('Nova Operação cadastrada com sucesso', 'success');
                setTimeout(() => {
                    navigate(`/operacao/${id}`)
                }, 2000);
            })
    }

    const validaDados = async () => {
        if (!grab | !periodo | !qtterra | !berco | !gerador | !equipamento | !qtbordo | !porao | !inicio) {
            showAlert('Preencha todos os campos!', 'error')
            return;
        }

        //addPeriodo() 
        setButtonText('Aguarde...');
        setIsButtonDisabled(true);

        try {
            await addPeriodo();
        } catch (error) {
            console.log(error);
            showAlert('Ocorreu um erro ao cadastrar a nova operação', 'error');
        } finally {
            setButtonText('Enviar');
            setIsButtonDisabled(false);
        }
    }

    //modal de 2 pesagem
    const [openA, setOpenA] = useState(false);
    const AbrirPesagem = () => {
        setOpenA(true);
    };
    const FecharPesagem = () => {
        setOpenA(false);
    };

    const [openB, setOpenB] = useState(false);
    const AbrirConfirm = () => {
        setOpenB(true);
    };
    const FecharConfirm = () => {
        setOpenB(false);
    };

    const [MostaInput, setMostaInput] = useState(false);

    const divClick = () => {
        setMostaInput(true);
    };

    const getPeriodo = () => {
        Axios.get(`https://hml.operacao.orion-op.com.br/api/periodos/gerais/${id}`).then((response) => {
            setPeriodo(response.data); // Armazena os períodos disponíveis
        });
    }

    const VerificaParalisacao = () => {
        Axios.get(`https://hml.operacao.orion-op.com.br/api/verifica/paralisacao/${id}`,)
            .then(function (res) {
                setExisteParalisacao(res.data)
            })
    }
    const DadosDashboard = () => {
        Axios.get(`https://hml.operacao.orion-op.com.br/api/periodo/dashboard/${id}`)
            .then(function (res) {
                setDadosDash(res.data[0]);
                const seqPeriodo = res.data[0]?.SEQ_PERIODO_OP; // Obtenha o SEQ_PERIODO_OP
                setSeqPeriodoOp(seqPeriodo); // Armazena no estado

                // Verificar se existe documento para o SEQ_PERIODO_OP
                verificarDocumento(seqPeriodo);

                // Pegar as paralisacoes para o período
                Axios.get(`https://hml.operacao.orion-op.com.br/api/paralisacao/periodo/${seqPeriodo}`)
                    .then(function (res) {
                        setParalisacoes(res.data);
                    });
            });
    };


    const getVeiculos = () => {
        Axios.get(`https://hml.operacao.orion-op.com.br/api/ultimapesagem/busca/${id}`,)
            .then(function (res) {
                setVeiculos(res.data)
            })
    }

    const downloadNota = async (idCarregamento) => {
        const data = {
            idCarregamento: idCarregamento
        };

        const config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `https://hml.operacao.orion-op.com.br/api/baixarnota`,
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
            data: data
        };

        Axios.request(config)
            .then((res) => {
                var byteCharacters = atob(res.data.pdf.split(',')[res.data.pdf.split(',').length - 1]);
                var byteNumbers = new Array(byteCharacters.length);
                for (var i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                var byteArray = new Uint8Array(byteNumbers);
                var file = new Blob([byteArray], { type: res.data.pdf.split(',')[0].split(":")[1] });

                const url = URL.createObjectURL(file);
                const link = document.createElement('a');
                link.href = url;
                link.download = res.data.filename
                link.click();
                URL.revokeObjectURL(url);
            })
            .catch((err) => {
                showAlert('Erro ao obter PDF', 'error')
                console.log(err.response.data.mensagem)
            })
    }

    const downloadTicket = (carregamento) => {
        const doc = new jsPDF({
            orientation: 'landscape',
            unit: 'cm',
            format: [4, 8]
        });

        // Adicionar o logo no PDF
        doc.addImage(LogoOrion, 'PNG', 0.5, 0.5, 2.5, 0.6); // As dimensões 4x2 são apenas um exemplo; ajuste conforme necessário

        // Adicionar o texto no PDF
        doc.setFontSize(10);
        doc.text(`ID: ${carregamento.ID_CARREGAMENTO}`, 0.5, 1.7);
        doc.text(`DI: ${carregamento.NUMERO_DOC} - PEDIDO: ${carregamento.PEDIDO_MIC}`, 0.5, 2.2);
        doc.text(`Cavalo: ${carregamento.PLACA_CAVALO}`, 0.5, 2.7);
        doc.text(`Peso MOEGA: ${(carregamento.PESO_CARREGADO).toLocaleString()} kg - ${moment(carregamento.DATA_CARREGAMENTO).format('DD/MM/YY HH:mm')}`, 0.5, 3.2);

        doc.save(`ticket_pesagem_${carregamento.ID_CARREGAMENTO}.pdf`);
    }

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return isNaN(date) ? 'Data inválida' : format(date, 'dd/MM - HH:mm');
    };

    const exportRelatorioPDF = () => {
        const doc = new jsPDF();

        // Função para adicionar o cabeçalho em cada página
        const addHeader = (doc, isFirstPage) => {
            if (isFirstPage) {
                doc.addImage(Logo_Orion, 'PNG', 10, 10, 40, 10);
            }
            doc.setFontSize(10); // Define o tamanho da fonte para corresponder ao conteúdo da tabela

            const headerText = `Relatório Período: ${relatorios}`;
            doc.text(headerText, isFirstPage ? 70 : 10, isFirstPage ? 20 : 10);

            // Adicionar informações adicionais no cabeçalho usando autoTable
            if (isFirstPage) {
                const tableData = autos.map(val => ({
                    totalAutos: `Autos: ${val.QTDE_AUTOS}`,
                    pesoHibrido: `Peso: ${(val.PESO_HIBRIDO / 1000).toLocaleString('pt-BR', { minimumFractionDigits: 3, maximumFractionDigits: 3 })} Tons`
                }));

                // Extraindo uma única entrada de autos para criar a tabela de totais
                const totalAutos = tableData.length > 0 ? tableData[0].totalAutos : 'Autos: 0';
                const pesoHibrido = tableData.length > 0 ? tableData[0].pesoHibrido : 'Peso: 0 Tons';

                doc.autoTable({
                    head: [['TOTAL', '']],
                    body: [
                        [totalAutos, ''],
                        [pesoHibrido, '']
                    ],
                    startY: 30,
                    theme: 'plain',
                    headStyles: {
                        fillColor: [0, 29, 79], // Azul
                        textColor: [255, 255, 255], // Branco
                        fontSize: 10,
                        fontStyle: 'bold'
                    },
                    bodyStyles: {
                        fontSize: 10
                    },
                    styles: {
                        cellPadding: 2
                    },
                    columnStyles: {
                        0: { cellWidth: 50 }, // Ajuste o valor conforme necessário para o tamanho do texto
                        1: { cellWidth: 10 } // Segunda coluna com largura mínima
                    }
                });
            }
        };

        // Inicializar o cabeçalho para a primeira página
        addHeader(doc, true);

        // Adicionar a tabela principal
        const tableColumn = ["#", "ID", "Nome", "Placa do Cavalo", "Peso Líquido", "DI", "Data Carregado"];
        const tableRows = [];

        operacoes.forEach((val, index) => {
            const rowData = [
                index + 1,
                val.ID_CARREGAMENTO,
                val.NOME_MOTORISTA,
                val.PLACA_CAVALO,
                val.PESO_LIQUIDO.toLocaleString(undefined, { maximumFractionDigits: 2 }),
                val.DOCUMENTO,
                formatDate(val.DATA_CARREGAMENTO)
            ];
            tableRows.push(rowData);
        });

        doc.autoTable({
            head: [tableColumn],
            body: tableRows,
            startY: 55,
            didDrawPage: (data) => {
                // Adicionar o cabeçalho em cada página sem o logo
                if (data.pageNumber > 1) {
                    addHeader(doc, false);
                }
            },
            theme: 'striped',
            headStyles: {
                fontSize: 10, // Correspondência de tamanho da fonte com o cabeçalho
                fillColor: [0, 29, 79], // Azul
                textColor: [255, 255, 255] // Branco
            },
            bodyStyles: {
                fontSize: 10 // Correspondência de tamanho da fonte com o cabeçalho
            }
        });

        doc.save('relatorio_periodo.pdf');
    };



    const handleFilterChange = (e) => {
        setFilter(e.target.value);
    };

    const handleFilterTypeChange = (e) => {
        setFilterType(e.target.value);
    };

    const filteredOperacoes = operacoes.filter((operacao) => {
        const filterLowerCase = filter.toLowerCase();
        switch (filterType) {
            case "NOME_MOTORISTA":
                return operacao.NOME_MOTORISTA.toLowerCase().includes(filterLowerCase);
            case "PLACA_CAVALO":
                return operacao.PLACA_CAVALO.toLowerCase().includes(filterLowerCase);
            case "DOCUMENTO":
                return operacao.REFERENCIA.toLowerCase().includes(filterLowerCase);
            case "NOME_CLIENTE":
                return operacao.NOME_CLIENTE.toLowerCase().includes(filterLowerCase);
            case "NOME_REDUZIDO_TRANSP":
                return operacao.NOME_REDUZIDO_TRANSP.toLowerCase().includes(filterLowerCase);
            case "PLACA_CARRETA":
                return (
                    (operacao.PLACA_CARRETA && operacao.PLACA_CARRETA.toLowerCase().includes(filterLowerCase)) ||
                    (operacao.PLACA_CARRETA2 && operacao.PLACA_CARRETA2.toLowerCase().includes(filterLowerCase)) ||
                    (operacao.PLACA_CARRETA3 && operacao.PLACA_CARRETA3.toLowerCase().includes(filterLowerCase))
                );
            default:
                return true;
        }
    });

    const handleDocumentSubmit = async (e) => {
        e.preventDefault(); // Previne o comportamento padrão de envio do formulário

        if (!file) {
            showAlert("Selecione um arquivo antes de enviar.", 'error');
            return;
        }

        if (!selectedSeqPeriodoOp) {
            showAlert("O período da operação não foi encontrado.", 'error');
            return;
        }

        const usuario = JSON.parse(localStorage.getItem("user_token")).id; // Obtenha o ID do usuário logado
        const formData = new FormData();
        formData.append('file', file); // Adiciona o arquivo PDF
        formData.append('codListaDocumento', 6); // Cod_lista_documento fixo como 6
        formData.append('codOperacao', id); // Use o `id` diretamente, que é o valor de `COD_OPERACAO`
        formData.append('nomeNavio', i.NOME_NAVIO); // Nome do navio (se necessário)
        formData.append('usuarioDocumento', usuario); // ID do usuário responsável
        formData.append('seqPeriodoOp', selectedSeqPeriodoOp); // Adiciona o SEQ_PERIODO_OP selecionado

        try {
            const response = await Axios.post('https://hml.operacao.orion-op.com.br/api/documento/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.status === 200) {
                showAlert('Documento adicionado com sucesso!', 'success');
                getListaDocumentoDoc(id); // Atualiza a lista de documentos usando `id`
                handleCloseDDSModal(); // Fecha o modal após o sucesso
            } else {
                showAlert('Erro ao adicionar o documento.', 'error');
            }
        } catch (error) {
            console.error('Erro ao adicionar o documento:', error);

        }
    };


    const [isDDSEnabled, setIsDDSEnabled] = useState(false); // Inicializa como "false" para ser desabilitado até que a verificação seja feita

    const verificarDocumento = (seqPeriodoOp) => {
        Axios.get(`https://hml.operacao.orion-op.com.br/api/documento/existe/${seqPeriodoOp}`)
            .then((response) => {
                console.log('Documento existe:', response.data.exists);
                setIsDDSEnabled(!response.data.exists); // Habilita o botão se não existir documento
            })
            .catch((error) => {
                console.error('Erro ao verificar documento:', error);
                setIsDDSEnabled(true); // Em caso de erro, mantemos o botão habilitado
            });
    };

    return (
        <>
            <Navbar relatorios />
            <Header />
            <Brackground />
            <Container>
                <div className={style.content}>
                    <div className={style.nav}>
                        <div onClick={() => navigate(`/relatorios`)}>
                            Selecione um navio
                        </div>
                        <div className={style.active}>
                            Relatório por Período
                        </div>
                        <div onClick={() => navigate(`/relatorios/operacao/${id}`)}>
                            Relatório por Viagem
                        </div>
                        <div onClick={() => navigate(`/relatorios/analiseoperacional/${id}`)}>
                            Análise Operacional
                        </div>

                        <div onClick={() => navigate(`/relatorios/idfa/${id}`)}>
                            IDFA
                        </div>
                    </div>

                    <div className={style.container}>
                        <div className={style.formulario}>
                            <section className={style.filters}>
                                <p> <center> <b><i class="fas fa-ship"></i>&nbsp;&nbsp;&nbsp;{dadosDash.NOME_NAVIO || "--"}</b> </center> </p>
                                <label>Selecione um período:</label>
                                <div className={style.formControl}>

                                    <select onChange={(e) => {
                                        const selectedPeriodo = periodo.find(val => val.PERIODO === e.target.value);
                                        setRelatorios(e.target.value); // Armazena o período selecionado
                                        setSelectedSeqPeriodoOp(selectedPeriodo?.SEQ_PERIODO_OP); // Armazena o SEQ_PERIODO_OP
                                        if (selectedPeriodo?.SEQ_PERIODO_OP) {
                                            verificarDocumento(selectedPeriodo.SEQ_PERIODO_OP); // Chama a função de verificação
                                        }
                                    }}>
                                        <option disabled selected>Selecione uma opção</option>
                                        {periodo?.map(val => (
                                            <option key={val.SEQ_PERIODO_OP} value={val.PERIODO}>{val.PERIODO}</option>
                                        ))}
                                    </select>

                                    <button className={style.submitButton} onClick={getOperacoes}>Buscar</button>
                                </div>

                                <div className={style.selectedPeriod}>
                                    <table className={style.moegaTable}>
                                        <tbody>
                                            {moega.map((val, index) => (
                                                <tr key={index}>
                                                    <td className={style.centered}>
                                                        <div><b>{relatorios}</b> - {val.TERNO} Terno</div>
                                                        <div>{val.MOEGA_1} - Porão {val.PORAO_01}</div>
                                                        {val.MOEGA_2 && val.PORAO_02 &&
                                                            <div>{val.MOEGA_2} - Porão {val.PORAO_02}</div>
                                                        }
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>

                            </section>
                            {moega.some(val => val.CORTE_CHUVA === 'S') ? (
                                <div>
                                    <div className={style.chuvaContainer}>
                                        <i className="fa fa-cloud-rain icon" />&nbsp; &nbsp;
                                        <p>CORTE DE PERÍODO POR CHUVA</p>
                                    </div>
                                    {(usuario === 'lucas' || usuario === 'jose.loreto' || usuario === 'gustavo.honorato' || usuario === 'michele') && (
                                        <button
                                            className={style.superviaButton}
                                            onClick={showModal} // Abre o modal ao clicar no botão SUPERVIA
                                        >
                                            SUPERVIA
                                        </button>
                                    )}
                                </div>
                            ) : moega.some(val => val.CORTE_OUTRO_OPERADOR === 'S') ? (
                                <div>
                                    <div className={style.chuvaContainer}>
                                        <i className="fa fa-user-times icon" />&nbsp; &nbsp;
                                        <p>CORTE DE PERÍODO POR OUTRO OPERADOR</p>
                                    </div>
                                    {(usuario === 'lucas' || usuario === 'jose.loreto' || usuario === 'gustavo.honorato' || usuario === 'michele') && (
                                        <button
                                            className={style.superviaButton}
                                            onClick={showModal} // Abre o modal ao clicar no botão SUPERVIA
                                            disabled={isButtonDisabled || moega.some(val => val.REGISTRADO_CORTE_OUTRO_OPERADOR === 'S')}
                                        >
                                            SUPERVIA
                                        </button>
                                    )}
                                </div>
                            ) : (
                                <div className={style.cardPara}>
                                    <table className={style.cardParalisacao}>
                                        <thead>
                                            <tr>
                                                <th className={style.linha}><abbr title="MOTIVO">Motivo</abbr></th>
                                                <th className={style.linha}><abbr title="INICIO">Ínicio</abbr></th>
                                                <th className={style.linha}><abbr title="TERMINO">Término</abbr></th>
                                                <th className={style.linha}><abbr title="DURACAO">Duração</abbr></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {paralisacao.filter((val) => {
                                                if (busca === "") {
                                                    return true;
                                                } else {
                                                    return val.DESC_MOTIVO.includes(busca) ||
                                                        val.OBSERVACO.includes(busca) ||
                                                        val.HORA_INICIO.includes(busca) ||
                                                        val.HORA_TERMINO.includes(busca) ||
                                                        val.DURACAO.includes(busca);
                                                }
                                            }).map((val) => {
                                                return (
                                                    <tr key={val.DESC_MOTIVO}>
                                                        <th className={style.tooltip}>
                                                            {val.DESC_MOTIVO}
                                                            {(val.OBSERVACO) && (
                                                                <th className={style.tooltiptext}>
                                                                    <table className={style.table2}>
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Observação</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <th>{val.OBSERVACO}</th>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </th>
                                                            )}
                                                        </th>
                                                        <th>{val.HORA_INICIO}</th>
                                                        <th>{val.HORA_TERMINO}</th>
                                                        <th>{val.DURACAO}</th>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            )}

                        </div>
                        {moega.every(val => val.CORTE_CHUVA !== 'S' && val.CORTE_OUTRO_OPERADOR !== 'S') && (
                            <section className={style.cards}>
                                <div className={style.card}>
                                    <i className="fa fa-truck icon"></i>
                                    <p><b>Carregados</b></p>
                                    {autos.map(val => (
                                        <span className={style.total}>{val.QTDE_AUTOS} Autos</span>
                                    ))}
                                </div>

                                <div className={style.card}>
                                    <i className="fa fa-battery-three-quarters icon"></i>
                                    <p><b>Peso Líquido</b></p>
                                    {autos.map(val => (
                                        <span className={style.total}>
                                            {(val.PESO_HIBRIDO / 1000).toLocaleString('pt-BR', { minimumFractionDigits: 3, maximumFractionDigits: 3 })} Tons
                                        </span>
                                    ))}
                                </div>

                                <div className={style.card}>
                                    <i className="fa fa-file-text icon"></i>
                                    <p><b>Peso por DI / BL</b></p>
                                    {documentos.map(val => (
                                        <div className={style.details}>
                                            <span>{val.DOC_CARGA}</span> | {(val.PESO_LIQUIDO_CARGA / 1000).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} Tons | {val.QTDE_AUTOS_CARGA} Autos
                                        </div>
                                    ))}
                                </div>

                                <div className={style.card}>
                                    <i className="fa fa-clock-o icon"></i>
                                    <p><b>Hora Hora</b></p>
                                    {hora.map(val => (
                                        <div className={style.details}>
                                            <b>{val.HORA}</b> | {val.QUANTIDADE_AUTOS} Autos
                                        </div>
                                    ))}
                                </div>

                            </section>
                        )}
                        {moega.every(val => val.CORTE_CHUVA !== 'S' && val.CORTE_OUTRO_OPERADOR !== 'S') && (

                            <section className={style.search}>

                                <div className={style.searchBox}>
                                    <select value={filterType} onChange={handleFilterTypeChange}>
                                        <option value="NOME_MOTORISTA">Motorista</option>
                                        <option value="NOME_CLIENTE">Recebedor</option>
                                        <option value="PLACA_CAVALO">Placa do Cavalo</option>
                                        <option value="PLACA_CARRETA">Placa da Carreta</option>
                                        <option value="DOCUMENTO">DI/BL</option>
                                        <option value="NOME_REDUZIDO_TRANSP">Transportadora</option>
                                    </select>
                                    <input
                                        type="text"
                                        placeholder="Pesquisar..."
                                        value={filter}
                                        onChange={handleFilterChange}
                                    />
                                </div>

                                <div className={style.dataFiltro}>
                                    <button
                                        className={style.superviaButton}
                                        onClick={handleOpenDDSModal} // Abre o modal de DDS
                                        disabled={!isDDSEnabled} // Desabilita o botão caso já exista um documento para o SEQ_PERIODO_OP
                                    >
                                        DDS
                                    </button>


                                    {(usuario === 'lucas' || usuario === 'jose.loreto') && (
                                        <button
                                            className={style.superviaButton}
                                            onClick={showModal} // Abre o modal ao clicar no botão SUPERVIA
                                        >
                                            SUPERVIA
                                        </button>
                                    )}

                                    <i
                                        className={`fa fa-file-pdf ${style.iconButton}`}
                                        onClick={exportRelatorioPDF}
                                    />

                                </div>
                            </section>

                        )}
                    </div>

                    {moega.every(val => val.CORTE_CHUVA !== 'S' && val.CORTE_OUTRO_OPERADOR !== 'S') && (
    <table className={style.table}>
        <thead>
            <tr>
                <th className={style.sequencial}>#</th>
                <th><abbr title="ID">ID</abbr></th>
                <th><abbr title="Nome">Nome</abbr></th>
                <th><abbr title="Placa do Cavalo">Placa (Cavalo)</abbr></th>
                <th><abbr title="Tara">1° Peso (Tara)</abbr></th>
                <th><abbr title="Segundo Peso">2° Peso (Moega)</abbr></th>
                <th><abbr title="Terceiro Peso">3° Peso (Bruto)</abbr></th>
                <th><abbr title="Peso Líquido">Peso Líquido</abbr></th>
                <th><abbr title="Documento">DI</abbr></th>
                <th><abbr title="Cliente">Recebedor</abbr></th>
                <th><abbr title="Transportadora">Transp.</abbr></th>
                <th><abbr title="Data Carregado">Data Carregado</abbr></th>
                <th><abbr title="(Liquido - Moega)">Diferença</abbr></th>
                <th><abbr title="Ticket">Ticket</abbr></th>
                <th><abbr title="Nota">N.F</abbr></th>
            </tr>
        </thead>

        <tbody>
            {filteredOperacoes.map((val, index) => {
                const isStatusSete = val.STATUS_NOTA_MIC === 7;
                const notaDownloadClass = [2, 3, 4, 6].includes(val.STATUS_NOTA_MIC)
                    ? style.nota_download
                    : style.nota_download_empty;

                const rowClasses = `${val.PESO_BRUTO === 0 ? style.yellowbackground : ''} ${isStatusSete ? style.strikethrough : ''}`;

                // Definir cor de fundo somente para o <td> da coluna sequencial
                let backgroundColor = '';

                if (val.PESO_MIC === val.PESO_CARREGADO) {
                    backgroundColor = 'green'; // Verde se PESO_MIC for igual a PESO_CARREGADO
                } else if (val.PESO_MIC === null) {
                    backgroundColor = 'gray'; // Cinza se PESO_MIC for igual a 0.000
                } else if (val.PESO_MIC !== null && val.PESO_MIC !== val.PESO_CARREGADO) {
                    backgroundColor = 'red'; // Vermelho se PESO_MIC for maior que 0.000 e diferente de PESO_CARREGADO
                }

                return (
                    <tr key={val.ID_CARREGAMENTO} className={rowClasses}>
                        <td className={style.sequencial} style={{ backgroundColor, color: 'white' }}>
                            {index + 1}
                        </td> {/* Adiciona a numeração sequencial com cor de fundo */}
                        <td>{val.ID_CARREGAMENTO}</td>
                        <td>{val.NOME_MOTORISTA}</td>
                        <td className={style.tooltip}>
                            {val.PLACA_CAVALO}
                            {(val.PLACA_CARRETA || val.PLACA_CARRETA2) && (
                                <div className={style.tooltiptext}>
                                    <table className={style.table2}>
                                        <thead>
                                            <tr>
                                                <th>Carreta_1</th>
                                                <th>Carreta_2</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{val.PLACA_CARRETA}</td>
                                                <td>{val.PLACA_CARRETA2}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </td>
                        <td>{(val.PESO_TARA).toLocaleString(undefined, { maximumFractionDigits: 2 })}</td>
                        <td>{(val.PESO_CARREGADO).toLocaleString(undefined, { maximumFractionDigits: 2 })}</td>
                        <td>{(val.PESO_BRUTO).toLocaleString(undefined, { maximumFractionDigits: 2 })}</td>
                        <td>{(val.PESO_LIQUIDO).toLocaleString(undefined, { maximumFractionDigits: 2 })}</td>
                        <td>{val.DOCUMENTO}</td>
                        <td>{val.NOME_CLIENTE}</td>
                        <td>{val.NOME_REDUZIDO_TRANSP}</td>
                        <td>{formatDate(val.DATA_CARREGAMENTO)}</td>
                        <td>{val.DIFERENCA} kg - {val.PERCENTUAL} %</td>
                        <td>
                            <i
                                onClick={() => downloadTicket(val)}
                                style={{ cursor: "pointer", color: "auto" }}
                                className="fa fa-file-pdf icon"
                            />
                        </td>
                        <td className={notaDownloadClass}>
                            <i
                                onClick={() => [2, 3, 4, 6].includes(val.STATUS_NOTA_MIC) ? downloadNota(val.ID_CARREGAMENTO) : undefined}
                                style={[2, 3, 4, 6].includes(val.STATUS_NOTA_MIC) ? { color: "auto" } : { color: "#bcbcbc" }}
                                className="fa fa-file-pdf icon"
                            />
                        </td>
                    </tr>
                );
            })}
        </tbody>
    </table>
)}

                    {/* Modal de confirmação do envio de dados para SUPERVIA */}
                    <Modal
                        open={openModal}
                        onClose={handleCloseModal}
                        aria-labelledby="modal-title"
                        aria-describedby="modal-description"
                    >
                        <Box
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: 400,
                                bgcolor: 'background.paper',
                                border: '2px solid #001d49',
                                boxShadow: 24,
                                p: 4,
                                textAlign: 'center'
                            }}
                        >
                            <Typography id="modal-title" variant="h6" component="h2">
                                DESEJA ENVIAR OS DADOS PARA SUPERVIA?
                            </Typography>
                            <Box mt={4} display="flex" justifyContent="space-between">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSendToSupervia}
                                >
                                    Sim
                                </Button>
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={handleCloseModal}
                                >
                                    Não
                                </Button>
                            </Box>
                        </Box>
                    </Modal>

                    <Modal
                        open={openDDSModal} // Estado para abrir/fechar modal
                        onClose={handleCloseDDSModal}
                        aria-labelledby="modal-dds-title"
                        aria-describedby="modal-dds-description"
                    >
                        <Box
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: 400,
                                bgcolor: 'background.paper',
                                border: '2px solid #001d49',
                                boxShadow: 24,
                                p: 4,
                                textAlign: 'center'
                            }}
                        >
                            <Typography id="modal-dds-title" variant="h6" component="h2">
                                Enviar Documento PDF (DDS)
                            </Typography>
                            <Box mt={2} sx={{ gap: '25px', justifyContent: 'space-between' }}>
                                <form onSubmit={handleDocumentSubmit}>
                                    <input
                                        type="file"
                                        accept="application/pdf"
                                        onChange={(e) => setFile(e.target.files[0])} // Captura o arquivo PDF
                                        required
                                    />
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        disabled={!file} // Desabilita o botão se não houver arquivo selecionado
                                    >
                                        Enviar
                                    </Button>
                                </form>
                            </Box>
                        </Box>
                    </Modal>


                </div>
            </Container>

        </>
    );
};

export default function IntegrationNotistack() {
    return (
        <SnackbarProvider
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            maxSnack={3}
            autoHideDuration={2500}>
            <RelatorioPeriodo />
        </SnackbarProvider >
    );
}