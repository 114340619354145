import React, { useState, useEffect, useRef } from 'react';
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { SnackbarProvider, useSnackbar } from 'notistack';
import SignatureCanvas from 'react-signature-canvas';
import Navbar from '../../../components/Navbar';
import Background from '../../../components/Background';
import Container from '../../../components/Container';
import Header from '../../../components/Header';
import style from './Forms.module.css';

const SwitchThreeStates = ({ name, value = 'Conforme', onChange }) => {
  const [status, setStatus] = useState(value);

  const handleSwitchChange = () => {
    const nextStatus =
      status === 'Conforme' ? 'Não Conforme' :
        status === 'Não Conforme' ? 'Não Aplicável' :
          'Conforme';

    setStatus(nextStatus);
    onChange(name, nextStatus); // Passa o novo status para a função onChange do formulário
  };

  return (
    <div className={`${style.switch}`} onClick={handleSwitchChange}>
      <span className={style.slider}></span>
      <div className={`${style.statusText}`}>{status}</div>
    </div>
  );
};


const Forms = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [navios, setNavios] = useState([]);
  const [moegaEcologicaItems, setMoegaEcologicaItems] = useState([]);
  const [formSectionsVisibility, setFormSectionsVisibility] = useState({
    moegaEcologica: false,
    bordoCostado: false,
    plataformaDeLonamento: false,
    grab: false,
    cestoDeResgate: false
  });
  const [isSignatureSaved, setIsSignatureSaved] = useState(false);
  const signatureSupervisorRef = useRef(null);
  const signatureManutencaoRef = useRef(null);
  const [observacao, setObservacao] = useState('');

  const [showSecondFileInput, setShowSecondFileInput] = useState(false);
  const [showThirdFileInput, setShowThirdFileInput] = useState(false);
  const [foto1, setFoto1] = useState(null);
  const [foto2, setFoto2] = useState(null);
  const [foto3, setFoto3] = useState(null);

  const usuario = JSON.parse(localStorage.getItem("user_token")).id;

  // Estados separados para cada campo
  const [supervisor, setSupervisor] = useState('');
  const [manutencao, setManutencao] = useState('');
  const [navio, setNavio] = useState('');
  const [tipoInspecao, setTipoInspecao] = useState('');
  const [calderaria, setCalderaria] = useState({});

  useEffect(() => {
    Axios.get('https://hml.operacao.orion-op.com.br/api/operacao/operando')
      .then((response) => {
        setNavios(response.data);
      })
      .catch((error) => {
        console.error('Erro ao buscar os navios:', error);
      });
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === 'supervisor') {
      setSupervisor(value);
    } else if (name === 'manutencao') {
      setManutencao(value);
    } else if (name === 'navio') {
      setNavio(value);
    } else if (name === 'tipoInspecao') {
      setTipoInspecao(value);

      // Condicional para mostrar/ocultar campos com base no tipo de inspeção selecionado
      let categoria = '';
      const visibility = {
        moegaEcologica: false,
        bordoCostado: false,
        plataformaDeLonamento: false,
        grab: false,
        cestoDeResgate: false
      };
      
      if (value === "MOEGA ECOLÓGICA") {
        categoria = 'MOEGA ECOLÓGICA';
        visibility.moegaEcologica = true;
      } else if (value === "BORDO/COSTADO") {
        categoria = 'BORDO/COSTADO';
        visibility.bordoCostado = true;
      } else if (value === "PLATAFORMA DE LONAMENTO") {
        categoria = 'PLATAFORMA DE LONAMENTO';
        visibility.plataformaDeLonamento = true;
      } else if (value === "GRAB") {
        categoria = 'GRAB';
        visibility.grab = true;
      } else if (value === "CESTO DE RESGATE") {
        categoria = 'CESTO DE RESGATE';
        visibility.cestoDeResgate = true;
      }

      setFormSectionsVisibility(visibility);

      if (categoria) {
        Axios.get(`https://hml.operacao.orion-op.com.br/api/api/moega-ecologica?categoria=${categoria}`)
          .then((response) => {
            setMoegaEcologicaItems(response.data);
          })
          .catch((error) => {
            console.error(`Erro ao buscar os dados para ${categoria}:`, error);
          });
      }
    } else if (name === 'observacao') {
      setObservacao(value);
    }
  };

  useEffect(() => {
    if (tipoInspecao) {
      let categoria = '';
      
      if (tipoInspecao === "MOEGA ECOLÓGICA") {
        categoria = 'MOEGA ECOLÓGICA';
      } else if (tipoInspecao === "BORDO/COSTADO") {
        categoria = 'BORDO/COSTADO';
      } else if (tipoInspecao === "PLATAFORMA DE LONAMENTO") {
        categoria = 'PLATAFORMA DE LONAMENTO';
      } else if (tipoInspecao === "GRAB") {
        categoria = 'GRAB';
      } else if (tipoInspecao === "CESTO DE RESGATE") {
        categoria = 'CESTO DE RESGATE';
      }
  
      if (categoria) {
        Axios.get(`https://hml.operacao.orion-op.com.br/api/api/moega-ecologica?categoria=${categoria}`)
          .then((response) => {
            const items = response.data;
            setMoegaEcologicaItems(items);
            
            // Inicializa todos os itens com 'Conforme' no estado calderaria
            const initialCalderaria = {};
            items.forEach(item => {
              initialCalderaria[item.NOME_SEG_FORMULARIO] = 'Conforme';
            });
            setCalderaria(initialCalderaria);
          })
          .catch((error) => {
            console.error(`Erro ao buscar os dados para ${categoria}:`, error);
          });
      }
    }
  }, [tipoInspecao]);
  

  // Função para lidar com mudanças nos switches
  const handleSwitchChange = (name, value) => {
    setCalderaria((prevData) => ({
        ...prevData,
        [name]: value,
    }));
    console.log('Novo estado de calderaria:', calderaria);
  };

  // Função para enviar os dados do formulário
  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log('Estado calderaria antes do envio:', calderaria);

    // Verifica se todos os campos obrigatórios estão preenchidos
    if (!supervisor || !manutencao || !navio || !tipoInspecao) {
      enqueueSnackbar('Por favor, preencha todos os campos obrigatórios.', { variant: 'warning' });
      return;
    }

    try {
      // Criar um FormData para enviar os dados, a foto e as assinaturas
      const formData = new FormData();
      formData.append('supervisor', supervisor);
      formData.append('manutencao', manutencao);
      formData.append('navio', navio);
      formData.append('tipoInspecao', tipoInspecao);
      formData.append('usuarioCadastro', usuario);
      formData.append('observacao_01', observacao);

      // Certifique-se de que `calderaria` é uma string JSON ao ser adicionado ao FormData
      if (calderaria && Object.keys(calderaria).length > 0) {
        formData.append('calderaria', JSON.stringify(calderaria));
      } else {
        console.warn('Calderaria está vazio ou indefinido!');
      }

      // Adicionar os anexos, se existirem
      if (foto1) formData.append('anexo_01', foto1);
      if (foto2) formData.append('anexo_02', foto2);
      if (foto3) formData.append('anexo_03', foto3);

      // Adicionar as assinaturas ao FormData
      if (signatureSupervisorRef.current) {
        const assinaturaSupervisorBlob = await new Promise(resolve =>
          signatureSupervisorRef.current.getTrimmedCanvas().toBlob(resolve, 'image/png')
        );
        formData.append('assinaturaSupervisor', assinaturaSupervisorBlob, 'ASSINATURA-SUPERVISOR.png');
      }

      if (signatureManutencaoRef.current) {
        const assinaturaManutencaoBlob = await new Promise(resolve =>
          signatureManutencaoRef.current.getTrimmedCanvas().toBlob(resolve, 'image/png')
        );
        formData.append('assinaturaManutencao', assinaturaManutencaoBlob, 'ASSINATURA-MANUTENCAO.png');
      }

      // Log para depuração do FormData antes do envio
      for (let pair of formData.entries()) {
        console.log(`${pair[0]}: ${pair[1]}`);
      }

      // Enviar dados ao backend
      const response = await Axios.post('https://hml.operacao.orion-op.com.br/api/api/formulario/inserir', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      const idHistorico = response.data.idHistorico;
      console.log('ID do histórico criado:', idHistorico);

      // Exibir mensagem de sucesso
      enqueueSnackbar('Formulário enviado com sucesso!', { variant: 'success' });

      // Recarregar a página após um pequeno atraso
      setTimeout(() => {
        window.location.reload();
      }, 2000);

    } catch (error) {
      console.error('Erro ao enviar o formulário:', error);
      enqueueSnackbar('Erro ao enviar o formulário!', { variant: 'error' });

      if (error.response) {
        console.error('Dados do erro:', error.response.data);
      }
    }
  };

  // Agrupamento dos itens por TABELA_SEG_FORMULARIO para cada categoria
  const groupedItems = (categoria) => {
    return moegaEcologicaItems
      .filter(item => item.CATEGORIA_SEG_FORMULARIO === categoria)
      .reduce((acc, item) => {
        if (!acc[item.TABELA_SEG_FORMULARIO]) {
          acc[item.TABELA_SEG_FORMULARIO] = [];
        }
        acc[item.TABELA_SEG_FORMULARIO].push(item);
        return acc;
      }, {});
  };

  const clearSignature = (signatureRef) => {
    signatureRef.current.clear();
    setIsSignatureSaved(false);
  };

  // Salvar a assinatura
  const saveSignature = () => {
    setIsSignatureSaved(true);
  };

  // Impedir que a assinatura seja limpa ao clicar fora
  const handleClickOutside = () => {
    if (!isSignatureSaved) {
      saveSignature();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleFileChange = (e, setFile) => {
    const file = e.target.files[0];
    setFile(file);
  };
  

  return (
    <>
      <Navbar motivados />
      <Header />
      <Background />
      <Container>
        <div className={style.content}>
          <div className={style.nav}>
            <div className={`${style.active} ${style.navItem}`}>
              Formulário de Inspeção
            </div>
            <div onClick={() => navigate("/segtrabalho/historicoforms")}>
              Histórico
            </div>
          </div>
          <form className={style.formulario} onSubmit={handleSubmit}>
            <div className={style.formGroupRow}>
              <div className={style.formGroup}>
                <label className={style.label}>Supervisor</label>
                <select
                  name="supervisor"
                  value={supervisor}
                  onChange={handleInputChange}
                  className={style.select}
                >
                  <option value="">Selecione o Supervisor</option>
                  <option value="GUSTAVO HONORATO NASCIMENTO">Gustavo Honorato Nascimento</option>
                  <option value="GENILSON LUIZ DA SILVA">Genilson Luiz da Silva</option>
                </select>
              </div>

              <div className={style.formGroup}>
                <label className={style.label}>Manutenção</label>
                <select
                  name="manutencao"
                  value={manutencao}
                  onChange={handleInputChange}
                  className={style.select}
                >
                  <option value="">Selecione a Manutenção</option>
                  <option value="ANDRÉ CRISTÓVÃO PEIXOTO">André Cristóvão Peixoto</option>
                  <option value="DENIS SANTANA DE SOUZA">Denis Santana de Souza</option>
                </select>
              </div>

              <div className={style.formGroup}>
                <label className={style.label}>Navio</label>
                <select
                  name="navio"
                  value={navio}
                  onChange={handleInputChange}
                  className={style.select}
                >
                  <option value="">Selecione o Navio</option>
                  {navios.map((navio) => (
                    <option key={navio.COD_OPERACAO} value={navio.COD_OPERACAO}>
                      {navio.NOME_NAVIO}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className={style.formGroup}>
              <label className={style.label}>Tipo de Inspeção</label>
              <select
                name="tipoInspecao"
                value={tipoInspecao}
                onChange={handleInputChange}
                className={style.select}
              >
                <option value="">Selecione a Inspeção</option>
                <option value="MOEGA ECOLÓGICA">MOEGA ECOLÓGICA</option>
                <option value="BORDO/COSTADO">BORDO/COSTADO</option>
                <option value="PLATAFORMA DE LONAMENTO">PLATAFORMA DE LONAMENTO</option>
                <option value="CESTO DE RESGATE">CESTO DE RESGATE</option>
                <option value="GRAB">GRAB</option>
              </select>
            </div>

            {formSectionsVisibility.moegaEcologica && (
              <>
                {Object.keys(groupedItems('MOEGA ECOLÓGICA')).map((tableName, index) => (
                  <div key={index} className={style.calderariaSection}>
                    <strong><h3>{tableName}</h3></strong>
                    <table className={style.calderariaTable}>
                      <thead>
                        <tr>
                          <th>Item</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {groupedItems('MOEGA ECOLÓGICA')[tableName].map((item, i) => (
                          <tr key={i}>
                            <td>{item.NOME_SEG_FORMULARIO}</td>
                            <td>
                              <SwitchThreeStates
                                name={item.NOME_SEG_FORMULARIO}
                                value={calderaria[item.NOME_SEG_FORMULARIO] || 'Conforme'}
                                onChange={handleSwitchChange}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ))}
              </>
            )}

            {formSectionsVisibility.grab && (
              <>
                {Object.keys(groupedItems('GRAB')).map((tableName, index) => (
                  <div key={index} className={style.calderariaSection}>
                    <strong><h3>{tableName}</h3></strong>
                    <table className={style.calderariaTable}>
                      <thead>
                        <tr>
                          <th>Item</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {groupedItems('GRAB')[tableName].map((item, i) => (
                          <tr key={i}>
                            <td>{item.NOME_SEG_FORMULARIO}</td>
                            <td>
                              <SwitchThreeStates
                                name={item.NOME_SEG_FORMULARIO}
                                value={calderaria[item.NOME_SEG_FORMULARIO] || 'Conforme'}
                                onChange={handleSwitchChange}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ))}
              </>
            )}

            {formSectionsVisibility.plataformaDeLonamento && (
              <>
                {Object.keys(groupedItems('PLATAFORMA DE LONAMENTO')).map((tableName, index) => (
                  <div key={index} className={style.calderariaSection}>
                    <strong><h3>{tableName}</h3></strong>
                    <table className={style.calderariaTable}>
                      <thead>
                        <tr>
                          <th>Item</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {groupedItems('PLATAFORMA DE LONAMENTO')[tableName].map((item, i) => (
                          <tr key={i}>
                            <td>{item.NOME_SEG_FORMULARIO}</td>
                            <td>
                              <SwitchThreeStates
                                name={item.NOME_SEG_FORMULARIO}
                                value={calderaria[item.NOME_SEG_FORMULARIO] || 'Conforme'}
                                onChange={handleSwitchChange}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ))}
              </>
            )}

            {formSectionsVisibility.bordoCostado && (
              <>
                {Object.keys(groupedItems('BORDO/COSTADO')).map((tableName, index) => (
                  <div key={index} className={style.calderariaSection}>
                    <strong><h3>{tableName}</h3></strong>
                    <table className={style.calderariaTable}>
                      <thead>
                        <tr>
                          <th>Item</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {groupedItems('BORDO/COSTADO')[tableName].map((item, i) => (
                          <tr key={i}>
                            <td>{item.NOME_SEG_FORMULARIO}</td>
                            <td>
                              <SwitchThreeStates
                                name={item.NOME_SEG_FORMULARIO}
                                value={calderaria[item.NOME_SEG_FORMULARIO] || 'Conforme'}
                                onChange={handleSwitchChange}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ))}
              </>
            )}

            {formSectionsVisibility.cestoDeResgate && (
              <>
                {Object.keys(groupedItems('CESTO DE RESGATE')).map((tableName, index) => (
                  <div key={index} className={style.calderariaSection}>
                    <strong><h3>{tableName}</h3></strong>
                    <table className={style.calderariaTable}>
                      <thead>
                        <tr>
                          <th>Item</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {groupedItems('CESTO DE RESGATE')[tableName].map((item, i) => (
                          <tr key={i}>
                            <td>{item.NOME_SEG_FORMULARIO}</td>
                            <td>
                              <SwitchThreeStates
                                name={item.NOME_SEG_FORMULARIO}
                                value={calderaria[item.NOME_SEG_FORMULARIO] || 'Conforme'}
                                onChange={handleSwitchChange}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ))}
              </>
            )}

            <div className={style.formGroup}>
              <label className={style.label}>Observação</label>
              <textarea
                name="observacao"
                value={observacao}
                onChange={handleInputChange}
                className={style.textarea}
              ></textarea>
            </div>

            <div className={style.formWrapper}>
              <div className={style.inputGroup}>
                <label className={style.inputLabel}>Anexe uma foto</label>
                <div className={style.uploadContainer}>
                  <div className={style.uploadWrapper}>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) => handleFileChange(e, setFoto1)}
                      className={style.uploadInput}
                    />
                  </div>

                  {showSecondFileInput && (
                    <div className={style.uploadWrapper}>
                      <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => handleFileChange(e, setFoto2)}
                        className={style.uploadInput}
                      />
                    </div>
                  )}

                  {showThirdFileInput && (
                    <div className={style.uploadWrapper}>
                      <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => handleFileChange(e, setFoto3)}
                        className={style.uploadInput}
                      />
                    </div>
                  )}
                </div>
              </div>

              {!showSecondFileInput && (
                <button
                  type="button"
                  onClick={() => setShowSecondFileInput(true)}
                  className={style.submitButton}
                >
                  Adicione outro anexo
                </button>
              )}

              {showSecondFileInput && !showThirdFileInput && (
                <button
                  type="button"
                  onClick={() => setShowThirdFileInput(true)}
                  className={style.submitButton}
                >
                  Adicione outro anexo
                </button>
              )}
            </div>

            <div className={style.signatureGroup}>
              <div className={style.signatureContainer}>
                <label className={style.label}>Assinatura do Supervisor</label>
                <SignatureCanvas
                  ref={signatureSupervisorRef}
                  penColor="blue"
                  minWidth={0.5}
                  maxWidth={1.5}
                  canvasProps={{ className: style.signatureCanvas }}
                />
                <button type="button" className={style.clearButton} onClick={() => clearSignature(signatureSupervisorRef)}>
                  Limpar Assinatura
                </button>
              </div>

              <div className={style.signatureContainer}>
                <label className={style.label}>Assinatura da Manutenção</label>
                <SignatureCanvas
                  ref={signatureManutencaoRef}
                  penColor="blue"
                  minWidth={0.5}
                  maxWidth={1.5}
                  canvasProps={{ className: style.signatureCanvas }}
                />
                <button type="button" className={style.clearButton} onClick={() => clearSignature(signatureManutencaoRef)}>
                  Limpar Assinatura
                </button>
              </div>
            </div>

            <div className={style.buttonContainer}>
              <button type="submit">
                Enviar
              </button>
            </div>

          </form>
        </div>
      </Container>
    </>
  );
};

export default function IntegrationNotistack() {
  return (
    <SnackbarProvider
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      maxSnack={3}
      autoHideDuration={2500}
    >
      <Forms />
    </SnackbarProvider>
  );
}
