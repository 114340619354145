import React from 'react';
import { useNavigate } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import Navbar from '../../../components/Navbar';
import Background from '../../../components/Background';
import Container from '../../../components/Container';
import Header from '../../../components/Header';
import style from './LaudosEquipamentos.module.css';

const LaudosEquipamentos = () => {
  const navigate = useNavigate();

  return (
    <>
      <Navbar motivados />
      <Header />
      <Background />
      <Container>
        <div className={style.content}>
          <div className={style.nav}>
            <div onClick={() => navigate("")}>
              Segurança do Trabalho &gt;&gt; LAUDOS DE EQUIPAMENTOS
            </div>
          </div>
          <div className={style.table}>
            <div className={style.table_outer_container}>
              <div className={style.table_container}>
                {/* Três quadrados de navegação com ícones ajustados */}
                <div className={style.table_item} onClick={() => navigate("/segtrabalho/forms")}>
                  <i className="fas fa-recycle"></i> Moega Ecológica
                </div>
                <div className={style.table_item} onClick={() => navigate("/segtrabalho/forms")}>
                  <i className="fas fa-anchor"></i> Grab
                </div>
                <div className={style.table_item} onClick={() => navigate("/segtrabalho/forms")}>
                  <i className="fas fa-truck-loading"></i> Plataforma de Enlonamento
                </div>
                <div className={style.table_item} onClick={() => navigate("/segtrabalho/cestoderesgate")}>
                  <i className="fas fa-life-ring"></i> Cesto de Resgate
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default function IntegrationNotistack() {
  return (
    <SnackbarProvider
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      maxSnack={3}
      autoHideDuration={2500}
    >
      <LaudosEquipamentos />
    </SnackbarProvider>
  );
}
