import { createContext, useEffect, useState } from "react";
//import Axios from 'axios';

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState();
    // const [users, setUsers] = useState();
    const usersStorage = [
        { "id": "lucas", "password": "Luc@s15826", "nivel": "mestre","nmcompleto": "Lucas Rodrigues", "depto": "TI" },
        { "id": "esther", "password": "esther", "nivel": "mestre","nmcompleto": "Esther", "depto": "CALLIDUS" },
        { "id": "graziela.simioni", "password": "grazi@24", "nivel": "mestre","nmcompleto": "Graziela Simioni", "depto": "Financeiro" },
        { "id": "genilson.silva", "password": "nilson@56", "nivel": "mestre","nmcompleto": "Genilson Luiz da Silva", "depto": "Op. Portuária" },
        { "id": "gustavo.honorato", "password": "gustavo@98", "nivel": "mestre","nmcompleto": "Gustavo Honorato Nascimento", "depto": "Op. Portuária" },
        { "id": "lucas.santos", "password": "paul1001", "nivel": "mestre","nmcompleto": "Lucas D. dos Santos Carvalho", "depto": "Op. Portuária" },
        { "id": "mauricio.junior", "password": "mauricio@88", "nivel": "mestre","nmcompleto": "Maurício Nascimento Júnior", "depto": "Op. Portuária" },
        { "id": "abdalla.lopes", "password": "abdalla@46", "nivel": "mestre","nmcompleto": "Abdalla Lopes", "depto": "Op. Portuária" },
        { "id": "aduilson.santos", "password": "dudu@22", "nivel": "mestre","nmcompleto": "Aduilson R. dos Santos Marinho", "depto": "Op. Portuária" },
        { "id": "andre.peixoto", "password": "andre@10", "nivel": "mestre","nmcompleto": "André Cristóvão Peixoto", "depto": "Op. Portuária" },
        { "id": "denis.souza", "password": "denis@15", "nivel": "mestre","nmcompleto": "Denis Santana de Souza", "depto": "Op. Portuária" },
        { "id": "fertigran", "password": "fertigran", "nivel": "mestre","nmcompleto": "FERTIGRAN", "depto": "Op. Portuária" },
        { "id": "jose.rodrigues", "password": "rodrigues@42", "nivel": "mestre","nmcompleto": "José Rodrigues", "depto": "Gerente Operacional" },
        { "id": "desiree", "password": "manteck@56", "nivel": "mestre","nmcompleto": "Desiree da Silva Manteck", "depto": "Administrativo" },
        { "id": "higo", "password": "pinheiro@33", "nivel": "mestre","nmcompleto": "Higo Kario Rodrigues Pinheiro", "depto": "Gerente Administrativo" },
        { "id": "paul", "password": "paul@77", "nivel": "mestre","nmcompleto": "Paul Anderson", "depto": "Gerente Comercial" },
        { "id": "michele", "password": "michele@34", "nivel": "mestre","nmcompleto": "Michele Teles", "depto": "Administrativo" },
        { "id": "natalia", "password": "lopes@2024", "nivel": "mestre","nmcompleto": "Natalia Lopes", "depto": "Administrativo" },
        { "id": "guilherme.rgd", "password": "guirgd@45", "nivel": "mestre","nmcompleto": "Guilherme", "depto": "Op. Portuária" },
        { "id": "vinicius.rgd", "password": "vinirgd@76", "nivel": "mestre","nmcompleto": "Vinicius", "depto": "Op. Portuária" },
        { "id": "darlan.rgd", "password": "darrgd@35", "nivel": "mestre","nmcompleto": "Darlan", "depto": "Op. Portuária" },
        { "id": "vitor.rgd", "password": "vitrgd@61", "nivel": "mestre","nmcompleto": "Vitor", "depto": "Op. Portuária" },
        { "id": "patrick.rgd", "password": "patrgd@00", "nivel": "mestre","nmcompleto": "Patrick", "depto": "Op. Portuária" },
        { "id": "bruno.rgd", "password": "brurgd@35", "nivel": "mestre","nmcompleto": "Bruno", "depto": "Op. Portuária" },
        { "id": "setport", "password": "setport", "nivel": "mestre","nmcompleto": "SetPort", "depto": "Op. Portuária" },

    ]

    useEffect(() => {//toda vez que for carregada a aplicação, verifica user e token
        const userToken = localStorage.getItem("user_token");


        if (userToken) {//if verifica se existe algum usuario e token
            const hasUser = usersStorage.filter(
                (user) => user.id === JSON.parse(userToken).id
            );//verifica se o usuario tem o mesmo id do token

            if (hasUser) setUser(hasUser[0]);
        }
    }, []);

    const login = (id, password) => {//func. que recebe id e senha
        //objeto que puxa os registros
        //const usersStorage = JSON.parse(localStorage.getItem("users_db"));
        //verifica se o id ja esta cadastrado
        const hasUser = usersStorage?.filter((user) => user.id === id);


        if (hasUser?.length) {//caso exista esse usuario, verifica se id e senha esta correto
            if (hasUser[0].id === id && hasUser[0].password === password) {
                //gera token
                const nome = hasUser[0].nmcompleto;
                const departamento = hasUser[0].depto;
                const token = Math.random().toString(36).substring(2);//gera token aleatorio
                localStorage.setItem("user_token", JSON.stringify({ id, token, nome, departamento }));
                setUser({ id, password, nome, departamento });
                return;
            } else {
                return "Usuário ou senha incorretos";
            }
        } else {
            return "Usuário não cadastrado";
        }
    };


    const logout = () => {//faz o logout
        setUser(null);
        localStorage.removeItem("user_token");
    };

    return ( //retorna os valores para que possam ser usados no resto da aplicação
        <AuthContext.Provider
            value={{ user, signed: !!user, login, logout }}
        >
            {children}
        </AuthContext.Provider>
    );
};
