import React, { useState, useEffect } from "react";
import Navbar from "../../components/Navbar";
import Background from "../../components/Background";
import Container from "../../components/Container";
import { SnackbarProvider, useSnackbar } from 'notistack';
import Header from "../../components/Header";
import Axios from "axios";
import style from "./Integracao.module.css";
import SubmitButton from "../../components/Button";
import moment from 'moment';

const Integracao = () => {
    const [idCarregamento, setIdCarregamento] = useState("");
    const [pesoCarregado, setPesoCarregado] = useState("");
    const { enqueueSnackbar } = useSnackbar();
    const usuario = JSON.parse(localStorage.getItem("user_token")).id;

    const handleSubmit = () => {
        const data = {
            ID_CARREGAMENTO: idCarregamento,
            PESO_CARREGADO_PY: pesoCarregado,
            DATA_CARREGAMENTO_PY: moment().format('YYYY-MM-DD HH:mm:ss'),
            USUARIO_CARREG_PY: usuario
        };

        Axios.post('https://hml.operacao.orion-op.com.br/api/integracao', data)
            .then(response => {
                enqueueSnackbar('Dados inseridos com sucesso!', { variant: 'success' });
                setTimeout(() => {
                    window.location.reload();
                }, 1500); // 1.5 segundos de espera antes da recarga
            })
            .catch(error => {
                enqueueSnackbar('Erro ao inserir dados!', { variant: 'error' });
                console.log("Erro ao inserir dados:", error);
            });
    };

    return (
        <>
            <Navbar relatorioCliente />
            <Header />
            <Background />
            <Container>
                <div className={style.content}>
                    <div className={style.container1}>
                        <div className={style.dados}>
                            <div className={style.form_control}>
                                <label>ID Carregamento:</label>
                                <input
                                    type="text"
                                    value={idCarregamento}
                                    onChange={(e) => setIdCarregamento(e.target.value)}
                                />
                            </div>
                            
                            <div className={style.form_control}>
                                <label>Peso Carregado (PY):</label>
                                <input
                                    type="text"
                                    value={pesoCarregado}
                                    onChange={(e) => setPesoCarregado(e.target.value)}
                                />
                            </div>
                            <div className={style.submit_button}>
                                <SubmitButton text={'Inserir'} onClick={handleSubmit} />
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    );
};

export default function IntegrationNotistack() {
    return (
        <SnackbarProvider
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            maxSnack={3}
            autoHideDuration={2500}>
            <Integracao />
        </SnackbarProvider>
    );
}
