import React, { useState, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../../contexts/auth';
import './styles.css'; // Certifique-se de que este é o caminho correto para o seu arquivo CSS

function Navbar() {
  const { user, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [isActive, setIsActive] = useState(false);

  const menuItems = [
  { route: "/dashboard", icon: "fa fa-dashboard", text: "Início", activeProp: "dashboard", allowedIds: ["patrick.rgd", "bruno.rgd","vitor.rgd","darlan.rgd","vinicius.rgd","guilherme.rgd","natalia","michele","paul","higo","desiree","fertigran", "esther", "jose.rodrigues","lucas","graziela.simioni","genilson.silva","gustavo.honorato","lucas.santos","mauricio.junior","abdalla.lopes","aduilson.santos","andre.peixoto","denis.souza"] },
    { route: "/lineup/posicaonavios", icon: "fa fa-dashboard", text: "Line Up", activeProp: "lineup", allowedIds: ["lucas","paul","jose.rodrigues"] },
    { route: "/navios", icon: "fa fa-ship icon", text: "Navios", activeProp: "navs", allowedIds: ["genilson.silva","michele","gustavo.honorato","paul","esther", "jose.rodrigues","lucas"] },
    { route: "/cargas", icon: "fa fa-area-chart", text: "Docs Viagem", activeProp: "cargas", allowedIds: ["abdalla.lopes","mauricio.junior","natalia","genilson.silva","michele","gustavo.honorato","paul","esther","jose.rodrigues","lucas"] },
    { route: "/segtrabalho/pageseg", icon: "fa fa-area-chart", text: "Seg Trabalho", activeProp: "Seg Trabalho", allowedIds: ["natalia","michele","paul","jose.rodrigues","lucas","genilson.silva","gustavo.honorato"] },
    { route: "/relatoriossetport/152", icon: "fa fa-area-chart", text: "SetPort", activeProp: "Seg Trabalho", allowedIds: ["setport"] },

    { route: "/agendamento/BuscarMotoristaAgendamento", icon: "fa fa-calendar icon", text: "Agendamento", activeProp: "agendamento", allowedIds: [] },
    { route: "/custeio/vigias", icon: "fa fa-money", text: "Custeio", activeProp: "custeio", allowedIds: ["michele","paul","higo","desiree","jose.rodrigues","lucas"] },
    { route: "/suprimentos/dashboardsuprimentos", icon: "fa fa-gas-pump", text: "Suprimentos", activeProp: "suprimentos", allowedIds: [] },
    { route: "/recursoshumanos/ficharegistro", icon: "fa fa-users", text: "Recursos Humanos", activeProp: "recursos", allowedIds: [] },
    { route: "/motivacao/Motivados", icon: "fa fa-credit-card icon", text: "Motivação", activeProp: "motivados", allowedIds: [] },
    { route: "/manutencao/Equipamentos", icon: "fa fa-hammer icon", text: "Manutenção", activeProp: "manutencao", allowedIds: ["lucas"] },
    { route: "/veiculos/BuscarMotorista", icon: "fa fa-truck icon", text: "Autos", activeProp: "veiculos", allowedIds: ["bruno.rgd","patrick.rgd","vitor.rgd","darlan.rgd","vinicius.rgd","guilherme.rgd","michele","paul","esther","jose.rodrigues","lucas","genilson.silva","gustavo.honorato","lucas.santos","mauricio.junior","abdalla.lopes","aduilson.santos","andre.peixoto","denis.souza"] },
    { route: "/operacoes", icon: "fa fa-anchor", text: "Viagem", activeProp: "operacao", allowedIds: ["patrick.rgd","vitor.rgd", "bruno.rgd", "darlan.rgd","vinicius.rgd","guilherme.rgd","michele","paul","esther","higo","desiree","jose.rodrigues","lucas","genilson.silva","gustavo.honorato","lucas.santos","mauricio.junior","abdalla.lopes","aduilson.santos","andre.peixoto","denis.souza"] },
    { route: "/relatorios", icon : "far fa-file-alt", text: "Relatórios", activeProp: "relatorios", allowedIds: ["michele","higo","desiree","esther","jose.rodrigues","lucas","graziela.simioni","genilson.silva","gustavo.honorato","lucas.santos","mauricio.junior","abdalla.lopes","aduilson.santos","andre.peixoto","paul","denis.souza"] },
    { route: "/yara", icon: "far fa-file-alt", text: "Yara", activeProp: "Yara", allowedIds: [] },
    { route: "/integracao", icon: "far fa-file-alt", text: "Integração", activeProp: "Integracao", allowedIds: ["abdalla.lopes","mauricio.junior"] },
    { route: "/fertigran", icon: "far fa-file-alt", text: "Fertigran", activeProp: "Fertigran", allowedIds: [] },
    { route: "/terrena", icon: "far fa-file-alt", text: "Terrena", activeProp: "Terrena", allowedIds: [] },
    { route: "/administrador", icon: "fa fa-wrench", text: "Administrador", activeProp: "Administrador", allowedIds: ["michele","genilson.silva","gustavo.honorato","jose.rodrigues","lucas","abdalla.lopes"] },
    { route: "/cibrafertil", icon: "far fa-file-alt", text: "Cibrafertil", activeProp: "Cibra", allowedIds: [] },
    { route: "/adufertil", icon: "far fa-file-alt", text: "Adufertil", activeProp: "Adufertil", allowedIds: [] },
    { route: "/iterum", icon: "far fa-file-alt", text: "Iterum", activeProp: "Iterum", allowedIds: [] },
    { route: "/araguaia", icon: "far fa-file-alt", text: "Araguaia", activeProp: "Araguaia", allowedIds: [] },
    { route: "/suporte", icon: "fas fa-sitemap icon", text: "Suporte", activeProp: "suporte", allowedIds: [] },
  ];

  const filteredMenuItems = menuItems.filter(item => user && item.allowedIds.includes(user.id));

  const handleNavigation = (route) => {
    setIsActive(false); // Fecha o menu ao selecionar um item
    navigate(route);
  };

  const toggleMenu = () => {
    setIsActive(!isActive); // Toggle the menu visibility
  };

  const handleMenuHover = () => {
    setIsActive(true); // Expandir o menu quando o cursor estiver sobre ele
  };

  const handleMenuLeave = () => {
    setIsActive(false); // Recolher o menu quando o cursor sair dele
  };

return (
    <div id="menu" onMouseEnter={handleMenuHover} onMouseLeave={handleMenuLeave}>
      <div className={`menu2`}>
        <nav className={`menu-horizontal ${isActive ? 'active' : ''} ${isActive ? '' : 'menu-hidden'} ${window.innerWidth <= 768 ? 'mobile' : ''}`}>
          <ul className="itens-menu">
            <li onClick={toggleMenu}>
              <div className="nav_bar">
                <i className="fas fa-bars"></i>
              </div>
            </li>
            
            {filteredMenuItems.map((item, index) => (
              <li 
                key={item.route} 
                onClick={() => handleNavigation(item.route)} 
                className={`${location.pathname === item.route ? 'ativo' : ''} ${index === 0 ? 'first-tab' : ''}`}
              >
                <div className="nav_bar">
                  <i className={item.icon}></i>
                  <span className="menu_text">{item.text}</span>
                </div>
              </li>
            ))}
            <li onClick={logout}>
              <div className="nav_bar">
                <i className="fas fa-sign-out-alt"></i>
                <span className="menu_text">SAIR</span>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}

export default Navbar;