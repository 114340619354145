// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".GerarNfe_btn__2AWSL{\n\twidth: auto;\n\tbackground: #2793ae;\n    font-size: 16px; \n\tcolor: white;\n\tfont-family: 'Titillium Web', sans-serif;\n\tborder: 0 none;\n\tborder-radius: 1px;\n\tcursor: pointer;\n\ttransition: 0.3s;\n\tmargin: auto;\n}\n.GerarNfe_btn__2AWSL:hover{\n\tbackground: #2793ae;\n\tbox-shadow: \n\t0 0 0 3px #ffffffd8,\n\t 0 0 0 4px #27AE60;\n}\n", "",{"version":3,"sources":["webpack://./src/components/GerarNfe/GerarNfe.module.css"],"names":[],"mappings":"AAAA;CACC,WAAW;CACX,mBAAmB;IAChB,eAAe;CAClB,YAAY;CACZ,wCAAwC;CACxC,cAAc;CACd,kBAAkB;CAClB,eAAe;CACf,gBAAgB;CAChB,YAAY;AACb;AACA;CACC,mBAAmB;CACnB;;mBAEkB;AACnB","sourcesContent":[".btn{\n\twidth: auto;\n\tbackground: #2793ae;\n    font-size: 16px; \n\tcolor: white;\n\tfont-family: 'Titillium Web', sans-serif;\n\tborder: 0 none;\n\tborder-radius: 1px;\n\tcursor: pointer;\n\ttransition: 0.3s;\n\tmargin: auto;\n}\n.btn:hover{\n\tbackground: #2793ae;\n\tbox-shadow: \n\t0 0 0 3px #ffffffd8,\n\t 0 0 0 4px #27AE60;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn": "GerarNfe_btn__2AWSL"
};
export default ___CSS_LOADER_EXPORT___;
