import React from 'react';
import { useNavigate } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import Navbar from '../../../components/Navbar';
import Background from '../../../components/Background';
import Container from '../../../components/Container';
import Header from '../../../components/Header';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import style from './CestoDeResgate.module.css';

const CestoDeResgate = () => {
  const pdfUrl = 'https://hml.operacao.orion-op.com.br/api/pdf/cesto-de-resgate'; // URL da rota do backend
  const navigate = useNavigate();
  return (
    <>
      <Navbar motivados />
      <Header />
      <Background />
      <Container>
        <div className={style.content}>
          <div className={style.nav}>
            <div onClick={() => navigate("")}>
              Laudos de Equipamentos &gt;&gt; CESTO DE RESGATE
            </div>
          </div>

          <div className={style.fluxograma}>
            {/* Carregar o PDF usando iframe como alternativa */}
            <iframe
              src={pdfUrl}
              style={{ width: '100%', height: '750px', border: 'none' }}
              title="PDF Viewer"
            ></iframe>
          </div>
        </div>
      </Container>
    </>
  );
};


export default function IntegrationNotistack() {
  return (
    <SnackbarProvider
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      maxSnack={3}
      autoHideDuration={2500}
    >
      <CestoDeResgate />
    </SnackbarProvider>
  );
};
