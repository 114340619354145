// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Header_header__HXQOm{\n    width: 100%;\n    padding: 5px 0;\n    position: absolute;\n    top: 0;\n    z-index: 8;\n    background-color: #001D49;\n    color: #fff;\n    text-align: end;\n    border-bottom: 3px solid #91D1BA;\n}\n\n.Header_header__HXQOm div{\n    margin-right:5vw;\n    font-size: 17px;\n}", "",{"version":3,"sources":["webpack://./src/components/Header/Header.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,cAAc;IACd,kBAAkB;IAClB,MAAM;IACN,UAAU;IACV,yBAAyB;IACzB,WAAW;IACX,eAAe;IACf,gCAAgC;AACpC;;AAEA;IACI,gBAAgB;IAChB,eAAe;AACnB","sourcesContent":[".header{\n    width: 100%;\n    padding: 5px 0;\n    position: absolute;\n    top: 0;\n    z-index: 8;\n    background-color: #001D49;\n    color: #fff;\n    text-align: end;\n    border-bottom: 3px solid #91D1BA;\n}\n\n.header div{\n    margin-right:5vw;\n    font-size: 17px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "Header_header__HXQOm"
};
export default ___CSS_LOADER_EXPORT___;
