import React, { useState, useEffect } from "react";
import Navbar from "../../../components/Navbar";
import Brackground from "../../../components/Background";
import Container from "../../../components/Container";
import Header from "../../../components/Header";
import style from "./DashboardAgendamento.module.css";
import Input from "../../../components/Input";
import SubmitButton from "../../../components/Button";
import Axios from "axios";
import InputMask from "react-input-mask";
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import jsPDF from "jspdf";
import Logo from './logo.png'; // Importe o logo aqui
import Transportadora from './Transportadora.png'; // Importe o logo aqui
import QRCode from 'qrcode';


import { SnackbarProvider, useSnackbar } from "notistack";

const DashboardAgendamento = () => {
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const [isFormVisible, setIsFormVisible] = useState(false); // Novo estado para controle de visibilidade
  const toggleFormVisibility = () => setIsFormVisible(!isFormVisible); // Função para alternar a visibilidade

  const [descricao, setDescricao] = useState(""); // Descrição da tarefa

  const [centroCusto, setCentroCusto] = useState(""); // Código do centro de custo
  const [centroCustos, setCentroCustos] = useState([]); // Código do centro de custo

  const [dataInicio, setDataInicio] = useState(""); // Data de início da tarefa

  const [dataTermino, setDataTermino] = useState(""); // Data de término da tarefa (opcional)

  const [agendamentoLista, setAgendamentoLista] = useState([]);

  const [responsavel, setResponsavel] = useState(""); // Responsável pela tarefa

  const [status, setStatus] = useState("PENDENTE"); // Status inicial da tarefa

  const [autos, setAutos] = useState([]);
  const [documentos, setDocumentos] = useState([]);
  const [hora, setHora] = useState([]);
  const [selectedNavio, setSelectedNavio] = useState("");
  const [selectedRap, setSelectedRap] = useState("");
  const [navios, setNavios] = useState([]);
  const [raps, setRaps] = useState([]);
  const [combustivelLista, setCombustivelLista] = useState([]);
  const [chavedeouroLista, setChaveDeOuroLista] = useState([]);


  const [prioridade, setPrioridade] = useState("BAIXA"); // Prioridade da tarefa

  const [tarefas, setTarefas] = useState([]);

  const [dataInicioFiltro, setDataInicioFiltro] = useState("");
  const [dataTerminoFiltro, setDataTerminoFiltro] = useState("");

  const [isModalDataTerminoVisivel, setIsModalDataTerminoVisivel] = useState(false);
  const [tarefaAtual, setTarefaAtual] = useState(null); // Armazena a tarefa atual para a qual o modal de data de término será aplicado

  const [exibirInputDataTermino, setExibirInputDataTermino] = useState(false);

  const [idTarefaConcluir, setIdTarefaConcluir] = useState(null); // Armazena o ID da tarefa selecionada para conclusão

  // Função para mostrar alertas
  const showAlert = (message, variant) => {
    enqueueSnackbar(message, { variant: variant });
  };

  const onSelectNavio = (e) => {
    const selectedValue = e.target.value;
    setSelectedNavio(selectedValue);
    getRapByOperacao(selectedValue);
  }

  const onSelectRap = (e) => {
    const codOperacao = e.target.value;
    setSelectedRap(codOperacao);
    getDadosCombustivel(codOperacao);
    getDadosChaveDeOuro(codOperacao);
  }

  const getRapByOperacao = (codNavio) => {
    Axios.get(`https://hml.operacao.orion-op.com.br/api/operacao/navio/${codNavio}`) // Ajustado para corresponder ao endpoint do backend
      .then((response) => {
        setRaps(response.data);
      })
      .catch((error) => {
        console.error("Erro ao buscar os RAPs: ", error);
        showAlert("Erro ao buscar os RAPs", "error");
      });
  }

  const getDadosCombustivel = (codOperacao) => {
    Axios.get(`https://hml.operacao.orion-op.com.br/api/combustivel/operacao/${codOperacao}`)
      .then((response) => {
        setCombustivelLista(response.data);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados de combustível: ", error);
        showAlert("Erro ao buscar dados de combustível", "error");
      });
  }

  const getDadosChaveDeOuro = (codOperacao) => {
    Axios.get(`https://hml.operacao.orion-op.com.br/api/chavedeouro/${codOperacao}`)
      .then((response) => {
        setChaveDeOuroLista(response.data);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados da Chave De Ouro: ", error);
        showAlert("Erro ao buscar dados da Chave De Ouro", "error");
      });
  }

  // Ajustando para cadastrar uma nova tarefa
  const cadastrarTarefa = async () => {
    const tarefaData = {
      descricao: descricao,
      codCentroCusto: centroCusto,
      dataInicio: dataInicio,
      responsavel: responsavel,
      status: status,
      prioridade: prioridade,
      usuarioCadastro: JSON.parse(localStorage.getItem("user_token")).id,
    };

    try {
      const response = await Axios.post(
        "https://hml.operacao.orion-op.com.br/api/dashboardsuprimentos/cadastrar",
        tarefaData, // Envio os dados diretamente como JSON
        {
          headers: {
            'Content-Type': 'application/json', // Ajuste importante aqui
          }
        }
      );

      showAlert("Tarefa cadastrada com sucesso!", "success");
      // Aguarde 2 segundos (2000 milissegundos) antes de recarregar a página
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      console.error("Erro ao cadastrar tarefa:", error.response ? error.response.data.message : error.message);
      showAlert("Erro ao cadastrar tarefa: " + (error.response ? error.response.data.message : error.message), "error");
    }
  };

  const exibirModalDataTermino = (tarefaSelecionada) => {
    setIdTarefaConcluir(tarefaSelecionada.ID_TAREFAS_SUPRIMENTOS); // Armazena o ID da tarefa
    setTarefaAtual(tarefaSelecionada);
    setDataTermino(""); // Resetar a data de término ao abrir
  };

  const concluirTarefaComData = async () => {
    if (!tarefaAtual || !dataTermino) {
      showAlert("Selecione uma data válida.", "error");
      return;
    }

    try {
      await Axios.put(`https://hml.operacao.orion-op.com.br/api/tarefas/suprimentos/${tarefaAtual.ID_TAREFAS_SUPRIMENTOS}`, {
        status: 'CONCLUÍDA',
        dataTermino: dataTermino,
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      showAlert('Tarefa concluída com sucesso!', 'success');
      setIdTarefaConcluir(null); // Esconde o input após a tarefa ser concluída

      setExibirInputDataTermino(false); // Esconde o input de data após a atualização
      setDataTermino(''); // Limpa a data selecionada
      fetchTarefas(); // Atualiza a lista de tarefas
    } catch (error) {
      console.error("Erro ao concluir tarefa com data de término", error);
      showAlert('Erro ao concluir a tarefa', 'error');
    }
  };

  useEffect(() => {
    Axios.get("https://hml.operacao.orion-op.com.br/api/centrocusto")
      .then((response) => {
        setCentroCustos(response.data);
      })
      .catch((error) => {
        console.error("Erro ao buscar os centros de custos: ", error);
        showAlert("Erro ao buscar os centros de custos", "error");
      });
  }, []);

  // Função para buscar tarefas
  const fetchTarefas = async () => {
    try {
      const response = await Axios.get("https://hml.operacao.orion-op.com.br/api/tarefas/suprimentos");
      setTarefas(response.data);
    } catch (error) {
      console.error("Erro ao buscar tarefas:", error);
      showAlert("Erro ao buscar tarefas", "error");
    }
  };

  useEffect(() => {
    fetchTarefas();
  }, []);

  useEffect(() => {
    Axios.get("https://hml.operacao.orion-op.com.br/api/agendamento")
      .then((response) => {
        setAgendamentoLista(response.data);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados: ", error);
        showAlert("Erro ao buscar dados", "error");
      });
  }, []);

  const filtrarPorIntervaloDeData = () => {
    return agendamentoLista.filter((agendamento) => {
      // Assume que agendamento.DATA já está no formato 'YYYY-MM-DD' ou o converte adequadamente.
      const dataAgendamento = moment(agendamento.DATA);
      const inicio = dataInicioFiltro ? moment(dataInicioFiltro, 'YYYY-MM-DD') : null;
      const termino = dataTerminoFiltro ? moment(dataTerminoFiltro, 'YYYY-MM-DD').endOf('day') : null; // Inclui todo o dia

      if (inicio && termino) {
        return dataAgendamento.isSameOrAfter(inicio) && dataAgendamento.isSameOrBefore(termino);
      } else if (inicio) {
        return dataAgendamento.isSameOrAfter(inicio);
      } else if (termino) {
        return dataAgendamento.isSameOrBefore(termino);
      }
      return true; // Se nenhuma data for definida, mostra todos os registros
    });
  };

  const generatePDF = (agendamento) => {
    const doc = new jsPDF();

    // Generate QR Code as base64 image
    QRCode.toDataURL(agendamento.NOME_MOTORISTA)
      .then((url) => {
        const logoWidth = 52; // Width of the logo
        const logoHeight = 20; // Height of the logo
        const qrSize = 25; // Size of the QR code
        const yOffset = 148; // Halfway down an A4 page

        const addContent = (offset) => {

          // Add the logo in the upper left corner
          doc.addImage(Transportadora, 'PNG', 10, 10 + offset, logoWidth, logoHeight);

          // Position the QR code next to the logo
          const qrX = 30 + logoWidth + 10; // X position for the QR code (right of the logo with some padding)
          doc.addImage(url, 'PNG', qrX, 10 + offset, qrSize, qrSize); // Adjusted to position QR code next to logo

          // Formatar CPF
          const cpfFormatado = formatarCPF(agendamento.CPF_MOTORISTA);
          const inscricaoEstadualFormatada = formatarInscricaoEstadual(agendamento.INSCRICAO_ESTADUAL);
          const cnpjFormatado = formatarCNPJ(agendamento.CNPJ_TRANSP);
          const placaCavaloFormatada = formatarPlaca(agendamento.PLACA_CAVALO);
          const placaCarretaFormatada = formatarPlaca(agendamento.PLACA_CARRETA);
          const placaCarreta2Formatada = formatarPlaca(agendamento.PLACA_CARRETA2);
          const placaCarreta3Formatada = formatarPlaca(agendamento.PLACA_CARRETA3);

          // Cabeçalho com informações da transportadora à direita
          doc.setFontSize(8);
          const transportadoraInfo = `${agendamento.NOME_REDUZIDO}\n ${agendamento.ENDERECO}\n ${agendamento.CIDADE_ESTADO} - ${agendamento.CEP}\n CNPJ: ${cnpjFormatado} - I.E.: ${inscricaoEstadualFormatada}`;
          let rightOffset = doc.internal.pageSize.width - 10; // Margem direita
          doc.text(transportadoraInfo, rightOffset, 20 + offset, { align: 'right', maxWidth: 190 });

          // Altera a fonte para negrito para o número ANTT
          doc.setFont('helvetica', 'bold');  // Muda a fonte para negrito
          doc.text(`\n ANTT CRNTRC N°: ${agendamento.ANTT}`, rightOffset, 30 + offset, { align: 'right', maxWidth: 190 });  // Supondo uma nova linha para ANTT

          // Adiciona o título centralizado
          doc.setFont('helvetica', 'bold');  // Define a fonte como Helvetica em negrito para o título
          doc.setFontSize(14);
          doc.text("ORDEM DE CARREGAMENTO", 105, 45 + offset, null, null, "center");

          // Define a fonte normal para o resto do texto
          doc.setFont('helvetica', 'normal');
          doc.setFontSize(9);

          // Adiciona informações do motorista com o nome em negrito
          doc.text("Motorista: ", 10, 55 + offset);
          let initialX = doc.getTextWidth("Motorista: "); // Largura do texto "Motorista: "

          // Configura o nome do motorista em negrito
          doc.setFont('helvetica', 'bold');
          doc.text(agendamento.NOME_MOTORISTA, 10 + initialX, 55 + offset); // Adiciona o nome do motorista em negrito

          // Continuação das informações do motorista em estilo normal
          doc.setFont('helvetica', 'normal');
          let additionalInfo = `  - CPF: ${cpfFormatado} - CNH: ${agendamento.CNH_MOTORISTA || "Não Encontrado"}`;
          doc.text(additionalInfo, 10 + initialX + doc.getTextWidth(agendamento.NOME_MOTORISTA), 55 + offset);

          // Adiciona informações das placas
          doc.setFontSize(9);
          let placaInfo = `PLACA CAVALO: ${placaCavaloFormatada} - PLACA CARRETA: ${placaCarretaFormatada} - PLACA CARRETA 2: ${placaCarreta2Formatada} - PLACA CARRETA 3: ${placaCarreta3Formatada}`;
          doc.text(placaInfo, 10, 60 + offset);

          // Adiciona informações das placas
          doc.setFontSize(9);
          placaInfo = `NAVIO: ${agendamento.NOME_NAVIO} - DI: ${agendamento.NUMERO_DOC} - PEDIDO: ${agendamento.PEDIDO_MIC} - DESTINO: ${placaCarreta2Formatada}`;
          doc.text(placaInfo, 10, 65 + offset);

          // Draw horizontal line below NAVIO information
          const lineWidth = doc.internal.pageSize.width - 20; // Line width
          doc.line(10, 70 + offset, lineWidth + 10, 70 + offset); // Position line just below the last text

          // Set font to bold for "CHECK LIST PRÉ CARREGAMENTO"
          doc.setFont('helvetica', 'bold');

          // Add centered bold text below the line
          const checklistY = 75 + offset; // Correctly define the variable in scope
          doc.text("CHECK LIST PRÉ CARREGAMENTO", 105, checklistY, null, null, "center");

          // Set font back to normal if additional text follows
          doc.setFont('helvetica', 'normal');

          // Column setup
          const column1StartX = 20;
          const column2StartX = 105;
          const startY = checklistY + 10;
          const lineSpacing = 5;

          // Column 1 items
          const column1Items = [
            "CNH Dentro da Validade",
            "Documento do Veículo - DUT e ANTT",
            "Extintor de Incêndio",
            "Vedação da Caçamba",
            "Limpeza da Caçamba",
            "Lona em Bom Estado",
            "Pneus e Estepe em Bom Estado"];

          // Column 2 items
          const column2Items = [
            "Traje - Calça, Camisa e Sapato Fechado",
            "Kit de EPI - Capacete, Óculos, Protetor de Ouvido, Máscara",
            "Freios Funcionando",
            "Para-Choque em Bom Estado",
            "Lanternas e Faróis Funcionando",
            "Curso MOPP Dentro da Validade"
          ];

          // Printing column 1 items
          doc.setFontSize(8);
          doc.setFont('helvetica', 'normal');
          let currentY = startY;
          column1Items.forEach(item => {
            doc.text(item, column1StartX, currentY, null, null, 'left');
            currentY += lineSpacing;
          });

          // Printing column 2 items
          currentY = startY;
          column2Items.forEach(item => {
            doc.text(item, column2StartX, currentY, null, null, 'left');
            currentY += lineSpacing;
          });

          // Additional Emergency Kits
          doc.setFont('helvetica', 'normal');
          currentY += 5; // Additional spacing to separate from columns
          const emergencyItems = [
            "Kit de Emergência - Ferramentas, Calços, Cones, Fita Zebrada e Suporte p/ Fita, Placa \"Perigo Afasta-se\"",
            "Kit de Emergência, Placas c/ o Número da ONU e Rótulos de Risco - Exclusivo p/ PRODUTO PERIGOSO."
          ];
          emergencyItems.forEach(item => {
            doc.text(item, 20, currentY, null, null, 'left');
            currentY += lineSpacing; // Slightly larger spacing for readability
          });

          // Confirmation text
          doc.setFont('helvetica', 'bold');
          doc.setFontSize(8);
          const confirmationText = "O Motorista confirma que todas as informações no check list são verdadeiras.";
          doc.text(confirmationText, 80, currentY + 5, null, null, 'left');

          // Date text
          doc.setFontSize(9);
          doc.setFont('helvetica', 'normal');
          const date = new Date();
          const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
          const dateString = `SANTOS, ${date.toLocaleDateString('pt-BR', dateOptions)}.`;
          doc.text(dateString, 142, currentY + 13, null, null, 'left');

          // Adiciona o logotipo no canto inferior esquerdo
          const pageHeight = doc.internal.pageSize.height;
          doc.addImage(Transportadora, 'PNG', 10, 10, 52, 20); // Adjusts the logo to appear in the upper left corner

          // Place the logo just below the "SANTOS, DATAHOJE()"
          // Assuming you know the y-position after adding all your text
          const lastTextY = 110 + offset; // You'll adjust this manually to fit your document's layout
          const dateTextY = lastTextY + 13;
          doc.addImage(Logo, 'PNG', 10, dateTextY + 10, 10, 10);


          if (offset === 148) {  // Only draw the cut line for the second copy
            doc.setDrawColor(0);
            doc.setLineWidth(0.3);
            doc.setLineDash([2, 2], 0);
            doc.line(10, offset - 2, 200, offset - 2); // Draw dashed line at the beginning of the second section
          }

        }

        // First half of the page
        addContent(0);

        // Second half of the page
        addContent(148);

        // Salva o PDF
        doc.save(`Ordem-de-Carregamento-${agendamento.NOME_MOTORISTA}.pdf`);
      })
      .catch(err => {
        console.error('Error generating QR Code: ', err);
      });
  };


  const handleDownload = (cupom) => {
    // Update the URL to match your backend's address and endpoint
    const url = `https://hml.operacao.orion-op.com.br/api/suprimentos/combustivel/download/${cupom}`;

    fetch(url)
      .then((response) => {
        if (response.ok) return response.blob();
        throw new Error('Failed to download file.');
      })
      .then((blob) => {
        // Create a URL for the blob
        const downloadUrl = window.URL.createObjectURL(blob);
        // Create an anchor (`<a>`) element to initiate the download
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', `${cupom}.jpg`); // This sets the filename for the downloaded file
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(downloadUrl); // Clean up by revoking the blob URL
      })
      .catch((error) => {
        console.error('Download error:', error.message);
        // Optionally, show an error message to the user
      });
  };

  const formatarCPF = (cpf) => {
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  };

  const formatarPlaca = (placa) => {
    if (!placa) return "";
    // Insere um hífen após o terceiro caractere
    return placa.replace(/^(\w{3})(\w{4})$/, "$1-$2");
  };

  function formatarInscricaoEstadual(ie) {
    // Exemplo de padrão: 123.456.789.012
    return ie.replace(/(\d{3})(\d{3})(\d{3})(\d{3})/, '$1.$2.$3.$4');
  }

  const formatarCNPJ = (cnpj) => {
    return cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
  };

  return (
    <>
      <Navbar veiculos />
      <Header />
      <Brackground />
      <Container>
        <div className={style.content}>

          <div className={style.nav}>
            <div className={style.active}>
              Dashboard
            </div>

            <div className={style.navItem} onClick={() => navigate("/Agendamento/BuscarMotoristaAgendamento")} >
              Motorista
            </div>

            <div className={style.navItem} onClick={() => navigate('/Agendamento/CadastroMotoristaAgendamento')}>
              Cadastrar Motorista
            </div>
          </div>


          <div className={style.columns}>
            <select
              className={style.navioDropdown}
              value={selectedNavio}
              onChange={onSelectNavio}
            >
              <option value="">Selecione uma Transportadora</option>
              {navios.map((val, key) => (
                <option key={key} value={val.COD_NAVIO}>{val.NOME_NAVIO || "-"}</option>
              ))}
            </select>

            <select
              className={style.navioDropdown}
              value={selectedNavio}
              onChange={onSelectNavio}
            >
              <option value="">Selecione um Navio</option>
              {navios.map((val, key) => (
                <option key={key} value={val.COD_NAVIO}>{val.NOME_NAVIO || "-"}</option>
              ))}
            </select>

            <select
              className={style.navioDropdown}
              value={selectedRap}
              onChange={onSelectRap}
            >
              <option value="">Selecione o RAP</option>
              {raps.map((rap, key) => (
                <option key={key} value={rap.COD_OPERACAO}>{rap.RAP}</option>
              ))}
            </select>

          </div>

          <section className={style.cards}>
            <div className={style.card}>
              <i className="fa fa-truck icon"></i>
              <p><b>Aguardando Aprovação</b></p>
              {autos.map(val => (
                <span className={style.total}>{val.QTDE_AUTOS} Autos</span>
              ))}
            </div>

            <div className={style.card}>
              <i className="fa fa-battery-three-quarters icon"></i>
              <p>Peso Líquido - HÍBRIDO</p>
              {autos.map(val => (
                <span className={style.total}>
                  {(val.PESO_HIBRIDO / 1000).toLocaleString('pt-BR', { minimumFractionDigits: 3, maximumFractionDigits: 3 })} Tons
                </span>
              ))}
            </div>

            <div className={style.card}>
              <i className="fa fa-file-text icon"></i>
              <p><b>Total de Peso por DI / BL - 2⁰ Pesagem</b></p>
              {documentos.map(val => (
                <div className={style.details}>
                  <span>{val.DOC_CARGA}</span> | {(val.PESO_LIQUIDO_CARGA / 1000).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} Tons | {val.QTDE_AUTOS_CARGA} Autos
                </div>
              ))}
            </div>

            <div className={style.card}>
              <i className="fa fa-clock-o icon"></i>
              <p><b>Hora Hora - 2⁰ Pesagem</b></p>
              {hora.map(val => (
                <div className={style.details}>
                  <b>{val.HORA}</b> | {val.QUANTIDADE_AUTOS} Autos
                </div>
              ))}
            </div>

          </section>

          <table className={style.table}>
            <thead>
              <tr>
                <th>ID</th>
                <th>MOTORISTA</th>
                <th>PESAGEM</th>
                <th>NAVIO</th>
                <th>PLACA CAVALO</th>
                <th>DI</th>
                <th>PEDIDO MIC</th>
                <th>MINUTA</th>
              </tr>
            </thead>
            <tbody>
              {filtrarPorIntervaloDeData().map((agendamento, index) => (
                <tr key={index}>
                  <td>{agendamento.ID_AGENDAMENTO}</td>
                  <td>{agendamento.NOME_MOTORISTA}</td>
                  <td>{agendamento.ID_AGENDAMENTO}</td>
                  <td>{agendamento.NOME_NAVIO}</td>
                  <td>{agendamento.PLACA_CAVALO}</td>
                  <td>{agendamento.REFERENCIA}</td>
                  <td>{agendamento.PEDIDO_MIC}</td>
                  <td>
                    <i
                      onClick={() => generatePDF(agendamento)}
                      style={{ color: "blue", cursor: "pointer" }}
                      className="fa fa-file-pdf-o"
                      aria-hidden="true"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

        </div>

      </Container>
    </>
  );
};

export default function IntegrationNotistack() {
  return (
    <SnackbarProvider
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      maxSnack={3}
      autoHideDuration={2500}
    >
      <DashboardAgendamento />
    </SnackbarProvider>
  );
}
