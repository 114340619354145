import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { SnackbarProvider, useSnackbar } from 'notistack';
import Navbar from '../../../components/Navbar';
import Brackground from '../../../components/Background';
import Container from '../../../components/Container';
import Header from '../../../components/Header';
import SubmitButton from '../../../components/Button';
import Input from '../../../components/Input';
import MaskedInput from '../../../components/InputMask';
import style from './BuscarMotorista.module.css';
import Modal from './Modal.module.css';


const BuscarMotorista = () => {
  const navigate = useNavigate();
  const [busca, setBusca] = useState('');
  const [motorista, setMotorista] = useState({});
  const { enqueueSnackbar } = useSnackbar();

  // Estados para o modal
  const [showModal, setShowModal] = useState(false);
  const [carregamentoAberto, setCarregamentoAberto] = useState(null);
  const [historico, setHistorico] = useState([]);

  const getMotorista = () => {
    Axios.get(`https://hml.operacao.orion-op.com.br/api/motorista/busca/${busca}`)
      .then((res) => {
        if (res.data.length > 0) {
          setMotorista(res.data[0]);
          showAlert('Dados do motorista', 'success');
          buscarHistorico(res.data[0].CPF_MOTORISTA);
        } else {
          showAlert('Motorista não cadastrado', 'error');
        }
      });
  };

  const showAlert = (txt, variant) => {
    enqueueSnackbar(txt, { variant });
  };

  const validaTecla = (e) => {
    if (e.key === 'Enter') {
      validaDados();
    }
  };

  const validaDados = () => {
    if (!busca) {
      showAlert('Digite um CPF válido!', 'error');
    } else {
      getMotorista();
    }
  };

  const validaPesagem = () => {
    if (!motorista.CPF_MOTORISTA) {
      showAlert('Digite um CPF válido!', 'error');
    } else {
      // Realize uma consulta ao backend para verificar o carregamento em aberto
      Axios.get(`https://hml.operacao.orion-op.com.br/api/motorista/valida/${motorista.CPF_MOTORISTA}`)
        .then((res) => {
          if (res.data.length > 0) {
            setCarregamentoAberto(res.data[0]);
            setShowModal(true);
          } else {
            navigate(`/veiculos/PesagemInicial/${motorista.NOME_MOTORISTA}/${motorista.CPF_MOTORISTA}/${motorista.CNH_MOTORISTA}/${motorista.COD_MOTORISTA}`);
          }
        });
    }
  };

  const obterUltimoCarregamento = async (cpf) => {
    try {
      const response = await Axios.get(`https://hml.operacao.orion-op.com.br/api/motorista/ultimoCarregamento/${cpf}`);
      const idCarregamento = response.data.ultimoCarregamento; // Certifique-se de que o ID seja acessado corretamente
      console.log("ID Carregamento Obtido:", idCarregamento); // Verifique se o ID é válido
      return idCarregamento;
    } catch (error) {
      console.error("Erro ao obter o último carregamento:", error);
      throw error;
    }
  };

  // Função para copiar o texto do arquivo com base no ID do carregamento
  const copiarTextoArquivo = async (idCarregamento) => {
    try {
      // Converte o ID do carregamento para uma string
      const idCarregamentoStr = idCarregamento.toString();

      // Faz a solicitação para obter o texto do arquivo com base no ID
      const response = await Axios.get(`https://hml.operacao.orion-op.com.br/api/motorista/lerArquivo/${idCarregamentoStr}`);

      // Verifica se o texto foi obtido com sucesso
      if (response.data) {
        // Cria um elemento de texto temporário para copiar o texto
        const tempTextArea = document.createElement('textarea');
        tempTextArea.value = response.data;

        // Adiciona o elemento à página (ele não precisa ser visível)
        document.body.appendChild(tempTextArea);

        // Seleciona o texto no elemento de texto
        tempTextArea.select();

        // Copia o texto para a área de transferência
        document.execCommand('copy');

        // Remove o elemento de texto temporário
        document.body.removeChild(tempTextArea);

        showAlert('Texto copiado!', 'success');
      } else {
        showAlert('Nenhum texto encontrado para o carregamento.', 'error');
      }
    } catch (error) {
      console.error("Erro ao copiar texto do carregamento:", error);
      showAlert('Motivação nao registrada', 'error');
    }
  };

  const copiarTextoDoUltimoCarregamento = async () => {
    try {
      const idCarregamento = await obterUltimoCarregamento(busca); // 'busca' contém o CPF
      await copiarTextoArquivo(idCarregamento);
    } catch (error) {
      console.error("Erro ao copiar texto do último carregamento:", error);
      showAlert('Erro ao copiar texto do último carregamento', 'error');
    }
  };

  const confirmarPesagem = () => {
    setShowModal(false);
    navigate(`/veiculos/PesagemInicial/${motorista.NOME_MOTORISTA}/${motorista.CPF_MOTORISTA}/${motorista.CNH_MOTORISTA}/${motorista.COD_MOTORISTA}`);
  };

  const cancelarPesagem = () => {
    setShowModal(false);
  };

  const buscarHistorico = (cpfMotorista) => {
    Axios.get(`https://hml.operacao.orion-op.com.br/api/motorista/historico/${cpfMotorista}`)
      .then((res) => {
        setHistorico(res.data);
      })
      .catch((error) => {
        console.error("Erro ao buscar histórico:", error);
      });
  };

  useEffect(() => {
    // Implemente a lógica do intervalo aqui, caso necessário
  }, []);

  const formatarNome = (nome) => {
    const nomes = nome.split(' ');
    if (nomes.length > 1) {
      const primeiroNome = nomes[0];
      const ultimoNome = nomes[nomes.length - 1];
      return `${primeiroNome} ${ultimoNome}`;
    }
    return nome;
  };  

  return (
    <>
      <Navbar veiculos />
      <Header />
      <Brackground />
      <Container>
        <div className={style.content}>
          <div className={style.nav}>
            <div className={style.active}>
              Motorista
            </div>
            <div onClick={() => navigate('/veiculos')}>
              Cadastrar Motorista
            </div>
            <div onClick={() => navigate('/veiculos/MotivacaoMotorista')}>
              Motivação Motorista
            </div>
            {/* <div onClick={() => navigate('/veiculos/BuscarMotoristaPlaca')}>
              Motorista Placa
            </div> */}
          </div>

          <div className="columns">
            <div className={style.column2}>
              <div className={style.flexContainer}>
                <div className={style.periodo}>
                  <MaskedInput
                    text="Buscar CPF"
                    mask="999.999.999-99"
                    placeholder="000.000.000-00"
                    onChange={(e) => setBusca(e.target.value)}
                    onKeyPress={validaTecla}
                  />
                </div>
                <SubmitButton text="Buscar" onClick={validaDados} />
              </div>
            </div>


            <div className="column">
            </div>

            <div className="column is-5">
              {motorista.CPF_MOTORISTA && (
                <div className={style.box}>
                  <div className="card">
                    <div className="card-content">
                      <div className="content">
                        <div>
                          <strong className={style.name}>Motorista:</strong> {motorista.NOME_MOTORISTA}
                        </div>
                        <div>
                          <strong className={style.name}>CPF:</strong> {motorista.CPF_MOTORISTA}
                        </div>
                        <div>
                          <strong className={style.name}>CNH:</strong> {motorista.CNH_MOTORISTA}
                        </div>
                        <div className={style.submit2}>
                          <SubmitButton text="Pesar" onClick={validaPesagem} onKeyPress={validaTecla} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* Tabela de Histórico */}
          {historico.length > 0 && (
            <div className={style.linha}>
              <div className={style.motivacaoContainer}>
                <div className={style.historico}>Histórico do Motorista:</div>
                <div className={style.motivacaoButton}>
                  <SubmitButton text="Motivação" onClick={() => copiarTextoDoUltimoCarregamento()} />
                </div>
              </div>

              <table className={style.table}>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Nome do Motorista</th>
                    <th>Navio</th>
                    <th>Placa Cavalo</th>
                    <th>Placa Carreta</th>
                    <th>Peso MOEGA</th>
                    <th>Data de Cadastro</th>
                  </tr>
                </thead>
                <tbody>
                  {historico.map((registro) => (
                    <tr key={registro.ID_CARREGAMENTO}>
                      <td data-label="ID">{registro.ID_CARREGAMENTO}</td>
                      <td data-label="Motorista">{formatarNome(registro.NOME_MOTORISTA)}</td>
                      <td data-label="Navio">{registro.NOME_NAVIO}</td>
                      <td data-label="Cavalo">{registro.PLACA_CAVALO}</td>
                      <td data-label="Carreta">{registro.PLACA_CARRETA}</td>
                      <td data-label="2° Peso">{registro.PESO_CARREGADO.toLocaleString('pt-BR')} KG</td>
                      <td data-label="Cadastro">
                        {new Date(registro.DATA_CADASTRO).toLocaleDateString('pt-BR', {
                          year: 'numeric',
                          month: 'numeric',
                          day: 'numeric',
                        })}{' '}
                        -{' '}
                        {new Date(registro.DATA_CADASTRO).toLocaleTimeString('pt-BR', {
                          hour: 'numeric',
                          minute: 'numeric',
                        })}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

        </div>
      </Container>

      {/* Modal de confirmação */}
      <div className={`${Modal.modal} ${showModal ? Modal.show : ''}`}>
        <div className={Modal.dialog}>
          <div className={Modal.content}>
            <div className={Modal.header}>
              <h5 className={Modal.title}><b>Confirmação de Pesagem </b></h5>

            </div>
            <div className={Modal.body}>
              {carregamentoAberto && (
                <>
                  <p>
                    Existe um carregamento aguardando a 2° Pesagem no navio <b>"{carregamentoAberto.NOME_NAVIO}"</b> com o motorista <b>"{carregamentoAberto.NOME_MOTORISTA}"</b>.
                    <br></br><br></br>Deseja prosseguir com a pesagem?
                  </p>
                </>
              )}
            </div>
            <div className={Modal.footer}>
              <button className={Modal.buttoncancelar} onClick={cancelarPesagem}>
                CANCELAR
              </button>
              <button className={Modal.buttonpesar} onClick={confirmarPesagem}>
                PESAR
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default function IntegrationNotistack() {
  return (
    <SnackbarProvider
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      maxSnack={3}
      autoHideDuration={2500}
    >
      <BuscarMotorista />
    </SnackbarProvider>
  );
}
