import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Axios from "axios";
import { SnackbarProvider, useSnackbar } from 'notistack';
import Navbar from "../../../components/Navbar";
import Brackground from "../../../components/Background";
import Container from "../../../components/Container";
import Header from "../../../components/Header";
import style from "./AgendamentoPesagem.module.css";
import SubmitButton from "../../../components/Button";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import modal from "./Modal.module.css";

const AgendamentoPesagem = () => {
  const navigate = useNavigate();

  useEffect(() => {
    getTipoveiculo()
    getOperacoes()
    getTransp()
    fetchPlacas(cpf); // Fetch PLACA_CAVALO based on the CPF
  }, [])

  const fetchPlacas = async (cpf) => {
    try {
      const response = await Axios.get(`https://hml.operacao.orion-op.com.br/api/pesageminicial/historico/${cpf}`);
      if (response.data.length > 0) {
        const { PLACA_CAVALO, PLACA_CARRETA, PLACA_CARRETA2, PLACA_CARRETA3, TIPO_VEICULO } = response.data[0]; // Destructure the response
        setPlacacavalo(PLACA_CAVALO);
        setPlaca1(PLACA_CARRETA || ''); // Set or default to empty string
        setPlaca2(PLACA_CARRETA2 || '');
        setPlaca3(PLACA_CARRETA3 || '');
        setTipoveiculo(TIPO_VEICULO || '');
      }
    } catch (error) {
      console.error('Error fetching vehicle plate data:', error);
    }
  };

  const [operacoesList, setOperacoesList] = useState([]);
  const [pedidosByDoc, setPedidosByDoc] = useState([]);
  const [docs, setDocs] = useState([]);
  const [pedidoMic, setPedidoMic] = useState([]);
  const [destino, setDestino] = useState('');
  const [doc, setDoc] = useState('');
  const [navio, setNavio] = useState('');
  const [placacavalo, setPlacacavalo] = useState('');
  const [placa1, setPlaca1] = useState('');
  const [placa2, setPlaca2] = useState(null);
  const [placa3, setPlaca3] = useState(null);
  const [data, setData] = useState('');
  const [transportadora, setTransportadora] = useState([])
  const [transportadoras, setTransportadoras] = useState([]);
  const [tipopesagem, setTipopesagem] = useState('M'); // Defina 'C' como valor inicial
  const [tipoveiculo, setTipoveiculo] = useState('');
  const [tipoveiculos, setTipoveiculos] = useState([])
  const [disabled, setDisabled] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [checked, setChecked] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };
 
  const handleLinkClick = (e) => {
    e.preventDefault(); // Impede o comportamento padrão do link
    handleOpenModal(); // Sempre abre o modal independentemente do estado do checkbox
  };
  

  const usuario = JSON.parse(localStorage.getItem("user_token")).id;
  const userTransportadora = usuario.split('.')[1]; // This extracts the part after the dot

  const validaPlaca = (placa) => {
    // Expressão regular para validar placas no formato antigo (AAA1234) ou Mercosul (AAA1B23)
    const placaRegex = /^[A-Z]{3}\d{4}$|^[A-Z]{3}\d{1}[A-Z]{1}\d{2}$|^[A-Z]{3}\d{2}[A-Z]{1}\d{1}$/;

    return placaRegex.test(placa);
  };

  const formatarCPF = (cpf) => {
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  }

  const validaDados = async () => {
    if (!checked) {
      showAlert('Por favor, aceite os termos antes de prosseguir com o agendamento.', 'warning');
      return;
    }

    if (
      !doc ||
      !tipoveiculo || // Verifique se o campo "Modelo do AUTO" foi preenchido
      !placacavalo ||
      !transportadora ||
      !tipopesagem
    ) {
      showAlert('Preencha todos os campos.', 'error'); // Mostra um alerta de erro
      return;
    }

    if (!validaPlaca(placacavalo)) {
      showAlert('Placa do cavalo deve estar no formato correto (Ex: AAA1234 ou AAA1B23)', 'error');
      return;
    }

    if (placa1 && !validaPlaca(placa1)) {
      showAlert('Placa da carreta 1 deve estar no formato correto (Ex: AAA1234 ou AAA1B23)', 'error');
      return;
    }

    if (placa2 && !validaPlaca(placa2)) {
      showAlert('Placa da carreta 2 deve estar no formato correto (Ex: AAA1234 ou AAA1B23)', 'error');
      return;
    }

    if (placa3 && !validaPlaca(placa3)) {
      showAlert('Placa da carreta 3 deve estar no formato correto (Ex: AAA1234 ou AAA1B23)', 'error');
      return;
    }

    else {
      setData(getDate());
      // Primeiro, execute a função AddAgendamentoPesagem
      AddAgendamentoPesagem();
    }

  };

  const validaTecla = (e) => {
    if (e.key === 'Enter') {
      validaDados()
    }
  }

  const validaTipo = (val) => {
    if (val === "C") {
      showAlert('Pesagem completa selecionada com sucesso!', 'success');
      setDisabled(false);
    } else {
      showAlert('Pesagem moega selecionada com sucesso!', 'success');
      setDisabled(true);
    }
  }

  const AddAgendamentoPesagem = () => {

    Axios.post('https://hml.operacao.orion-op.com.br/api/Agendamento/AddAgendamento', {

      COD_MOTORISTA: id,
      TIPO_PESAGEM: tipopesagem,
      COD_OPERACAO: navio,
      TIPO_VEICULO: tipoveiculo,
      PLACA_CAVALO: placacavalo,
      PLACA_CARRETA: placa1,
      PLACA_CARRETA2: placa2,
      PLACA_CARRETA3: placa3,
      COD_CARGA: doc,
      PEDIDO_MIC: pedidoMic,
      COD_TRANSP: transportadora,
      STATUS_CARREG: '5',
      USUARIO_AGENDAMENTO: usuario,
      DATA_AGENDAMENTO: data || getDate(),

    }).then(function (res) {
      if (res.data.success) {
        const idAgendamento = res.data.ID_AGENDAMENTO;
        showAlert(`Agendamento cadastrado com sucesso! ID: ${idAgendamento}`, 'success');
        setTimeout(() => {
          navigate('/Agendamento/BuscarMotoristaAgendamento')
        }, 2500);
      } else if (res.data.sqlMessage) {
        showAlert(res.data.sqlMessage, 'error');
      }
    }).catch(function (error) {
      console.error('Erro ao cadastrar o agendamento:', error);
      showAlert('Ocorreu um erro ao cadastrar o agendamento.', 'error');
    });
  }

  const getDate = () => {
    const date = new Date();
    date.setHours(date.getHours() - 3); // Adjust for timezone if needed
    return date.toISOString().slice(0, 19).replace('T', ' ');
  }


  const getOperacoes = () => {
    Axios.get('https://hml.operacao.orion-op.com.br/api/operacao')
      .then((response) => {
        setOperacoesList(response.data)
        console.log(response.data);
        getCargas()
      });
  }

  const getDestino = (idPedido) => {
    Axios.get(`https://hml.operacao.orion-op.com.br/api/destino/${idPedido}`)
      .then((res) => {
        // A resposta direta contém o nome do destino
        const destinoInfo = res.data;
        setDestino(destinoInfo.NOME_DESTINO); // Atualiza o estado do destino com o nome obtido
      })
      .catch((error) => {
        console.error('Erro ao buscar informações do destino:', error);
        showAlert('Erro ao carregar informações do destino.', 'error');
      });
  };

  const getCargas = (id) => {
    Axios.get(`https://hml.operacao.orion-op.com.br/api/carga/busca/${id}`,)
      .then(function (res) {
        setDocs(res.data);
        console.log(res.data);
        getPedido(id)
      });
  }

  const getTransp = () => {
    Axios.get(`https://hml.operacao.orion-op.com.br/api/transportadora`)
      .then(function (res) {
        const allTransportadoras = res.data;
        // Filter based on user's transportadora
        let filtered = allTransportadoras.filter((val) =>
          val.NOME_TRANSP.toLowerCase().includes(userTransportadora.toLowerCase())
        );
        // If no transportadoras are matched, show all
        if (filtered.length === 0) {
          filtered = allTransportadoras;
        }
        setTransportadoras(filtered);
      })
      .catch(function (error) {
        console.error('Erro ao buscar transportadoras:', error);
        showAlert('Erro ao carregar transportadoras.', 'error');
      });
  };

  const getTipoveiculo = () => {
    Axios.get(`https://hml.operacao.orion-op.com.br/api/tipoveiculo`,)
      .then(function (res) {
        setTipoveiculos(res.data);
        console.log(res.data);
      });
  }

  const getPedido = (id) => {
    Axios.get(`https://hml.operacao.orion-op.com.br/api/pesageminicial/pedidos/${id}`)
      .then(function (res) {
        setPedidosByDoc(res.data);
        console.log(res.data);
      });
  }

  const handlePedidoChange = (e) => {
    const selectedPedidoMic = e.target.value;
    setPedidoMic(selectedPedidoMic);
    // Chama getDestino e getTransportadora com o ID do pedido selecionado
    getDestino(selectedPedidoMic);
  };

  const { enqueueSnackbar } = useSnackbar();
  const showAlert = (txt, variant) => {
    enqueueSnackbar(txt, { variant: variant });
  }

  let { nome } = useParams();
  let { cpf } = useParams();
  let { cnh } = useParams();
  let { id } = useParams();

  return (
    <>
      <Navbar veiculos />
      <Header />
      <Brackground />
      <Container>
        <div className={style.content}>
          <div className={style.nav}>
            <div className={style.nav}>
              <div className={style.navItem} onClick={() => navigate("/Agendamento/DashboardAgendamento")} >
                Dashboard
              </div>
              <div className={style.navItem} onClick={() => navigate("/Agendamento/BuscarMotoristaAgendamento")} >
                Motorista
              </div>
              <div className={style.navItem} onClick={() => navigate("/Agendamento/CadastroMotoristaAgendamento")}>
                Cadastrar Motorista
              </div>
              <div className={style.active}>
                Agendamento
              </div>
            </div>
          </div>

          <div className={style.align}>
            <div className="columns">
              <div className="column is-4">
                <div className={style.box}>
                  <div className="card">
                    <div className="card-content">
                      <div className={style.cabecario}>
                        DADOS DO MOTORISTA
                      </div>
                      <div className="content">
                        <div> <strong className={style.name}>Motorista:</strong> {nome}</div>
                        <div><strong className={style.name}>CPF:</strong> {formatarCPF(cpf)}</div>
                        <div><strong className={style.name}>CNH:</strong> {cnh == 'null' ? "Não Registrado" : cnh}</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={style.radio}>
                  <div className="control">
                    <label className="radio">
                      <input
                        type="radio"
                        onChange={(e) => [setTipopesagem(e.target.value), validaTipo(e.target.value)]}
                        value="C"
                        name="tipoPesagem"
                        checked={tipopesagem === 'C'} // Defina checked como true se tipopesagem for igual a 'C'
                      />
                      CHAVE DE OURO
                    </label>
                    <label className="radio">
                      <input
                        type="radio"
                        onChange={(e) => [setTipopesagem(e.target.value), validaTipo(e.target.value)]}
                        value="M"
                        name="tipoPesagem"
                        checked={tipopesagem === 'M'} // Defina checked como true se tipopesagem for igual a 'M'
                      />
                      MOEGA
                    </label>
                  </div>
                </div>

                <div className={style.form_control}>
                  <label>Escolha o navio (VIAGEM)</label>

                  <select onChange={(e) => [getCargas(e.target.value), setNavio(e.target.value)]}>{/*
                    Chama o getCatgas() passando o id o codigo da operacao que vai ser selecinado no select e depois seta o navio que vai ser operado
                    porem na função getCargas, quando é enviado o id, automaticamente seta os documentos que vao ser lidos no outro select 
                  */}
                    <option disabled selected>Escolha uma opção</option>
                    {operacoesList?.map((val) => {
                      if (val.STATUS_OPERACAO == 'FECHADA') {
                        return (
                          null
                        )
                      }
                      return (
                        <option value={val.COD_OPERACAO}>{val.NOME_NAVIO}</option>
                      )
                    })}
                  </select>
                </div>
              </div>
              <div className="column is-3">

                <div className={style.form_control}>
                  <label>Modelo do AUTO</label>
                  <select
                    onChange={(e) => {
                      setTipoveiculo(e.target.value); // Atualize o valor de tipoveiculo
                    }}
                    value={tipoveiculo} // Certifique-se de que o valor seja definido corretamente
                  >
                    <option disabled value="">
                      Escolha uma opção
                    </option>
                    {tipoveiculos?.map((val) => {
                      return (
                        <option key={val.COD_TIPO} value={val.COD_TIPO}>
                          {val.DESC_TIPO_VEICULO}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className={style.form_control_trans}>

                  <div className={style.placas}>
                    <div className={style.placaContainer}>
                      <label htmlFor="placaCavalo">Placa Cavalo</label>
                      <input
                        type="text"
                        id="placaCavalo"
                        placeholder="Ex: AAA1234"
                        value={placacavalo}
                        onChange={(e) => setPlacacavalo(e.target.value.toUpperCase())}
                      />
                    </div>

                    <div className={style.placaContainer}>
                      <label htmlFor="placa1">Placa Carreta 1</label>
                      <input
                        type="text"
                        id="placa1"
                        placeholder="Ex: AAA1234"
                        value={placa1}
                        onChange={(e) => setPlaca1(e.target.value.toUpperCase())}
                      />
                    </div>

                    <div className={style.placaContainer}>
                      <label htmlFor="placa2">Placa Carreta 2</label>
                      <input
                        type="text"
                        id="placa2"
                        placeholder="Ex: AAA1234"
                        value={placa2}
                        onChange={(e) => setPlaca2(e.target.value.toUpperCase())}
                      />
                    </div>

                    <div className={style.placaContainer}>
                      <label htmlFor="placa3">Placa Carreta 3</label>
                      <input
                        type="text"
                        id="placa3"
                        placeholder="Ex: AAA1234"
                        value={placa3}
                        onChange={(e) => setPlaca3(e.target.value.toUpperCase())}
                      />
                    </div>
                  </div>

                </div>


              </div>

              <div className="column is-4">

                <div className={style.form_control}>
                  <label>(DI ou BL)</label>
                  <select onChange={(e) => { setDoc(e.target.value); getPedido(e.target.value); }}>
                    <option disabled selected>Escolha uma opção</option>
                    {docs?.map((val) => {
                      return (
                        <option value={val.COD_CARGA}>{val.TIPO} - {val.NUMERO}</option>
                      )
                    })}
                  </select>

                </div>

                <div className={style.form_controllab}>

                  <div className={style.form_controlsaldo}>
                    <label>Pedido MIC</label>
                    <select onChange={handlePedidoChange}>
                      <option disabled selected>Escolha uma opção</option>
                      {pedidosByDoc?.map((val) => (
                        <option value={val.ID_PEDIDO}>{val.NR_PEDIDO}</option>
                      ))}
                    </select>

                  </div>

                  <div className={style.form_controldoc}>

                    <label>Destino</label>
                    <p>{destino}</p> {/* Exibir o destino */}
                  </div>


                </div>

                <div className={style.form_control_trans}>
                  <label>Transportadora</label>
                  <select onChange={(e) => { setTransportadora(e.target.value) }}>
                    <option disabled selected>Escolha uma opção</option>
                    {transportadoras.map((val) => (
                      <option value={val.COD_TRANSP}>{val.NOME_TRANSP}</option>
                    ))}
                  </select>
                </div>

                <div className={style.agreementSection}>
                  <input
                    type="checkbox"
                    className={style.agreementCheckbox}
                    checked={checked}
                    onChange={handleCheckboxChange}  // Este manipulador apenas muda o estado do checkbox
                    id="agreementCheckbox"
                  />
                  <label className={style.agreementLabel}>
                    Li e valido os termos de
                  </label>
                  <a
                    className={style.agreementLink}
                    href="#"
                    onClick={handleLinkClick} // Alterado para usar o novo manipulador
                  >
                    Check List do Carregamento
                  </a>
                </div>


                <Dialog
                  open={isModalOpen}
                  onClose={handleCloseModal}
                  maxWidth="md"
                  PaperProps={{
                    style: {
                      maxHeight: '80vh',
                      width: '80%',
                    },
                  }}
                >
                  <DialogTitle>{"CHECK LIST PRÉ CARREGAMENTO"}</DialogTitle>
                  <DialogContent>
                    <div className={modal.dialogContentContainer}>
                      <div className={modal.column}>
                        <p className={modal.listItem}><i className={`fas fa-check-circle ${modal.iconValidated}`}></i>CNH Dentro da Validade</p>
                        <p className={modal.listItem}><i className={`fas fa-check-circle ${modal.iconValidated}`}></i>Documento do Veículo - DUT e ANTT</p>
                        <p className={modal.listItem}><i className={`fas fa-check-circle ${modal.iconValidated}`}></i>Extintor de Incêndio</p>
                        <p className={modal.listItem}><i className={`fas fa-check-circle ${modal.iconValidated}`}></i>Vedação da Caçamba</p>
                        <p className={modal.listItem}><i className={`fas fa-check-circle ${modal.iconValidated}`}></i>Limpeza da Caçamba</p>
                        <p className={modal.listItem}><i className={`fas fa-check-circle ${modal.iconValidated}`}></i>Lona em Bom Estado</p>
                        <p className={modal.listItem}><i className={`fas fa-check-circle ${modal.iconValidated}`}></i>Pneus e Estepe em Bom Estado</p>
                      </div>
                      <div className={modal.column}>
                        <p className={modal.listItem}><i className={`fas fa-check-circle ${modal.iconValidated}`}></i>Traje - Calça, Camisa e Sapato Fechado</p>
                        <p className={modal.listItem}><i className={`fas fa-check-circle ${modal.iconValidated}`}></i>Kit de EPI - Capacete, Óculos, Protetor de Ouvido, Máscara</p>
                        <p className={modal.listItem}><i className={`fas fa-check-circle ${modal.iconValidated}`}></i>Freios Funcionando</p>
                        <p className={modal.listItem}><i className={`fas fa-check-circle ${modal.iconValidated}`}></i>Para-Choque em Bom Estado</p>
                        <p className={modal.listItem}><i className={`fas fa-check-circle ${modal.iconValidated}`}></i>Lanternas e Faróis Funcionando</p>
                        <p className={modal.listItem}><i className={`fas fa-check-circle ${modal.iconValidated}`}></i>Curso MOPP Dentro da Validade</p>
                      </div>
                    </div>
                  </DialogContent>

                  <DialogActions>
                    <Button onClick={handleCloseModal}>Fechar</Button>
                  </DialogActions>
                </Dialog>

              </div>
            </div>

          </div>
          <div className={style.button}>
            <SubmitButton text={"Agendar"}
              onClick={validaDados}
              onkeypress={(e) => validaTecla(e)}
            />
          </div>

        </div>
      </Container>
    </>
  );
};

export default function IntegrationNotistack() {
  return (
    <SnackbarProvider
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      maxSnack={3}
      autoHideDuration={2500}>
      <AgendamentoPesagem />
    </SnackbarProvider >
  );
}

