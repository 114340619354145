import React, { useState, useEffect } from "react";
import Navbar from "../../components/Navbar";
import Background from "../../components/Background";
import Container from "../../components/Container";
import Header from "../../components/Header";
import Axios from "axios";
import GraficoPercent from "../../components/GraficoPercent";
import style from "./RelatorioOperacao.module.css";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import Periodo from '@mui/material/Dialog';
import { SnackbarProvider, useSnackbar } from 'notistack';
import SubmitButton from "../../components/Button";
import html2pdf from "html2pdf.js";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';


const RelatorioPeriodo = () => {

    useEffect(() => {
        getEquipamentos();
        getPeriodos();
        getBercos();
        VerificaPeriodo();
        getOp();
        getPeriodo();
        getComplemento();
    }, []);

    const { id } = useParams();
    const [navioslist, setNaviosList] = useState([]);
    const [equipamento, setEquipamento] = useState("");
    const [periodo, setPeriodo] = useState([]);
    const [berco, setBerco] = useState("");
    const [qtbordo, setQtBordo] = useState("");
    const [qtterra, setQtTerra] = useState("");
    const [gerador, setGerador] = useState("");
    const [grab, setGrab] = useState("");
    const [porao, setPorao] = useState("");
    const [requisicao, setRequisicao] = useState("");
    const [conexo, setConexo] = useState("");
    const [inicio, setInicio] = useState("");
    const [existePeriodo, setExistePeriodo] = useState(0);
    const usuario = JSON.parse(localStorage.getItem("user_token")).id;
    const [operacoes, setOperacoes] = useState([]);
    const [complemento, setComplemento] = useState([]);
    const [equipamentos, setEquipamentos] = useState([]);
    const [periodos, setPeriodos] = useState('');
    const [autos, setAutos] = useState([]);
    const [documentos, setDocumentos] = useState([]);
    const [carregamentos, setCarregamentos] = useState([]);
    const [bercos, setBercos] = useState([]);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [buttonText, setButtonText] = useState('Cadastrar');
    const [openModal, setOpenModal] = useState(false);
    const [openProdModal, setOpenProdModal] = useState(false);
    const [openRankingModal, setOpenRankingModal] = useState(false);
    const navigate = useNavigate();

    const getOp = () => {
        Axios.get('https://hml.operacao.orion-op.com.br/api/relatorios/operacoes').then((response) => {
            setNaviosList(response.data);
        });
    };

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };


    const downloadAsPDF = () => {
        const tabela = document.getElementById("prodperiodo");

        const opt = {
            margin: [10, 10, 10, 10],
            filename: 'Produção_por_periodo.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
        };

        html2pdf().from(tabela).set(opt).save();
    };

    const getDate = () => {
        const date = new Date();
        date.setHours(date.getHours() - 3);
        return (date.toISOString().slice(0, 19).replace('T', ' '));
    };

    const getEquipamentos = () => {
        Axios.get('https://hml.operacao.orion-op.com.br/api/equipamentos').then((response) => {
            setEquipamentos(response.data);
        });
    };

    const getPeriodos = () => {
        Axios.get('https://hml.operacao.orion-op.com.br/api/periodos/horarios').then((response) => {
            setPeriodos(response.data);
        });
    };

    const getBercos = () => {
        Axios.get('https://hml.operacao.orion-op.com.br/api/bercos').then((response) => {
            setBercos(response.data);
        });
    };

    const { enqueueSnackbar } = useSnackbar();

    const showAlert = (txt, variant) => {
        enqueueSnackbar(txt, { variant: variant });
    };

    const VerificaOperacao = () => {
        Axios.get();
    };

    const VerificaPeriodo = () => {
        Axios.get(`https://hml.operacao.orion-op.com.br/api/periodo/busca/${id}`).then((res) => {
            setExistePeriodo(res.data[0].EXISTE);
        });
    };

    const getOperacoes = () => {
        getAutos();
        getDocumentos();
        getComplemento();
        getProdperiodo();
        getRanking();
        getCarregamentos();
        getRankingprod();
        Axios.post(`https://hml.operacao.orion-op.com.br/api/operacao/paralisacao/${id}`, {
            data: relatorios
        }).then((response) => {
            setOperacoes(response.data);
        });
    };

    const getAutos = () => {
        Axios.post(`https://hml.operacao.orion-op.com.br/api/operacao/autos/${id}`, {
            data: relatorios
        }).then((response) => {
            setAutos(response.data);
        });
    };

    const getDocumentos = () => {
        Axios.post(`https://hml.operacao.orion-op.com.br/api/operacao/documentos/${id}`, {
            data: relatorios
        }).then((response) => {
            setDocumentos(response.data);
        });
    };

    const getComplemento = () => {
        Axios.post(`https://hml.operacao.orion-op.com.br/api/operacao/complemento/${id}`, {
            data: relatorios
        }).then((response) => {
            setComplemento(response.data);
        });
    };

    const getCarregamentos = () => {
        Axios.post(`https://hml.operacao.orion-op.com.br/api/operacao/carregamentos/${id}`, {
            data: relatorios
        }).then((response) => {
            setCarregamentos(response.data);
        });
    };

    useEffect(() => {
        DadosDashboard();
        getVeiculos();
        VerificaParalisacao();
    }, []);

    const [busca, setBusca] = useState("");
    const [filtroDI, setFiltroDI] = useState("");
    const [filtroPedidoMIC, setFiltroPedidoMIC] = useState("");
    const [filtroTransportadora, setFiltroTransportadora] = useState("");
    const [filtroCliente, setFiltroCliente] = useState("");
    const [filtroPeriodo, setFiltroPeriodo] = useState("");
    const [filtroPorao, setFiltroPorao] = useState("");
    const [existeParalisacao, setExisteParalisacao] = useState(0);
    const [dadosDash, setDadosDash] = useState([]);
    const [veiculos, setVeiculos] = useState([]);
    const [paralisacoes, setParalisacoes] = useState([]);
    const [descarregado, setDescarregado] = useState([]);
    const [relatorios, setRelatorios] = useState([]);
    const [prodperiodo, setProdperiodo] = useState([]);
    const [rankingprod, setRankingprod] = useState([]);
    const [ranking, setRanking] = useState([]);
    const [i, setI] = useState({});
    const [peso2, setPeso2] = useState("");
    const [data, setData] = useState("");
    const [obsPesagem, setObsPesagem] = useState("");
    const [pesobruto, setPesoBruto] = useState("");
    const [databruto, setDataBruto] = useState("");

    const getPeriodo = () => {
        Axios.get(`https://hml.operacao.orion-op.com.br/api/operacao/gerais/${id}`).then((response) => {
            setPeriodo(response.data);
        });
    };

    const getRanking = () => {
        Axios.get(`https://hml.operacao.orion-op.com.br/api/operacao/ranking/${id}`).then((response) => {
            setRanking(response.data);
        });
    };

    const VerificaParalisacao = () => {
        Axios.get(`https://hml.operacao.orion-op.com.br/api/verifica/paralisacao/${id}`).then((res) => {
            setExisteParalisacao(res.data);
        });
    };

    const DadosDashboard = () => {
        Axios.get(`https://hml.operacao.orion-op.com.br/api/periodo/dashboard/${id}`).then((res) => {
            setDadosDash(res.data[0]);
            Axios.get(`https://hml.operacao.orion-op.com.br/api/paralisacao/periodo/${res.data[0].SEQ_PERIODO_OP}`).then((res) => {
                setParalisacoes(res.data);
            });
        });
    };

    const getVeiculos = () => {
        Axios.get(`https://hml.operacao.orion-op.com.br/api/ultimapesagem/busca/${id}`).then((res) => {
            setVeiculos(res.data);
        });
    };

    const getProdperiodo = () => {
        Axios.post(`https://hml.operacao.orion-op.com.br/api/operacao/prodperiodo/${id}`, {
            data: relatorios
        }).then((response) => {
            setProdperiodo(response.data);
        });
    };

    const getRankingprod = () => {
        Axios.post(`https://hml.operacao.orion-op.com.br/api/operacao/rankingprod/${id}`, {
            data: relatorios
        }).then((response) => {
            setRankingprod(response.data);
        });
    };

    const downloadNota = async (idCarregamento) => {
        const data = {
            idCarregamento: idCarregamento
        };

        const config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `https://hml.operacao.orion-op.com.br/api/baixarnota`,
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
            data: data
        };

        Axios.request(config)
            .then((res) => {
                var byteCharacters = atob(res.data.pdf.split(',')[res.data.pdf.split(',').length - 1]);
                var byteNumbers = new Array(byteCharacters.length);
                for (var i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                var byteArray = new Uint8Array(byteNumbers);
                var file = new Blob([byteArray], { type: res.data.pdf.split(',')[0].split(":")[1] });

                const url = URL.createObjectURL(file);
                const link = document.createElement('a');
                link.href = url;
                link.download = res.data.filename;
                link.click();
                URL.revokeObjectURL(url);
            })
            .catch((err) => {
                showAlert('Erro ao obter PDF', 'error');
                console.log(err.response.data.mensagem);
            });
    };

    const getDistinctValues = (array, key) => {
        return [...new Set(array.map(item => item[key]))];
    };

    const distinctDIs = getDistinctValues(carregamentos, "DOCUMENTO");
    const distinctPedidoMICs = getDistinctValues(carregamentos, "PEDIDO_MIC");
    const distinctTransportadoras = getDistinctValues(carregamentos, "NOME_REDUZIDO");
    const distinctPorao = getDistinctValues(carregamentos, "PORAO");
    const distinctCliente = getDistinctValues(carregamentos, "NOME_CLIENTE");
    const distinctPeriodo = getDistinctValues(carregamentos, "PERIODO_CARREGAMENTO");

    const filteredCarregamentos = carregamentos.filter((val) => {
        if (busca === "" && filtroDI === "" && filtroPedidoMIC === "" && filtroTransportadora === "" && filtroPorao === "" && filtroCliente === "" && filtroPeriodo === "") {
            return val;
        } else {
            return (
                (busca === "" ||
                    String(val.ID_CARREGAMENTO).toLowerCase().includes(busca.toLowerCase()) ||
                    val.NOME_MOTORISTA.toLowerCase().includes(busca.toLowerCase()) ||
                    val.PLACA_CAVALO.toLowerCase().includes(busca.toLowerCase()) ||
                    String(val.PESO_TARA).toLowerCase().includes(busca.toLowerCase()) ||
                    String(val.PESO_CARREGADO).toLowerCase().includes(busca.toLowerCase()) ||
                    String(val.PESO_BRUTO).toLowerCase().includes(busca.toLowerCase()) ||
                    String(val.PESO_LIQUIDO).toLowerCase().includes(busca.toLowerCase()) ||
                    String(val.DOCUMENTO).toLowerCase().includes(busca.toLowerCase()) ||
                    String(val.PEDIDO_MIC).toLowerCase().includes(busca.toLowerCase()) ||
                    String(val.DATA_CARREGAMENTO).toLowerCase().includes(busca.toLowerCase()) ||
                    String(val.DIFERENCA).toLowerCase().includes(busca.toLowerCase())) &&
                (filtroDI === "" || val.DOCUMENTO === filtroDI) &&
                (filtroPedidoMIC === "" || val.PEDIDO_MIC === filtroPedidoMIC) &&
                (filtroTransportadora === "" || val.NOME_REDUZIDO === filtroTransportadora) &&
                (filtroPorao === "" || val.PORAO === filtroPorao) &&
                (filtroCliente === "" || val.NOME_CLIENTE === filtroCliente) &&
                (filtroPeriodo === "" || val.PERIODO_CARREGAMENTO === filtroPeriodo)
            );
        }
    });

    // Handlers para abrir e fechar os modais
    const handleOpenProdModal = () => {
        setOpenProdModal(true);
    };
    const handleCloseProdModal = () => {
        setOpenProdModal(false);
    };

    const handleOpenRankingModal = () => {
        setOpenRankingModal(true);
    };
    const handleCloseRankingModal = () => {
        setOpenRankingModal(false);
    };

    const [openParalisacaoModal, setOpenParalisacaoModal] = useState(false);

    // Handlers para abrir e fechar o modal de paralisação
    const handleOpenParalisacaoModal = () => {
        setOpenParalisacaoModal(true);
    };
    const handleCloseParalisacaoModal = () => {
        setOpenParalisacaoModal(false);
    };

    return (
        <>
            <Navbar relatorios />
            <Header />
            <Background />
            <Container>
                <div className={style.content}>
                    <div className={style.nav}>
                        <div onClick={() => navigate(`/relatorios`)}>
                            Selecione um navio
                        </div>
                        <div className={style.nav}>
                            <div onClick={() => navigate(`/relatorios/${id}`)}>
                                Relatório por Período
                            </div>
                        </div>
                        <div className={style.active}>
                            Relatório por Viagem
                        </div>
                        <div onClick={() => navigate(`/relatorios/analiseoperacional/${id}`)}>
                            Análise Operacional
                        </div>
                        <div onClick={() => navigate(`/relatorios/idfa/${id}`)}>
                            IDFA
                        </div>
                    </div>

                    <div className={style.rankingcontainer}>
                        <section className={style.search}>
                            <div className={style.searchBox}>
                                <section className={style.filters}>
                                    <div className={style.form_control}>
                                        <label>Selecione uma operação:</label>
                                        <select onChange={(e) => { setRelatorios(e.target.value) }}>
                                            <option disabled selected>Selecione uma opção</option>
                                            {periodo?.map((val) => {
                                                return (
                                                    <option value={[val.NAVIO]}>{val.NAVIO}</option>
                                                )
                                            })}
                                        </select>
                                        <div className={style.submit_button}>
                                            <SubmitButton text={'Buscar'} onClick={getOperacoes} />
                                        </div>
                                        <div className={style.periodo}>
                                            <div className={style.data}>
                                                {relatorios}
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                <div className={style.itensblock}>
                                    <div className={style.table_container}>
                                        <div className={style.table_item} onClick={handleOpenProdModal}>
                                            Produção por Período
                                        </div>

                                        <div className={style.table_item} onClick={handleOpenRankingModal}>
                                            Ranking de Movimentação.
                                        </div>

                                        <div className={style.table_item} onClick={handleOpenParalisacaoModal}>
                                            Paralisação e Justificativa.
                                        </div>
                                    </div>

                                    <div className={style.cards}>
                                        <span className={style.cardinfo}>
                                            <div className={style.box}>
                                                <div className="content">
                                                    <center><i className="fa fa-truck icon"></i> Carregados</center>
                                                    <div className={style.total}>
                                                        {filteredCarregamentos.length} Autos
                                                    </div>
                                                </div>
                                            </div>
                                        </span>

                                        <span className={style.cardinfo}>
                                            <div className={style.box}>
                                                <div className="content">
                                                    <center><i className="fa fa-battery-three-quarters icon"></i> Peso Líquido Moega </center>
                                                    <div className={style.total}>
                                                        {(filteredCarregamentos.reduce((acc, val) => acc + (val.PESO_LIQUIDO || 0), 0) / 1000).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} Tons
                                                    </div>
                                                </div>
                                            </div>
                                        </span>


                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>

                    <div className={style.rankingcontainer}>
                        <section className={style.search}>
                            <div className={style.gftitle}>
                                Carregamentos
                            </div>
                            <div className={style.searchBox}>
                                <label htmlFor="searchInput" className="visuallyHidden"></label>
                                <select
                                    id="filtroCliente"
                                    onChange={(e) => { setFiltroCliente(e.target.value) }}
                                    className={style.selectInput}
                                >
                                    <option value="">Selecione Recebedor</option>
                                    {distinctCliente.map((cliente, index) => (
                                        <option key={index} value={cliente}>{cliente}</option>
                                    ))}
                                </select>
                                <select
                                    id="filtroPeriodo"
                                    onChange={(e) => { setFiltroPeriodo(e.target.value) }}
                                    className={style.selectInput}
                                >
                                    <option value="">Selecione Período</option>
                                    {distinctPeriodo.map((periodo, index) => (
                                        <option key={index} value={periodo}>{periodo}</option>
                                    ))}
                                </select>
                                <select
                                    id="filtroDI"
                                    onChange={(e) => { setFiltroDI(e.target.value) }}
                                    className={style.selectInput}
                                >
                                    <option value="">Selecione DI</option>
                                    {distinctDIs.map((di, index) => (
                                        <option key={index} value={di}>{di}</option>
                                    ))}
                                </select>
                                <select
                                    id="filtroPedidoMIC"
                                    onChange={(e) => { setFiltroPedidoMIC(e.target.value) }}
                                    className={style.selectInput}
                                >
                                    <option value="">Selecione Pedido MIC</option>
                                    {distinctPedidoMICs.map((pedidoMIC, index) => (
                                        <option key={index} value={pedidoMIC}>{pedidoMIC}</option>
                                    ))}
                                </select>
                                <select
                                    id="filtroTransportadora"
                                    onChange={(e) => { setFiltroTransportadora(e.target.value) }}
                                    className={style.selectInput}
                                >
                                    <option value="">Selecione Transportadora</option>
                                    {distinctTransportadoras.map((transportadora, index) => (
                                        <option key={index} value={transportadora}>{transportadora}</option>
                                    ))}
                                </select>
                                <select
                                    id="filtroPorao"
                                    onChange={(e) => { setFiltroPorao(e.target.value) }}
                                    className={style.selectInput}
                                >
                                    <option value="">Selecione Porão</option>
                                    {distinctPorao.map((porao, index) => (
                                        <option key={index} value={porao}>{porao}</option>
                                    ))}
                                </select>


                            </div>
                        </section>
                    </div>

                    <table className={style.tableAutos}>
                        <thead>
                            <tr>
                                <th><abbr title="ID">ID</abbr></th>
                                <th><abbr title="Período">Período</abbr></th>
                                <th><abbr title="Nome">Nome </abbr></th>
                                <th><abbr title="Placa do Cavalo">Placa (Cavalo)</abbr></th>
                                <th><abbr title="Tara">1° Peso (Tara)</abbr></th>
                                <th><abbr title="SegundoPeso">2° Peso (Moega)</abbr></th>
                                <th><abbr title="TerceiroPeso">3° Peso (Bruto)</abbr></th>
                                <th><abbr title="PesoLiquido">Peso Líquido </abbr></th>
                                <th><abbr title="Documento">DI </abbr></th>
                                <th><abbr title="PedidoMic">Pedido MIC</abbr></th>
                                <th><abbr title="DataCarregado">Data Carregado </abbr></th>
                                <th><abbr title="Diferenca">(Liquido - Moega)</abbr></th>
                                <th><abbr title="Cliente">Recebedor</abbr></th>
                                <th><abbr title="Transportadora">Transportadora</abbr></th>
                                <th><abbr title="Porao">Porão</abbr></th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredCarregamentos.map((val) => {
                                return (
                                    <tr key={val.ID_CARREGAMENTO}>
                                        <th>{val.ID_CARREGAMENTO}</th>
                                        <th>{val.PERIODO_CARREGAMENTO}</th>
                                        <th>{val.NOME_MOTORISTA}</th>
                                        <th>{val.PLACA_CAVALO}</th>
                                        <th>{(val.PESO_TARA).toLocaleString(undefined, { maximumFractionDigits: 2, })} KG</th>
                                        <th>{(val.PESO_CARREGADO).toLocaleString(undefined, { maximumFractionDigits: 2, })} KG</th>
                                        <th>{(val.PESO_BRUTO).toLocaleString(undefined, { maximumFractionDigits: 2, })} KG</th>
                                        <th>{(val.PESO_LIQUIDO).toLocaleString(undefined, { maximumFractionDigits: 2, })} KG</th>
                                        <th>{val.DOCUMENTO}</th>
                                        <th>{val.PEDIDO_MIC}</th>
                                        <th>{moment(val.DATA_CARREGAMENTO).format('DD/MM - HH:mm')}</th>
                                        <th>{val.DIFERENCA} kg</th>
                                        <th>{val.NOME_CLIENTE}</th>
                                        <th>{val.NOME_REDUZIDO}</th>
                                        <th>{val.PORAO}</th>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </Container>

            <Dialog open={openProdModal} onClose={handleCloseProdModal} maxWidth="md" fullWidth>
                <div className={style.modalContent}>
                    <div className={style.nav}>Produção por Período</div>
                    <table id="prodperiodo" border="1" className={style.customtable}>
                        <thead>
                            <tr>
                                <th>Período</th>
                                <th>Peso Líquido</th>
                                <th>Produto</th>
                                <th>Autos</th>
                                <th>Descarregado</th> {/* Nova coluna para o total acumulado */}
                                <th>Saldo</th> {/* Nova coluna para o saldo */}
                            </tr>
                        </thead>
                        <tbody>
                            {prodperiodo
                                .slice() // Faz uma cópia do array
                                .reverse() // Inverte o array para iterar de baixo para cima
                                .reduce((acc, item, index) => {
                                    // Cálculo do total acumulado
                                    const pesoAtual = item.CORTE_CHUVA === 'S' ? 0 : item.PESO_LIQUIDO;
                                    const descarregadoTotal = acc.descarregadoTotal + pesoAtual;

                                    // Cálculo do saldo (QTDE_MANIFESTADA_TOTAL - descarregadoTotal)
                                    const saldo = item.QTDE_MANIFESTADA_TOTAL - descarregadoTotal;

                                    return {
                                        descarregadoTotal,
                                        rows: [
                                            ...acc.rows,
                                            <tr key={index}>
                                                {/* Mantém a coluna de Período normal */}
                                                <td>{item.PERIODO}</td>

                                                {/* Verificação se há corte de período por chuva */}
                                                {item.CORTE_CHUVA === 'S' ? (
                                                    <td colSpan="5" style={{ backgroundColor: '#fff9b5', textAlign: 'center', color: 'black' }}>
                                                        <strong>🌧️ CORTE DE PERÍODO POR CHUVA</strong>
                                                    </td>
                                                ) : (
                                                    <>
                                                        <td>{(item.PESO_LIQUIDO / 1000).toLocaleString(undefined, { minimumFractionDigits: 3, maximumFractionDigits: 3 })} Tons</td>
                                                        <td>{item.NOME_PRODUTO}</td>
                                                        <td>{item.AUTOS}</td>
                                                        <td>{(descarregadoTotal / 1000).toLocaleString(undefined, { minimumFractionDigits: 3, maximumFractionDigits: 3 })} Tons</td>
                                                        <td>{(saldo / 1000).toLocaleString(undefined, { minimumFractionDigits: 3, maximumFractionDigits: 3 })} Tons</td>
                                                    </>
                                                )}
                                            </tr>
                                        ]
                                    };
                                }, { descarregadoTotal: 0, rows: [] })
                                .rows.reverse() /* Inverte de volta para que apareça de cima para baixo na tabela */}
                        </tbody>
                    </table>
                    <button className={style.downloadButton} onClick={downloadAsPDF}>Exportar em PDF</button>
                </div>
            </Dialog>

            <Dialog open={openRankingModal} onClose={handleCloseRankingModal} maxWidth="md" fullWidth>
                <div className={style.modalContent}>
                    <div className={style.nav}>Ranking de Movimentação</div>


                    <div className={style.gftitle}>Maior Movimentação por Navio</div>
                    <table id="rankingNavio" border="1" className={style.customtable}>
                        <thead>
                            <tr>
                                <th>Posição</th>
                                <th>Operador</th>
                                <th>Peso Carregado</th>
                            </tr>
                        </thead>
                        <tbody>
                            {ranking.map((item, index) => (
                                <tr key={index}>
                                    <td>{index === 0 ? "🥇" : index === 1 ? "🥈" : index === 2 ? "🥉" : item.RANK_POSITION}</td>
                                    <td>{item.RANKING_USUARIO}</td>
                                    <td>{(item.RANKING_PESO_CARREGADO / 1000).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} Tons</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <div className={style.gftitle}>Maior Movimentação por Período</div>
                    <table id="rankingPeriodo" border="1" className={style.customtable}>
                        <thead>
                            <tr>
                                <th>Posição</th>
                                <th>Período</th>
                                <th>Peso Carregado</th>
                                <th>Operador</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rankingprod.map((item, index) => (
                                <tr key={index}>
                                    <td>{index === 0 ? "🥇" : index === 1 ? "🥈" : index === 2 ? "🥉" : item.RANK_POSITION}</td>
                                    <td>{item.PERIODO}</td>
                                    <td>{(item.PESO_LIQUIDO / 1000).toLocaleString(undefined, { minimumFractionDigits: 3, maximumFractionDigits: 3 })} Tons</td>
                                    <td>{item.USUARIO_CARREG}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </Dialog>

            <Dialog open={openParalisacaoModal} onClose={handleCloseParalisacaoModal} maxWidth="md" fullWidth>
                <div className={style.modalContent}>
                    <div className={style.nav}>Paralisação</div>

                    <div className={style.gftitle}>Paralisação - Super Via</div>
                    <table className={style.customtableranking}>
                        <thead>
                            <tr>
                                <th>MOTIVO</th>
                                <th>HORAS</th>
                            </tr>
                        </thead>
                        <tbody>
                            {operacoes.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.MOTIVO}</td>
                                    <td>{item.HORAS}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <div className={style.gftitle}>Complemento - Justificativa</div>
                    <table className={style.customtableranking}>
                        <thead>
                            <tr>
                                <th>Complemento</th>
                                <th>Observação</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {complemento.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.COMPLEMENTO}</td>
                                    <td>{item.OBSERVACAO}</td>
                                    <td>{item.HORA}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </Dialog>


        </>
    );
};

export default function IntegrationNotistack() {
    return (
        <SnackbarProvider
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            maxSnack={3}
            autoHideDuration={2500}>
            <RelatorioPeriodo />
        </SnackbarProvider>
    );
}
