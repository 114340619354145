import React, { useState, useEffect } from "react";
import Navbar from "../../components/Navbar";
import Background from "../../components/Background";
import Container from "../../components/Container";
import { SnackbarProvider } from 'notistack';
import Header from "../../components/Header";
import Axios from "axios";
import style from "./Adufertil.module.css";
import SubmitButton from "../../components/Button";
import moment from 'moment';
import AdufertilLogo from "./Adufertil-Logo.png";

const Yara = () => {
    const [navio, setNavio] = useState([]);
    const [selectedNavio, setSelectedNavio] = useState(null);
    const [selectedViagem, setSelectedViagem] = useState(null);
    const [relatorios, setRelatorios] = useState([]);
    const [complementr, setComplementr] = useState([]);
    const [busca, setBusca] = useState("");
    const [listaDeObjetos, setListaDeObjetos] = useState([]);
    const [prodperiodo, setProdperiodo] = useState([]);
    const [periodos, setPeriodos] = useState([]);
    const [viewMode, setViewMode] = useState('MOEGA');
    const [paralisacao, setParalisacao] = useState([]);
    const [paralisacaoviagem, setParalisacaoviagem] = useState([]);
    const [selectedPeriodo, setSelectedPeriodo] = useState("");
    const [autos, setAutos] = useState([]);
    const [documentos, setDocumentos] = useState([]);
    const [hora, setHora] = useState([]);
    const [operacoes, setOperacoes] = useState([]);

    const toggleView = (mode) => {
        setViewMode(mode);
    };

    useEffect(() => {
        Axios.get('https://hml.operacao.orion-op.com.br/api/adufertil/dashboard/')
            .then(response => setNavio(response.data))
            .catch(error => console.log("Erro ao pegar os navios:", error));
    }, []);

    useEffect(() => {
        if (selectedNavio) {
            const selected = navio.find(n => n.COD_OPERACAO === parseInt(selectedNavio));
            if (selected) {
                setSelectedViagem(selected.VIAGEM_NAVIO);
            }
        }
    }, [selectedNavio, navio]);

    const getViagem = () => {
        if (selectedNavio) {
            Promise.all([
                Axios.get(`https://hml.operacao.orion-op.com.br/api/adufertil/autos/${selectedNavio}`),
                Axios.get(`https://hml.operacao.orion-op.com.br/api/adufertil/carregamentos/${selectedNavio}`),
                Axios.get(`https://hml.operacao.orion-op.com.br/api/adufertil/documento/${selectedNavio}`),
                Axios.get(`https://hml.operacao.orion-op.com.br/api/adufertil/prodperiodo/${selectedNavio}`),
                Axios.get(`https://hml.operacao.orion-op.com.br/api/periodos/gerais/${selectedNavio}`),
                Axios.post(`https://hml.operacao.orion-op.com.br/api/operacao/paralisacao/${selectedNavio}`)

            ])
                .then(([response1, response2, response3, response4, response5, response6]) => {
                    console.log("Dados recebidos from autos endpoint:", response1.data);
                    if (response1.data && response1.data.length > 0) {
                        setRelatorios(response1.data[0]);
                    }

                    console.log("Dados recebidos from carregamentos endpoint:", response2.data);
                    setComplementr(response2.data);

                    console.log("Dados recebidos from carregamentos endpoint:", response3.data);
                    setListaDeObjetos(response3.data);

                    console.log("Dados recebidos from carregamentos endpoint:", response4.data);
                    setProdperiodo(response4.data);

                    console.log("Dados recebidos from carregamentos endpoint:", response5.data);
                    setPeriodos(response5.data);

                    console.log("Dados recebidos from carregamentos endpoint:", response6.data);
                    setParalisacaoviagem(response6.data);

                })
                .catch(error => {
                    console.log("Error fetching data:", error);
                });
        }
    };

    const buscarParalisacao = () => {
        if (selectedNavio && selectedPeriodo) {
            Axios.post(`https://hml.operacao.orion-op.com.br/api/periodo/paralisacao/${selectedNavio}`, {
                data: selectedPeriodo,
            })
                .then((response) => {
                    console.log(response.data);
                    setParalisacao(response.data);

                    // Após receber os dados de paralisação, chame as outras funções
                    if (selectedNavio && relatorios) {
                        getAutos();
                        getDocumentos();
                        getHora();
                        getOperacoes();
                    }
                })
                .catch((error) => {
                    console.log("Erro ao buscar dados de paralisação:", error);
                });
        }
    }


    const getOperacoes = () => {
        if (selectedNavio && selectedPeriodo) {
            Axios.post(`https://hml.operacao.orion-op.com.br/api/periodo/carregamentos/${selectedNavio}`, {
                data: selectedPeriodo,
            })
                .then((response) => {
                    console.log(response.data);
                    setOperacoes(response.data);
                })
                .catch((error) => {
                    console.log("Erro ao buscar dados de autos:", error);
                });
        }
    }

    const getAutos = () => {
        if (selectedNavio && selectedPeriodo) {
            Axios.post(`https://hml.operacao.orion-op.com.br/api/periodo/autos/${selectedNavio}`, {
                data: selectedPeriodo,
            })
                .then((response) => {
                    console.log(response.data);
                    setAutos(response.data);
                })
                .catch((error) => {
                    console.log("Erro ao buscar dados de autos:", error);
                });
        }
    }

    const getDocumentos = () => {
        if (selectedNavio && selectedPeriodo) {
            Axios.post(`https://hml.operacao.orion-op.com.br/api/periodo/documentos/${selectedNavio}`, {
                data: selectedPeriodo,
            })
                .then((response) => {
                    console.log(response.data);
                    setDocumentos(response.data);
                })
                .catch((error) => {
                    console.log("Erro ao buscar dados de documentos:", error);
                });
        }
    }

    const getHora = () => {
        if (selectedNavio && selectedPeriodo) {
            Axios.post(`https://hml.operacao.orion-op.com.br/api/periodo/horahora/${selectedNavio}`, {
                data: selectedPeriodo,
            })
                .then((response) => {
                    console.log(response.data);
                    setHora(response.data);
                })
                .catch((error) => {
                    console.log("Erro ao buscar dados de hora:", error);
                });
        }
    }

    return (
        <>
            <Navbar relatorioCliente />
            <Header />
            <Background />
            <Container>
                <div className={style.content}>
                    <div className={style.container1}>

                        <section className={style.filters}>
                            <div className={style.navio}>
                                {selectedViagem}
                            </div>
                            <div className={style.form_control}>
                                <label>Selecione o NAVIO + VIAGEM:</label>
                                <select value={selectedNavio || ''} onChange={(e) => setSelectedNavio(e.target.value)}>
                                    <option value="" disabled>Selecione uma opção</option>
                                    {navio.map(val => (
                                        <option key={val.COD_NAVIO} value={val.COD_OPERACAO} className={style.form_control}>
                                            {val.VIAGEM_NAVIO}
                                        </option>
                                    ))}
                                </select>
                                <div className={style.submit_button}>
                                    <SubmitButton text={'Buscar'} onClick={getViagem} />
                                </div>

                                <table className={style.customtableranking}>
                                    {navio.map((item, index) => (
                                        <tbody key={index}>
                                            <tr>
                                                <td>EOSP</td>
                                                <td>--/--/----{item.PERIODO}</td>
                                            </tr>
                                            <tr>
                                                <td>Notice of Readiness tendered</td>
                                                <td>--/--/----{item.NOME_PRODUTO}</td>
                                            </tr>
                                            <tr>
                                                <td>Notice of Readiness accepted</td>
                                                <td>--/--/----{item.AUTOS}</td>
                                            </tr>
                                            <tr>
                                                <td>POB for berthing</td>
                                                <td>--/--/----{item.AUTOS}</td>
                                            </tr>
                                            <tr>
                                                <td>First line ashore</td>
                                                <td>--/--/----{item.AUTOS}</td>
                                            </tr>
                                            <tr>
                                                <td>Made all fast alongside</td>
                                                <td>--/--/----{item.AUTOS}</td>
                                            </tr>
                                            <tr>
                                                <td>Gangway secured ashore</td>
                                                <td>--/--/----{item.AUTOS}</td>
                                            </tr>
                                            <tr>
                                                <td>Commenced discharge operation</td>
                                                <td>--/--/----{item.AUTOS}</td>
                                            </tr>
                                            <tr>
                                                <td>Concluded discharge operation</td>
                                                <td>Sem Data{item.AUTOS}</td>
                                            </tr>
                                        </tbody>
                                    ))}
                                </table>
                            </div>
                        </section>


                        <div className={style.dados}>
                            <div className={style.yara}>
                                <img src={AdufertilLogo} alt="Logo" className={style.logoImage} />
                            </div>
                            <div className={style.cards}>
                                <span className={style.cardinfo}>
                                    <div className={style.card}>
                                        <center><i className="fa fa-truck icon"></i> Carregados</center>
                                        <div className={style.total}>
                                            {relatorios.QTDE_AUTOS} Autos
                                        </div>
                                    </div>
                                </span>
                                <span className={style.cardinfo}>
                                    <div className={style.card}>
                                        <center><i className="fa fa-battery-three-quarters icon"></i> Peso Líquido Moega </center>
                                        <div className={style.total}>
                                            {(relatorios.PESO_MOEGA / 1000).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2, })} Tons
                                        </div>
                                    </div>
                                </span>
                                <span className={style.cardinfo2}>
                                    <div className={style.card}>
                                        <center><i className="fa fa-battery-three-quarters icon"></i> Peso Líquido Balança </center>
                                        <div className={style.total}>
                                            {(relatorios.PESO_LIQUIDO / 1000).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2, })} Tons
                                        </div>
                                    </div>
                                </span>
                            </div>

                            <div className={style.dataBoxBTTotal}>
                                <div className={style.gftitle}>
                                    <b>Escolha a opção de relatório</b>
                                </div>
                                <div className={style.twobutton}>
                                    <button
                                        className={`${style.dataBoxBT} ${viewMode === 'RELATORIO_POR_VIAGEM' ? style.dataButtonActive : ''}`}
                                        onClick={() => toggleView('RELATORIO_POR_VIAGEM')}>
                                        Relatório por Viagem
                                    </button>

                                    <button
                                        className={`${style.dataBoxBT} ${viewMode === 'RELATORIO_POR_PERIODO' ? style.dataButtonActive : ''}`}
                                        onClick={() => toggleView('RELATORIO_POR_PERIODO')}>
                                        Relatório por Período
                                    </button>
                                </div>
                            </div>

                        </div>

                    </div>
                    {viewMode === 'RELATORIO_POR_VIAGEM' && (
                        <>

                            <div className={style.gftitle}>
                                <b>Quantidade descarregada por (DI/BL)</b>
                            </div>
                            <div className={style.centralizar}>
                                <div>
                                    {listaDeObjetos.map((graficdocs, index) => (
                                        <div key={index} className={style.gfcolum}>

                                            <div className={style.grpercent}>{graficdocs.PERC_BALANCA} %</div>
                                            <div className={style.grbackbar}>
                                                <div className={graficdocs.PERC_BALANCA >= 90 ? `${style.grred}` : `${style.grblue}`}>
                                                </div>
                                            </div>
                                            <div className={style.grdesc}>
                                                <br />
                                                <div className={style.NOME_REDUZIDO}><b>{graficdocs.NUMERO_DOC}</b></div>
                                                <div className={style.manifestado}>
                                                    <b>MANIFESTADO:</b> {(graficdocs.MANIFESTADO / 1000).toLocaleString('pt-BR', { minimumFractionDigits: 3, maximumFractionDigits: 3 })} Tons
                                                </div>

                                                <div className={style.linha}></div>
                                                <b>Descarregado:</b> {(graficdocs.PESO_CARREGADO_BALANCA / 1000).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} Tons
                                                <br />
                                                <b>Saldo:</b> {((graficdocs.SALDO_BALANCA) / 1000).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} Tons
                                                <br />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <div className={style.rankingcontainer}>
                                <div className={style.ranking1}>
                                    PARALISAÇÃO - SUPER VIA
                                    <table id="prodperiodo" border="2" className={style.customtable}>
                                        <thead>
                                            <tr>
                                                <th>MOTIVO</th>
                                                <th>HORAS</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {paralisacaoviagem.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{item.MOTIVO}</td>
                                                    <td>{item.HORAS}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div className={style.ranking2}>
                                    Produção por Período

                                    <table id="prodperiodo" border="1" className={style.customtable}>

                                        <thead>
                                            <tr>
                                                <th>Período</th>
                                                <th>Produto</th>
                                                <th>Peso Líquido</th>
                                                <th>Autos</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {prodperiodo.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{item.PERIODO}</td>
                                                    <td>{(item.PESO_LIQUIDO / 1000).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2, })} Tons</td>
                                                    <td>{item.NOME_PRODUTO}</td>
                                                    <td>{item.AUTOS}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <section className={style.search}>
                                <div className={style.gftitle}>
                                    Carregamentos a partir da 2° Pesagem
                                </div>
                                <div className={style.searchBox}>
                                    <label htmlFor="searchInput" className="visuallyHidden"></label>
                                    <input
                                        id="searchInput"
                                        placeholder="Pesquisar..."
                                        type="text"
                                        onChange={(e) => { setBusca(e.target.value) }}
                                    />
                                </div>
                            </section>
                            <table className={style.table} >

                                <thead>
                                    <tr>
                                        <th><abbr title="ID">ID </abbr></th>
                                        <th><abbr title="Nome">Nome </abbr></th>
                                        <th><abbr title="Placa do Cavalo">Placa (Cavalo)</abbr></th>
                                        <th><abbr title="Tara">1° Peso (Tara)</abbr></th>
                                        <th><abbr title="SegundoPeso">2° Peso (Moega)</abbr></th>
                                        <th><abbr title="TerceiroPeso">3° Peso (Bruto)</abbr></th>
                                        <th><abbr title="PesoLiquido">Peso Liquido </abbr></th>
                                        <th><abbr title="Documento">DI/BL </abbr></th>
                                        <th><abbr title="DataCarregado">Data Carregado </abbr></th>
                                        <th><abbr title="Diferenca">(Liquido - Moega)</abbr></th>
                                        <th><abbr title="DiferencaPerc">(%)</abbr></th>
                                        <th><abbr title="Nota">Nota Fiscal</abbr></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {complementr.filter((val) => {

                                        if (busca == "") {
                                            return val
                                        } else if (
                                            String(val.ID_CARREGAMENTO).toLowerCase().includes(busca.toLowerCase()) ||
                                            val.NOME_MOTORISTA.toLowerCase().includes(busca.toLowerCase()) ||
                                            val.PLACA_CAVALO.toLowerCase().includes(busca.toLowerCase()) ||
                                            String(val.PESO_TARA).toLowerCase().includes(busca.toLowerCase()) ||
                                            String(val.PESO_CARREGADO).toLowerCase().includes(busca.toLowerCase()) ||
                                            String(val.PESO_BRUTO).toLowerCase().includes(busca.toLowerCase()) ||
                                            String(val.PESO_LIQUIDO).toLowerCase().includes(busca.toLowerCase()) ||
                                            String(val.DOCUMENTO).toLowerCase().includes(busca.toLowerCase()) ||
                                            String(val.DATA_CARREGAMENTO).toLowerCase().includes(busca.toLowerCase()) ||
                                            String(val.DIFERENCA).toLowerCase().includes(busca.toLowerCase()) ||
                                            String(val.PERCENTUAL).toLowerCase().includes(busca.toLowerCase())
                                        ) {
                                            return val
                                        }
                                    }).map((val) => {
                                        return (
                                            <tr>
                                                <th>{val.ID_CARREGAMENTO}</th>
                                                <th>{val.NOME_MOTORISTA}</th>
                                                <th>{val.PLACA_CAVALO}</th>
                                                <th>{(val.PESO_TARA).toLocaleString(undefined, { maximumFractionDigits: 2, })} Kg</th>
                                                <th>{(val.PESO_CARREGADO).toLocaleString(undefined, { maximumFractionDigits: 2, })} Kg</th>
                                                <th>{(val.PESO_BRUTO).toLocaleString(undefined, { maximumFractionDigits: 2, })} Kg</th>
                                                <th>{(val.PESO_LIQUIDO).toLocaleString(undefined, { maximumFractionDigits: 2, })} Kg</th>
                                                <th>{val.DOCUMENTO}</th>
                                                <th>{moment(val.DATA_CARREGAMENTO).format('DD/MM - HH:mm')}</th>
                                                <th>{val.DIFERENCA} kg</th>
                                                <th>{val.PERCENTUAL} %</th>
                                            </tr>
                                        )
                                    })}


                                </tbody>

                            </table>

                        </>
                    )}

                    {viewMode === 'RELATORIO_POR_PERIODO' && (
                        <>
                            <div className={style.container}>
                                <div className={style.formulario}>
                                    <section className={style.filters}>
                                        <div className={style.formControl}>
                                            <label>Selecione um período:</label>
                                            <select
                                                value={selectedPeriodo || ''}
                                                onChange={(e) => setSelectedPeriodo(e.target.value)}>
                                                <option value="" disabled>Selecione uma opção</option>
                                                {periodos?.map(val => (
                                                    <option key={val.PERIODO} value={val.PERIODO}>
                                                        {val.PERIODO}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className={style.buttonSection}>
                                            <button className={style.submitButton} onClick={buscarParalisacao}>
                                                Buscar
                                            </button>
                                        </div>
                                    </section>

                                    <div className={style.cardPara}>
                                        <table className={style.cardParalisacao} >


                                            <thead>
                                                <tr>
                                                    <th className={style.linha}><abbr title="MOTIVO">Motivo</abbr></th>
                                                    <th className={style.linha}><abbr title="INICIO">Ínicio</abbr></th>
                                                    <th className={style.linha}><abbr title="TERMINO">Término</abbr></th>
                                                    <th className={style.linha}><abbr title="DURACAO">Duração</abbr></th>

                                                </tr>
                                            </thead>

                                            <tbody>
                                                {paralisacao.filter((val) => {
                                                    if (busca === "") {
                                                        return true;
                                                    } else {
                                                        return val.DESC_MOTIVO.includes(busca) ||
                                                            val.HORA_INICIO.includes(busca) ||
                                                            val.HORA_TERMINO.includes(busca) ||
                                                            val.DURACAO.includes(busca);
                                                    }
                                                }).map((val) => {
                                                    return (
                                                        <tr>
                                                            <th>{val.DESC_MOTIVO}</th>
                                                            <th>{val.HORA_INICIO}</th>
                                                            <th>{val.HORA_TERMINO}</th>
                                                            <th>{val.DURACAO}</th>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>

                                        </table>
                                    </div>
                                </div>

                                <section className={style.cards}>
                                    <div className={style.card}>
                                        <i className="fa fa-truck icon"></i>
                                        <p><b>Carregados - 2° Pesagem</b></p>
                                        {autos.map(val => (
                                            <span className={style.total}>{val.QTDE_AUTOS} Autos</span>
                                        ))}
                                    </div>

                                    <div className={style.card}>
                                        <i className="fa fa-battery-three-quarters icon"></i>
                                        <p>Peso Líquido - HÍBRIDO</p>
                                        {autos.map(val => (
                                            <span className={style.total}>
                                                {(val.PESO_HIBRIDO / 1000).toLocaleString('pt-BR', { minimumFractionDigits: 3, maximumFractionDigits: 3 })} Tons
                                            </span>
                                        ))}
                                    </div>

                                    <div className={style.card}>
                                        <i className="fa fa-file-text icon"></i>
                                        <p><b>Total de Peso por DI / BL - 2⁰ Pesagem</b></p>
                                        {documentos.map(val => (
                                            <div className={style.details}>
                                                <span>{val.DOC_CARGA}</span> | {(val.PESO_LIQUIDO_CARGA / 1000).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} Tons | {val.QTDE_AUTOS_CARGA} Autos
                                            </div>
                                        ))}
                                    </div>

                                    <div className={style.card}>
                                        <i className="fa fa-clock-o icon"></i>
                                        <p><b>Hora Hora - 2⁰ Pesagem</b></p>
                                        {hora.map(val => (
                                            <div className={style.details}>
                                                <b>{val.HORA}</b> | {val.QUANTIDADE_AUTOS} Autos
                                            </div>
                                        ))}
                                    </div>

                                </section>

                                <section className={style.search}>
                                    <div className={style.searchBox}>
                                        <label htmlFor="searchInput" className="visuallyHidden"></label>
                                        <input
                                            id="searchInput"
                                            placeholder="Pesquisar..."
                                            type="text"
                                            onChange={(e) => { setBusca(e.target.value) }}
                                        />
                                    </div>
                                </section>

                                <table className={style.table}>
                                    <thead>
                                        <tr>
                                            <th><abbr title="ID">ID</abbr></th>
                                            <th><abbr title="Nome">Nome</abbr></th>
                                            <th><abbr title="Placa do Cavalo">Placa (Cavalo)</abbr></th>
                                            <th><abbr title="Tara">1° Peso (Tara)</abbr></th>
                                            <th><abbr title="Segundo Peso">2° Peso (Moega)</abbr></th>
                                            <th><abbr title="Terceiro Peso">3° Peso (Bruto)</abbr></th>
                                            <th><abbr title="Peso Líquido">Peso Líquido</abbr></th>
                                            <th><abbr title="Documento">DI/BL</abbr></th>
                                            <th><abbr title="Data Carregado">Data Carregado</abbr></th>
                                            <th><abbr title="(Liquido - Moega)">Diferença</abbr></th>
                                            <th><abbr title="%">Diferença (%)</abbr></th>

                                        </tr>
                                    </thead>

                                    <tbody>
                                        {operacoes
                                            .filter((val) => {
                                                let horario = moment(val.DATA_CARREGAMENTO).format('DD/MM HH:mm');
                                                if (busca === "") {
                                                    return val;
                                                } else if (
                                                    String(val.ID_CARREGAMENTO).toLowerCase().includes(busca.toLowerCase()) ||
                                                    val.NOME_MOTORISTA.toLowerCase().includes(busca.toLowerCase()) ||
                                                    val.PLACA_CAVALO.toLowerCase().includes(busca.toLowerCase()) ||
                                                    String(val.PESO_TARA).toLowerCase().includes(busca.toLowerCase()) ||
                                                    String(val.PESO_CARREGADO).toLowerCase().includes(busca.toLowerCase()) ||
                                                    String(val.PESO_BRUTO).toLowerCase().includes(busca.toLowerCase()) ||
                                                    String(val.PESO_LIQUIDO).toLowerCase().includes(busca.toLowerCase()) ||
                                                    String(val.DOCUMENTO).toLowerCase().includes(busca.toLowerCase()) ||
                                                    String(val.DATA_CARREGAMENTO).toLowerCase().includes(busca.toLowerCase()) ||
                                                    String(val.DIFERENCA).toLowerCase().includes(busca.toLowerCase()) ||
                                                    String(val.PERCENTUAL).toLowerCase().includes(busca.toLowerCase())
                                                ) {
                                                    return val;
                                                }
                                            })
                                            .map((val) => {
                                                const isStatusSete = val.STATUS_NOTA_MIC === 7;
                                                const notaDownloadClass = [2, 3, 4, 6].includes(val.STATUS_NOTA_MIC)
                                                    ? style.nota_download
                                                    : style.nota_download_empty;

                                                return (
                                                    <tr key={val.ID_CARREGAMENTO} className={isStatusSete ? style.strikethrough : undefined}>
                                                        <th>{val.ID_CARREGAMENTO}</th>
                                                        <th>{val.NOME_MOTORISTA}</th>
                                                        <th>{val.PLACA_CAVALO}</th>
                                                        <th>{(val.PESO_TARA).toLocaleString(undefined, { maximumFractionDigits: 2 })}</th>
                                                        <th>{(val.PESO_CARREGADO).toLocaleString(undefined, { maximumFractionDigits: 2 })}</th>
                                                        <th>{(val.PESO_BRUTO).toLocaleString(undefined, { maximumFractionDigits: 2 })}</th>
                                                        <th>{(val.PESO_LIQUIDO).toLocaleString(undefined, { maximumFractionDigits: 2 })}</th>
                                                        <th>{val.DOCUMENTO}</th>
                                                        <th>{moment(val.DATA_CARREGAMENTO).format('DD/MM - HH:mm')}</th>
                                                        <th>{val.DIFERENCA} kg</th>
                                                        <th>{val.PERCENTUAL} %</th>
                                                    </tr>
                                                );
                                            })}
                                    </tbody>
                                </table>
                            </div>
                        </>
                    )}




                </div>
            </Container>
        </>
    );
};

export default function IntegrationNotistack() {
    return (
        <SnackbarProvider
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            maxSnack={3}
            autoHideDuration={2500}>
            <Yara />
        </SnackbarProvider>
    );
}
