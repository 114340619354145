import React from "react";
import Navbar from "../../../components/Navbar";
import Brackground from "../../../components/Background";
import Container from "../../../components/Container";
import Header from "../../../components/Header";
import style from "./PesagemInicial.module.css";
import { useNavigate, useParams } from "react-router-dom";
import Input from "../../../components/Input";
import SubmitButton from "../../../components/Button";
import Select from "../../../components/select";
import { useState, useEffect } from "react";
import Axios from "axios";
import { SnackbarProvider, useSnackbar } from 'notistack';
import MaskedInput from "../../../components/InputMask";
import moment from "moment";

const PesagemInicial = () => {
  const navigate = useNavigate();

  useEffect(() => {
    getTipoveiculo()
    getOperacoes()
    getTransp()
    fetchPlacas(cpf); // Fetch PLACA_CAVALO based on the CPF
  }, [])

  const fetchPlacas = async (cpf) => {
    try {
      const response = await Axios.get(`https://hml.operacao.orion-op.com.br/api/pesageminicial/historico/${cpf}`);
      if (response.data.length > 0) {
        const { PLACA_CAVALO, PLACA_CARRETA, PLACA_CARRETA2, PLACA_CARRETA3, TIPO_VEICULO } = response.data[0]; // Destructure the response
        setPlacacavalo(PLACA_CAVALO);
        setPlaca1(PLACA_CARRETA || ''); // Set or default to empty string
        setPlaca2(PLACA_CARRETA2 || '');
        setPlaca3(PLACA_CARRETA3 || '');
        setTipoveiculo(TIPO_VEICULO || '');
      }
    } catch (error) {
      console.error('Error fetching vehicle plate data:', error);
    }
  };

  const [operacoesList, setOperacoesList] = useState([]);
  const [pedidosByDoc, setPedidosByDoc] = useState([]);
  const [docs, setDocs] = useState([]);
  const [pedidoMic, setPedidoMic] = useState([]);
  const [teste, setTeste] = useState(false);
  const [destinos, setDestinos] = useState([]);
  const [destino, setDestino] = useState('');
  const [mensagemNavioSelecionado, setMensagemNavioSelecionado] = useState('');
  const [pedidos, setPedidos] = useState([])
  const [saldodoc, setSaldodoc] = useState([])
  const [doc, setDoc] = useState('');
  const [navio, setNavio] = useState('');
  const [tara, setTara] = useState('');
  const [placacavalo, setPlacacavalo] = useState('');
  const [placaCarreta, setPlacaCarreta] = useState('');
  const [placaCarreta2, setPlacaCarreta2] = useState('');
  const [placaCarreta3, setPlacaCarreta3] = useState('');
  const [placa1, setPlaca1] = useState('');
  const [placa2, setPlaca2] = useState(null);
  const [placa3, setPlaca3] = useState(null);
  const [data, setData] = useState('');
  const [transportadora, setTransportadora] = useState([])
  const [transportadoras, setTransportadoras] = useState([])
  const [tipopesagem, setTipopesagem] = useState('C'); // Defina 'C' como valor inicial
  const [tipoveiculo, setTipoveiculo] = useState('');
  const [tipoveiculos, setTipoveiculos] = useState([])
  const [disabled, setDisabled] = useState(false)
  const [porcentagem, setPorcentagem] = useState(null);
  const [saldoTons, setSaldoTons] = useState(null);
  const [motivacao, setMotivacao] = useState(''); // Add this line
  const [gate, setGate] = useState('');

  const usuario = JSON.parse(localStorage.getItem("user_token")).id;

  const validaPlaca = (placa) => {
    // Expressão regular para validar placas no formato antigo (AAA1234) ou Mercosul (AAA1B23)
    const placaRegex = /^[A-Z]{3}\d{4}$|^[A-Z]{3}\d{1}[A-Z]{1}\d{2}$|^[A-Z]{3}\d{2}[A-Z]{1}\d{1}$/;

    return placaRegex.test(placa);
  };

  const validaDados = async () => {
    // Define a data atual de forma síncrona
    const date = new Date();
    date.setHours(date.getHours() - 3); // Ajuste de fuso horário, se necessário
    const currentDate = date.toISOString().slice(0, 19).replace('T', ' ');

    // Continue com as validações antes de prosseguir
    if (
      !doc ||
      !destino ||
      !tipoveiculo ||
      !placacavalo ||
      !transportadora ||
      !tipopesagem ||
      !motivacao
    ) {
      showAlert('Preencha todos os campos.', 'error');
      return;
    }

    if (!validaPlaca(placacavalo)) {
      showAlert('Placa do cavalo deve estar no formato correto (Ex: AAA1234 ou AAA1B23)', 'error');
      return;
    }

    if (placa1 && !validaPlaca(placa1)) {
      showAlert('Placa da carreta 1 deve estar no formato correto (Ex: AAA1234 ou AAA1B23)', 'error');
      return;
    }

    if (placa2 && !validaPlaca(placa2)) {
      showAlert('Placa da carreta 2 deve estar no formato correto (Ex: AAA1234 ou AAA1B23)', 'error');
      return;
    }

    if (placa3 && !validaPlaca(placa3)) {
      showAlert('Placa da carreta 3 deve estar no formato correto (Ex: AAA1234 ou AAA1B23)', 'error');
      return;
    }

    if (!tara) {
      showAlert('"Peso (Tara)" é obrigatório. Coloque em 2.000 KG caso não tenha o conhecimento da TARA.', 'error');
      return;
    }

    try {
      // Verifica se há registros em aberto para o motorista e o navio selecionado
      const response = await Axios.get(`https://hml.operacao.orion-op.com.br/api/pesageminicial/valida/${cpf}/${navio}`);
      const registroEmAberto = response.data;

      if (registroEmAberto.length > 0) {
        const nomeNavio = registroEmAberto[0].NOME_NAVIO;
        showAlert(`Motorista aguardando a segunda pesagem do navio: ${nomeNavio}.`, 'error');
      } else {
        // Chama a função addPesagem passando a data atual
        addPesagem(currentDate);
      }
    } catch (error) {
      console.error('Erro ao verificar registro em aberto ou fazer a solicitação Puppeteer:', error);
      showAlert('Ocorreu um erro ao verificar o registro em aberto ou fazer a solicitação Puppeteer.', 'error');
    }
  };



  const validaTecla = (e) => {
    if (e.key === 'Enter') {
      validaDados()
    }
  }

  const validaTipo = (val) => {
    if (val === "C") {
      showAlert('Pesagem completa selecionada com sucesso!', 'success');
      setDisabled(false);
      setTara(0);
    } else {
      showAlert('Pesagem moega selecionada com sucesso!', 'success');
      setTara(1000);
      setDisabled(true);
    }
  }

  const addPesagem = (dataTara) => {
    const motivacaoValue = motivacao === "Sim" ? "S" : "N";

    Axios.post('https://hml.operacao.orion-op.com.br/api/pesagem/primeirapesagem', {
      COD_CARGA: doc,
      COD_OPERACAO: navio,
      PLACA_CAVALO: placacavalo,
      COD_MOTORISTA: id,
      PLACA_CARRETA: placa1,
      PLACA_CARRETA2: placa2,
      PLACA_CARRETA3: placa3,
      TIPO_VEICULO: tipoveiculo,
      COD_TRANSP: transportadora,
      PESO_TARA: tara,
      DATA_TARA: dataTara, // Usa a data passada como argumento
      USUARIO_TARA: usuario,
      STATUS_CARREG: '1',
      USUARIO: usuario,
      DATA_CADASTRO: getDate(),
      NR_PEDIDO: pedidoMic,
      TIPO_PESAGEM: tipopesagem,
      MOTIVACAO: motivacaoValue,
      GATE: gate
    }).then(function (res) {
      if (res.data.success) {
        const idCarregamento = res.data.ID_CARREGAMENTO;

        // Incluindo o ID_CARREGAMENTO na mensagem de sucesso
        showAlert(`Pesagem cadastrada com sucesso! ID: ${idCarregamento}`, 'success');
        setTimeout(() => {
          navigate('/veiculos/BuscarMotorista')
        }, 2500);

        if (motivacao === "Sim") {
          Axios.post('https://hml.operacao.orion-op.com.br/api/executarPuppeteer', {
            ID_CARREGAMENTO: idCarregamento,
            COD_OPERACAO: navio,
            COD_MOTORISTA: id,
            CPF_MOTORISTA: cpf // Adicionando o CPF aqui
          }).then(puppeteerResponse => {
            // Tratamento da resposta do Puppeteer
          }).catch(error => {
            console.error('Erro ao executar Puppeteer:', error);
            showAlert('Erro ao executar Puppeteer.', 'error');
          });
        }
      } else if (res.data.sqlMessage) {
        showAlert(res.data.sqlMessage, 'error');
      }
    }).catch(function (error) {
      console.error('Erro ao cadastrar a pesagem:', error);
      showAlert('Ocorreu um erro ao cadastrar a pesagem.', 'error');
    });
  };




  const getDate = () => {
    const date = new Date()
    date.setHours(date.getHours() - 3)
    return (date.toISOString().slice(0, 19).replace('T', ' '))
  }

  const getOperacoes = () => {
    Axios.get('https://hml.operacao.orion-op.com.br/api/operacao')
      .then((response) => {
        setOperacoesList(response.data)
        console.log(response.data);
        getCargas()
      });
  }

  const getCargas = (id) => {
    Axios.get(`https://hml.operacao.orion-op.com.br/api/carga/busca/${id}`,)
      .then(function (res) {
        setDocs(res.data);
        console.log(res.data);
        getPedido(id)
      });
  }

  const getTransp = () => {
    Axios.get(`https://hml.operacao.orion-op.com.br/api/transportadora`,)
      .then(function (res) {
        setTransportadoras(res.data);
        console.log(res.data);
      });
  }

  const getDestino = (idPedido) => {
    Axios.get(`https://hml.operacao.orion-op.com.br/api/pesageminicial/pedido/${idPedido}`)
      .then((res) => {
        // A resposta direta contém o nome do destino
        const destinoInfo = res.data;
        setDestino(destinoInfo.NOME_DESTINO); // Atualiza o estado do destino com o nome obtido
      })
      .catch((error) => {
        console.error('Erro ao buscar informações do destino:', error);
        showAlert('Erro ao carregar informações do destino.', 'error');
      });
  };

  const getTipoveiculo = () => {
    Axios.get(`https://hml.operacao.orion-op.com.br/api/tipoveiculo`,)
      .then(function (res) {
        setTipoveiculos(res.data);
        console.log(res.data);
      });
  }

  const getPedido = (id) => {
    Axios.get(`https://hml.operacao.orion-op.com.br/api/pesageminicial/pedidos/${id}`)
      .then(function (res) {
        setPedidosByDoc(res.data);
        console.log(res.data);
      });
  }

  const getSaldodoc = (id) => {
    Axios.get(`https://hml.operacao.orion-op.com.br/api/pesageminicial/saldodoc/${id}`)
      .then(function (res) {
        setSaldoTons(res.data);
        // Assuming you want the first element from the response
        if (res.data.length > 0) {
          setSaldoTons(res.data[0].SALDO_TONS);
          setPorcentagem(res.data[0].PORCENTAGEM); // Set the PORCENTAGEM value
        }
      })
      .catch(function (error) {
        console.error('Error fetching SALDO_TONS:', error);
      });
  };

  const { enqueueSnackbar } = useSnackbar();
  const showAlert = (txt, variant) => {
    enqueueSnackbar(txt, { variant: variant });
  }

  const handlePedidoChange = (e) => {
    const selectedPedido = e.target.value;
    setPedidoMic(selectedPedido);
    getDestino(selectedPedido); // Atualiza o destino com base no pedido selecionado
  };

  let { nome } = useParams();
  let { cpf } = useParams();
  let { cnh } = useParams();
  let { id } = useParams();

  return (
    <>
      <Navbar veiculos />
      <Header />
      <Brackground />
      <Container>
        <div className={style.content}>
          <div className={style.nav}>
            <div className={style.nav}>
              <div onClick={() => navigate("/veiculos/BuscarMotorista")} >
                Motorista
              </div>
              <div onClick={() => navigate("/veiculos")}>
                Cadastrar Motorista
              </div>
              <div className={style.active}>
                1° Pesagem
              </div>
            </div>
          </div>

          <div className={style.align}>
            <div className="columns">
              {/* Dados do Motorista */}
              <div className="column is-4">

                <fieldset className={style.fieldset_alt}>
                  <legend className={style.legend}>Dados do Motorista</legend>
                  <div className={style.card}>
                    <div className={style.cabecario}>
                      <p> <strong>Motorista:</strong> {nome}</p>
                      <p><strong>CPF:</strong> {cpf}</p>
                      <p><strong>CNH:</strong> {cnh == 'null' ? "Não Registrado" : cnh}</p>
                    </div>
                  </div>
                </fieldset>

                {/* Tipo de Pesagem */}
                <fieldset className={style.fieldset}>
                  <legend className={style.legend}>Tipo de Pesagem</legend>

                  <div className={style.control}>
                    <label className={style.input}>
                      <input
                        type="radio"
                        onChange={(e) => [setTipopesagem(e.target.value), validaTipo(e.target.value)]}
                        value="C"
                        name="tipoPesagem"
                        checked={tipopesagem === 'C'} // Defina checked como true se tipopesagem for igual a 'C'
                      />
                      CHAVE DE OURO
                    </label>
                    <label className={style.input}>
                      <input
                        type="radio"
                        onChange={(e) => [setTipopesagem(e.target.value), validaTipo(e.target.value)]}
                        value="M"
                        name="tipoPesagem"
                        checked={tipopesagem === 'M'} // Defina checked como true se tipopesagem for igual a 'M'
                      />
                      MOEGA
                    </label>
                  </div>

                </fieldset>
                <fieldset className={style.fieldset_alt}>
                  <legend className={style.legend}>Escolha o Navio</legend>

                  <select onChange={(e) => [getCargas(e.target.value), setNavio(e.target.value)]}>{/*
                    Chama o getCatgas() passando o id o codigo da operacao que vai ser selecinado no select e depois seta o navio que vai ser operado
                    porem na função getCargas, quando é enviado o id, automaticamente seta os documentos que vao ser lidos no outro select 
                  */}
                    <option disabled selected>Escolha uma opção</option>
                    {operacoesList?.map((val) => {
                      if (val.STATUS_OPERACAO == 'FECHADA') {
                        return (
                          null
                        )
                      }
                      return (
                        <option value={val.COD_OPERACAO}>{val.NOME_NAVIO}</option>
                      )
                    })}
                  </select>
                </fieldset>

                {disabled == true ?
                  <fieldset className={style.fieldset}>
                    <legend className={style.legend}>Peso Moega</legend>
                    <input className={style.form_input} type={"text"}
                      text={"Peso Vazio (Tara)"}
                      placeholder={" 1.000 KG"}
                      disabled={true}
                    />
                  </fieldset>
                  :
                  <fieldset className={style.fieldset}>
                    <legend className={style.legend}>Peso Vazio (Tara)</legend>
                    <input className={style.form_input} type={"text"}
                      placeholder={"Insira o Peso em KG"}
                      onChange={(e) => setTara(e.target.value)}
                    /></fieldset>
                }
              </div>
              <div className="column is-4">
                <fieldset className={style.fieldset_alt}>
                  <legend className={style.legend}>Dados do Veículo</legend>


                  <div className={style.form_control}>
                    <label>Modelo do AUTO</label>
                    <select
                      onChange={(e) => {
                        setTipoveiculo(e.target.value); // Atualize o valor de tipoveiculo
                      }}
                      value={tipoveiculo} // Certifique-se de que o valor seja definido corretamente
                    >
                      <option disabled value="">
                        Escolha uma opção
                      </option>
                      {tipoveiculos?.map((val) => {
                        return (
                          <option key={val.COD_TIPO} value={val.COD_TIPO}>
                            {val.DESC_TIPO_VEICULO}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className={style.placas}>
                    <div className={style.placaContainer}>
                      <label htmlFor="placaCavalo">Cavalo</label>
                      <input
                        type="text"
                        id="placaCavalo"
                        placeholder="Ex: AAA1234"
                        value={placacavalo}
                        onChange={(e) => setPlacacavalo(e.target.value.toUpperCase())}
                      />
                    </div>

                    <div className={style.placaContainer}>
                      <label htmlFor="placa1">Carreta 1</label>
                      <input
                        type="text"
                        id="placa1"
                        placeholder="Ex: AAA1234"
                        value={placa1}
                        onChange={(e) => setPlaca1(e.target.value.toUpperCase())}
                      />
                    </div>

                    <div className={style.placaContainer}>
                      <label htmlFor="placa2">Carreta 2</label>
                      <input
                        type="text"
                        id="placa2"
                        placeholder="Ex: AAA1234"
                        value={placa2}
                        onChange={(e) => setPlaca2(e.target.value.toUpperCase())}
                      />
                    </div>

                    <div className={style.placaContainer}>
                      <label htmlFor="placa3">Carreta 3</label>
                      <input
                        type="text"
                        id="placa3"
                        placeholder="Ex: AAA1234"
                        value={placa3}
                        onChange={(e) => setPlaca3(e.target.value.toUpperCase())}
                      />
                    </div>
                  </div>
                </fieldset>
                <fieldset className={style.fieldset_alt}>
                  <legend className={style.legend}>Motivação</legend>
                  <div className={style.form_motivacao}>
                    <div className={style.motivacao}>
                      <select onChange={(e) => setMotivacao(e.target.value)}>
                        <option disabled selected>Escolha uma opção</option>
                        <option value="Sim">Sim</option>
                        <option value="Não">Não</option>
                      </select>
                    </div>

                    {motivacao === "Sim" && ( // Verifica se a motivacao é "Sim"
                      <div className={style.motivacao}>
                        <label>Gate</label>
                        <select onChange={(e) => setGate(e.target.value)}>
                          <option disabled selected>Escolha uma opção</option>
                          <option value="5° Secção">5° Secção</option>
                          <option value="Saboó">Saboó</option>
                        </select>
                      </div>
                    )}
                  </div>
                </fieldset>
              </div>
              <div className="column is-4">
                <fieldset className={style.fieldset_alt}>
                  <legend className={style.legend}>Informações da Operação</legend>
                  <div className={style.form_controllab}>
                    <div className={style.form_controldoc}>
                      <label>(DI ou BL)</label>
                      <select onChange={(e) => { setDoc(e.target.value); getSaldodoc(e.target.value); getPedido(e.target.value); }}>
                        <option disabled selected>Escolha uma opção</option>
                        {docs?.map((val) => {
                          return (
                            <option value={val.COD_CARGA}>{val.TIPO} - {val.NUMERO}</option>
                          )
                        })}
                      </select>
                    </div>

                    <div className={style.form_controlsaldo} style={{ backgroundColor: porcentagem !== null && porcentagem < 90 ? 'green' : 'red' }}>
                      <div className={`${style.label} ${style.value}`}><b>SALDO</b></div>
                      <div className={style.value}>{saldoTons !== null ? saldoTons : ''} Tons</div>
                      <div className={style.value}>{porcentagem !== null ? porcentagem : ''} %</div>
                    </div>

                  </div>


                  <div className={style.bigbags}>
                    <div className={style.peso_bigbag}>
                      <label>Pedido MIC</label>
                      <select onChange={handlePedidoChange}>
                        <option disabled selected>Escolha opção</option>
                        {pedidosByDoc?.map((val) => {
                          return (
                            <option value={val.NR_PEDIDO}>{val.NR_PEDIDO}</option>
                          );
                        })}
                      </select>
                    </div>

                    <div className={style.quantidade}>

                      <label>Destino</label>
                      <p>{destino}</p> {/* Exibir o destino */}
                    </div>

                  </div>
                </fieldset>
                <div className={style.form_control}>
                  <fieldset className={style.fieldset_alt}>
                    <legend className={style.legend}>Transportadora</legend>
                    <select onChange={(e) => { setTransportadora(e.target.value) }}>
                      <option disabled selected>Escolha opção</option>
                      {transportadoras?.map((val) => {
                        return (
                          <option value={val.COD_TRANSP}>{val.NOME_TRANSP}</option>
                        )
                      })}
                    </select>
                  </fieldset>
                </div>

                <div className={style.button}>
                  <SubmitButton text={"Cadastrar"}
                    onClick={validaDados}
                    onkeypress={(e) => validaTecla(e)}
                  />
                </div>
              </div>
            </div>

          </div>
          <div className={style.button}></div>

        </div>
      </Container>
    </>
  );
};

export default function IntegrationNotistack() {
  return (
    <SnackbarProvider
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      maxSnack={3}
      autoHideDuration={2500}>
      <PesagemInicial />
    </SnackbarProvider >
  );
}

