import React from "react";
import { useEffect, useState } from 'react';
import Axios from 'axios';
import { SnackbarProvider, useSnackbar } from 'notistack';
import Navbar from "../../../components/Navbar";
import Brackground from "../../../components/Background";
import Container from "../../../components/Container";
import Header from "../../../components/Header";
import Input from "../../../components/Input";
import Confirm from '@mui/material/Dialog';
import moment from "moment";
import SubmitButton from "../../../components/Button";
import { useNavigate, useParams } from "react-router-dom";
import style from "./CadastroCarga.module.css";
import modal from "./Modal.module.css";
import MaskedInput from "../../../components/InputMask";

const CadastroCarga = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { nome } = useParams();
  const usuario = JSON.parse(localStorage.getItem("user_token")).id;

  const [tipo, setTipo] = useState("DI");
  const [perigo, setPerigo] = useState("");
  const [numero, setNumero] = useState("");
  const [agentes, setAgentes] = useState();
  //const [agente, setAgente] = useState("");
  const [emissao, setEmissao] = useState("");
  const [produto, setProduto] = useState("");
  const [ncm, setNcm] = useState("");
  const [manifestado, setManifestado] = useState("");
  const [cemercante, setCemercante] = useState("");
  const [porao, setPorao] = useState("");
  const [cargas, setCargas] = useState([]);
  const [clientes, setClientes] = useState("");
  const [paisOrigem, setPaisOrigem] = useState("");
  const [paises, setPaises] = useState([]);
  const [cliente, setCliente] = useState([]);
  const [produtos, setProdutos] = useState([]);
  const [pentagrama, setPentagrama] = useState([]);
  const [pentagramas, setPentagramas] = useState([]);
  const [filteredPentagramas, setFilteredPentagramas] = useState([]);
  const [pentagramaOrigem, setPentagramaOrigem] = useState('');


  useEffect(() => {
    getCargas();
    getClientes();
    getPaises();
    getPentagramas();
    getAgentes()
    getProdutos();
    getPentagramas();
  }, [])

  const getClientes = () => {
    Axios.get('https://hml.operacao.orion-op.com.br/api/clientes').then((response) => {
      setCliente(response.data);
    });
  }
  const getPaises = () => {
    Axios.get('https://hml.operacao.orion-op.com.br/api/paises').then((response) => {
      setPaises(response.data);
    });
  }
  const getPentagramas = () => {
    Axios.get('https://hml.operacao.orion-op.com.br/api/pentagrama').then((response) => {
      setPentagramas(response.data);
    });
  };
  const getProdutos = () => {
    Axios.get('https://hml.operacao.orion-op.com.br/api/produtos').then((response) => {
      setProdutos(response.data);
    });
  }
  const getCargas = () => {
    Axios.get(`https://hml.operacao.orion-op.com.br/api/carga/busca/${id}`,)
      .then(function (res) {
        setCargas(res.data);
      });
  }

  const handleTipoChange = (e) => {
    setTipo(e.target.value);
    setPerigo("");
    setNumero("");
    setEmissao("");
    setProduto("");
    setClientes([]);
    setCemercante("");
    setPerigo("");
    setManifestado("");
    setPaisOrigem("");
    setPentagrama([]);
    setCemercante("");
    setNcm("");
  };

  const handlePaisOrigemChange = (e) => {
    const selectedCountry = e.target.value;
    setPaisOrigem(selectedCountry);

    // Filtrar os pentagramas com base no país selecionado
    const pentagramasFiltrados = pentagramas.filter(p => p.NOME_PAIS === selectedCountry);
    setFilteredPentagramas(pentagramasFiltrados);
  };

  var total = cargas.reduce(getTotal, 0);
  function getTotal(total, item) {
    return total + (item.QTDE_MANIFESTADA * 1);
  }

  const addCarga = () => {
    // Definindo o valor padrão para ncm caso esteja vazio ou indefinido
    const ncmValue = ncm || "3102.10.00";
    
    // Definindo o valor padrão para pentagramaOrigem caso esteja vazio ou indefinido
    const pentagramaOrigemValue = pentagramaOrigem || "1";
  
    // Enviar a requisição POST com todos os dados, incluindo o pentagrama
    Axios.post('https://hml.operacao.orion-op.com.br/api/carga/criar', {
      operacao: id,
      tipo: tipo,
      perigo: perigo,
      numero: numero,
      emissao: emissao,
      cliente: clientes,
      referencia: numero.substring(6),
      produto: produto,
      pentagramaOrigem: pentagramaOrigemValue,
      ncm: ncmValue,
      cemercante: cemercante,
      manifestado: manifestado,
      status: '1',
      usuario: usuario,
    })
    .then(function (res) {
      showAlert(res.data.sqlMessage ? res.data.sqlMessage : 'Carga cadastrada com sucesso!', res.data.sqlMessage ? 'error' : 'success');
      if (!res.data.sqlMessage) {
        getCargas();
      }
    }).catch(function (error) {
      console.log(error);
      showAlert('Erro ao cadastrar carga!', 'error');
    });
  };
  
  

  const deleteCarga = (id) => {
    Axios.delete(`https://hml.operacao.orion-op.com.br/api/carga/delete/${id}`)
      .then(function (res) {
        console.log(res);
        res.data.sqlMessage ?
          showAlert(res.data.sqlMessage, 'error') :
          showAlert('Carga DELETADA com sucesso!', 'success');
        getCargas();
      });
  }

  const concluirDocs = () => {
    Axios.put('https://hml.operacao.orion-op.com.br/api/operacao/concluir/docs',
      {
        id: id,
        status: 'AGUARDANDO ATRACAÇÃO'
      }).then(function (res) {
        res.data.sqlMessage ?
          showAlert(res.data.sqlMessage, 'error') :
          showAlert('Documentação concluida com sucesso!', 'success');
        FecharConfirm();
        setTimeout(() => {
          navigate("/cargas");
        }, 1500);
      });
  }


  const { enqueueSnackbar } = useSnackbar();
  const showAlert = (txt, variant) => {
    enqueueSnackbar(txt, { variant: variant });
  }

  const validaDados = () => {
    // if (!tipo | !numero | !emissao | !produto) {
    //   showAlert('Preencha todos os campos!', 'error')
    //   return;
    // }

    addCarga();
  }


  const [openA, setOpenA] = useState(false);
  const AbrirConfirm = () => {
    if (cargas.length == 0) {
      return showAlert('Nenhuma carga cadastrada. Insira uma carga para continuar!', 'error')
    }
    setOpenA(true);
  };
  const FecharConfirm = () => {
    setOpenA(false);
  };

  const getAgentes = () => {
    Axios.get('https://hml.operacao.orion-op.com.br/api/agentes').then((response) => {
      setAgentes(response.data);
    });
  }


  return (
    <>
      <Navbar cargas />
      <Header />
      <Brackground />
      <Container>
        <div className={style.content}>
          <div className={style.nav}>
            <div onClick={() => navigate("/cargas")}>
              Voltar
            </div>
            <div className={style.active}>
              Cadastrar Carga
            </div>
          </div>

          <div className={style.navio}>
            <i className="fa fa-ship icon"></i>
            &nbsp;&nbsp;&nbsp;
            {nome}
          </div>
          <div className={style.flex}>
            {/* Primeira Linha */}
            <div className={style.form_control}>
              <label>Tipo:</label>
              <select value={tipo} onChange={handleTipoChange}>
                <option value="BL">BL</option>
                <option value="DI">DI</option>
              </select>
            </div>
            <div className={style.form_control}>
              <label>Número do Documento:</label>
              <input
                type="text"
                placeholder=""
                value={numero}
                onChange={(e) => setNumero(e.target.value)}
              />
            </div>
            <div className={style.form_control}>
              <label>Produto:</label>
              <select onChange={(e) => setProduto(e.target.value)} >
                <option disabled selected>Selecione uma opção</option>
                {produtos?.map((val) => (
                  <option key={val.COD_PRODUTO} value={val.COD_PRODUTO}>{val.PRODUTO}</option>
                ))}
              </select>
            </div>
            <div className={style.form_control}>
              <label>Data de Emissão:</label>
              <input
                type="date"
                onChange={(e) => setEmissao(e.target.value)}
              />
            </div>
          </div>

          {tipo !== 'BL' && (
            <div className={style.flex}>
              {/* Segunda Linha */}
              <div className={style.form_control}>
                <label>Importador:</label>
                <select onChange={(e) => setClientes(e.target.value)}>
                  <option disabled selected>Selecione uma opção</option>
                  {cliente?.map((val) => (
                    <option key={val.COD_CLIENTE} value={val.COD_CLIENTE}>{val.NOME_CLIENTE} - CNPJ: {val.CNPJ_CLIENTE}</option>
                  ))}
                </select>
              </div>
              <div className={style.form_control}>
                <label>CE Mercante:</label>
                <input
                  type="text"
                  onChange={(e) => setCemercante(e.target.value)}
                  value={cemercante}
                />
              </div>
              <div className={style.form_control}>
                <label>Produto Perigoso:</label>
                <select onChange={(e) => setPerigo(e.target.value)}>
                  <option disabled selected>Selecione uma opção</option>
                  <option value={"S"}>Sim</option>
                  <option value={"N"}>Não</option>
                </select>
              </div>

              <div className={style.form_control}>
                <label>Qtde Manifestada (Kg):</label>
                <input
                  type="text"
                  onChange={(e) => setManifestado(e.target.value)}
                  value={manifestado}
                />
              </div>
            </div>
          )}
          {tipo !== 'DI' && (
            <div className={style.flex}>
              {/* Terceira Linha */}
              <div className={style.form_control}>
                <label>País de Origem:</label>
                <select value={paisOrigem} onChange={handlePaisOrigemChange}>
                  <option value="" disabled>Selecione uma opção</option>
                  {paises.map((val) => (
                    <option key={val.COD_PAIS_ORIGEM} value={val.COD_PAIS_ORIGEM}>
                      {val.NOME_PAIS}
                    </option>
                  ))}
                </select>
              </div>


              <div className={style.form_control}>
                <label>Pentagrama:</label>
                <select onChange={(e) => setPentagrama(e.target.value)}>
                  <option disabled selected>
                    Selecione uma opção
                  </option>
                  {filteredPentagramas.map((val) => (
                    <option key={val.COD_PAIS_ORIGEM} value={val.COD_PAIS_ORIGEM}>
                      {val.PENTAGRAMA}
                    </option>
                  ))}
                </select>
              </div>
              <div className={style.form_control}>
                <label>NCM:</label>
                <input
                  type="text"
                  onChange={(e) => setNcm(e.target.value)}
                />
              </div>
              <div className={style.form_control}>
                <label>Porão:</label>
                <input
                  type="number"
                  onChange={(e) => setPorao(e.target.value)}
                />
              </div>

            </div>
          )}

        </div>

        <div className={style.listatitulo}>Histórico</div>
        <div className={style.cargas}>
          <div className={style.sumario}>
            <div>TIPO</div>
            <div>N° Documento</div>
            <div>PRODUTO</div>
            <div>DT. EMISSÃO</div>
            <div>IMPORTADOR</div>
            <div>CE MERCANTE</div>
            <div>PERIGO</div>
            <div>NCM</div>
            <div>QT. MANIFESTADA</div>
            <div></div>
          </div>
          <div className={style.lista}>
            {cargas.length === 0 ?
              <div>Nenhuma Carga Registrada</div> :
              cargas.map((val, index) => (
                <div key={index} className={style.item}>
                  <div>{val.TIPO}</div>
                  <div>{val.NUMERO}</div>
                  <div>{val.PRODUTO}</div>
                  <div>{moment(val.DATA_EMISSAO).format("DD/MM/YYYY")}</div>
                  <div>{val.IMPORTADOR}</div>
                  <div>{val.CE_MERCANTE}</div>
                  <div>{val.PERIGOSO}</div>
                  <div>{val.NCM}</div>
                  <div>{val.QTDE_MANIFESTADA}</div>
                  <div>
                    <span className={style.delete}>
                      <i className="fa fa-trash" onClick={() => deleteCarga(val.COD_CARGA)}></i>
                    </span>
                  </div>
                </div>
              ))
            }
          </div>
        </div>

        <div className={style.flextotal}>
          <SubmitButton text={"ADICIONAR"} onClick={validaDados} />
          <div className={style.total}>
            TOTAL DO NAVIO
            <div>
              {/* 120.000,000 KG */}
              {total} KG
            </div>
          </div>
          <SubmitButton text={"CONCLUIR"} onClick={AbrirConfirm} />
        </div>

      </Container>
      <Confirm open={openA} onClose={FecharConfirm} fullWidth>
        <div className={modal.modal}>
          <div className={modal.nav}>
            <div onClick={FecharConfirm}>Voltar</div>
          </div>
          <div className={modal.center}>
            Deseja concluir toda documentação desta Operação?
            <br />
            <div>ao confirmar não será mais possível editar os documentos! </div>
          </div>

          <div className={modal.flex}>
            <button className={modal.cancelar} onClick={FecharConfirm}>CANCELAR</button>
            <div className={modal.navio}><i className="fa fa-ship icon"></i>&nbsp;&nbsp;&nbsp;{nome}</div>
            <button className={modal.confirmar} onClick={concluirDocs}>CONFIRMAR</button>
          </div>
        </div>
      </Confirm>
    </>
  );
};

export default function IntegrationNotistack() {
  return (
    <SnackbarProvider
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      maxSnack={3}
      autoHideDuration={2500}>
      <CadastroCarga />
    </SnackbarProvider >
  );
}