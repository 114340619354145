import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { SnackbarProvider, useSnackbar } from 'notistack';
import Navbar from '../../../components/Navbar';
import Background from '../../../components/Background';
import Container from '../../../components/Container';
import Header from '../../../components/Header';
import SubmitButton from '../../../components/Button';
import Input from '../../../components/Input';
import style from './Motivados.module.css';
import { FaFileExcel } from 'react-icons/fa'; // Para o ícone
import * as XLSX from 'xlsx'; // Para exportar os dados

const Motivados = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [motivados, setMotivados] = useState([]);
  const [bercospa, setBercoSPA] = useState([]);
  const [empresaSelecionada, setEmpresaSelecionada] = useState('ORION');
  const [statusSelecionado, setStatusSelecionado] = useState('ATIVO');
  const [diasSelecionado, setDiasSelecionado] = useState('5');
  const [bercoSelecionado, setBercoSelecionado] = useState('');

  const [navios, setNavios] = useState([]);

  const toggleFormVisibility = () => setMostrarNavios1(!mostrarNavios1); // Função para alternar a visibilidade

  const [operacao1, setOperacao1] = useState('');
  const [operacao2, setOperacao2] = useState('');
  const [operacao3, setOperacao3] = useState('');
  const [operacao4, setOperacao4] = useState('');

  const [mostrarNavios1, setMostrarNavios1] = useState(false);
  const [mostrarNavios2, setMostrarNavios2] = useState(false);
  const [mostrarNavios3, setMostrarNavios3] = useState(false);
  const [mostrarNavios4, setMostrarNavios4] = useState(false);

  const toggleMostrarNavios1 = () => {

    if (mostrarNavios1) {
      setMostrarNavios2(false);
      setMostrarNavios3(false);
      setMostrarNavios4(false);
    }
    setMostrarNavios1(!mostrarNavios1);
  };

  const toggleMostrarNavios2 = () => {

    if (mostrarNavios2) {
      setMostrarNavios3(false);
      setMostrarNavios4(false);
    }
    setMostrarNavios2(!mostrarNavios2);
  };


  const toggleMostrarNavios3 = () => {

    if (mostrarNavios3) {
      setMostrarNavios4(false);
    }
    setMostrarNavios3(!mostrarNavios3);
  };

  const toggleMostrarNavios4 = () => {
    setMostrarNavios4(!mostrarNavios4);
  };


  const usuario = JSON.parse(localStorage.getItem("user_token")).id;

  // Adicionando a função showAlert
  const showAlert = (message, variant) => {
    enqueueSnackbar(message, { variant });
  };

  useEffect(() => {
    Axios.get(`https://hml.operacao.orion-op.com.br/api/motivacao/motivados`)
      .then(response => {
        setMotivados(response.data);
      })
      .catch(error => {
        console.error("Erro ao buscar dados: ", error);
        enqueueSnackbar('Erro ao buscar dados', { variant: 'error' });
      });
  }, []);

  useEffect(() => {
    Axios.get(`https://hml.operacao.orion-op.com.br/api/bercospa`)
      .then(response => {
        setBercoSPA(response.data);
      })
      .catch(error => {
        console.error("Erro ao buscar dados: ", error);
        enqueueSnackbar('Erro ao buscar dados', { variant: 'error' });
      });
  }, []);

  useEffect(() => {
    Axios.get(`https://hml.operacao.orion-op.com.br/api/operacao/operando`)
      .then(response => {
        setNavios(response.data);
      })
      .catch(error => {
        console.error("Erro ao buscar dados: ", error);
        enqueueSnackbar('Erro ao buscar dados', { variant: 'error' });
      });
  }, []);

  const renderStatusDot = (status) => {
    if (status === 'ATIVO') {
      return (
        <span>
          <span className={style.greenDot}></span>
          {status}
        </span>
      );
    } else if (status === 'INATIVO') {
      return (
        <span>
          <span className={style.redDot}></span>
          {status}
        </span>
      );
    }
    return status; // Caso status não seja "ATIVO" ou "INATIVO"
  };


  const formatarCPF = (cpf) => {
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  };

  const empresasUnicas = [...new Set(motivados.map(item => item.EMPRESA))];
  const statusUnicos = [...new Set(motivados.map(item => item.STATUS))];
  const nomeMotivadoUnicos = [...new Set(motivados.map(item => item.NOME_MOTIVADO))];

  const validaTecla = (e) => {
    if (e.key === 'Enter') {
      validaDados()
    }
  }

  const validaDados = () => {
    // Primeiro, verifica se o Período (dias) foi selecionado
    if (!diasSelecionado) {
      showAlert('Selecione um período!', 'error');
      return; // Interrompe a execução da função se não houver período selecionado
    }

    // Em seguida, verifica se o Berço foi selecionado
    if (!bercoSelecionado) {
      showAlert('Selecione um berço!', 'error');
      return; // Interrompe a execução da função se não houver berço selecionado
    }

    // Se ambos os campos foram preenchidos corretamente, a função pode prosseguir
    // Aqui você pode inserir a lógica de envio dos dados para motivação
    motivar(); // Por exemplo, chamando a função que executa a lógica de "Motivar"
  };

  const filtrarMotivados = () => {
    return motivados
      .filter(item => empresaSelecionada === '' || item.EMPRESA === empresaSelecionada)
      .filter(item => statusSelecionado === '' || item.STATUS === statusSelecionado);
  };

  const toggleMotivadoStatus = (motivadoId, currentStatus) => {
    const updatedStatus = currentStatus === 'ATIVO' ? 'INATIVO' : 'ATIVO';

    // Optimistically update the UI
    const updatedMotivados = motivados.map(motivado =>
      motivado.COD_MOTIVADO === motivadoId ? { ...motivado, STATUS: updatedStatus } : motivado
    );
    setMotivados(updatedMotivados);

    // Attempt to update the backend
    Axios.post(`https://hml.operacao.orion-op.com.br/api/api/motivado/status/update`, { motivadoId, updatedStatus })
      .then(response => {
        showAlert('Status atualizado com sucesso!', 'success');
      })
      .catch(error => {
        console.error("Erro ao atualizar status: ", error);
        showAlert('Erro ao atualizar status', 'error');
        // Revert the optimistic update in case of error
        setMotivados(motivados.map(motivado =>
          motivado.COD_MOTIVADO === motivadoId ? { ...motivado, STATUS: currentStatus } : motivado
        ));
      });
  };

  const renderStatusSwitch = (motivado) => {
    return (
      <label className={style.switch}>
        <input
          type="checkbox"
          className={style.inputhidden}
          checked={motivado.STATUS === 'ATIVO'}
          onChange={() => toggleMotivadoStatus(motivado.COD_MOTIVADO, motivado.STATUS)}
        />
        <span className={`${style.slider} ${style.statusSlider}`}></span>
      </label>
    );
  };

  const motivar = () => {
    // Obtém a data atual como a data inicial
    const dataInicial = new Date();

    // Converte a quantidade de dias selecionados em um número
    const dias = parseInt(diasSelecionado, 10);

    // Cria um novo objeto Date para a data final, começando pela data inicial
    const dataFinal = new Date(dataInicial);
    dataFinal.setDate(dataFinal.getDate() + dias); // Adiciona a quantidade de dias à data inicial

    // Formata as datas para o formato YYYY-MM-DD
    const dataInicialFormatada = dataInicial.toISOString().split('T')[0];
    const dataFinalFormatada = dataFinal.toISOString().split('T')[0];

    const cpfMotivados = filtrarMotivados().map(item => item.CPF_MOTIVADO).join(';');

    // Prepara os dados de motivação a serem enviados
    const dadosMotivacao = {
      codBercoSpa: bercoSelecionado,
      cpfMotivados,
      empresa: empresaSelecionada,
      dataInicial: dataInicialFormatada,
      dataFinal: dataFinalFormatada,
      usuarioCadastro: usuario
    };

    if (operacao1) dadosMotivacao.operacao1 = operacao1;
    if (operacao2) dadosMotivacao.operacao2 = operacao2;
    if (operacao3) dadosMotivacao.operacao3 = operacao3;
    if (operacao4) dadosMotivacao.operacao4 = operacao4;

    // Log dos dados para fins de depuração
    console.log('Dados de motivação sendo enviados:', dadosMotivacao);

    // Aguarde 2 segundos (2000 milissegundos) antes de recarregar a página
    setTimeout(() => {
      window.location.reload();
    }, 2000);

    // Envia os dados de motivação para o backend
    Axios.post('https://hml.operacao.orion-op.com.br/api/motivacao/registrar', dadosMotivacao)
      .then(response => {
        showAlert('Ação de motivar realizada com sucesso!', 'success');
      })
      .catch(error => {
        const mensagemErro = error.response?.data?.message || 'Erro desconhecido ao realizar a ação de motivar.';
        console.error(mensagemErro);
        showAlert(mensagemErro, 'error');
      });
  };

  const exportToExcel = () => {
    // Ajusta os dados para exportação com base nos dados filtrados que estão sendo exibidos na tabela
    const dataForExport = filtrarMotivados().map(motivado => ({
      'Nome Motivado': motivado.NOME_MOTIVADO,
      'CPF Motivado': formatarCPF(motivado.CPF_MOTIVADO), // Usando a função de formatação de CPF
      'Empresa': motivado.EMPRESA,
      'Status': motivado.STATUS
    }));

    // Cria uma planilha a partir dos dados ajustados
    const worksheet = XLSX.utils.json_to_sheet(dataForExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Motivados");

    // Define um nome para o arquivo Excel
    const fileName = "ListaDeMotivados.xlsx";

    // Usa XLSX.writeFile() para salvar o arquivo localmente
    XLSX.writeFile(workbook, fileName);
  };


  return (
    <>
      <Navbar motivados />
      <Header />
      <Background />
      <Container>
        <div className={style.content}>
          <div className={style.nav}>
            <div className={`${style.active} ${style.navItem}`}>
              Motivado
            </div>
            <div className={style.navItem} onClick={() => navigate('/motivacao/historicomotivacao')}>
              Motivação
            </div>
            <div className={style.navItem} onClick={() => navigate('/motivacao/cadastro')}>
              Cadastrar Motivado
            </div>
          </div>

          <div className={style.filtersContainer}>
            <select
              className={style.selectEmpresa}
              value={empresaSelecionada}
              onChange={(e) => setEmpresaSelecionada(e.target.value)}
            >
              <option value="">Todas as Empresas</option>
              {empresasUnicas.map((empresa, index) => (
                <option key={index} value={empresa}>{empresa}</option>
              ))}
            </select>

            <select
              className={style.selectDias}
              value={diasSelecionado}
              onChange={(e) => setDiasSelecionado(e.target.value)}
            >
              <option value="">Período</option>
              <option value="1">1 Dia</option>
              <option value="2">2 Dias</option>
              <option value="3">3 Dias</option>
              <option value="4">4 Dias</option>
              <option value="5">5 Dias</option>
            </select>


            <select className={style.selectEmpresa} onChange={(e) => { setBercoSelecionado(e.target.value) }}>
              <option disabled selected>Escolha uma opção</option>
              {bercospa?.map((bercospa) => {
                return (
                  <option value={bercospa.COD_BERCO_SPA}>{bercospa.DESC_BERCO_SPA}</option>
                )
              })}
            </select>

            <button className={style.addButton} onClick={toggleMostrarNavios1}>{mostrarNavios1 ? '-' : '+'}</button>


            <div class={style.switch_container}>
              <label className={style.switch}>
                <input
                  type="checkbox"
                  className={style.inputhidden}
                  checked={statusSelecionado === 'ATIVO'} // Altere para 'INATIVO' se necessário
                  onChange={() => {
                    setStatusSelecionado(statusSelecionado === 'ATIVO' ? 'INATIVO' : 'ATIVO');
                  }}
                />
                <span className={`${style.slider} ${style.statusSlider}`}></span>
              </label>
            </div>

            <div className={style.submitButton}>
              <SubmitButton text="Motivar" onClick={validaDados} onKeyPress={(e) => validaTecla(e)} />
            </div>

          </div>

          <div className={style.operacao}>
            <div className={style.selectButtonGroup}>

              {mostrarNavios1 && (
                <>
                  <select className={style.selectEmpresa} onChange={(e) => { setOperacao1(e.target.value) }}>
                    <option disabled selected>Escolha uma opção</option>
                    {navios?.map((navios) => {
                      return (
                        <option value={navios.NOME_NAVIO}>{navios.NOME_NAVIO}</option>
                      )
                    })}
                  </select>
                  <button className={style.addButton} onClick={toggleMostrarNavios2}>{mostrarNavios2 ? '-' : '+'}</button>
                </>
              )}

              {mostrarNavios2 && (
                <>
                  <select className={style.selectEmpresa} onChange={(e) => { setOperacao2(e.target.value) }}>
                    <option disabled selected>Escolha uma opção</option>
                    {navios?.map((navios) => {
                      return (
                        <option value={navios.NOME_NAVIO}>{navios.NOME_NAVIO}</option>
                      )
                    })}
                  </select>
                  <button className={style.addButton} onClick={toggleMostrarNavios3}>{mostrarNavios3 ? '-' : '+'}</button>
                </>
              )}

              {mostrarNavios3 && (
                <>
                  <select className={style.selectEmpresa} onChange={(e) => { setOperacao3(e.target.value) }}>
                    <option disabled selected>Escolha uma opção</option>
                    {navios?.map((navios) => {
                      return (
                        <option value={navios.NOME_NAVIO}>{navios.NOME_NAVIO}</option>
                      )
                    })}
                  </select>
                  <button className={style.addButton} onClick={toggleMostrarNavios4}>{mostrarNavios4 ? '-' : '+'}</button>
                </>
              )}

              {mostrarNavios4 && (
                <>
                  <select className={style.selectEmpresa} onChange={(e) => { setOperacao4(e.target.value) }}>
                    <option disabled selected>Escolha uma opção</option>
                    {navios?.map((navios) => {
                      return (
                        <option value={navios.NOME_NAVIO}>{navios.NOME_NAVIO}</option>
                      )
                    })}
                  </select>
                </>
              )}

            </div>
          </div>

          <div className={style.dataFiltro}>
            <FaFileExcel
              onClick={exportToExcel}
              style={{ fontSize: '1.5em', color: 'green', cursor: 'pointer' }}
            />
          </div>


          <table className={style.table}>
            <thead>
              <tr>
                <th>NOME MOTIVADO</th>
                <th>CPF MOTIVADO</th>
                <th>EMPRESA</th>
                <th>STATUS</th>
              </tr>
            </thead>
            <tbody>
              {filtrarMotivados().map((item, index) => (
                <tr key={index}>
                  <td>{item.NOME_MOTIVADO}</td>
                  <td>{formatarCPF(item.CPF_MOTIVADO)}</td>
                  <td>{item.EMPRESA}</td>
                  <td>{renderStatusSwitch(item)}</td> {/* Updated line here */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Container>
    </>
  );
};

export default function IntegrationNotistack() {
  return (
    <SnackbarProvider
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      maxSnack={3}
      autoHideDuration={2500}
    >
      <Motivados />
    </SnackbarProvider>
  );
}
