// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SubmitButton_form_control__eOjpP button{\n\twidth: auto;\n\tbackground: #001D49;\n    font-size: 20px;\n\tcolor: white;\n\tfont-family: 'sans-serif';\n\tborder: 0 none;\n\tborder-radius: 35px;\n\tcursor: pointer;\n\ttransition: 0.3s;\n\tpadding: 7px 45px;\n\tmargin: auto;\n\tmargin-top: 25px;\n\tmargin-left: 2px;\t\n}.SubmitButton_form_control__eOjpP button:hover{\n\tbackground: #307ABD;\n\tbox-shadow: \n\t0 0 0 3px #ffffffd8,\n\t 0 0 0 4px #307ABD;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Button/SubmitButton.module.css"],"names":[],"mappings":"AAAA;CACC,WAAW;CACX,mBAAmB;IAChB,eAAe;CAClB,YAAY;CACZ,yBAAyB;CACzB,cAAc;CACd,mBAAmB;CACnB,eAAe;CACf,gBAAgB;CAChB,iBAAiB;CACjB,YAAY;CACZ,gBAAgB;CAChB,gBAAgB;AACjB,CAAC;CACA,mBAAmB;CACnB;;mBAEkB;AACnB","sourcesContent":[".form_control button{\n\twidth: auto;\n\tbackground: #001D49;\n    font-size: 20px;\n\tcolor: white;\n\tfont-family: 'sans-serif';\n\tborder: 0 none;\n\tborder-radius: 35px;\n\tcursor: pointer;\n\ttransition: 0.3s;\n\tpadding: 7px 45px;\n\tmargin: auto;\n\tmargin-top: 25px;\n\tmargin-left: 2px;\t\n}.form_control button:hover{\n\tbackground: #307ABD;\n\tbox-shadow: \n\t0 0 0 3px #ffffffd8,\n\t 0 0 0 4px #307ABD;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form_control": "SubmitButton_form_control__eOjpP"
};
export default ___CSS_LOADER_EXPORT___;
