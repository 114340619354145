import { SnackbarProvider, useSnackbar } from 'notistack';
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Axios from "axios";
import Brackground from "../../../components/Background";
import Container from "../../../components/Container";
import Header from "../../../components/Header";
import Input from "../../../components/Input";
import Navbar from "../../../components/Navbar";
import React from "react";
import Select from "../../../components/select";
import SubmitButton from "../../../components/Button";
import moment from "moment";
import style from "./RelatorioSetPort.module.css"
import { useStepContext } from "@mui/material";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import LogoOrion from './LOGO_ORION.png';
import { format } from 'date-fns';

const RelatorioSetPort = () => {

    useEffect(() => {
        getEquipamentos();
        getMoegas();
        getPeriodos();
        getBercos();
        VerificaPeriodo();
        getOp()
        getPeriodo()
    }, [])


    const { id } = useParams();
    const [navioslist, setNaviosList] = useState([])
    const [equipamento, setEquipamento] = useState("");
    const [periodo, setPeriodo] = useState([]);
    const [berco, setBerco] = useState("");
    const [qtbordo, setQtBordo] = useState("");
    const [qtterra, setQtTerra] = useState("");
    const [gerador, setGerador] = useState("");
    const [grab, setGrab] = useState("");
    const [porao, setPorao] = useState("");
    const [requisicao, setRequisicao] = useState("");
    const [conexo, setConexo] = useState("");
    const [inicio, setInicio] = useState("");
    const [existePeriodo, setExistePeriodo] = useState(0);
    const usuario = JSON.parse(localStorage.getItem("user_token")).id;
    const [operacoes, setOperacoes] = useState([]);
    const [equipamentos, setEquipamentos] = useState([]);
    const [periodos, setPeriodos] = useState('');
    const [autos, setAutos] = useState([]);
    const [documentos, setDocumentos] = useState([]);
    const [moegas, setMoegas] = useState([]);
    const [hora, setHora] = useState([]);
    const [paralisacao, setParalisacao] = useState([]);
    const [moega, setMoega] = useState([]);
    const [bercos, setBercos] = useState([])
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [buttonText, setButtonText] = useState('Cadastrar');
    const navigate = useNavigate();

    const getOp = () => {
        Axios.get('https://hml.operacao.orion-op.com.br/api/relatorios/operacoes').then((response) => {
            setNaviosList(response.data)
        });
    }

    const getDate = () => {
        const date = new Date()
        date.setHours(date.getHours() - 3)
        return (date.toISOString().slice(0, 19).replace('T', ' '))
    }

    const getEquipamentos = () => {
        Axios.get('https://hml.operacao.orion-op.com.br/api/equipamentos').then((response) => {
            setEquipamentos(response.data)
        });
    }

    const getMoegas = () => {
        Axios.get(`https://hml.operacao.orion-op.com.br/api/periodo/moega/152`, {
            data: relatorios
        }).then((response) => {
            console.log(response.data);
            setMoegas(response.data);
        });
    }

    const getPeriodos = () => {
        Axios.get('https://hml.operacao.orion-op.com.br/api/periodos/horarios').then((response) => {
            setPeriodos(response.data)
        });
    }

    const getBercos = () => {
        Axios.get('https://hml.operacao.orion-op.com.br/api/bercos').then((response) => {
            setBercos(response.data);
        });
    }

    const { enqueueSnackbar } = useSnackbar();

    const showAlert = (txt, variant) => {
        enqueueSnackbar(txt, { variant: variant });
    }

    const VerificaPeriodo = () => {
        Axios.get(`https://hml.operacao.orion-op.com.br/api/periodo/busca/152`,)
            .then(function (res) {
                setExistePeriodo(res.data[0].EXISTE)
            })
    }

    const getOperacoes = () => {
        getAutos()
        getDocumentos()
        getMoegas();
        getHora()
        getParalisacao()
        getMoega()
        console.log(autos)
        console.log(documentos)
        Axios.post(`https://hml.operacao.orion-op.com.br/api/periodo/carregamentos/152`, {
            data: relatorios
        }).then((response) => {
            console.log(response.data);
            setOperacoes(response.data);
        });
    }

    const getAutos = () => {
        Axios.post(`https://hml.operacao.orion-op.com.br/api/periodo/autos/152`, {
            data: relatorios
        }).then((response) => {
            console.log(response.data);
            setAutos(response.data);
        });
    }

    const getDocumentos = () => {
        Axios.post(`https://hml.operacao.orion-op.com.br/api/periodo/documentos/${id}`, {
            data: relatorios
        }).then((response) => {
            console.log(response.data);
            setDocumentos(response.data);
        });
    }

    const getHora = () => {
        Axios.post(`https://hml.operacao.orion-op.com.br/api/periodo/horahora/152`, {
            data: relatorios
        }).then((response) => {
            console.log(response.data);
            setHora(response.data);
        });
    }

    const getParalisacao = () => {
        Axios.post(`https://hml.operacao.orion-op.com.br/api/periodo/paralisacao/152`, {
            data: relatorios
        }).then((response) => {
            console.log(response.data);
            setParalisacao(response.data);
        });
    }

    const getMoega = () => {
        Axios.post(`https://hml.operacao.orion-op.com.br/api/periodo/moega/152`, {
            data: relatorios
        }).then((response) => {
            console.log(response.data);
            setMoega(response.data);
        });
    }

    const addPeriodo = async () => {
        await Axios.post('https://hml.operacao.orion-op.com.br/api/periodo/criar', {
            operacao: id,
            periodo: periodo,
            inicio: inicio,
            berco: berco,
            qtbordo: qtbordo,
            qtterra: qtterra,
            porao: porao,
            moega: equipamento,
            conexo: conexo,
            requisicao: requisicao,
            gerador: gerador,
            grab: grab,
            usuario: usuario,
            dtcadastro: getDate()
        })
            .then(function (res) {
                console.log(res);
                res.data.sqlMessage ?
                    showAlert(res.data.sqlMessage, 'error') :
                    showAlert('Nova Operação cadastrada com sucesso', 'success');
                setTimeout(() => {
                    navigate(`/operacao/${id}`)
                }, 2000);
            })
    }

    const validaDados = async () => {
        if (!grab | !periodo | !qtterra | !berco | !gerador | !equipamento | !qtbordo | !porao | !inicio) {
            showAlert('Preencha todos os campos!', 'error')
            return;
        }

        //addPeriodo() 
        setButtonText('Aguarde...');
        setIsButtonDisabled(true);

        try {
            await addPeriodo();
        } catch (error) {
            console.log(error);
            showAlert('Ocorreu um erro ao cadastrar a nova operação', 'error');
        } finally {
            setButtonText('Enviar');
            setIsButtonDisabled(false);
        }
    }

    useEffect(() => {
        DadosDashboard();
        getVeiculos();
        VerificaParalisacao();

    }, [])

    const [busca, setBusca] = useState("");

    const [existeParalisacao, setExisteParalisacao] = useState(0);
    const [dadosDash, setDadosDash] = useState([]);
    const [veiculos, setVeiculos] = useState([]);
    const [paralisacoes, setParalisacoes] = useState([]);
    const [descarregado, setDescarregado] = useState([]);
    const [relatorios, setRelatorios] = useState([]);

    const [i, setI] = useState({});
    const [peso2, setPeso2] = useState("");
    const [data, setData] = useState("");
    const [obsPesagem, setObsPesagem] = useState("");

    const [pesobruto, setPesoBruto] = useState("");
    const [databruto, setDataBruto] = useState("");

    //modal de 2 pesagem
    const [openA, setOpenA] = useState(false);
    const AbrirPesagem = () => {
        setOpenA(true);
    };
    const FecharPesagem = () => {
        setOpenA(false);
    };

    const [openB, setOpenB] = useState(false);
    const AbrirConfirm = () => {
        setOpenB(true);
    };
    const FecharConfirm = () => {
        setOpenB(false);
    };

    const [MostaInput, setMostaInput] = useState(false);

    const divClick = () => {
        setMostaInput(true);
    };

    const getPeriodo = () => {
        Axios.get(`https://hml.operacao.orion-op.com.br/api/periodos/gerais/${id}`).then((response) => {
            setPeriodo(response.data)
        });
    }
    const VerificaParalisacao = () => {
        Axios.get(`https://hml.operacao.orion-op.com.br/api/verifica/paralisacao/${id}`,)
            .then(function (res) {
                setExisteParalisacao(res.data)
            })
    }
    const DadosDashboard = () => {
        Axios.get(`https://hml.operacao.orion-op.com.br/api/periodo/dashboard/${id}`,)
            .then(function (res) {
                setDadosDash(res.data[0])
                Axios.get(`https://hml.operacao.orion-op.com.br/api/paralisacao/periodo/${res.data[0].SEQ_PERIODO_OP}`,)
                    .then(function (res) {
                        setParalisacoes(res.data)
                    })
            })
    }

    const getVeiculos = () => {
        Axios.get(`https://hml.operacao.orion-op.com.br/api/ultimapesagem/busca/${id}`,)
            .then(function (res) {
                setVeiculos(res.data)
            })
    }

    const downloadNota = async (idCarregamento) => {
        const data = {
            idCarregamento: idCarregamento
        };

        const config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `https://hml.operacao.orion-op.com.br/api/baixarnota`,
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
            },
            data: data
        };

        Axios.request(config)
            .then((res) => {
                var byteCharacters = atob(res.data.pdf.split(',')[res.data.pdf.split(',').length - 1]);
                var byteNumbers = new Array(byteCharacters.length);
                for (var i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                var byteArray = new Uint8Array(byteNumbers);
                var file = new Blob([byteArray], { type: res.data.pdf.split(',')[0].split(":")[1] });

                const url = URL.createObjectURL(file);
                const link = document.createElement('a');
                link.href = url;
                link.download = res.data.filename
                link.click();
                URL.revokeObjectURL(url);
            })
            .catch((err) => {
                showAlert('Erro ao obter PDF', 'error')
                console.log(err.response.data.mensagem)
            })
    }

    const downloadTicket = (carregamento) => {
        const doc = new jsPDF({
            orientation: 'landscape',
            unit: 'cm',
            format: [4, 8]
        });

        // Adicionar o logo no PDF
        doc.addImage(LogoOrion, 'PNG', 0.5, 0.5, 2.5, 0.6); // As dimensões 4x2 são apenas um exemplo; ajuste conforme necessário

        // Adicionar o texto no PDF
        doc.setFontSize(10);
        doc.text(`ID: ${carregamento.ID_CARREGAMENTO}`, 0.5, 1.7);
        doc.text(`DI: ${carregamento.NUMERO_DOC} - PEDIDO: ${carregamento.PEDIDO_MIC}`, 0.5, 2.2);
        doc.text(`Cavalo: ${carregamento.PLACA_CAVALO}`, 0.5, 2.7);
        doc.text(`Peso MOEGA: ${(carregamento.PESO_CARREGADO).toLocaleString()} kg - ${moment(carregamento.DATA_CARREGAMENTO).format('DD/MM/YY HH:mm')}`, 0.5, 3.2);

        doc.save(`ticket_pesagem_${carregamento.ID_CARREGAMENTO}.pdf`);
    }

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return isNaN(date) ? 'Data inválida' : format(date, 'dd/MM - HH:mm');
    };

    const exportRelatorioPDF = () => {
        const doc = new jsPDF();

        // Função para adicionar o cabeçalho em cada página
        const addHeader = (doc, isFirstPage) => {
            if (isFirstPage) {
                doc.addImage(LogoOrion, 'PNG', 10, 10, 50, 10);
            }
            doc.setFontSize(10); // Define o tamanho da fonte para corresponder ao conteúdo da tabela

            const headerText = `Relatório Período: ${relatorios}`;
            doc.text(headerText, isFirstPage ? 70 : 10, isFirstPage ? 20 : 10);

            // Adicionar informações adicionais no cabeçalho usando autoTable
            if (isFirstPage) {
                const tableData = autos.map(val => ({
                    totalAutos: `Autos: ${val.QTDE_AUTOS}`,
                    pesoHibrido: `Peso: ${(val.PESO_HIBRIDO / 1000).toLocaleString('pt-BR', { minimumFractionDigits: 3, maximumFractionDigits: 3 })} Tons`
                }));

                // Extraindo uma única entrada de autos para criar a tabela de totais
                const totalAutos = tableData.length > 0 ? tableData[0].totalAutos : 'Autos: 0';
                const pesoHibrido = tableData.length > 0 ? tableData[0].pesoHibrido : 'Peso: 0 Tons';

                doc.autoTable({
                    head: [['TOTAL', '']],
                    body: [
                        [totalAutos, ''],
                        [pesoHibrido, '']
                    ],
                    startY: 30,
                    theme: 'plain',
                    headStyles: {
                        fillColor: [0, 29, 79], // Azul
                        textColor: [255, 255, 255], // Branco
                        fontSize: 10,
                        fontStyle: 'bold'
                    },
                    bodyStyles: {
                        fontSize: 10
                    },
                    styles: {
                        cellPadding: 2
                    },
                    columnStyles: {
                        0: { cellWidth: 50 }, // Ajuste o valor conforme necessário para o tamanho do texto
                        1: { cellWidth: 10 } // Segunda coluna com largura mínima
                    }
                });
            }
        };

        // Inicializar o cabeçalho para a primeira página
        addHeader(doc, true);

        // Adicionar a tabela principal
        const tableColumn = ["#", "ID", "Nome", "Placa do Cavalo", "Peso Líquido", "DI", "Data Carregado"];
        const tableRows = [];

        operacoes.forEach((val, index) => {
            const rowData = [
                index + 1,
                val.ID_CARREGAMENTO,
                val.NOME_MOTORISTA,
                val.PLACA_CAVALO,
                val.PESO_LIQUIDO.toLocaleString(undefined, { maximumFractionDigits: 2 }),
                val.DOCUMENTO,
                formatDate(val.DATA_CARREGAMENTO)
            ];
            tableRows.push(rowData);
        });

        doc.autoTable({
            head: [tableColumn],
            body: tableRows,
            startY: 55,
            didDrawPage: (data) => {
                // Adicionar o cabeçalho em cada página sem o logo
                if (data.pageNumber > 1) {
                    addHeader(doc, false);
                }
            },
            theme: 'striped',
            headStyles: {
                fontSize: 10, // Correspondência de tamanho da fonte com o cabeçalho
                fillColor: [0, 29, 79], // Azul
                textColor: [255, 255, 255] // Branco
            },
            bodyStyles: {
                fontSize: 10 // Correspondência de tamanho da fonte com o cabeçalho
            }
        });

        doc.save('relatorio_periodo.pdf');
    };

    return (
        <>
            <Navbar relatorios />
            <Header />
            <Brackground />
            <Container>
                <div className={style.content}>
                    <div className={style.nav}>
                        <div className={style.active}>
                            Relatório por Período
                        </div>
                    </div>

                    <div className={style.container}>
                        <div className={style.formulario}>
                            <section className={style.filters}>
                                <p> <center> <b><i class="fas fa-ship"></i>&nbsp;&nbsp;&nbsp;{dadosDash.NOME_NAVIO || "--"}</b> </center> </p>
                                <label>Selecione um período:</label>
                                <div className={style.formControl}>

                                    <select onChange={(e) => { setRelatorios(e.target.value) }}>
                                        <option disabled selected>Selecione uma opção</option>
                                        {periodo?.map(val => (
                                            <option value={val.PERIODO}>{val.PERIODO}</option>
                                        ))}
                                    </select>
                                    <button className={style.submitButton} onClick={getOperacoes}>Buscar</button>
                                </div>

                                <div className={style.selectedPeriod}>
                                    <table className={style.moegaTable}>
                                        <tbody>
                                            {moega.map((val, index) => (
                                                <tr key={index}>
                                                    <td className={style.centered}>
                                                        <div><b>{relatorios}</b> - {val.TERNO} Terno</div>
                                                        <div>{val.MOEGA_1} - Porão {val.PORAO_01}</div>
                                                        {val.MOEGA_2 && val.PORAO_02 &&
                                                            <div>{val.MOEGA_2} - Porão {val.PORAO_02}</div>
                                                        }
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>

                            </section>
                            {moega.some(val => val.CORTE_CHUVA === 'S') ? (
                                <div className={style.chuvaContainer}>
                                    <i className="fa fa-cloud-rain icon" />&nbsp; &nbsp;
                                    <p>CORTE DE PERÍODO POR CHUVA</p>
                                </div>
                            ) : (
                                <div className={style.cardPara}>
                                    <table className={style.cardParalisacao}>
                                        <thead>
                                            <tr>
                                                <th className={style.linha}><abbr title="MOTIVO">Motivo</abbr></th>
                                                <th className={style.linha}><abbr title="INICIO">Ínicio</abbr></th>
                                                <th className={style.linha}><abbr title="TERMINO">Término</abbr></th>
                                                <th className={style.linha}><abbr title="DURACAO">Duração</abbr></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {paralisacao.filter((val) => {
                                                if (busca === "") {
                                                    return true;
                                                } else {
                                                    return val.DESC_MOTIVO.includes(busca) ||
                                                        val.OBSERVACO.includes(busca) ||
                                                        val.HORA_INICIO.includes(busca) ||
                                                        val.HORA_TERMINO.includes(busca) ||
                                                        val.DURACAO.includes(busca);
                                                }
                                            }).map((val) => {
                                                return (
                                                    <tr key={val.DESC_MOTIVO}>
                                                        <th className={style.tooltip}>
                                                            {val.DESC_MOTIVO}
                                                            {(val.OBSERVACO) && (
                                                                <th className={style.tooltiptext}>
                                                                    <table className={style.table2}>
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Observação</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <th>{val.OBSERVACO}</th>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </th>
                                                            )}
                                                        </th>
                                                        <th>{val.HORA_INICIO}</th>
                                                        <th>{val.HORA_TERMINO}</th>
                                                        <th>{val.DURACAO}</th>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            )}

                        </div>
                        {moega.some(val => val.CORTE_CHUVA !== 'S') && (
                            <section className={style.cards}>
                                <div className={style.card}>
                                    <i className="fa fa-truck icon"></i>
                                    <p><b>Carregados</b></p>
                                    {autos.map(val => (
                                        <span className={style.total}>{val.QTDE_AUTOS} Autos</span>
                                    ))}
                                </div>

                                <div className={style.card}>
                                    <i className="fa fa-battery-three-quarters icon"></i>
                                    <p><b>Peso Líquido</b></p>
                                    {autos.map(val => (
                                        <span className={style.total}>
                                            {(val.PESO_HIBRIDO / 1000).toLocaleString('pt-BR', { minimumFractionDigits: 3, maximumFractionDigits: 3 })} Tons
                                        </span>
                                    ))}
                                </div>

                                <div className={style.card}>
                                    <i className="fa fa-file-text icon"></i>
                                    <p><b>Peso por DI / BL</b></p>
                                    {documentos.map(val => (
                                        <div className={style.details}>
                                            <span>{val.DOC_CARGA}</span> | {(val.PESO_LIQUIDO_CARGA / 1000).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} Tons | {val.QTDE_AUTOS_CARGA} Autos
                                        </div>
                                    ))}
                                </div>

                                <div className={style.card}>
                                    <i className="fa fa-clock-o icon"></i>
                                    <p><b>Hora Hora</b></p>
                                    {hora.map(val => (
                                        <div className={style.details}>
                                            <b>{val.HORA}</b> | {val.QUANTIDADE_AUTOS} Autos
                                        </div>
                                    ))}
                                </div>

                            </section>
                        )}
                        {moega.some(val => val.CORTE_CHUVA !== 'S') && (

                            <section className={style.search}>

                                <div className={style.dataFiltro}>
                                    <i
                                        onClick={exportRelatorioPDF}
                                        className="fa fa-file-pdf icon custom-pdf-icon"
                                        style={{ fontSize: '1.5em', color: '#001d49', cursor: 'pointer' }}
                                    />
                                </div>
                                <div className={style.searchBox}>
                                    <label htmlFor="searchInput" className="visuallyHidden"></label>
                                    <input
                                        id="searchInput"
                                        placeholder="Pesquisar..."
                                        type="text"
                                        onChange={(e) => { setBusca(e.target.value) }}
                                    />
                                </div>
                            </section>
                        )}
                    </div>

                    {moega.some(val => val.CORTE_CHUVA !== 'S') && (
                        <table className={style.table}>
                            <thead>
                                <tr>
                                    <th className={style.sequencial}>#</th>
                                    <th><abbr title="ID">ID</abbr></th>
                                    <th><abbr title="Nome">Nome</abbr></th>
                                    <th><abbr title="Placa do Cavalo">Placa (Cavalo)</abbr></th>
                                    <th><abbr title="Tara">1° Peso (Tara)</abbr></th>
                                    <th><abbr title="Segundo Peso">2° Peso (Moega)</abbr></th>
                                    <th><abbr title="Terceiro Peso">3° Peso (Bruto)</abbr></th>
                                    <th><abbr title="Peso Líquido">Peso Líquido</abbr></th>
                                    <th><abbr title="Documento">DI</abbr></th>
                                    <th><abbr title="Data Carregado">Data Carregado</abbr></th>
                                    <th><abbr title="(Liquido - Moega)">Diferença</abbr></th>
                                    <th><abbr title="%">Dif._(%)</abbr></th>
                                    <th><abbr title="Ticket">Ticket</abbr></th>
                                    <th><abbr title="Nota">N.F</abbr></th>
                                </tr>
                            </thead>

                            <tbody>
                                {operacoes
                                    .filter((val) => {
                                        let horario = moment(val.DATA_CARREGAMENTO).format('DD/MM HH:mm');
                                        if (busca === "") {
                                            return val;
                                        } else if (
                                            String(val.ID_CARREGAMENTO).toLowerCase().includes(busca.toLowerCase()) ||
                                            val.NOME_MOTORISTA.toLowerCase().includes(busca.toLowerCase()) ||
                                            val.PLACA_CAVALO.toLowerCase().includes(busca.toLowerCase()) ||
                                            String(val.PESO_TARA).toLowerCase().includes(busca.toLowerCase()) ||
                                            String(val.PESO_CARREGADO).toLowerCase().includes(busca.toLowerCase()) ||
                                            String(val.PESO_BRUTO).toLowerCase().includes(busca.toLowerCase()) ||
                                            String(val.PESO_LIQUIDO).toLowerCase().includes(busca.toLowerCase()) ||
                                            String(val.DOCUMENTO).toLowerCase().includes(busca.toLowerCase()) ||
                                            String(val.DATA_CARREGAMENTO).toLowerCase().includes(busca.toLowerCase()) ||
                                            String(val.DIFERENCA).toLowerCase().includes(busca.toLowerCase()) ||
                                            String(val.PERCENTUAL).toLowerCase().includes(busca.toLowerCase())
                                        ) {
                                            return val;
                                        }
                                    })
                                    .map((val, index) => {
                                        const isStatusSete = val.STATUS_NOTA_MIC === 7;
                                        const notaDownloadClass = [2, 3, 4, 6].includes(val.STATUS_NOTA_MIC)
                                            ? style.nota_download
                                            : style.nota_download_empty;
                                        const rowClasses = `${val.PESO_BRUTO === 0 ? style.yellowbackground : ''} ${isStatusSete ? style.strikethrough : ''}`;

                                        return (
                                            <tr key={val.ID_CARREGAMENTO} className={rowClasses}>
                                                <td className={style.sequencial}>{index + 1}</td> {/* Adiciona a numeração sequencial */}
                                                <td>{val.ID_CARREGAMENTO}</td>
                                                <td>{val.NOME_MOTORISTA}</td>
                                                <td className={style.tooltip}>
                                                    {val.PLACA_CAVALO}
                                                    {(val.PLACA_CARRETA || val.PLACA_CARRETA2) && (
                                                        <div className={style.tooltiptext}>
                                                            <table className={style.table2}>
                                                                <thead>
                                                                    <tr>
                                                                        <th>Carreta_1</th>
                                                                        <th>Carreta_2</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>{val.PLACA_CARRETA}</td>
                                                                        <td>{val.PLACA_CARRETA2}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    )}
                                                </td>

                                                <td>{(val.PESO_TARA).toLocaleString(undefined, { maximumFractionDigits: 2 })}</td>
                                                <td>{(val.PESO_CARREGADO).toLocaleString(undefined, { maximumFractionDigits: 2 })}</td>
                                                <td>{(val.PESO_BRUTO).toLocaleString(undefined, { maximumFractionDigits: 2 })}</td>
                                                <td>{(val.PESO_LIQUIDO).toLocaleString(undefined, { maximumFractionDigits: 2 })}</td>
                                                <td>{val.DOCUMENTO}</td>
                                                <td>{formatDate(val.DATA_CARREGAMENTO)}</td>
                                                <td>{val.DIFERENCA} kg</td>
                                                <td>{val.PERCENTUAL} %</td>
                                                <td>
                                                    <i
                                                        onClick={() => downloadTicket(val)}
                                                        style={{ cursor: "pointer", color: "auto" }}
                                                        className="fa fa-file-pdf icon"
                                                    />
                                                </td>
                                                <td className={notaDownloadClass}>
                                                    <i
                                                        onClick={() => [2, 3, 4, 6].includes(val.STATUS_NOTA_MIC) ? downloadNota(val.ID_CARREGAMENTO) : undefined}
                                                        style={[2, 3, 4, 6].includes(val.STATUS_NOTA_MIC) ? { color: "auto" } : { color: "#bcbcbc" }}
                                                        className="fa fa-file-pdf icon"
                                                    />
                                                </td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </table>
                    )}
                </div>
            </Container>
        </>
    );
};

export default function IntegrationNotistack() {
    return (
        <SnackbarProvider
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            maxSnack={3}
            autoHideDuration={2500}>
            <RelatorioSetPort />
        </SnackbarProvider >
    );
}