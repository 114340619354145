// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Dash_centerLogo__pCjOa {\n  text-align: center;\n}\n\n.Dash_logoImage__yvfHI {\n  width: 200px; /* Adjust the size as needed */\n}\n\n.Dash_videoWrapper__48MUH {\n  text-align: center;\n  margin-top: 20px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/Dashboard/Dash.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,YAAY,EAAE,8BAA8B;AAC9C;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;AAClB","sourcesContent":[".centerLogo {\n  text-align: center;\n}\n\n.logoImage {\n  width: 200px; /* Adjust the size as needed */\n}\n\n.videoWrapper {\n  text-align: center;\n  margin-top: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"centerLogo": "Dash_centerLogo__pCjOa",
	"logoImage": "Dash_logoImage__yvfHI",
	"videoWrapper": "Dash_videoWrapper__48MUH"
};
export default ___CSS_LOADER_EXPORT___;
