import React, { useState } from 'react';
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { SnackbarProvider, useSnackbar } from 'notistack';
import Navbar from '../../../components/Navbar';
import Background from '../../../components/Background';
import Container from '../../../components/Container';
import Header from '../../../components/Header';
import SubmitButton from '../../../components/Button';
import MaskedInput from '../../../components/InputMask';
import style from './BuscarMotoristaPlaca.module.css';

const BuscarMotoristaPlaca = () => {
  const navigate = useNavigate();
  const [buscas, setBuscas] = useState('');
  const [motorista, setMotorista] = useState({});
  const { enqueueSnackbar } = useSnackbar();

  const getMotorista = () => {
    if (!buscas.trim()) {
      showAlert('Por favor, digite uma placa válida.', 'error');
      return;
    }
  
    Axios.get(`https://hml.operacao.orion-op.com.br/api/motorista/placa/${buscas.toUpperCase()}`)
      .then((res) => {
        if (res.data && res.data.CPF_MOTORISTA) {
          setMotorista(res.data);
          showAlert('Dados do motorista encontrados.', 'success');
        } else {
          setMotorista({});
          showAlert('Motorista não encontrado com a placa fornecida.', 'error');
        }
      })
      .catch(err => {
        setMotorista({});
        const errorMessage = err.response ? err.response.data.message : err.message;
        showAlert(`Erro ao buscar dados: ${errorMessage}`, 'error');
      });
  };
  
  

  const showAlert = (txt, variant) => {
    enqueueSnackbar(txt, { variant });
  };

  const validaTecla = (e) => {
    if (e.key === 'Enter') {
      validaDados();
    }
  };

  const validaDados = () => {
    
      getMotorista();
    
  };

  return (
    <>
      <Navbar veiculos />
      <Header />
      <Background />
      <Container>
        <div className={style.content}>
          <div className={style.nav}>
            <div onClick={() => navigate('/veiculos/BuscarMotorista')}>Motorista</div>
            <div onClick={() => navigate('/veiculos')}>Cadastrar Motorista</div>
            <div className={style.active}>Motorista Placa</div>
          </div>

          <div className="columns">
            <div className="column is-4">
            <MaskedInput
  text="Buscar PLACA"
  mask="AAA0A00"
  placeholder="XXX0X00"
  value={buscas} // Adicionando a propriedade value
  onChange={(e) => setBuscas(e.target.value.toUpperCase())}
  onKeyPress={validaTecla}
/>


              <SubmitButton text="Buscar" onClick={validaDados} />
            </div>
            {motorista.CPF_MOTORISTA && (
  <div className="column is-8">
    <div className={style.box}>
      <strong>CPF do Motorista:</strong> {motorista.CPF_MOTORISTA}
    </div>
  </div>
)}


          </div>
        </div>
      </Container>
    </>
  );
};

export default function IntegrationNotistack() {
  return (
    <SnackbarProvider
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      maxSnack={3}
      autoHideDuration={2500}
    >
      <BuscarMotoristaPlaca />
    </SnackbarProvider>
  );
}
