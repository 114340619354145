import { Chart as ChartJS, BarElement, CategoryScale, LinearScale } from 'chart.js'
import { Bar } from 'react-chartjs-2'
import React, { useEffect } from "react";
import { useState } from "react";
import Axios from 'axios';
import { useParams } from "react-router-dom";
import { bottomNavigationActionClasses, stepLabelClasses } from '@mui/material';
import { height } from '@mui/system';
import style from './GraficoPercent.module.css'

const GraficoPercent = ({ docs }) => {
    return (
        <div className={style.gfbox}>
            <div className={style.gftitle}>
                Quantidade descarregada por (DI/BL)
            </div>
            <div className={style.gfcoluns}>
                {docs.map((val, index) => {
                    let saldo = val.PERC.toFixed(2);

                    // Definir a variável CSS --saldo com o valor do saldo atual
                    const barStyle = {
                        "--saldo": saldo + "%"
                    };

                    return (
                        <div className={style.gfcolum} style={barStyle} key={index}>
                            <div className={style.grpercent}>{saldo} %</div>
                            <div className={style.grbackbar}>
                                <div
                                    className={saldo >= 90 ? `${style.grred}` : `${style.grblue}`}>
                                </div>
                            </div>
                            <div className={style.grdesc}>
                                <div className={style.DI_BL}> <b> {val.TIPO_DOC}: </b> {val.NUMERO_DOC} </div>
                                <br />
                                <div className={style.NOME_REDUZIDO}> <b>{val.NOME_REDUZIDO}</b> </div>
                                <br />
                                <b>Saldo:</b> {(val.SALDO / 1000).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2, })} Tons
                                <br />
                                <b>Carregado:</b> {(val.PESO_CARREGADO / 1000).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2, })} Tons
                                <div className={style.linha}></div>
                                <div className={style.manifestado}>
                                    <b>MANIFESTADO:</b> {(val.MANIFESTADO / 1000).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2, })} Tons
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default GraficoPercent;
