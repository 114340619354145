import React, { useState, useEffect } from "react";
import Navbar from "../../../components/Navbar";
import Brackground from "../../../components/Background";
import Container from "../../../components/Container";
import Header from "../../../components/Header";
import style from "./AberturaPeriodo.module.css";
import SubmitButton from "../../../components/Button";
import { SnackbarProvider, useSnackbar } from "notistack";
import Input from "../../../components/Input";
import { useNavigate, useParams } from "react-router-dom";
import Axios from "axios";

const AberturaPeriodo = () => {
  useEffect(() => {
    getEquipamentos();
    getPeriodos();
    getBercos();
    VerificaPeriodo();
  }, []);

  let { id } = useParams();
  const [equipamento, setEquipamento] = useState("");
  const [periodo, setPeriodo] = useState("");
  const [berco, setBerco] = useState("");
  const [qtbordo, setQtBordo] = useState("");
  const [qtterra, setQtTerra] = useState("");
  const [gerador, setGerador] = useState("");
  const [grab, setGrab] = useState("");
  const [porao, setPorao] = useState("");
  const [porao_02, setPorao02] = useState("");
  const [terno, setTerno] = useState("");
  const [showPorao02, setShowPorao02] = useState(false);
  const [requisicao, setRequisicao] = useState("");
  const [conexo, setConexo] = useState("");
  const [inicio, setInicio] = useState("");
  const [existePeriodo, setExistePeriodo] = useState(0);
  const usuario = JSON.parse(localStorage.getItem("user_token")).id;
  const [showMoega2, setShowMoega2] = useState(false);
  const [equipamento2, setEquipamento2] = useState(null);
  const [corteChuva, setCorteChuva] = useState(false);
  const [corteOperador, setCorteOperador] = useState(false);

  const [equipamentos, setEquipamentos] = useState([]);
  const [periodos, setPeriodos] = useState([]);
  const [bercos, setBercos] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [buttonText, setButtonText] = useState("Cadastrar");
  const navigate = useNavigate();

  const handlePoraoChange = (e) => {
    const novoValorPorao = e.target.value;
    setPorao(novoValorPorao);
    setTerno(novoValorPorao ? "1" : "");
  };

  const handlePorao2Change = (e) => {
    const novoValorPorao2 = e.target.value;
    setPorao02(novoValorPorao2);

    if (novoValorPorao2) {
      setTerno("2");
    } else if (porao) {
      setTerno("1");
    } else {
      setTerno("");
    }
  };

  const toggleElements = () => {
    setShowPorao02(!showPorao02);
    setShowMoega2(!showMoega2);
  };

  const getDate = () => {
    const date = new Date();
    date.setHours(date.getHours() - 3);
    return date.toISOString().slice(0, 19).replace("T", " ");
  };

  const getEquipamentos = () => {
    Axios.get("https://hml.operacao.orion-op.com.br/api/equipamentos").then((response) => {
      setEquipamentos(response.data);
    });
  };
  const getPeriodos = () => {
    Axios.get("https://hml.operacao.orion-op.com.br/api/periodos/horarios").then((response) => {
      setPeriodos(response.data);
    });
  };
  const getBercos = () => {
    Axios.get("https://hml.operacao.orion-op.com.br/api/bercos").then((response) => {
      setBercos(response.data);
    });
  };

  const { enqueueSnackbar } = useSnackbar();
  const showAlert = (txt, variant) => {
    enqueueSnackbar(txt, { variant: variant });
  };

  const VerificaPeriodo = () => {
    Axios.get(`https://hml.operacao.orion-op.com.br/api/periodo/busca/${id}`).then(function (res) {
      setExistePeriodo(res.data[0].EXISTE);
    });
  };

  const periodsData = [
    {
      COD_PERIODO: 1,
      DEN_PERIODO: "01h⁰⁰/07h⁰⁰",
      INI_PERIODO: "01:00",
      FIM_PERIODO: "07:00",
    },
    {
      COD_PERIODO: 2,
      DEN_PERIODO: "07h⁰⁰/13h⁰⁰",
      INI_PERIODO: "07:00",
      FIM_PERIODO: "13:00",
    },
    {
      COD_PERIODO: 3,
      DEN_PERIODO: "13h⁰⁰/19h⁰⁰",
      INI_PERIODO: "13:00",
      FIM_PERIODO: "19:00",
    },
    {
      COD_PERIODO: 4,
      DEN_PERIODO: "19h⁰⁰/01h⁰⁰",
      INI_PERIODO: "19:00",
      FIM_PERIODO: "01:00",
    },
  ];

  const calculateEndDateTime = (inicio) => {
    const startDateTime = new Date(inicio);
    // Adiciona 6 horas ao início
    startDateTime.setTime(startDateTime.getTime() + 6 * 60 * 60 * 1000);
    return startDateTime.toISOString().slice(0, 19).replace("T", " ");
  };

  const validaDados = async () => {
    if (corteChuva || corteOperador) {
      if (!periodo || !berco || !inicio) {
        showAlert("Preencha todos os campos obrigatórios!", "error");
        return;
      }
    } else {
      if (!grab || !periodo || !qtterra || !berco || !gerador || !equipamento || !qtbordo || !porao || !inicio) {
        showAlert("Preencha todos os campos!", "error");
        return;
      }

      if (equipamento == equipamento2) {
        showAlert("Os dois Ternos não podem ter a mesma moega em operação!", "error");
        return;
      }

      if (porao == porao_02) {
        showAlert("Os dois Ternos não podem ter o mesmo porão em operação!", "error");
        return;
      }

      const selectedPeriod = periodsData.find((period) => period.COD_PERIODO === parseInt(periodo));
      if (selectedPeriod) {
        const horaInicio = new Date(inicio).getHours();
        const horaInicioPeriodo = parseInt(selectedPeriod.INI_PERIODO.split(":")[0]);

        if (horaInicio !== horaInicioPeriodo) {
          showAlert("A hora de início deve ser igual à hora de início do período selecionado.", "error");
          return;
        }
      }
    }

    setButtonText("Aguarde...");
    setIsButtonDisabled(true);

    try {
      const alreadyRegistered = await checkPeriodoRegistrado();
      if (alreadyRegistered) {
        showAlert("Período já registrado com esta data e período", "error");
        setButtonText("Cadastrar");
        setIsButtonDisabled(false);
      } else {
        await addPeriodo();
      }
    } catch (error) {
      console.log(error);
      showAlert("Ocorreu um erro ao verificar ou cadastrar a nova operação", "error");
      setButtonText("Cadastrar");
      setIsButtonDisabled(false);
    }
  };

  const checkPeriodoRegistrado = async () => {
    try {
      const response = await Axios.get(`https://hml.operacao.orion-op.com.br/api/periodo/registro/${id}/${periodo}/${inicio.split('T')[0]}`);
      return response.data.length > 0;
    } catch (error) {
      console.error("There was an error checking the period registration!", error);
      throw error;
    }
  };

  const addPeriodo = async () => {
    const fimPeriodo = corteChuva ? calculateEndDateTime(inicio) : null;
    const fimPeriodoCorteOperador = corteOperador ? calculateEndDateTime(inicio) : null;

    await Axios.post("https://hml.operacao.orion-op.com.br/api/periodo/criar", {
      operacao: id,
      periodo: periodo,
      inicio: inicio,
      berco: berco,
      qtbordo: qtbordo === "" ? null : qtbordo,
      qtterra: qtterra === "" ? null : qtterra,
      porao: porao === "" ? null : porao,
      porao_02: porao_02 === "" ? null : porao_02,
      terno: terno === "" ? null : terno,
      moega: equipamento === "" ? null : equipamento,
      moega_2: equipamento2 === "" ? null : equipamento2,
      conexo: conexo === "" ? null : conexo,
      requisicao: requisicao === "" ? null : requisicao,
      gerador: gerador === "" ? null : gerador,
      grab: grab === "" ? null : grab,
      usuario: usuario,
      dtcadastro: getDate(),
      corteChuva: corteChuva ? 'S' : null,
      corteOperador: corteOperador ? 'S' : null,
      fimPeriodo: fimPeriodo || fimPeriodoCorteOperador,
    })
      .then(function (res) {
        console.log(res);
        res.data.sqlMessage
          ? showAlert(res.data.sqlMessage, "error")
          : showAlert("Nova Operação cadastrada com sucesso", "success");
        setTimeout(() => {
          navigate(`/operacao/${id}`);
        }, 2000);
      })
      .catch(function (error) {
        console.error("There was an error!", error);
        showAlert("Ocorreu um erro ao cadastrar a nova operação", "error");
      });
  };

  const handleCorteChuvaChange = (e) => {
    setCorteChuva(e.target.checked);
    if (e.target.checked) {
      setCorteOperador(false);
    }
  };

  const handleCorteOperadorChange = (e) => {
    setCorteOperador(e.target.checked);
    if (e.target.checked) {
      setCorteChuva(false);
    }
  };


  return (
    <>
      <Navbar operacao />
      <Header />
      <Brackground />
      <Container>
        <div className={style.content}>
          <div className={style.nav}>
            <div onClick={() => navigate(`/operacoes`)}>Viagens</div>
            <div onClick={() => navigate(`/operacao/${id}`)}>2° Pesagem</div>
            <div onClick={() => navigate(`/operacao/pesagemfinal/${id}`)}>3° Pesagem</div>
            <div onClick={() => navigate(`/operacao/GraficoDocs/${id}`)}>DashBoard</div>
            <div className={style.active}>Abertura de Período</div>
          </div>
          {existePeriodo >= 1 ? (
            <div className={style.notform}>Encerre o período atual para iniciar um novo!</div>
          ) : (
            <div className={style.modal}>
              <div className={"columns"}>
                <div className={"column"}>
                  <div className={style.flexContainer}>
                    <div className={style.form_control}>
                      <label>Período:</label>
                      <select onChange={(e) => setPeriodo(e.target.value)}>
                        <option disabled selected>Escolha o Período</option>
                        {periodos?.map((val) => (
                          <option value={val.COD_PERIODO}>{val.DEN_PERIODO}</option>
                        ))}
                      </select>
                    </div>
                    <div className={style.form_control}>
                      <label>Berço:</label>
                      <select onChange={(e) => setBerco(e.target.value)}>
                        <option disabled selected>Escolha o Berço</option>
                        {bercos?.map((val) => (
                          <option value={val.COD_BERCO}>{val.NOME_BERCO}</option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className={style.selecti}>
                    <div className="control">
                      <div>Grab</div>
                      <label className="radio">
                        <input
                          type="radio"
                          name="grab"
                          value={"PRÓPRIO"}
                          onChange={(e) => setGrab(e.target.value)}
                          disabled={corteChuva || corteOperador}
                        />{" "}
                        Próprio
                      </label>
                      <label className="radio">
                        <input
                          type="radio"
                          name="grab"
                          value={"ALUGADO"}
                          onChange={(e) => setGrab(e.target.value)}
                          disabled={corteChuva || corteOperador}
                        />{" "}
                        Alugado
                      </label>
                    </div>
                  </div>

                  <div className={style.selecti}>
                    <div className="control">
                      <div>Gerador</div>
                      <label className="radio">
                        <input
                          type="radio"
                          name="gerador"
                          value={"PRÓPRIO"}
                          onChange={(e) => setGerador(e.target.value)}
                          disabled={corteChuva || corteOperador}
                        />{" "}
                        Próprio
                      </label>
                      <label className="radio">
                        <input
                          type="radio"
                          name="gerador"
                          value={"ALUGADO"}
                          onChange={(e) => setGerador(e.target.value)}
                          disabled={corteChuva || corteOperador}
                        />{" "}
                        Alugado
                      </label>
                    </div>
                  </div>
                </div>
                <div className={"column"}>
                  <div className={style.flexContainer}>
                    <div className={style.form_control}>
                      <div className={style.labelSelectCombo}>
                        <label>Moega:</label>
                        <select onChange={(e) => setEquipamento(e.target.value)} disabled={corteChuva || corteOperador}>
                          <option disabled selected>Qual Moega?</option>
                          {equipamentos?.map((val) => (
                            <option value={val.COD_EQUIPAMENTO}>{val.DESC_EQUIPAMENTO}</option>
                          ))}
                        </select>
                      </div>
                    </div>

                    {showMoega2 && (
                      <div className={style.form_control}>
                        <div className={style.labelSelectCombo}>
                          <label>Moega 2:</label>
                          <select onChange={(e) => setEquipamento2(e.target.value)} disabled={corteChuva || corteOperador}>
                            <option disabled selected>Qual Moega?</option>
                            {equipamentos?.map((val) => (
                              <option value={val.COD_EQUIPAMENTO}>{val.DESC_EQUIPAMENTO}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className={style.flexContainer}>
                    <div className={style.form_control2}>
                      <Input
                        text="Porão 1"
                        type="number"
                        value={porao}
                        onChange={handlePoraoChange}
                        disabled={corteChuva || corteOperador}
                        className="inputField"
                      />
                    </div>
                    {showPorao02 && (
                      <div className={style.form_control2}>
                        <Input
                          text="Porão 2"
                          type="number"
                          value={porao_02}
                          onChange={handlePorao2Change}
                          disabled={corteChuva || corteOperador}
                        />
                      </div>
                    )}
                  </div>

                  <button
                    className={style.smallButton}
                    onClick={toggleElements}
                    disabled={corteChuva || corteOperador}
                  >
                    +
                  </button>

                  <div className={style.flexContainer}>
                    <div className={style.flexItem}>
                      <Input
                        text="Homens a Bordo"
                        type="number"
                        onChange={(e) => setQtBordo(e.target.value)}
                        disabled={corteChuva || corteOperador}
                      />
                    </div>
                    <div className={style.flexItem}>
                      <Input
                        text="Homens em terra"
                        type="number"
                        onChange={(e) => setQtTerra(e.target.value)}
                        disabled={corteChuva || corteOperador}
                      />
                    </div>
                  </div>
                  <p className={style.ternoInfo}>* Ao inserir um porão, você informa que há somente 1 Terno.<br />
                    <em>Caso insira dois porões,</em> o mesmo informa que há 2 Ternos.
                  </p>
                </div>
                <div className={"column is-4"}>
                  <div className={style.selecti}>
                    Requisição
                    <div>
                      <label className="radio">
                        <input
                          type="radio"
                          name="requisicao"
                          value={"EMPILHADEIRA"}
                          onChange={(e) => setRequisicao(e.target.value)}
                          disabled={corteChuva || corteOperador}
                        />{" "}
                        Empilhadeira
                      </label>
                    </div>
                    <label className="radio">
                      <input
                        type="radio"
                        name="requisicao"
                        value={"ESCAVADEIRA"}
                        onChange={(e) => setRequisicao(e.target.value)}
                        disabled={corteChuva || corteOperador}
                      />{" "}
                      Escavadeira
                    </label>

                    <div className={style.conexo}>
                      Conexo
                      <div>
                        <label className="radio">
                          <input
                            type="radio"
                            name="conexo"
                            value={"ARRUMAÇÃO DE SERRAPILHEIRA"}
                            onChange={(e) => setConexo(e.target.value)}
                            disabled={corteChuva || corteOperador}
                          />{" "}
                          Arrumação da Serrapilheira
                        </label>
                      </div>
                      <label className="radio">
                        <input
                          type="radio"
                          name="conexo"
                          value={"MUDANÇA DE BERÇO/PUXADA"}
                          onChange={(e) => setConexo(e.target.value)}
                          disabled={corteChuva || corteOperador}
                        />{" "}
                        Mudança de Berço/Puxada
                      </label>
                    </div>
                  </div>
                  <Input
                    text={"Data e Hora de início"}
                    type={"datetime-local"}
                    onChange={(e) => setInicio(e.target.value)}
                  />

                  <div className={style.corteChuvaContainer}>
                    <label className={style.corteChuvaLabel}>
                      <input
                        type="checkbox"
                        checked={corteChuva}
                        onChange={handleCorteChuvaChange}
                      />
                      Corte de Chuva
                    </label>

                    <label className={style.corteChuvaLabel}>
                      <input
                        type="checkbox"
                        checked={corteOperador}
                        onChange={handleCorteOperadorChange}
                      />
                      Corte Por Operador
                    </label>
                  </div>


                  <div className={style.submit}>
                    <SubmitButton text={buttonText} onClick={validaDados} disabled={isButtonDisabled} className={style.form_item} />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </Container>
    </>
  );
};

export default function IntegrationNotistack() {
  return (
    <SnackbarProvider anchorOrigin={{ vertical: "bottom", horizontal: "right" }} maxSnack={3} autoHideDuration={2500}>
      <AberturaPeriodo />
    </SnackbarProvider>
  );
}
