import React, { useState, useEffect } from "react";
import Navbar from "../../../components/Navbar";
import Brackground from "../../../components/Background";
import Container from "../../../components/Container";
import Header from "../../../components/Header";
import style from "./HistoricoForms.module.css";
import Axios from "axios";
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { SnackbarProvider, useSnackbar } from "notistack";
import Modal from "react-modal";
import modalStyles from "./Modal.module.css";

const HistoricoForms = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [inspections, setInspections] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedInspectionItems, setSelectedInspectionItems] = useState([]);
  const [modalTitle, setModalTitle] = useState("");
  const [assinaturaSupervisor, setAssinaturaSupervisor] = useState("");
  const [assinaturaManutencao, setAssinaturaManutencao] = useState("");
  const [anexos, setAnexos] = useState([]);
  const [currentUser, setCurrentUser] = useState(""); // Para armazenar o usuário atual
  const [validationChecked, setValidationChecked] = useState(false); // Para controlar o radio button

  const showAlert = (message, variant) => {
    enqueueSnackbar(message, { variant: variant });
  };

  useEffect(() => {
    Axios.get("https://hml.operacao.orion-op.com.br/api/inspections")
      .then((response) => {
        const groupedInspections = response.data.reduce((acc, inspection) => {
          const existing = acc.find(item => item.ID_HISTORICO === inspection.ID_HISTORICO);
          if (existing) {
            existing.CATEGORIA_SEG_FORMULARIO += `, ${inspection.CATEGORIA_SEG_FORMULARIO}`;
          } else {
            acc.push(inspection);
          }
          return acc;
        }, []);
        setInspections(groupedInspections);
      })
      .catch((error) => {
        console.error("Erro ao buscar as inspeções: ", error);
        showAlert("Erro ao buscar as inspeções", "error");
      });
  }, []);

  const openModal = (inspectionId, navioNome) => {
    Axios.get(`https://hml.operacao.orion-op.com.br/api/inspection/items/${inspectionId}`)
      .then((response) => {
        const { CAMINHO_ASSINATURA_SUPERVISOR, CAMINHO_ASSINATURA_MANUTENCAO, CAMINHO_ANEXO_01, CAMINHO_ANEXO_02, CAMINHO_ANEXO_03 } = response.data[0];
        setSelectedInspectionItems(response.data);
        setModalTitle(`Detalhes da Inspeção - Navio: ${navioNome}`);

        // Configurar URLs das assinaturas
        if (CAMINHO_ASSINATURA_SUPERVISOR) {
          const supervisorPath = CAMINHO_ASSINATURA_SUPERVISOR.split('\\').pop();
          setAssinaturaSupervisor(`https://hml.operacao.orion-op.com.br/api/assinaturas/${supervisorPath}`);
        }
        if (CAMINHO_ASSINATURA_MANUTENCAO) {
          const manutencaoPath = CAMINHO_ASSINATURA_MANUTENCAO.split('\\').pop();
          setAssinaturaManutencao(`https://hml.operacao.orion-op.com.br/api/assinaturas/${manutencaoPath}`);
        }

        // Configurar URLs dos anexos
        const anexosArray = [];
        if (CAMINHO_ANEXO_01) {
          const anexo1Path = CAMINHO_ANEXO_01.split('\\').pop();
          anexosArray.push(`https://hml.operacao.orion-op.com.br/api/assinaturas/${anexo1Path}`);
        }
        if (CAMINHO_ANEXO_02) {
          const anexo2Path = CAMINHO_ANEXO_02.split('\\').pop();
          anexosArray.push(`https://hml.operacao.orion-op.com.br/api/assinaturas/${anexo2Path}`);
        }
        if (CAMINHO_ANEXO_03) {
          const anexo3Path = CAMINHO_ANEXO_03.split('\\').pop();
          anexosArray.push(`https://hml.operacao.orion-op.com.br/api/assinaturas/${anexo3Path}`);
        }
        setAnexos(anexosArray);

        setModalIsOpen(true);
      })
      .catch((error) => {
        console.error("Erro ao buscar os itens da inspeção: ", error);
        showAlert("Erro ao buscar os itens da inspeção", "error");
      });
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedInspectionItems([]);
    setAssinaturaSupervisor("");
    setAssinaturaManutencao("");
    setAnexos([]);
  };

  return (
    <>
      <Navbar veiculos />
      <Header />
      <Brackground />
      <Container>
        <div className={style.content}>
          <div className={style.nav}>
            <div onClick={() => navigate("/segtrabalho/forms")}>
              Formulário de Inspeção
            </div>
            <div className={`${style.navItem} ${style.active}`}>
              Histórico de Inspeções
            </div>
          </div>
          <table className={style.table}>
            <thead>
              <tr>
                <th>ID</th>
                <th>NAVIO</th>
                <th>TIPO DE INSPEÇÃO</th>
                <th>SUPERVISOR</th>
                <th>MANUTENÇÃO</th>
                <th>DATA DE REGISTRO</th>
              </tr>
            </thead>
            <tbody>
              {inspections.map((inspection) => (
                <tr key={inspection.ID_HISTORICO} onClick={() => openModal(inspection.ID_HISTORICO, inspection.NOME_NAVIO)}>
                  <td>{inspection.ID_HISTORICO}</td>
                  <td>{inspection.NOME_NAVIO}</td>
                  <td>{inspection.CATEGORIA_SEG_FORMULARIO.split(',')[0]}</td> {/* Exibindo apenas o primeiro tipo de inspeção */}
                  <td>{inspection.SUPERVISOR}</td>
                  <td>{inspection.MANUTENCAO}</td>
                  <td>{moment(inspection.DATA_CADASTRO).format('DD/MM/YY HH:mm')}</td>
                </tr>
              ))}
            </tbody>

          </table>
        </div>
      </Container>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Detalhes da Inspeção"
        className={modalStyles.modal}
        overlayClassName={modalStyles.overlay}
      >
        <div className={style.nav}>
            Detalhes da Inspeção
          </div>

            {/* Mostrar o radio button apenas para os usuários Natalia e Lucas */}
  {(currentUser === 'natalia' || currentUser === 'lucas') && (
    <div className={modalStyles.validator}>
      <label>
        <input type="radio" name="validateForm" /> Deseja Validar o Formulário?
      </label>
    </div>
  )}
          {selectedInspectionItems.length > 0 && (
    <>
      <p><strong>Navio:</strong> {selectedInspectionItems[0].NOME_NAVIO}</p>
      <p><strong>Tipo de Inspeção:</strong> {selectedInspectionItems[0].CATEGORIA_SEG_FORMULARIO.split(',')[0]}</p>
    </>
  )}

        <div className={modalStyles.modalcontent}>
          <table className={modalStyles.table}>
            <thead>
              <tr>
                <th>ITEM</th>
                <th>STATUS</th>
              </tr>
            </thead>
            <tbody>
              {selectedInspectionItems.map((item, index) => (
                <tr key={index}>
                  <td>{item.NOME_ITEM}</td>
                  <td>{item.STATUS_ITEM}</td>
                </tr>
              ))}
            </tbody>
          </table>

          {selectedInspectionItems.length > 0 && (
            <p>
              <strong>Observações:</strong> {selectedInspectionItems[0].OBSERVACAO_01}
            </p>
          )}

          {/* Exibir anexos */}
          {anexos.length > 0 && (
            <div className={modalStyles.anexoContainer}>
              {anexos.map((anexo, index) => (
                <div key={index} className={modalStyles.anexoColumn}>
                  <p><strong>Anexo {index + 1}:</strong></p>
                  <img src={anexo} alt={`Anexo ${index + 1}`} className={modalStyles.anexoImage} />
                </div>
              ))}
            </div>
          )}

          <div className={modalStyles.signatureContainer}>
            {assinaturaSupervisor && (
              <div className={modalStyles.signatureColumn}>
                <img src={assinaturaSupervisor} alt="Assinatura do Supervisor" className={modalStyles.signatureImage} />
                <div className={modalStyles.lineUnderSignature}></div> {/* Linha abaixo da assinatura */}
                <p>Supervisor de Operações:</p>
                <p>{selectedInspectionItems[0].SUPERVISOR}</p>
              </div>
            )}
            {assinaturaManutencao && (
              <div className={modalStyles.signatureColumn}>
                <img src={assinaturaManutencao} alt="Assinatura da Manutenção" className={modalStyles.signatureImage} />
                <div className={modalStyles.lineUnderSignature}></div> {/* Linha abaixo da assinatura */}
                <p>Setor de Manutenção:</p>
                <p>{selectedInspectionItems[0].MANUTENCAO}</p>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default function IntegrationNotistack() {
  return (
    <SnackbarProvider
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      maxSnack={3}
      autoHideDuration={2500}
    >
      <HistoricoForms />
    </SnackbarProvider>
  );
}
