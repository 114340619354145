import React, { useState, useEffect } from "react";
import Navbar from "../../../components/Navbar";
import Brackground from "../../../components/Background";
import Container from "../../../components/Container";
import Header from "../../../components/Header";
import { SnackbarProvider } from 'notistack';
import { useNavigate, useParams } from "react-router-dom";
import Axios from "axios";
import GraficoPercent from "../../../components/GraficoPercent";
import style from "./GraficoDocs.module.css";
import { Bar } from 'react-chartjs-2';

const GraficoDocs = () => {
  const [list, setList] = useState([]);
  const [totalData, setTotalData] = useState({});
  const [listaDeObjetos, setListaDeObjetos] = useState([]);
  const [moegaView, setMoegaView] = useState(true);
  const [acordeaoAberto, setAcordeaoAberto] = useState(null);

  const { id } = useParams();
  const navigate = useNavigate();

  // Função para alternar o acordeão
  const toggleAcordeao = (indiceDoc) => {
    if (acordeaoAberto === indiceDoc) {
      setAcordeaoAberto(null);
    } else {
      setAcordeaoAberto(indiceDoc);
    }
  };

  // Process the fetched data to group by NUMERO_DOC
  const processData = (data) => {
    return data.reduce((acc, item) => {
      if (!acc[item.NUMERO_DOC]) {
        acc[item.NUMERO_DOC] = [];
      }
      acc[item.NUMERO_DOC].push(item);
      return acc;
    }, {});
  };

  async function fetchData() {
    try {
      const response = await Axios.get(`https://hml.operacao.orion-op.com.br/api/grafico/${id}`);
      setList(response.data);

      const totalResponse = await Axios.get(`https://hml.operacao.orion-op.com.br/api/grafico/total/${id}`);
      setTotalData(totalResponse.data);

      const graficResponse = await Axios.get(`https://hml.operacao.orion-op.com.br/api/graficdocs/${id}`);
      const processedData = processData(graficResponse.data); // Process data for grouping
      setListaDeObjetos(processedData);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    fetchData();
  }, [id]);

  const toggleView = () => {
    setMoegaView(!moegaView);
  };

  return (
    <>
      <Navbar operacao />
      <Header />
      <Brackground />
      <Container>
        <div className={style.content}>
          <div className={style.nav}>
            <div onClick={() => navigate(`/operacoes`)}>Viagens</div>
            <div onClick={() => navigate(`/operacao/${id}`)}>2° Pesagem</div>
            <div onClick={() => navigate(`/operacao/pesagemfinal/${id}`)}>3° Pesagem</div>
            <div className={style.active}>DashBoard</div>
            <div onClick={() => navigate(`/operacao/${id}/AberturaPeriodo`)}>Abertura de Período</div>
          </div>

          <div className={style.buttons}>
            <button onClick={toggleView} className={!moegaView ? `${style.activeButton} ${style.button}` : `${style.button}`}>
              Moega
            </button>
            <button onClick={toggleView} className={moegaView ? `${style.activeButton} ${style.button}` : `${style.button}`}>
              Balança
            </button>
          </div>

          {moegaView ? (  /* BALANÇA */
            <div>
              <div>

                <div className={style.gftitle}>
                  <b>Controle Geral ({totalData.NOME_NAVIO})</b>
                </div>

                <div className={style.gfbox}>
                  <div className={style.gfcolumgeral}>
                    <div className={style.grpercent}>{totalData.PORCENTAGEM_PESO_LIQUIDO_BALANCA} %</div>
                    <div className={style.grbackbar}>
                      <div className={totalData.PORCENTAGEM_PESO_LIQUIDO_BALANCA >= 90 ? `${style.grred}` : `${style.grblue}`}>
                      </div>
                    </div>
                    <div className={style.grdesc}>

                      <div className={style.NOME_REDUZIDO}><b>BALANÇA</b></div>
                      <div className={style.manifestado}>
                        <b>MANIFESTADO:</b> {(totalData.TOTAL_MANIFESTADO / 1000).toLocaleString('pt-BR', { minimumFractionDigits: 3, maximumFractionDigits: 3 })} Tons
                      </div>

                      <div className={style.linha}></div>
                      <b>Descarregado:</b> {(totalData.TOTAL_PESO_LIQUIDO_BALANCA / 1000).toLocaleString('pt-BR', { minimumFractionDigits: 3, maximumFractionDigits: 3 })} Tons
                      <br />
                      <b>Saldo:</b> {((totalData.TOTAL_MANIFESTADO - totalData.TOTAL_PESO_LIQUIDO_BALANCA) / 1000).toLocaleString('pt-BR', { minimumFractionDigits: 3, maximumFractionDigits: 3 })} Tons
                      <br />
                    </div>
                  </div>

                </div>
              </div>

              <div className={style.gftitle}>
                <b>Quantidade descarregada por (DI/BL)</b>
              </div>
              <div className={style.centralizar}>
                <div>

                </div>
              </div>
            </div>
          ) : (   /* MOEGA */
            <div>
              <div>

                <div className={style.gftitle}>
                  <b>Controle Geral ({totalData.NOME_NAVIO})</b>
                </div>

                <div className={style.gfbox}>
                  <div className={style.gfcolumgeral}>
                    <div className={style.grpercent}>{totalData.PORCENTAGEM_PESO_CARREGADO_MOEGA} %</div>
                    <div className={style.grbackbar}>
                      <div className={totalData.PORCENTAGEM_PESO_CARREGADO_MOEGA >= 90 ? `${style.grred}` : `${style.grblue}`}>
                      </div>
                    </div>
                    <div className={style.grdesc}>

                      <div className={style.NOME_REDUZIDO}><b>MOEGA</b></div>
                      <div className={style.manifestado}>
                        <b>MANIFESTADO:</b> {(totalData.TOTAL_MANIFESTADO / 1000).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2, })} Tons
                      </div>
                      <div className={style.linha}></div>
                      <b>Descarregado:</b> {(totalData.TOTAL_PESO_CARREGADO_MOEGA / 1000).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} Tons
                      <br />
                      <b>Saldo:</b> {((totalData.TOTAL_MANIFESTADO - totalData.TOTAL_PESO_CARREGADO_MOEGA) / 1000).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} Tons
                      <br />
                    </div>
                  </div>

                </div>
              </div>


              <div className={style.gftitle}>
                <b>Quantidade descarregada por (DI/BL)</b>
              </div>
              <div className={style.centralizar}>
                <div>
                  {Object.entries(listaDeObjetos).map(([numeroDoc, dados], indiceDoc) => (
                    <div key={indiceDoc} className={style.gfcolum}>
                      
                      <div className={style.acordeaoCabecalho} onClick={() => toggleAcordeao(indiceDoc)}>
                        <div className={style.grpercent}>{dados[0].PERC_MOEGA} %</div>
                        <div className={style.grbackbar}>
                          <div className={dados[0].PERC_MOEGA >= 90 ? `${style.grred}` : `${style.grblue}`}></div>
                        </div>
                        <div className={style.manifestado}>
                        <h3><b>{numeroDoc}</b></h3></div>
                        
                        <div className={style.manifestado}>
                        <p>{dados[0].CLIENTE}</p></div>
                        <p><b>{dados[0].PRODUTO}</b></p>
                        <p><b>Manifestado:</b> {(dados[0].MANIFESTADO / 1000).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} Tons</p>
                        <div className={style.linha}></div>
                        <p><b>Descarregado:</b> {(dados[0].PESO_CARREGADO_MOEGA / 1000).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} Tons</p>
                        <p><b>Saldo:</b> {(dados[0].SALDO_MOEGA / 1000).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} Tons</p>
                        {/* Ícone de expansão/colapso */}
                        {acordeaoAberto === indiceDoc ? <span>&uarr;</span> : <span>&darr;</span>}
                      </div>
                      {/* Conteúdo do acordeão */}
                      {acordeaoAberto === indiceDoc && (
                        <div className={style.acordeaoConteudo}>

                          {/* Detalhes dos pedidos */}
                          {dados.map((pedido, indicePedido) => (
      <div key={indicePedido} className={style.cardPedido}>
        {/* Cabeçalho do Card */}
        <div className={style.cabecalhoCard}>
        <span className={pedido.PERC_MOEGA_PEDIDO >= 90 ? style.iconeAlto : style.iconeNormal}>
            {pedido.PERC_MOEGA_PEDIDO}%
          </span>
        <p><b>Pedido MIC:</b> {pedido.NR_PEDIDO}</p>         
        </div>

        {/* Corpo do Card */}
        <div className={style.corpoCard}>
          <p><b>Manifestado:</b> {(pedido.QTDE_MANIFESTADA_PEDIDO / 1000).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} Tons</p>
          <p><b>Saldo:</b> {(pedido.SALDO_MOEGA_PEDIDO / 1000).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} Tons</p>
        </div>

      </div>
    ))}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </Container>
    </>
  );
};

function IntegrationNotistack() {
  return (
    <SnackbarProvider
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      maxSnack={3}
      autoHideDuration={2500}>
      <GraficoDocs />
    </SnackbarProvider>
  );
}

export default IntegrationNotistack;
