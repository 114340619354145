import { SnackbarProvider, useSnackbar } from 'notistack';
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Axios from "axios";
import Brackground from "../../../components/Background";
import Container from "../../../components/Container";
import Header from "../../../components/Header";
import Input from "../../../components/Input";
import Navbar from "../../../components/Navbar";
import React from "react";
import Select from "../../../components/select";
import SubmitButton from "../../../components/Button";
import moment from "moment";
import style from "./AnaliseOperacional.module.css"
import { useStepContext } from "@mui/material";



const AnaliseOperacional = () => {

    useEffect(() => {
        getPeriodo();
        getOp()
        getPeriodo()
    }, [])


    const { id } = useParams();
    const [navioslist, setNaviosList] = useState([])
    const [nomeNavio, setNomeNavio] = useState(""); // Estado para armazenar o nome do navio
    const [periodo, setPeriodo] = useState([]);
    const [qtdeManifestadaTotal, setQtdeManifestadaTotal] = useState(0);
    const [operacoes, setOperacoes] = useState([]);
    const [autos, setAutos] = useState([]);
    const [atracacao, setAtracacao] = useState("");
    const [nomeCliente, setNomeCliente] = useState("");
    const [documentos, setDocumentos] = useState([]);
    const [codMoega, setCodMoega] = useState("");
    const navigate = useNavigate();

    const getOp = () => {
        Axios.get('https://hml.operacao.orion-op.com.br/api/relatorios/operacoes').then((response) => {
            setNaviosList(response.data)
        });
    }

    const getDate = () => {
        const date = new Date()
        date.setHours(date.getHours() - 3)
        return (date.toISOString().slice(0, 19).replace('T', ' '))
    }

    const getPeriodo = () => {
        Axios.get(`https://hml.operacao.orion-op.com.br/api/operacao/gerais/${id}`).then((response) => {
            setPeriodo(response.data)
        });
    }

    const getOperacoes = () => {
        Axios.post(`https://hml.operacao.orion-op.com.br/api/operacao/paralisacao/${id}`, {
            data: relatorios
        }).then((response) => {
            setOperacoes(response.data);

            Axios.get(`https://hml.operacao.orion-op.com.br/api/relatorio/analiseoperacional/navio/${id}`)
                .then((navioResponse) => {
                    const data = navioResponse.data;

                    // Extract unique client names
                    const uniqueNomesClientes = new Set();
                    // Extract unique COD_MOEGA
                    const uniqueCodMoega = new Set();

                    data.forEach(item => {
                        if (item.NOME_REDUZIDO) {
                            uniqueNomesClientes.add(item.NOME_REDUZIDO);
                        }
                        if (item.COD_MOEGA !== undefined) {
                            uniqueCodMoega.add(item.COD_MOEGA);
                        }
                    });

                    setNomesClientes([...uniqueNomesClientes]);
                    setCodMoega([...uniqueCodMoega]);

                    // Set other states if needed
                    if (data.length > 0) {
                        setNomeNavio(data[0].NOME_NAVIO);
                        setQtdeManifestadaTotal(data[0].QTDE_MANIFESTADA_TOTAL);
                        setAtracacao(data[0].ATRACACAO);
                    }
                })
                .catch((err) => {
                    console.error("Error fetching navio data:", err);
                });
        }).catch((error) => {
            console.error("Error fetching operacoes:", error);
        });
    }



    useEffect(() => {
        DadosDashboard();
        VerificaParalisacao();

    }, [])

    const [busca, setBusca] = useState("");
    const [existeParalisacao, setExisteParalisacao] = useState(0);
    const [dadosDash, setDadosDash] = useState([]);
    const [paralisacoes, setParalisacoes] = useState([]);
    const [relatorios, setRelatorios] = useState([]);
    const [nomesClientes, setNomesClientes] = useState([]);



    const VerificaParalisacao = () => {
        Axios.get(`https://hml.operacao.orion-op.com.br/api/verifica/paralisacao/${id}`,)
            .then(function (res) {
                setExisteParalisacao(res.data)
            })
    }
    const DadosDashboard = () => {
        Axios.get(`https://hml.operacao.orion-op.com.br/api/periodo/dashboard/${id}`,)
            .then(function (res) {
                setDadosDash(res.data[0])
                Axios.get(`https://hml.operacao.orion-op.com.br/api/paralisacao/periodo/${res.data[0].SEQ_PERIODO_OP}`,)
                    .then(function (res) {
                        setParalisacoes(res.data)
                    })
            })
    }

    return (
        <>
            <Navbar relatorios />
            <Header />
            <Brackground />
            <Container>
                <div className={style.content}>
                    <div className={style.nav}>
                        <div onClick={() => navigate(`/relatorios`)}>
                            Selecione um navio
                        </div>
                        <div className={style.nav}>
                            <div onClick={() => navigate(`/relatorios/${id}`)}>
                                Relatório por Período
                            </div>
                        </div>
                        <div onClick={() => navigate(`/relatorios/operacao/${id}`)}>
                            Relatório por Viagem
                        </div>
                        <div className={style.active}>
                            Análise Operacional
                        </div>

                        <div onClick={() => navigate(`/relatorios/idfa/${id}`)}>
                            IDFA
                        </div>
                    </div>
                    <div className={style.flex}>

                        <div>
                            <section className={style.filters}>
                                <div className={style.form_control}>
                                    <label>Selecione uma operação:</label>
                                    <select onChange={(e) => { setRelatorios(e.target.value) }}>
                                        <option disabled selected>Selecione uma opção</option>
                                        {periodo?.map((val) => {
                                            return (
                                                <option value={[val.NAVIO]}>{val.NAVIO}</option>

                                            )
                                        })}
                                    </select>

                                    <div className={style.submit_button}>
                                        <SubmitButton text={'Buscar'}
                                            onClick={getOperacoes} />
                                    </div>
                                    <div className={style.periodo}>

                                        <div className={style.data}>
                                            {relatorios}
                                        </div>

                                    </div>

                                </div>


                            </section>


                        </div>

                        <div className={style.cards}>
                            <span className={style.cardinfo}>
                                <div className={style.oneline}>
                                    <span className={style.boldGreenLabel}>NAVIO: </span>
                                    <span className={style.normalLabel}>{nomeNavio}</span>
                                </div>

                                <div className={style.oneline}>
                                    <span className={style.boldGreenLabel}>CLIENTES:</span> {nomesClientes.join(", ")}
                                </div>

                                <div className={style.operationTiming}>
                                    <span className={style.terminoOperacao}>
                                        <span className={style.boldGreenLabel}>MANIFESTADO TOTAL: </span>
                                        <span>{(qtdeManifestadaTotal / 1000).toLocaleString('pt-BR', { minimumFractionDigits: 3, maximumFractionDigits: 3 })} Tons</span>
                                    </span>
                                    <span className={style.terminoOperacao}>
                                        <span className={style.boldGreenLabel}>ATRACAÇÃO: </span>

                                        {atracacao ? (
                                            new Date(atracacao).toLocaleDateString('pt-BR', {
                                                day: '2-digit',
                                                month: '2-digit',
                                                year: 'numeric'
                                            }) + ' - ' + new Date(atracacao).toLocaleTimeString('pt-BR', {
                                                hour: '2-digit',
                                                minute: '2-digit'
                                            })
                                        ) : " "}</span>
                                </div>

                                <div className={style.operationTiming}>
                                    <span className={style.terminoOperacao}>
                                        <span className={style.boldGreenLabel}>ÍNICIO DA OPERAÇÃO: </span>
                                        <span> </span>
                                    </span>
                                    <span className={style.terminoOperacao}>
                                        <span className={style.boldGreenLabel}>TÉRMINO DA OPERAÇÃO: </span>
                                    </span>
                                </div>

                                <div className={style.operationTiming}>
                                    <span className={style.terminoOperacao}>
                                        <span className={style.boldGreenLabel}>PRANCHA GERAL: </span>
                                        <span> </span>
                                    </span>
                                    <span className={style.terminoOperacao}>
                                        <span className={style.boldGreenLabel}>PRANCHA ESPECÍFICA TOU: </span>
                                    </span>
                                </div>

                                <div className={style.operationTiming}>
                                    <span className={style.terminoOperacao}>
                                        <span className={style.boldGreenLabel}>TEMPO OPERACIONAL: </span>
                                        <span> </span>
                                    </span>
                                    <span className={style.terminoOperacao}>
                                        <span className={style.boldGreenLabel}>EQUIPAMENTOS:</span>
                                        {Array.isArray(codMoega) && codMoega.length > 0 ? (
                                            codMoega.map((moega, index) => (
                                                <span key={index}> Moega {moega}{index < codMoega.length - 1 ? ', ' : ''}</span>
                                            ))
                                        ) : (
                                            <span> </span>
                                        )}
                                    </span>
                                </div>

                            </span>

                        </div>
                    </div>
                    <div className={style.rankingcontainer}>
                        <div className={style.ranking1}>
                            PARALISAÇÃO - SUPER VIA
                            <table className={style.customtableranking}>
                                <thead>
                                    <tr>
                                        <th>MOTIVO</th>
                                        <th>HORAS</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {operacoes.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item.MOTIVO}</td>
                                            <td>{item.HORAS}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className={style.ranking1}>
                            COMPLEMENTO - JUSTIFICATIVA
                            <table className={style.customtableranking}>
                                <thead>
                                    <tr>
                                        <th>Complemento</th>
                                        <th>Observação</th>
                                        <th>Total</th>
                                    </tr>
                                </thead>
                                <tbody>

                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>


            </Container>
        </>
    );
};

export default function IntegrationNotistack() {
    return (
        <SnackbarProvider
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            maxSnack={3}
            autoHideDuration={2500}>
            <AnaliseOperacional />
        </SnackbarProvider >
    );
}