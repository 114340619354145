import React from "react";
import { useParams } from "react-router-dom";
import Navbar from "../../components/Navbar";
import Background from "../../components/Background";
import Container from "../../components/Container";
import Header from "../../components/Header";
import style from "./Dash.module.css";
import logo from "./logo.png";  // Importing the logo image

const Dashboard = () => {
  const { id } = useParams();

  return (
    <>
      <Navbar />
      <Header />
      <Background />
      <Container>
        <div className={style.centerLogo}>
          <img src={logo} alt="Logo" className={style.logoImage} />
        </div>
        <br />
        
      </Container>
    </>
  );
};

export default Dashboard;
