import React, { useState } from 'react';
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { SnackbarProvider, useSnackbar } from 'notistack';
import Navbar from '../../../components/Navbar';
import Background from '../../../components/Background';
import Container from '../../../components/Container';
import Header from '../../../components/Header';
import SubmitButton from '../../../components/Button';
import Input from '../../../components/Input';
import style from './Equipamentos.module.css';

const Equipamentos = () => {
  const navigate = useNavigate();
  const [message, setMessage] = useState("");

  const handleChange = (event) => {
    if (event.target.name === 'message') {
      setMessage(event.target.value);
    }
  };

  const handleSubmit = () => {

    Axios.post("https://hml.operacao.orion-op.com.br/api/manutencao/sendmessage", {
      message,
    })
        .then(function (res) {
            console.log(res);
            console.log("res");
            if (res.data.sqlMessage) {
                showAlert(res.data.sqlMessage, 'error');
            } else {
                showAlert('Send Message cadastrado com sucesso!', 'success');
                setTimeout(() => {
                    navigate("/manutencao/equipamentos"); // Redirecionar após o atraso
                }, 3000); // Atraso de 3000 milissegundos (3 segundos)
            }
        });
}

  const validaDados = () => {
    if (message) {
      handleSubmit();
    } else {
      enqueueSnackbar('Por favor, preencha todos os campos.', { variant: 'warning' });
    }
  };

  const validaTecla = (e) => {
    if (e.key === 'Enter') {
      validaDados();
    }
  };

  const { enqueueSnackbar } = useSnackbar();
  const showAlert = (txt, variant) => {
      enqueueSnackbar(txt, { variant: variant });
  }


  return (
    <>
      <Navbar motivados />
      <Header />
      <Background />
      <Container>
        <div className={style.content}>
          <div className={style.nav}>
            <div className={`${style.active} ${style.navItem}`}>
              Equipamentos
            </div>
            <div className={style.navItem} onClick={() => navigate('/manutencao/relatarequipamentos')}>
              Relatar Equipamentos
            </div>
          </div>
          <div className={style.columns}>
            
            <div className={style.nome}>
              <label>Mensagem</label>
              <Input
                type="text"
                name="message"
                value={message}
                onChange={handleChange}
                onKeyPress={validaTecla}
              />
            </div>
            <SubmitButton
              text="Cadastrar"
              onClick={validaDados}
            />
          </div>
        </div>
      </Container>
    </>
  );
};

export default function IntegrationNotistack() {
  return (
    <SnackbarProvider
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      maxSnack={3}
      autoHideDuration={2500}
    >
      <Equipamentos />
    </SnackbarProvider>
  );
};
