import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import { SnackbarProvider, useSnackbar } from 'notistack';
import GRAB from "./GRAB.png";
import MOEGA from "./MOEGA.png";
import GERADOR from "./GERADOR.png";
import Navbar from "../../../components/Navbar";
import Background from "../../../components/Background";
import Container from "../../../components/Container";
import Header from "../../../components/Header";
import SubmitButton from "../../../components/Button";
import style from './RelatarEquipamentos.module.css'; // Caminho do seu arquivo CSS

const RelatarEquipamentos = () => {

    const [equipamentos, setEquipamentos] = useState([]);
    const [selectedEquipamento, setSelectedEquipamento] = useState('');
    const [selectedEquipamentoImage, setSelectedEquipamentoImage] = useState(null); // State para armazenar a imagem do equipamento selecionado

    const [statusEletrica, setStatusEletrica] = useState('F');
    const [obsEletrica, setObsEletrica] = useState('');
    const [statusPintura, setStatusPintura] = useState('F');
    const [obsPintura, setObsPintura] = useState(''); // Certifique-se de que esta linha esteja presente
    const [statusRoldana, setStatusRoldana] = useState('F');
    const [obsRoldana, setObsRoldana] = useState('');
    const [statusCabo, setStatusCabo] = useState('F');
    const [obsCabo, setObsCabo] = useState('');
    const [statusNota, setStatusNota] = useState('F');
    const [obsNota, setObsNota] = useState('');
    const [statusBloco, setStatusBloco] = useState('F');
    const [obsBloco, setObsBloco] = useState('');

    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        // Função para buscar os equipamentos
        const fetchEquipamentos = async () => {
            try {
                const response = await Axios.get("https://hml.operacao.orion-op.com.br/api/equipamentos");
                setEquipamentos(response.data);
            } catch (error) {
                console.error('Erro ao buscar equipamentos:', error);
            }
        };

        // Chama a função de busca ao montar o componente
        fetchEquipamentos();
    }, []);

    const selectEquipamentoImage = (equipamento) => {
        switch (equipamento) {
            case 'MOEGA R1':
            case 'MOEGA R2':
            case 'MOEGA R3':
            case 'MOEGA R4':
                return MOEGA;
            case 'GRAB 12T':
            case 'GRAB 9T':
                return GRAB;
            case 'GERADOR MILLIS':
                return GERADOR;
            default:
                return null;
        }
    };


    const postStatus = () => {
        Axios.post("https://hml.operacao.orion-op.com.br/api/manutencao/equipamentos/relatar", {
            statusEletrica,
            statusPintura,
            statusRoldana,
            statusCabo,
            statusNota,
            statusBloco
        })
            .then((res) => {
                enqueueSnackbar('Status do equipamento reportado com sucesso!', { variant: 'success' });
                navigate("/caminho_para_redirecionamento_apos_sucesso");
            })
            .catch((err) => {
                console.error(err);
                enqueueSnackbar('Erro ao reportar status do equipamento', { variant: 'error' });
            });
    };
    const handleEquipamentoChange = (event) => {
        const selectedEquipamentoName = event.target.value;
        setSelectedEquipamento(selectedEquipamentoName);

        // Verificar se o equipamento selecionado tem uma imagem correspondente
        const selectedImage = selectEquipamentoImage(selectedEquipamentoName);
        setSelectedEquipamentoImage(selectedImage);
    };

    return (
        <>
            <Navbar />
            <Header />
            <Background />
            <Container>
                <div className={style.content}>
                    <div className={style.nav}>
                        <div className={style.navItem} onClick={() => navigate('/manutencao/relatarequipamentos')}>
                            Equipamentos
                        </div>
                        <div className={`${style.active} ${style.navItem}`}>
                            Relatar Equipamentos
                        </div>
                    </div>
                    
                    <div className={style.rowdouble}>

                    <div className={style.row}>

                        <div className={style.column}>
                            <h2>Equipamento</h2>
                            <select value={selectedEquipamento} onChange={handleEquipamentoChange}>
                                <option value="">Selecione um equipamento</option>
                                {equipamentos.map((equipamento) => (
                                    <option key={equipamento.COD_EQUIPAMENTO} value={equipamento.DESC_EQUIPAMENTO}>
                                        {equipamento.DESC_EQUIPAMENTO}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className={style.colum}>
                            {selectedEquipamentoImage && <img src={selectedEquipamentoImage} alt="Imagem do Equipamento" />}
                        </div>

                    </div>

                    <div className={style.rowtri}>
                    
    
                    <div className={style.column}>
                    <div><span className={style.greenDot}></span> FUNCIONANDO</div>
                    </div>

                    <div className={style.column}>
                    <div><span className={style.redDot}></span> PROBLEMA</div>
                    </div>
                    </div>
                    </div>

                    {/* Container de itens */}
                    <div className={style.itemsGrid}>

                        {/* Item: Pintura */}
                        <div className={style.itemContainer}>
                            <div className={style.switchContainer}>
                                <div className={style.itemLabel}>Pintura</div>
                                <label className={style.switch}>
                                    <input
                                        type="checkbox"
                                        checked={statusPintura === 'F'}
                                        onChange={() => setStatusPintura(statusPintura === 'F' ? 'P' : 'F')}
                                    />
                                    <span className={`${style.slider} ${style.statusSlider}`}></span>
                                </label>
                            </div>
                            <input
                                type="text"
                                placeholder="Observações"
                                value={obsPintura}
                                onChange={(e) => setObsPintura(e.target.value)}
                                className={style.obsField}
                            />
                        </div>

                        {/* Item: Roldana */}
                        <div className={style.itemContainer}>
                            <div className={style.switchContainer}>
                                <div className={style.itemLabel}>Roldana</div>
                                <label className={style.switch}>
                                    <input
                                        type="checkbox"
                                        checked={statusRoldana === 'F'}
                                        onChange={() => setStatusRoldana(statusRoldana === 'F' ? 'P' : 'F')}
                                    />
                                    <span className={`${style.slider} ${style.statusSlider}`}></span>
                                </label>
                            </div>
                            <input
                                type="text"
                                placeholder="Observações"
                                value={obsRoldana}
                                onChange={(e) => setObsRoldana(e.target.value)}
                                className={style.obsField}
                            />
                        </div>

                        {/* Item: Cabo */}
                        <div className={style.itemContainer}>
                            <div className={style.switchContainer}>
                                <div className={style.itemLabel}>Cabo</div>
                                <label className={style.switch}>
                                    <input
                                        type="checkbox"
                                        checked={statusCabo === 'F'}
                                        onChange={() => setStatusCabo(statusCabo === 'F' ? 'P' : 'F')}
                                    />
                                    <span className={`${style.slider} ${style.statusSlider}`}></span>
                                </label>
                            </div>
                            <input
                                type="text"
                                placeholder="Observações"
                                value={obsCabo}
                                onChange={(e) => setObsCabo(e.target.value)}
                                className={style.obsField}
                            />
                        </div>

                        {/* Item: Nota */}
                        <div className={style.itemContainer}>
                            <div className={style.switchContainer}>
                                <div className={style.itemLabel}>Nota</div>
                                <label className={style.switch}>
                                    <input
                                        type="checkbox"
                                        checked={statusNota === 'F'}
                                        onChange={() => setStatusNota(statusNota === 'F' ? 'P' : 'F')}
                                    />
                                    <span className={`${style.slider} ${style.statusSlider}`}></span>
                                </label>
                            </div>
                            <input
                                type="text"
                                placeholder="Observações"
                                value={obsNota}
                                onChange={(e) => setObsNota(e.target.value)}
                                className={style.obsField}
                            />
                        </div>

                        {/* Item: Bloco */}
                        <div className={style.itemContainer}>
                            <div className={style.switchContainer}>
                                <div className={style.itemLabel}>Bloco</div>
                                <label className={style.switch}>
                                    <input
                                        type="checkbox"
                                        checked={statusBloco === 'F'}
                                        onChange={() => setStatusBloco(statusBloco === 'F' ? 'P' : 'F')}
                                    />
                                    <span className={`${style.slider} ${style.statusSlider}`}></span>
                                </label>
                            </div>
                            <input
                                type="text"
                                placeholder="Observações"
                                value={obsBloco}
                                onChange={(e) => setObsBloco(e.target.value)}
                                className={style.obsField}
                            />
                        </div>

                    </div>


                    {/* Botão para reportar o status */}
                    <SubmitButton text="Reportar Status" onClick={postStatus} />

                </div>
            </Container>
        </>
    );
};

export default function IntegrationNotistack() {
    return (
        <SnackbarProvider anchorOrigin={{ vertical: "bottom", horizontal: "right" }} maxSnack={3} autoHideDuration={2500}>
            <RelatarEquipamentos />
        </SnackbarProvider>
    );
}
