import React, { useState, useEffect } from "react";
import Navbar from "../../../components/Navbar";
import Brackground from "../../../components/Background";
import Container from "../../../components/Container";
import Header from "../../../components/Header";
import style from "./Puxadas.module.css";
import Input from "../../../components/Input";
import SubmitButton from "../../../components/Button";
import Axios from "axios";
import InputMask from "react-input-mask";
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { FaFileExcel } from 'react-icons/fa'; // Para o ícone
import * as XLSX from 'xlsx'; // Para exportar os dados


import { SnackbarProvider, useSnackbar } from "notistack";

const Puxadas = () => {
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const [fornecedor, setFornecedor] = useState("");
  const [fornecedores, setFornecedores] = useState([]);

  const [datainicio, setDataInicio] = useState("");

  const [operacao, setOperacao] = useState("");
  const [operacoes, setOperacoes] = useState([]);

  const [berco, setBerco] = useState("");
  const [bercos, setBercos] = useState([]);

  const [equipamento, setEquipamento] = useState("");
  const [equipamentos, setEquipamentos] = useState([]);

  const [observacao, setObservacao] = useState("");
  const [observacoes, setObservacoes] = useState([]);

  const [valor, setValor] = useState("");

  const [puxadasLista, setPuxadasLista] = useState([]);

  const [editMode, setEditMode] = useState(null); // Armazena o ID do vigia em edição
  const [editedDataPagamento, setEditedDataPagamento] = useState(''); // Armazena a data de pagamento editada
  const [editedObservacao, setEditedObservacao] = useState('');

  const [fornecedormaq, setFornecedorMaq] = useState("");

  const [usoDeMaquina, setUsoDeMaquina] = useState(false);
  const [imagemAnexada, setImagemAnexada] = useState(null);

  const [dataInicioFiltro, setDataInicioFiltro] = useState("");
  const [dataTerminoFiltro, setDataTerminoFiltro] = useState("");

  const [selectedFile, setSelectedFile] = useState(null);


  const usuario = JSON.parse(localStorage.getItem("user_token")).id;

  // Função para mostrar alertas
  const showAlert = (message, variant) => {
    enqueueSnackbar(message, { variant: variant });
  };

  // Função para enviar dados do cliente
  const cadastrarVigia = async () => {
    if (!fornecedor || !datainicio || !equipamento) {
      showAlert("Preencha todos os campos", "error");
      return;
    }

    // Utilizando moment para obter a data e hora atuais formatadas
    const dataEmail = moment().format('YYYY-MM-DD'); // Ajuste para incluir apenas a data

    try {
      const response = await Axios.post(
        "https://hml.operacao.orion-op.com.br/api/puxadas/cadastrar",
        {
          fornecedor: fornecedor,
          navio: operacao,
          berco: berco,
          data_inicio: datainicio,
          equipamento: equipamento,
          valor: valor,
          data_email: dataEmail,
          usuario: usuario,
          observacao: observacao,
          fornecedormaq: fornecedormaq,

        }
      );

      showAlert("Vigia cadastrado com sucesso!", "success");
      // Aguarde 2 segundos (2000 milissegundos) antes de recarregar a página
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      console.error("Erro ao cadastrar a PUXADA:", error);
      showAlert("Erro ao cadastrar a PUXADA", "error");
    }
  };

  const handleEditDataPagamento = (puxadas) => {
    setEditedDataPagamento(puxadas.DATA_PAGAMENTO ? moment(puxadas.DATA_PAGAMENTO).format('YYYY-MM-DD') : '');
    setEditedObservacao(puxadas.OBSERVACAO || ''); // Define a observação atual ou uma string vazia se não houver
    setEditMode(puxadas.ID_PUXADAS);
  };

  const handleCancelEditDataPagamento = () => {
    setEditMode(null);
    setEditedDataPagamento('');
  };

  const handleSaveDataPagamento = async (idPuxadas) => {
    try {
      await Axios.put('https://hml.operacao.orion-op.com.br/api/puxadas/atualizarDataObservacao', {
        idPuxadas, // Aqui foi corrigido para idPuxadas, assumindo que é o nome correto
        dataPagamento: editedDataPagamento,
        observacao: editedObservacao
      });
      showAlert("Dados atualizados com sucesso!", "success");
      setEditMode(null);
      setEditedDataPagamento('');
      setEditedObservacao('');
      atualizarListaPuxadas();
    } catch (error) {
      showAlert("Erro ao atualizar os dados", "error");
      console.error("Erro ao atualizar os dados:", error);
    }
  };


  const atualizarListaPuxadas = () => {
    Axios.get("https://hml.operacao.orion-op.com.br/api/custeio/puxadas")
      .then(response => {
        setPuxadasLista(response.data);
      })
      .catch(error => {
        console.error("Erro ao buscar dados atualizados: ", error);
        showAlert("Erro ao buscar dados atualizados", "error");
      });
  };

  useEffect(() => {
    Axios.get("https://hml.operacao.orion-op.com.br/api/fornecedores")
      .then((response) => {
        setFornecedores(response.data);
      })
      .catch((error) => {
        console.error("Erro ao buscar os fornecedores: ", error);
        showAlert("Erro ao buscar os fornecedores", "error");
      });
  }, []);

  useEffect(() => {
    Axios.get("https://hml.operacao.orion-op.com.br/api/equipamentos")
      .then((response) => {
        setEquipamentos(response.data);
      })
      .catch((error) => {
        console.error("Erro ao buscar equipamentos: ", error);
        showAlert("Erro ao buscar equipamentos", "error");
      });
  }, []);

  useEffect(() => {
    Axios.get("https://hml.operacao.orion-op.com.br/api/bercos")
      .then((response) => {
        setBercos(response.data);
      })
      .catch((error) => {
        console.error("Erro ao buscar os berços: ", error);
        showAlert("Erro ao buscar os berços", "error");
      });
  }, []);

  useEffect(() => {
    Axios.get("https://hml.operacao.orion-op.com.br/api/operacao")
      .then((response) => {
        setOperacoes(response.data);
      })
      .catch((error) => {
        console.error("Erro ao buscar as operações: ", error);
        showAlert("Erro ao buscar as operações", "error");
      });
  }, []);

  useEffect(() => {
    Axios.get("https://hml.operacao.orion-op.com.br/api/custeio/puxadas")
      .then((response) => {
        let filteredData = response.data;
        if (dataInicioFiltro && dataTerminoFiltro) {
          const startDate = new Date(dataInicioFiltro);
          const endDate = new Date(dataTerminoFiltro);
          filteredData = filteredData.filter((puxada) => {
            const puxadaDate = new Date(puxada.DATA_INICIO);
            return puxadaDate >= startDate && puxadaDate <= endDate;
          });
        }
        setPuxadasLista(filteredData);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados: ", error);
        showAlert("Erro ao buscar dados", "error");
      });
  }, [dataInicioFiltro, dataTerminoFiltro]); // Add the filters as dependencies
  

  const exportToExcel = () => {
    // Adjusted data preparation for export
    const data = puxadasLista.map((puxada) => ({
      Fornecedor: puxada.NOME_FORNECEDOR,
      'Data Início': moment(puxada.DATA_INICIO).format('DD/MM/YYYY - HH:mm'),
      Navio: puxada.NOME_NAVIO,
      Local: puxada.NOME_BERCO,
      Moega: puxada.DESC_EQUIPAMENTO,
      Valor: `R$ ${parseFloat(puxada.VALOR).toFixed(2).replace('.', ',')}`,
      'Data E-mail': puxada.DATA_EMAIL_RECEBIMENTO ? moment(puxada.DATA_EMAIL_RECEBIMENTO).format('DD/MM/YYYY') : "N/A",
      'Solicitante da Puxada': puxada.USUARIO_CADASTRO,
      'Data Pagamento': puxada.DATA_PAGAMENTO ? moment(puxada.DATA_PAGAMENTO).format('DD/MM/YYYY') : "N/A",
      Observação: puxada.OBSERVACAO || 'N/A',
    }));
  
    // Create a worksheet with the data
    const ws = XLSX.utils.json_to_sheet(data);
  
    // Add a workbook and append the worksheet to it
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Puxadas");
  
    // Write the workbook to a file
    XLSX.writeFile(wb, "RelatorioPuxadas.xlsx");
  };
  

  return (
    <>
      <Navbar veiculos />
      <Header />
      <Brackground />
      <Container>
        <div className={style.content}>

          <div className={style.nav}>
            <div className={style.navItem} onClick={() => navigate('/custeio/vigias')}>
              Vigias
            </div>

            <div className={style.active}>
              Puxadas
            </div>

            <div className={style.navItem} onClick={() => navigate('/custeio/custosnavio')}>
              Custos de Navio
            </div>
          </div>

          <div className={style.columns}>
            <div className={style.itens}>
              <label>FORNECEDOR</label>
              <select onChange={(e) => { setFornecedor(e.target.value) }}>
                <option disabled selected>Escolha uma opção</option>
                {fornecedores?.map((fornecedor) => {
                  return (
                    <option value={fornecedor.COD_FORNECEDORES}>{fornecedor.NOME_FORNECEDOR}</option>
                  )
                })}
              </select>
            </div>

            <div className={style.cnpj}>
              <Input type={"datetime-local"} text={"DATA E HORA"}
                onChange={(e) => setDataInicio(e.target.value)}
              />
            </div>

            <div className={style.itens}>
              <label>NAVIO</label>
              <select onChange={(e) => { setOperacao(e.target.value) }}>
                <option disabled selected>Selecione uma opção</option>
                {operacoes
                  .filter((operacao) => operacao.STATUS_OPERACAO.includes("DEFAULT") || operacao.STATUS_OPERACAO.includes("OPERANDO"))
                  .map((operacaoFiltrado) => (
                    <option value={operacaoFiltrado.COD_OPERACAO}>{operacaoFiltrado.NOME_NAVIO}</option>
                  ))}
              </select>
            </div>

            <div className={style.itens}>
              <label>LOCAL</label>
              <select onChange={(e) => { setBerco(e.target.value) }}>
                <option disabled selected>Escolha uma opção</option>
                {bercos?.map((berco) => {
                  return (
                    <option value={berco.COD_BERCO}>{berco.NOME_BERCO}</option>
                  )
                })}
              </select>
            </div>

            <div className={style.itens}>
              <label>EQUIPAMENTO</label>
              <select onChange={(e) => { setEquipamento(e.target.value) }}>
                <option disabled selected>Selecione uma opção</option>
                {equipamentos
                  .filter((equipamento) => equipamento.DESC_EQUIPAMENTO.includes("MOEGA"))
                  .map((equipamentoFiltrado) => (
                    <option value={equipamentoFiltrado.COD_EQUIPAMENTO}>{equipamentoFiltrado.DESC_EQUIPAMENTO}</option>
                  ))}
              </select>
            </div>

            <div className={style.itens}>
              <label>VALOR</label>
              <input
                type="number"
                onChange={(e) => setValor(e.target.value.toUpperCase())}
              />
            </div>
          </div>

          <div className={style.columnsmaq}>

            <div className={style.itensObs}>
              <label>OBSERVAÇÃO</label>
              <textarea
                onChange={(e) => setObservacao(e.target.value)}
              />
            </div>

            <div className={style.cnpj}>
              <label>Uso de Máquina?</label>
              <select onChange={(e) => setUsoDeMaquina(e.target.value === 'SIM')}>
                <option value="NÃO">NÃO</option>
                <option value="SIM">SIM</option>
              </select>
            </div>

            {usoDeMaquina && (
              <>
                <div className={style.cnpj}>
                  <label>FORNECEDOR MÁQ</label>
                  <select onChange={(e) => setFornecedorMaq(e.target.value)}>
                    <option disabled selected>Escolha uma opção</option>
                    {fornecedores.map((fornecedormaq) => (
                      <option value={fornecedormaq.COD_FORNECEDORES}>{fornecedormaq.NOME_FORNECEDOR}</option>
                    ))}
                  </select>
                </div>
                <div className={style.cnpj}>
                  <label>Anexar Recibo</label>
                  <input type="file" onChange={(e) => setImagemAnexada(e.target.files[0])} />
                </div>
              </>
            )}
          </div>

          <SubmitButton text="Cadastrar" onClick={cadastrarVigia} />

          <div className={style.dataFiltro}>
            <FaFileExcel
              onClick={exportToExcel}
              style={{ fontSize: '1.5em', color: 'green', cursor: 'pointer' }}
            />
            <label htmlFor="dataInicioFiltro">Filtrar Data: </label>
            <input
              type="date"
              id="dataInicioFiltro"
              value={dataInicioFiltro}
              onChange={(e) => setDataInicioFiltro(e.target.value)}
            />
            <label htmlFor="dataTerminoFiltro">- </label>
            <input
              type="date"
              id="dataTerminoFiltro"
              value={dataTerminoFiltro}
              onChange={(e) => setDataTerminoFiltro(e.target.value)}
            />
            <button className={style.limparFiltros} onClick={() => { setDataInicioFiltro(""); setDataTerminoFiltro(""); }}>
              Limpar Filtros
            </button>

          </div>

          <table className={style.table}>
            <thead>
              <tr>
                <th>Fornecedor</th>
                <th>Data Início</th>
                <th>Navio</th>
                <th>Local</th>
                <th>Moega</th>
                <th>Valor</th>
                <th>Data E-mail</th>
                <th>Solicitante da Puxada</th>
                <th>Data Pagamento</th>
                <th>Observação</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {puxadasLista.map((puxadas, index) => (
                <tr key={index}>
                  <td>{puxadas.NOME_FORNECEDOR}</td>
                  <td>{moment(puxadas.DATA_INICIO).format('DD/MM/YYYY - HH:mm')}</td>
                  <td>{puxadas.NOME_NAVIO}</td>
                  <td>{puxadas.NOME_BERCO}</td>
                  <td>{puxadas.DESC_EQUIPAMENTO}</td>
                  <td>R$ {parseFloat(puxadas.VALOR).toFixed(2)}</td>
                  <td>{puxadas.DATA_EMAIL_RECEBIMENTO ? moment(puxadas.DATA_EMAIL_RECEBIMENTO).format('DD/MM/YYYY') : ""}</td>
                  <td>{puxadas.USUARIO_CADASTRO}</td>
                  <td>
                    {editMode === puxadas.ID_PUXADAS ? (
                      // Campo de entrada para editar a "Data de Pagamento"
                      <input
                        type="date"
                        value={editedDataPagamento}
                        onChange={(e) => setEditedDataPagamento(e.target.value)}
                      />
                    ) : (
                      // Exibição da "Data de Pagamento" quando não está no modo de edição
                      puxadas.DATA_PAGAMENTO ? moment(puxadas.DATA_PAGAMENTO).format('DD/MM/YYYY') : ""
                    )}
                  </td>
                  <td>
                    {editMode === puxadas.ID_PUXADAS ? (
                      // Campo de entrada para editar a "Observação"
                      <input
                        type="text"
                        value={editedObservacao}
                        onChange={(e) => setEditedObservacao(e.target.value)}
                      />
                    ) : (
                      // Exibição da "Observação" quando não está no modo de edição
                      puxadas.OBSERVACAO
                    )}
                  </td>
                  <td>
                    {editMode === puxadas.ID_PUXADAS ? (
                      // Botões para salvar ou cancelar a edição
                      <>
                        <span onClick={() => handleSaveDataPagamento(puxadas.ID_PUXADAS)} style={{ cursor: 'pointer', marginRight: '5px' }}>✅</span>
                        <span onClick={handleCancelEditDataPagamento} style={{ cursor: 'pointer', marginRight: '5px' }}>❌</span>
                      </>
                    ) : (
                      // Ícone para entrar no modo de edição
                      <span onClick={() => handleEditDataPagamento(puxadas)} style={{ cursor: 'pointer' }}>✏️</span>
                    )}
                  </td>
                </tr>
              ))}

            </tbody>

          </table>
        </div>
      </Container>
    </>
  );
};

export default function IntegrationNotistack() {
  return (
    <SnackbarProvider
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      maxSnack={3}
      autoHideDuration={2500}
    >
      <Puxadas />
    </SnackbarProvider>
  );
}
