// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Input_form_control__929Hf {\n\twidth: 100%;\n\tmargin-top: 2vh;\n}\n\n.Input_form_control__929Hf {\n\tfont-size: 16px;\n\theight: 70px;\n}\n\n.Input_form_control__929Hf input{\n\tfont-size: 20px;\n\twidth: 75%;\n\tpadding: 8px 5px;\n\ttransition: 0.1s;\n\tmargin-top: 3px;\n\tfont-size: 15px;\n\tborder-radius: 3px;\n\tborder: 1px solid #ccc;\n}\n\n.Input_form_control__929Hf input:focus {\n\tborder: 1px solid #8ad4ee;\n\toutline: 3px solid #8ad4ee80;\n}", "",{"version":3,"sources":["webpack://./src/components/Input/Input.module.css"],"names":[],"mappings":"AAAA;CACC,WAAW;CACX,eAAe;AAChB;;AAEA;CACC,eAAe;CACf,YAAY;AACb;;AAEA;CACC,eAAe;CACf,UAAU;CACV,gBAAgB;CAChB,gBAAgB;CAChB,eAAe;CACf,eAAe;CACf,kBAAkB;CAClB,sBAAsB;AACvB;;AAEA;CACC,yBAAyB;CACzB,4BAA4B;AAC7B","sourcesContent":[".form_control {\n\twidth: 100%;\n\tmargin-top: 2vh;\n}\n\n.form_control {\n\tfont-size: 16px;\n\theight: 70px;\n}\n\n.form_control input{\n\tfont-size: 20px;\n\twidth: 75%;\n\tpadding: 8px 5px;\n\ttransition: 0.1s;\n\tmargin-top: 3px;\n\tfont-size: 15px;\n\tborder-radius: 3px;\n\tborder: 1px solid #ccc;\n}\n\n.form_control input:focus {\n\tborder: 1px solid #8ad4ee;\n\toutline: 3px solid #8ad4ee80;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form_control": "Input_form_control__929Hf"
};
export default ___CSS_LOADER_EXPORT___;
