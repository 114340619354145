import React, { useState } from "react";
import style from "./Login.module.css";
import { Link, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import logoReliance from '../../img/Logo_Reliance.png';


const Login = () => {
  const { login } = useAuth();
  const navigate = useNavigate();

  const [id, setId] = useState("");
  const [senha, setSenha] = useState("");
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleLogin = (e) => {
    e.preventDefault();
    if (!id || !senha) {
      setError("*Preencha todos os campos*");
      return;
    }

    const res = login(id.toLowerCase(), senha);

    if (res) {
      setError(res);
      return;
    }

    navigate("/dashboard");
  };

  return (
    <div className={style.wrapper}>
      <div className={style.loginBox}>
        <div className={style.loginHeader}>
        <img src={logoReliance} alt="Logo Reliance" />
        </div>
        <form onSubmit={handleLogin}>
          <div className={style.inputBox}>
            <input
              id="user"
              className={style.inputField}
              type="text"
              value={id}
              onChange={(e) => { setId(e.target.value); setError(""); }}
              required
            />
            <label htmlFor="user" className={style.label}>Usuário</label>
          </div>
          <div className={style.inputBox}>
            <input
              id="pass"
              className={style.inputField}
              type={showPassword ? "text" : "password"}
              value={senha}
              onChange={(e) => { setSenha(e.target.value); setError(""); }}
              required
            />
            <label htmlFor="pass" className={style.label}>Senha</label>
            <span
              className={style.eyeIcon}
              onClick={() => setShowPassword(!showPassword)}
            >
              {/* Ícone para mostrar/esconder senha */}
            </span>
          </div>
          {error && <div className={style.errorMsg}>{error}</div>}
          <button type="submit" className={style.inputSubmit}>ENTRAR</button>
        </form>
        <div className={style.register}>
          <span>Não tem uma conta?
            <Link to="/register"> Registrar</Link></span>
        </div>
      </div>
    </div>
  );
};

export default Login;
