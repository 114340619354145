import React, { useState, useEffect } from "react";
import Navbar from "../../../components/Navbar";
import Brackground from "../../../components/Background";
import Container from "../../../components/Container";
import Header from "../../../components/Header";
import style from "./Cotacao.module.css";
import Input from "../../../components/Input";
import SubmitButton from "../../../components/Button";
import Axios from "axios";
import InputMask from "react-input-mask";
import { useNavigate } from 'react-router-dom';
import moment from 'moment';


import { SnackbarProvider, useSnackbar } from "notistack";

const Cotacao = () => {
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();



  const [datainicio, setDataInicio] = useState("");
  const [datatermino, setDataTermino] = useState("");

  const [fornecedor, setFornecedor] = useState("");
  const [fornecedores, setFornecedores] = useState([]);

  const [equipamento, setEquipamento] = useState("");
  const [equipamentos, setEquipamentos] = useState([]);

  const [valor, setValor] = useState("");

  const [vigiasLista, setVigiasLista] = useState([]);

  const [editMode, setEditMode] = useState(null); // Armazena o ID do vigia em edição
  const [editedDataPagamento, setEditedDataPagamento] = useState(''); // Armazena a data de pagamento editada
  const [editedObservacao, setEditedObservacao] = useState('');


  const usuario = JSON.parse(localStorage.getItem("user_token")).id;

  // Função para mostrar alertas
  const showAlert = (message, variant) => {
    enqueueSnackbar(message, { variant: variant });
  };

  // Função para enviar dados do cliente
  const cadastrarVigia = async () => {
    if (!fornecedor || !datainicio || !datatermino || !equipamento) {
      showAlert("Preencha todos os campos", "error");
      return;
    }

    // Utilizando moment para obter a data e hora atuais formatadas
    const dataEmail = moment().format('YYYY-MM-DD'); // Ajuste para incluir apenas a data

    try {
      const response = await Axios.post(
        "https://hml.operacao.orion-op.com.br/api/vigias/cadastrar",
        {
          fornecedor: fornecedor,
          data_inicio: datainicio,
          data_termino: datatermino,
          equipamento: equipamento,
          valor: valor,
          data_email: dataEmail, // Incluindo a data e hora atuais
          usuario: usuario,
        }
      );

      showAlert("Vigia cadastrado com sucesso!", "success");
      // Aguarde 2 segundos (2000 milissegundos) antes de recarregar a página
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      console.error("Erro ao cadastrar o VIGIA:", error);
      showAlert("Erro ao cadastrar o VIGIA", "error");
    }
  };

  const handleEditDataPagamento = (vigias) => {
    setEditedDataPagamento(vigias.DATA_PAGAMENTO ? moment(vigias.DATA_PAGAMENTO).format('YYYY-MM-DD') : '');
    setEditedObservacao(vigias.OBSERVACAO || ''); // Define a observação atual ou uma string vazia se não houver
    setEditMode(vigias.ID_VIGIAS);
  };


  const handleCancelEditDataPagamento = () => {
    setEditMode(null);
    setEditedDataPagamento('');
  };

  const handleSaveDataPagamento = (idVigia) => {
    // Lógica para enviar a nova DATA_PAGAMENTO e OBSERVACAO ao servidor
    // Exemplo:
    Axios.put('https://hml.operacao.orion-op.com.br/api/vigias/atualizar', {
      idVigias: idVigia,
      dataPagamento: editedDataPagamento,
      observacao: editedObservacao
    })
      .then(response => {
        showAlert("Dados atualizados com sucesso!", "success");
        setEditMode(null);
        setEditedDataPagamento('');
        setEditedObservacao('');
        atualizarListaVigias();
      })
      .catch(error => {
        showAlert("Erro ao atualizar os dados", "error");
        console.error("Erro ao atualizar os dados:", error);
      });
  };

  const atualizarListaVigias = () => {
    Axios.get("https://hml.operacao.orion-op.com.br/api/custeio/vigias")
      .then(response => {
        setVigiasLista(response.data);
      })
      .catch(error => {
        console.error("Erro ao buscar dados atualizados: ", error);
        showAlert("Erro ao buscar dados atualizados", "error");
      });
  };


  useEffect(() => {
    Axios.get("https://hml.operacao.orion-op.com.br/api/equipamentos")
      .then((response) => {
        setEquipamentos(response.data);
      })
      .catch((error) => {
        console.error("Erro ao buscar equipamentos: ", error);
        showAlert("Erro ao buscar equipamentos", "error");
      });
  }, []);

  useEffect(() => {
    Axios.get("https://hml.operacao.orion-op.com.br/api/fornecedores")
      .then((response) => {
        setFornecedores(response.data);
      })
      .catch((error) => {
        console.error("Erro ao buscar os fornecedores: ", error);
        showAlert("Erro ao buscar os fornecedores", "error");
      });
  }, []);

  useEffect(() => {
    Axios.get("https://hml.operacao.orion-op.com.br/api/custeio/vigias")
      .then((response) => {
        setVigiasLista(response.data);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados: ", error);
        showAlert("Erro ao buscar dados", "error");
      });
  }, []);

  return (
    <>
      <Navbar veiculos />
      <Header />
      <Brackground />
      <Container>
        <div className={style.content}>

          <div className={style.nav}>
            <div className={style.navItem} onClick={() => navigate('/suprimentos/dashboardsuprimentos')}>
              Dashboard
            </div>

            <div className={style.navItem} onClick={() => navigate('/suprimentos/combustivel')}>
              Combustível
            </div>

            <div className={style.active}>
              Cotação
            </div>
          </div>


          <div className={style.columns}>
            <div className={style.itens}>
              <label>FORNECEDOR</label>
              <select onChange={(e) => { setFornecedor(e.target.value) }}>
                <option disabled selected>Escolha uma opção</option>
                {fornecedores?.map((fornecedor) => {
                  return (
                    <option value={fornecedor.COD_FORNECEDORES}>{fornecedor.NOME_FORNECEDOR}</option>
                  )
                })}
              </select>
            </div>

            <div className={style.cnpj}>
              <Input type={"datetime-local"} text={"Data (Início)"}
                onChange={(e) => setDataInicio(e.target.value)}
              />
            </div>

            <div className={style.cnpj}>
              <Input type={"datetime-local"} text={"Data (Término)"}
                onChange={(e) => setDataTermino(e.target.value)}
              />
            </div>

            <div className={style.itens}>
              <label>MOEGA</label>
              <select onChange={(e) => { setEquipamento(e.target.value) }}>
                <option disabled selected>Selecione uma opção</option>
                {equipamentos
                  .filter((equipamento) => equipamento.DESC_EQUIPAMENTO.includes("MOEGA"))
                  .map((equipamentoFiltrado) => (
                    <option value={equipamentoFiltrado.COD_EQUIPAMENTO}>{equipamentoFiltrado.DESC_EQUIPAMENTO}</option>
                  ))}
              </select>
            </div>

            <div className={style.itens}>
              <label>VALOR</label>
              <input
                type="number"
                onChange={(e) => setValor(e.target.value.toUpperCase())}
              />
            </div>

            <div className={style.submitButton}>
              <SubmitButton text="Cadastrar" onClick={cadastrarVigia} />
            </div>
          </div>

          <table className={style.table}>
            <thead>
              <tr>
                <th>Fornecedor</th>
                <th>Data Início</th>
                <th>Data Término</th>
                <th>Moega</th>
                <th>Valor</th>
                <th>Venc. Pagamento</th>
                <th>Recebimento de E-mail</th>
                <th>Usuário Registrado</th>
                <th>Observação</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {vigiasLista.map((vigias, index) => (
                <tr key={index}>
                  <td>{vigias.NOME_FORNECEDOR}</td>
                  <td>{moment(vigias.DATA_INICIO).format('DD/MM/YYYY - HH:mm')}</td>
                  <td>{moment(vigias.DATA_TERMINO).format('DD/MM/YYYY - HH:mm')}</td>
                  <td>{vigias.DESC_EQUIPAMENTO}</td>
                  <td>R$ {parseFloat(vigias.VALOR).toFixed(2)}</td>
                  <td>
                    {editMode === vigias.ID_VIGIAS ? (
                      <input
                        type="date"
                        value={editedDataPagamento}
                        onChange={(e) => setEditedDataPagamento(e.target.value)}
                      />
                    ) : (
                      vigias.DATA_PAGAMENTO ? moment(vigias.DATA_PAGAMENTO).format('DD/MM/YYYY') : ""
                    )}
                  </td>
                  <td>{vigias.DATA_EMAIL_RECEBIMENTO ? moment(vigias.DATA_EMAIL_RECEBIMENTO).format('DD/MM/YYYY') : ""}</td>
                  <td>{vigias.USUARIO_CADASTRO}</td>
                  <td>
                    {editMode === vigias.ID_VIGIAS ? (
                      <input
                        type="text"
                        value={editedObservacao}
                        onChange={(e) => setEditedObservacao(e.target.value)}
                      />
                    ) : (
                      vigias.OBSERVACAO
                    )}
                  </td>
                  <td>
                    {editMode === vigias.ID_VIGIAS ? (
                      <>
                        <span onClick={() => handleSaveDataPagamento(vigias.ID_VIGIAS)} style={{ cursor: 'pointer', margin: '0 5px' }}>✅</span>
                        <span onClick={handleCancelEditDataPagamento} style={{ cursor: 'pointer', margin: '0 5px' }}>❌</span>
                      </>
                    ) : (
                      <span onClick={() => handleEditDataPagamento(vigias)} style={{ cursor: 'pointer' }}>✏️</span>
                    )}
                  </td>
                </tr>
              ))}

            </tbody>

          </table>
        </div>
      </Container>
    </>
  );
};

export default function IntegrationNotistack() {
  return (
    <SnackbarProvider
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      maxSnack={3}
      autoHideDuration={2500}
    >
      <Cotacao />
    </SnackbarProvider>
  );
}
