import React, { useState, useEffect } from "react";
import Navbar from "../../../components/Navbar";
import Brackground from "../../../components/Background";
import Container from "../../../components/Container";
import Header from "../../../components/Header";
import style from "./FichaRegistro.module.css";
import Input from "../../../components/Input";
import SubmitButton from "../../../components/Button";
import Axios from "axios";
import InputMask from "react-input-mask";
import MaskedInput from '../../../components/InputMask';
import { useNavigate } from 'react-router-dom';
import modal from "./Modal.module.css";
import moment from 'moment';
import { SnackbarProvider, useSnackbar } from "notistack";


function Dialog({ isOpen, handleClose, children }) {
  if (!isOpen) return null;

  return (
    <div className={modal.Backdrop}>
      <div className={modal.Content}>
        {children}
      </div>
    </div>
  );
}

const FichaRegistro = () => {
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();


  const toggleFormVisibility = () => setIsFormVisible(!isFormVisible); // Função para alternar a visibilidade

  const [registrofuncionario, setRegistroFuncionario] = useState([]);

  const [nome, setNome] = useState("");

  const [nomedependente01, setNomeDependente01] = useState("");
  const [cpfdependente01, setCPFDependente01] = useState("");
  const [datadependente01, setDataDependente01] = useState("");
  const [grauParentesco01, setGrauParentesco01] = useState("");

  const [nomedependente02, setNomeDependente02] = useState("");
  const [cpfdependente02, setCPFDependente02] = useState("");
  const [datadependente02, setDataDependente02] = useState("");
  const [grauParentesco02, setGrauParentesco02] = useState("");

  const [nomedependente03, setNomeDependente03] = useState("");
  const [cpfdependente03, setCPFDependente03] = useState("");
  const [datadependente03, setDataDependente03] = useState("");
  const [grauParentesco03, setGrauParentesco03] = useState("");

  const [nomedependente04, setNomeDependente04] = useState("");
  const [cpfdependente04, setCPFDependente04] = useState("");
  const [datadependente04, setDataDependente04] = useState("");
  const [grauParentesco04, setGrauParentesco04] = useState("");

  const [nomedependente05, setNomeDependente05] = useState("");
  const [cpfdependente05, setCPFDependente05] = useState("");
  const [datadependente05, setDataDependente05] = useState("");
  const [grauParentesco05, setGrauParentesco05] = useState("");

  const [contrato, setContrato] = useState("");
  const [matricula_esocial, setMatriculaEsocial] = useState("");
  const [nome_pai, setNomePai] = useState("");
  const [nome_mae, setNomeMae] = useState("");
  const [data_nascimento, setDataNascimento] = useState("");
  const [raca_cor, setRacaCor] = useState("");
  const [sexo, setSexo] = useState("");
  const [deficiente, setDeficiente] = useState("");
  const [tipo_deficiencia, setTipoDeficiencia] = useState("");
  const [tipo_sanguineo, setTipoSanguineo] = useState("");
  const [naturalidade, setNaturalidade] = useState("");
  const [estado, setEstado] = useState("");
  const [nacionalidade, setNacionalidade] = useState("");
  const [chegada_brasil, setChegadaBrasil] = useState("");
  const [cpf, setCpf] = useState("");
  const [identidade, setIdentidade] = useState("");
  const [data_emissao, setDataEmissao] = useState("");
  const [orgao_uf, setOrgaoUf] = useState("");
  const [habilitacao, setHabilitacao] = useState("");
  const [categoria_habilitacao, setCategoriaHabilitacao] = useState("");
  const [validade_habilitacao, setValidadeHabilitacao] = useState("");
  const [ctps, setCtps] = useState("");
  const [serie_ctps, setSerieCtps] = useState("");
  const [digito_ctps, setDigitoCtps] = useState("");
  const [carteira_reservista, setCarteiraReservista] = useState("");
  const [banco, setBanco] = useState("");
  const [agencia, setAgencia] = useState("");
  const [conta_corrente, setContaCorrente] = useState("");
  const [digito_conta, setDigitoConta] = useState("");
  const [titulo_eleitor, setTituloEleitor] = useState("");
  const [zona_eleitoral, setZonaEleitoral] = useState("");
  const [secao_eleitoral, setSecaoEleitoral] = useState("");

  const [grau_instrucao, setGrauInstrucao] = useState("");

  const [numero_pis, setNumeroPis] = useState("");
  const [estado_civil, setEstadoCivil] = useState("");
  const [endereco, setEndereco] = useState("");
  const [numero_endereco, setNumeroEndereco] = useState("");
  const [complemento, setComplemento] = useState("");
  const [bairro, setBairro] = useState("");
  const [cidade, setCidade] = useState("");
  const [estadoendereco, setEstadoEndereco] = useState("");
  const [cep, setCep] = useState("");
  const [telefone, setTelefone] = useState("");
  const [email, setEmail] = useState("");
  const [celular, setCelular] = useState("");
  const [data_admissao, setDataAdmissao] = useState("");
  const [funcao, setFuncao] = useState("");
  const [cbo, setCbo] = useState("");
  const [salario_inicial, setSalarioInicial] = useState("");
  const [tipo_pagamento, setTipoPagamento] = useState("");
  const [insalubridade, setInsalubridade] = useState("");
  const [periculosidade, setPericulosidade] = useState("");
  const [comissao, setComissao] = useState("");
  const [categoria, setCategoria] = useState("");
  const [sindicato, setSindicato] = useState("");

  const [centro_de_custo, setCentroDeCusto] = useState([]);
  const [centro_custo, setCentroCusto] = useState("");

  const [localizacao, setLocalizacao] = useState("");
  const [horario, setHorario] = useState("");
  const [data_rescisao, setDataRescisao] = useState("");
  const [aviso_previo, setAvisoPrevio] = useState("");
  const [saldo_fgts, setSaldoFgts] = useState("");
  const [maior_remuneracao, setMaiorRemuneracao] = useState("");
  const [recolheu_fgts, setRecolheuFgts] = useState("");
  const [causa_rescisao, setCausaRescisao] = useState("");

  const [vigiasLista, setVigiasLista] = useState([]);

  const [isFormVisible, setIsFormVisible] = useState(false);

  const [mostrarDependente1, setMostrarDependente1] = useState(false);
  const [mostrarDependente2, setMostrarDependente2] = useState(false);
  const [mostrarDependente3, setMostrarDependente3] = useState(false);
  const [mostrarDependente4, setMostrarDependente4] = useState(false);
  const [mostrarDependente5, setMostrarDependente5] = useState(false);

  const [selectedFuncionario, setSelectedFuncionario] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleRowClick = (funcionario) => {
    setSelectedFuncionario(funcionario); // Guarda o funcionário selecionado no estado
    setIsModalOpen(true); // Abre o modal
  };



  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedFuncionario(null); // Opcional: Limpa o funcionário selecionado
  };


  const toggleMostrarDependente1 = () => {

    if (mostrarDependente1) {
      setMostrarDependente2(false);
      setMostrarDependente3(false);
      setMostrarDependente4(false);
      setMostrarDependente5(false);
    }
    setMostrarDependente1(!mostrarDependente1);
  };

  const toggleMostrarDependente2 = () => {

    if (mostrarDependente2) {
      setMostrarDependente3(false);
      setMostrarDependente4(false);
    }
    setMostrarDependente2(!mostrarDependente2);
  };


  const toggleMostrarDependente3 = () => {

    if (mostrarDependente3) {
      setMostrarDependente4(false);
    }
    setMostrarDependente3(!mostrarDependente3);
  };

  const toggleMostrarDependente4 = () => {

    if (mostrarDependente4) {
      setMostrarDependente5(false);
    }
    setMostrarDependente4(!mostrarDependente4);
  };

  const toggleMostrarDependente5 = () => {
    setMostrarDependente5(!mostrarDependente5);
  };

  const usuario = JSON.parse(localStorage.getItem("user_token")).id;

  // Função para mostrar alertas
  const showAlert = (message, variant) => {
    enqueueSnackbar(message, { variant: variant });
  };

  const cadastrarFuncionario = async () => {
    // Objeto simplificado, com dados diretamente prontos para envio
    const dadosParaEnvio = {
      CONTRATO: contrato,
      NOME: nome,
      MATRICULA_ESOCIAL: matricula_esocial,
      NOME_PAI: nome_pai || null,
      NOME_MAE: nome_mae,
      DATA_NASCIMENTO: moment(data_nascimento).format("YYYY-MM-DD"),
      RACA_COR: raca_cor,
      SEXO: sexo,

      DEPENDENTE_01: nomedependente01 || null,
      DATA_NASC_DEPENDENTE_01: datadependente01 ? moment(datadependente01).format("YYYY-MM-DD") : "" || null,
      CPF_DEPENDENTE_01: cpfdependente01 || null,
      GRAU_PARENTESCO_01: grauParentesco01 || null,

      DEPENDENTE_02: nomedependente02 || null,
      DATA_NASC_DEPENDENTE_02: datadependente02 ? moment(datadependente02).format("YYYY-MM-DD") : "" || null,
      CPF_DEPENDENTE_02: cpfdependente02 || null,
      GRAU_PARENTESCO_02: grauParentesco02 || null,

      DEPENDENTE_03: nomedependente03 || null,
      DATA_NASC_DEPENDENTE_03: datadependente03 ? moment(datadependente03).format("YYYY-MM-DD") : "" || null,
      CPF_DEPENDENTE_03: cpfdependente03 || null,
      GRAU_PARENTESCO_03: grauParentesco03 || null,

      DEPENDENTE_04: nomedependente04 || null,
      DATA_NASC_DEPENDENTE_04: datadependente04 ? moment(datadependente04).format("YYYY-MM-DD") : "" || null,
      CPF_DEPENDENTE_04: cpfdependente04 || null,
      GRAU_PARENTESCO_04: grauParentesco04 || null,

      DEPENDENTE_05: nomedependente05 || null,
      DATA_NASC_DEPENDENTE_05: datadependente05 ? moment(datadependente05).format("YYYY-MM-DD") : "" || null,
      CPF_DEPENDENTE_05: cpfdependente05 || null,
      GRAU_PARENTESCO_05: grauParentesco05 || null,

      DEFICIENTE: deficiente,
      TIPO_DEFICIENCIA: tipo_deficiencia || null,
      TIPO_SANGUINEO: tipo_sanguineo || null,
      NATURALIDADE: naturalidade,
      ESTADO: estado,
      NACIONALIDADE: nacionalidade,
      CHEGADA_BRASIL: chegada_brasil ? moment(chegada_brasil).format("YYYY-MM-DD") : "" || null,
      CPF: cpf,
      IDENTIDADE: identidade,
      DATA_EMISSAO: data_emissao ? moment(data_emissao).format("YYYY-MM-DD") : "" || null,
      ORGAO_UF: orgao_uf,
      HABILITACAO: habilitacao,
      CATEGORIA_HABILITACAO: categoria_habilitacao || null,
      VALIDADE_HABILITACAO: validade_habilitacao ? moment(validade_habilitacao).format("YYYY-MM-DD") : "" || null,
      CTPS: ctps,
      SERIE_CTPS: serie_ctps,
      DIGITO_CTPS: digito_ctps || null,
      CARTEIRA_RESERVISTA: carteira_reservista || null,
      BANCO: banco,
      AGENCIA: agencia,
      CONTA_CORRENTE: conta_corrente,
      DIGITO_CONTA: digito_conta,
      TITULO_ELEITOR: titulo_eleitor,
      ZONA_ELEITORAL: zona_eleitoral,
      SECAO_ELEITORAL: secao_eleitoral,
      GRAU_INSTRUCAO: grau_instrucao,
      NUMERO_PIS: numero_pis,
      ESTADO_CIVIL: estado_civil,
      ENDERECO: endereco,
      NUMERO_ENDERECO: numero_endereco,
      COMPLEMENTO: complemento || null,
      BAIRRO: bairro,
      CIDADE: cidade,
      ESTADOENDERECO: estadoendereco,
      CEP: cep,
      TELEFONE: telefone || null,
      EMAIL: email,
      CELULAR: celular,
      DATA_ADMISSAO: data_admissao ? moment(data_admissao).format("YYYY-MM-DD") : "",
      FUNCAO: funcao,
      CBO: cbo,
      SALARIO_INICIAL: salario_inicial,
      TIPO_PAGAMENTO: tipo_pagamento,
      INSALUBRIDADE: insalubridade || null,
      PERICULOSIDADE: periculosidade || null,
      COMISSAO: comissao || null,
      CATEGORIA: categoria,
      SINDICATO: sindicato,
      CENTRO_CUSTO: centro_custo,
      LOCALIZACAO: localizacao,
      HORARIO: horario,
      DATA_RESCISAO: data_rescisao ? moment(data_rescisao).format("YYYY-MM-DD") : "" || null,
      AVISO_PREVIO: aviso_previo ? moment(aviso_previo).format("YYYY-MM-DD") : "" || null,
      SALDO_FGTS: saldo_fgts || null,
      MAIOR_REMUNERACAO: maior_remuneracao || null,
      RECOLHEU_FGTS: recolheu_fgts || null,
      CAUSA_RESCISAO: causa_rescisao || null,
    };

    const camposObrigatorios = [
      "CONTRATO",
      "NOME",
      "MATRICULA_ESOCIAL",
      "NOME_MAE",
      "DATA_NASCIMENTO",
      "RACA_COR",
      "SEXO",
      "DEFICIENTE",
      "NATURALIDADE",
      "ESTADO",
      "NACIONALIDADE",
      "CPF",
      "IDENTIDADE",
      "ORGAO_UF",
      "HABILITACAO",
      "CTPS",
      "SERIE_CTPS",
      "BANCO",
      "AGENCIA",
      "CONTA_CORRENTE",
      "DIGITO_CONTA",
      "TITULO_ELEITOR",
      "ZONA_ELEITORAL",
      "SECAO_ELEITORAL",
      "GRAU_INSTRUCAO",
      "NUMERO_PIS",
      "ESTADO_CIVIL",
      "ENDERECO",
      "NUMERO_ENDERECO",
      "BAIRRO",
      "CIDADE",
      "ESTADOENDERECO",
      "CEP",
      "EMAIL",
      "CELULAR",
      "DATA_ADMISSAO",
      "FUNCAO",
      "CBO",
      "SALARIO_INICIAL",
      "TIPO_PAGAMENTO",
      "CATEGORIA",
      "SINDICATO",
      "CENTRO_CUSTO",
      "LOCALIZACAO",
      "HORARIO",
    ];

    // Verifica cada campo obrigatório
    let campoFaltante = camposObrigatorios.find(campo => !dadosParaEnvio[campo]);

    if (campoFaltante) {
      showAlert(`O campo "${campoFaltante}" é obrigatório e não foi preenchido.`, "error");
      return;
    }

    try {
      const response = await Axios.post("https://hml.operacao.orion-op.com.br/api/rh/cadastrar", dadosParaEnvio);
      showAlert("REGISTRO_FUNCIONARIOS cadastrado com sucesso!", "success");
      // Após o sucesso, você pode querer redirecionar o usuário ou limpar o formulário
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      console.error("Erro ao tentar cadastrar:", error);
      const errorMessage = error.response?.data?.message || "Erro desconhecido no servidor";
      showAlert(`Erro ao cadastrar o funcionário: ${errorMessage}`, "error");
    }
  };



  useEffect(() => {
    Axios.get("https://hml.operacao.orion-op.com.br/api/centrocusto")
      .then((response) => {
        setCentroDeCusto(response.data);
      })
      .catch((error) => {
        console.error("Erro ao buscar os centros de custos: ", error);
        showAlert("Erro ao buscar os centros de custos", "error");
      });
  }, []);

  useEffect(() => {
    Axios.get("https://hml.operacao.orion-op.com.br/api/registrofuncionario")
      .then((response) => {
        setRegistroFuncionario(response.data);
      })
      .catch((error) => {
        console.error("Erro ao buscar os registros dos funcionarios: ", error);
        showAlert("Erro ao buscar os registros dos funcionarios", "error");
      });
  }, []);


  useEffect(() => {
    Axios.get("https://hml.operacao.orion-op.com.br/api/custeio/vigias")
      .then((response) => {
        setVigiasLista(response.data);
      })
      .catch((error) => {
        console.error("Erro ao buscar dados: ", error);
        showAlert("Erro ao buscar dados", "error");
      });
  }, []);

  const formatarCPF = (cpf) => {
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  };

  function formatCelular(celular) {
    // Pattern matches the area code, the next five digits, and the last four digits
    return celular.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
  };

  function formatSalario(salario) {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    }).format(salario);
  };

  return (
    <>
      <Navbar veiculos />
      <Header />
      <Brackground />
      <Container>
        <div className={style.content}>

          <div className={style.nav}>
            <div className={style.active}>
              Ficha Registro
            </div>

            <div className={style.navItem}>
              A Definir
            </div>
          </div>

          <table className={style.table}>
            <thead>
              <tr>
                <th>Nome</th>
                <th>CPF</th>
                <th>Cargo</th>
                <th>Data de Admissão</th>
                <th>Salário Inicial</th> {/* Se necessário */}
                <th></th>
              </tr>
            </thead>
            <tbody>
              {registrofuncionario.map((funcionario, index) => (
                <tr key={index} onClick={() => handleRowClick(funcionario)}>
                  <td>{funcionario.NOME}</td>
                  <td>{formatarCPF(funcionario.CPF)}</td>
                  <td>{funcionario.FUNCAO}</td>
                  <td>{moment(funcionario.DATA_ADMISSAO).format('DD/MM/YYYY')}</td>
                  <td>R$ {funcionario.SALARIO_INICIAL.toFixed(2)}</td>
                </tr>
              ))}
            </tbody>

          </table>

          <button onClick={toggleFormVisibility} className={style.addButton}>
            {isFormVisible ? '-' : '+'} {/* Texto do botão muda com o estado */}
          </button>

          {isFormVisible && (
            <>
              <div className={style.linethree}>
                <div className={style.cnpj}>
                  <Input type={"text"} text={"Nome Completo (*)"}
                    onChange={(e) => setNome(e.target.value.toUpperCase())}
                  />
                </div>

                <div className={style.cnpj}>
                  <Input type={"text"} text={"Contrato (*)"}
                    onChange={(e) => setContrato(e.target.value.toUpperCase())}
                  />
                </div>

                <div className={style.cnpj}>
                  <Input type={"text"} text={"Matricula ESocial (*)"}
                    onChange={(e) => setMatriculaEsocial(e.target.value.toUpperCase())}
                  />
                </div>
              </div>

              <div className={style.linetwo}>
                <div className={style.cnpj}>
                  <Input type={"text"} text={"Nome Pai"}
                    onChange={(e) => setNomePai(e.target.value.toUpperCase())}
                  />
                </div>

                <div className={style.cnpj}>
                  <Input type={"text"} text={"Nome Mãe (*)"}
                    onChange={(e) => setNomeMae(e.target.value.toUpperCase())}
                  />
                </div>
              </div>

              <div className={style.linethree}>
                <div className={style.cnpj}>
                  <Input type={"date"} text={"Data de Nascimento (*)"}
                    onChange={(e) => setDataNascimento(e.target.value)}
                  />
                </div>

                <div className={style.itens}>
                  <label for="sexo">Raça/Cor (*):</label>
                  <select id="racaCor" name="racaCor" onChange={(e) => setRacaCor(e.target.value)}>
                    <option value="" disabled selected>Selecione</option>
                    <option value="BRANCA">Branca</option>
                    <option value="PRETA">Preta</option>
                    <option value="AMARELA">Amarela</option>
                    <option value="PARDA">Parda</option>
                    <option value="INDIGENA">Indígena</option>
                    <option value="SEM DECLARACAO">Sem declaração</option>
                  </select>

                </div>

                <div className={style.itens}>
                  <label for="sexo">Sexo (*):</label>
                  <select id="sexo" name="sexo" onChange={(e) => setSexo(e.target.value)}>
                    <option value="" disabled selected>Selecione</option>
                    <option value="Masculino">Masculino</option>
                    <option value="Feminino">Feminino</option>
                    <option value="Transgenero">Transgênero</option>
                    <option value="Neutro">Gênero Neutro</option>
                    <option value="Binario">Não-Binário</option>
                    <option value="Agenero">Agênero</option>
                    <option value="Pangenero">Pangênero</option>
                    <option value="Genderqueer">Genderqueer</option>
                    <option value="Two-Spirit">Two-Spirit</option>
                  </select>
                </div>
              </div>

              <div className={style.lineone}>
                <button className={style.addButton} onClick={toggleMostrarDependente1}>
                  {mostrarDependente1 ? '-' : '+'}
                </button>
                <span>1° Dependente</span>
              </div>

              <div className={style.linedependente}>

                {mostrarDependente1 && (
                  <>
                    <div className={style.linefour}>
                      <div className={style.labeldependente}>
                        <Input type={"text"} text={"Nome"}
                          onChange={(e) => setNomeDependente01(e.target.value.toUpperCase())}
                        />
                      </div>

                      <div className={style.cnpj}>
                        <MaskedInput
                          text="CPF (*)"
                          mask="999.999.999-99"
                          placeholder="000.000.000-00"
                          onChange={(e) => setCPFDependente01(e.target.value)}
                        />
                      </div>

                      <div className={style.cnpj}>
                        <Input type={"date"} text={"Data de Nascimento"}
                          onChange={(e) => setDataDependente01(e.target.value)}
                        />
                      </div>

                      <div className={style.itens}>
                        <label htmlFor="grauParentesco01">Grau de Parentesco:</label>
                        <select id="grauParentesco01"
                          onChange={(e) => setGrauParentesco01(e.target.value)}
                          defaultValue="">
                          <option value="" disabled>Selecione o grau de parentesco</option>
                          <option value="FILHO(A)">Filho(a)</option>
                          <option value="ESPOSA">Esposa</option>
                          <option value="MARIDO">Marido</option>
                          <option value="MÃE">Mãe</option>
                          <option value="PAI">Pai</option>
                        </select>
                      </div>

                    </div>
                    <div className={style.lineone}>
                      <button className={style.addButton} onClick={toggleMostrarDependente2}>
                        {mostrarDependente2 ? '-' : '+'}
                      </button>
                      <span>2° Dependente</span>
                    </div>
                  </>
                )}

                <div className={style.linedependente}>

                  {mostrarDependente2 && (
                    <>
                      <div className={style.linefour}>
                        <div className={style.labeldependente}>
                          <Input type={"text"} text={"Nome"}
                            onChange={(e) => setNomeDependente02(e.target.value.toUpperCase())}
                          />
                        </div>

                        <div className={style.cnpj}>
                          <MaskedInput
                            text="CPF (*)"
                            mask="999.999.999-99"
                            placeholder="000.000.000-00"
                            onChange={(e) => setCPFDependente02(e.target.value)}
                          />
                        </div>

                        <div className={style.cnpj}>
                          <Input type={"date"} text={"Data de Nascimento"}
                            onChange={(e) => setDataDependente02(e.target.value)}
                          />
                        </div>

                        <div className={style.itens}>
                          <label htmlFor="grauParentesco02">Grau de Parentesco:</label>
                          <select id="grauParentesco02"
                            onChange={(e) => setGrauParentesco02(e.target.value)}
                            defaultValue="">
                            <option value="" disabled>Selecione o grau de parentesco</option>
                            <option value="FILHO(A)">Filho(a)</option>
                            <option value="ESPOSA">Esposa</option>
                            <option value="MARIDO">Marido</option>
                            <option value="MÃE">Mãe</option>
                            <option value="PAI">Pai</option>
                          </select>
                        </div>

                      </div>
                      <div className={style.lineone}>
                        <button className={style.addButton} onClick={toggleMostrarDependente3}>
                          {mostrarDependente3 ? '-' : '+'}
                        </button>
                        <span>3° Dependente</span>
                      </div>
                    </>
                  )}
                </div>

                <div className={style.linedependente}>

                  {mostrarDependente3 && (
                    <>
                      <div className={style.linefour}>
                        <div className={style.labeldependente}>
                          <Input type={"text"} text={"Nome"}
                            onChange={(e) => setNomeDependente03(e.target.value.toUpperCase())}
                          />
                        </div>

                        <div className={style.cnpj}>
                          <MaskedInput
                            text="CPF (*)"
                            mask="999.999.999-99"
                            placeholder="000.000.000-00"
                            onChange={(e) => setCPFDependente03(e.target.value)}
                          />
                        </div>

                        <div className={style.cnpj}>
                          <Input type={"date"} text={"Data de Nascimento"}
                            onChange={(e) => setDataDependente03(e.target.value)}
                          />
                        </div>

                        <div className={style.itens}>
                          <label htmlFor="grauParentesco03">Grau de Parentesco:</label>
                          <select id="grauParentesco03"
                            onChange={(e) => setGrauParentesco03(e.target.value)}
                            defaultValue="">
                            <option value="" disabled>Selecione o grau de parentesco</option>
                            <option value="FILHO(A)">Filho(a)</option>
                            <option value="ESPOSA">Esposa</option>
                            <option value="MARIDO">Marido</option>
                            <option value="MÃE">Mãe</option>
                            <option value="PAI">Pai</option>
                          </select>
                        </div>

                      </div>

                      <div className={style.lineone}>
                        <button className={style.addButton} onClick={toggleMostrarDependente4}>
                          {mostrarDependente4 ? '-' : '+'}
                        </button>
                        <span>4° Dependente</span>
                      </div>
                    </>
                  )}
                </div>

                <div className={style.linedependente}>

                  {mostrarDependente4 && (
                    <>
                      <div className={style.linefour}>
                        <div className={style.labeldependente}>
                          <Input type={"text"} text={"Nome"}
                            onChange={(e) => setNomeDependente04(e.target.value.toUpperCase())}
                          />
                        </div>

                        <div className={style.cnpj}>
                          <MaskedInput
                            text="CPF (*)"
                            mask="999.999.999-99"
                            placeholder="000.000.000-00"
                            onChange={(e) => setCPFDependente04(e.target.value)}
                          />
                        </div>

                        <div className={style.cnpj}>
                          <Input type={"date"} text={"Data de Nascimento"}
                            onChange={(e) => setDataDependente04(e.target.value)}
                          />
                        </div>

                        <div className={style.itens}>
                          <label htmlFor="grauParentesco04">Grau de Parentesco:</label>
                          <select id="grauParentesco04"
                            onChange={(e) => setGrauParentesco04(e.target.value)}
                            defaultValue="">
                            <option value="" disabled>Selecione o grau de parentesco</option>
                            <option value="FILHO(A)">Filho(a)</option>
                            <option value="ESPOSA">Esposa</option>
                            <option value="MARIDO">Marido</option>
                            <option value="MÃE">Mãe</option>
                            <option value="PAI">Pai</option>
                          </select>
                        </div>
                      </div>
                      <div className={style.lineone}>
                        <button className={style.addButton} onClick={toggleMostrarDependente5}>
                          {mostrarDependente5 ? '-' : '+'}
                        </button>
                        <span>5° Dependente</span>
                      </div>
                    </>
                  )}
                </div>

                <div className={style.linedependente}>

                  {mostrarDependente5 && (

                    <>
                      <div className={style.linefour}>
                        <div className={style.labeldependente}>
                          <Input type={"text"} text={"Nome"}
                            onChange={(e) => setNomeDependente05(e.target.value.toUpperCase())}
                          />
                        </div>

                        <div className={style.cnpj}>
                          <MaskedInput
                            text="CPF (*)"
                            mask="999.999.999-99"
                            placeholder="000.000.000-00"
                            onChange={(e) => setCPFDependente05(e.target.value)}
                          />
                        </div>

                        <div className={style.cnpj}>
                          <Input type={"date"} text={"Data de Nascimento"}
                            onChange={(e) => setDataDependente05(e.target.value)}
                          />
                        </div>

                        <div className={style.itens}>
                          <label htmlFor="grauParentesco05">Grau de Parentesco:</label>
                          <select id="grauParentesco05"
                            onChange={(e) => setGrauParentesco05(e.target.value)}
                            defaultValue="">
                            <option value="" disabled>Selecione o grau de parentesco</option>
                            <option value="FILHO(A)">Filho(a)</option>
                            <option value="ESPOSA">Esposa</option>
                            <option value="MARIDO">Marido</option>
                            <option value="MÃE">Mãe</option>
                            <option value="PAI">Pai</option>
                          </select>
                        </div>
                      </div>
                    </>
                  )}
                </div>

              </div>

              <div className={style.linethree}>
                <div className={style.itens}>
                  <label for="sangue">Deficiente (*):</label>
                  <select id="sangue" name="sangue" onChange={(e) => setDeficiente(e.target.value)}>
                    <option value="" disabled selected>Selecione</option>
                    <option value="S">Sim</option>
                    <option value="N">Não</option>
                  </select>
                </div>

                <div className={style.cnpj}>
                  <Input type={"text"} text={"Tipo de Deficiência"}
                    onChange={(e) => setTipoDeficiencia(e.target.value)}
                  />
                </div>

                <div className={style.itens}>
                  <label for="sangue">Tipo Sanguíneo:</label>
                  <select id="sangue" name="sangue" onChange={(e) => setTipoSanguineo(e.target.value)}>
                    <option value="" disabled selected>Selecione</option>
                    <option value="a+">A+</option>
                    <option value="a-">A-</option>
                    <option value="b+">B+</option>
                    <option value="b-">B-</option>
                    <option value="ab+">AB+</option>
                    <option value="ab-">AB-</option>
                    <option value="o+">O+</option>
                    <option value="o-">O-</option>
                  </select>
                </div>
              </div>

              <div className={style.linetwo}>
                <div className={style.cnpj}>
                  <Input type={"text"} text={"Naturalidade (*)"}
                    onChange={(e) => setNaturalidade(e.target.value.toUpperCase())}
                  />
                </div>

                <div className={style.itens}>
                  <label for="sangue">Estado (*):</label>
                  <select id="estado" name="estado" onChange={(e) => setEstado(e.target.value)}>
                    <option value="" disabled selected>Selecione</option>
                    <option value="AC">AC</option>
                    <option value="AL">AL</option>
                    <option value="AP">AP</option>
                    <option value="AM">AM</option>
                    <option value="BA">BA</option>
                    <option value="CE">CE</option>
                    <option value="DF">DF</option>
                    <option value="ES">ES</option>
                    <option value="GO">GO</option>
                    <option value="MA">MA</option>
                    <option value="MT">MT</option>
                    <option value="MS">MS</option>
                    <option value="MG">MG</option>
                    <option value="PA">PA</option>
                    <option value="PB">PB</option>
                    <option value="PR">PR</option>
                    <option value="PE">PE</option>
                    <option value="PI">PI</option>
                    <option value="RJ">RJ</option>
                    <option value="RN">RN</option>
                    <option value="RS">RS</option>
                    <option value="RO">RO</option>
                    <option value="RR">RR</option>
                    <option value="SC">SC</option>
                    <option value="SP">SP</option>
                    <option value="SE">SE</option>
                    <option value="TO">TO</option>
                  </select>
                </div>
              </div>

              <div className={style.linetwo}>
                <div className={style.cnpj}>
                  <Input type={"text"} text={"Nacionalidade (*)"}
                    onChange={(e) => setNacionalidade(e.target.value.toUpperCase())}
                  />
                </div>

                <div className={style.cnpj}>
                  <Input type={"date"} text={"Chegada ao Brasil"}
                    onChange={(e) => setChegadaBrasil(e.target.value)}
                  />
                </div>
              </div>

              <div className={style.lineseven}>
                <div className={style.cnpj}>
                  <MaskedInput
                    text="CPF (*)"
                    mask="999.999.999-99"
                    placeholder="000.000.000-00"
                    onChange={(e) => setCpf(e.target.value)}
                  />
                </div>

                <div className={style.cnpj}>
                  <Input type={"text"} text={"Cédula de Identidade (*)"}
                    onChange={(e) => setIdentidade(e.target.value)}
                  />
                </div>

                <div className={style.cnpj}>
                  <Input type={"date"} text={"Data de Emissão"}
                    onChange={(e) => setDataEmissao(e.target.value)}
                  />
                </div>

                <div className={style.itens}>
                  <label for="estado2">Órgão/UF (*):</label>
                  <select id="estado2" name="estado" onChange={(e) => setOrgaoUf(e.target.value)}>
                    <option value="" disabled selected>Selecione</option>
                    <option value="AC">AC</option>
                    <option value="AL">AL</option>
                    <option value="AP">AP</option>
                    <option value="AM">AM</option>
                    <option value="BA">BA</option>
                    <option value="CE">CE</option>
                    <option value="DF">DF</option>
                    <option value="ES">ES</option>
                    <option value="GO">GO</option>
                    <option value="MA">MA</option>
                    <option value="MT">MT</option>
                    <option value="MS">MS</option>
                    <option value="MG">MG</option>
                    <option value="PA">PA</option>
                    <option value="PB">PB</option>
                    <option value="PR">PR</option>
                    <option value="PE">PE</option>
                    <option value="PI">PI</option>
                    <option value="RJ">RJ</option>
                    <option value="RN">RN</option>
                    <option value="RS">RS</option>
                    <option value="RO">RO</option>
                    <option value="RR">RR</option>
                    <option value="SC">SC</option>
                    <option value="SP">SP</option>
                    <option value="SE">SE</option>
                    <option value="TO">TO</option>
                  </select>
                </div>

                <div className={style.itens}>
                  <label for="habilitacao">Habilitação (*):</label>
                  <select id="habilitacao" name="habilitacao" onChange={(e) => setHabilitacao(e.target.value)}>
                    <option value="" disabled selected>Selecione</option>
                    <option value="S">Sim</option>
                    <option value="N">Não</option>
                  </select>
                </div>

                <div className={style.itens}>
                  <label for="estado">Categoria:</label>
                  <select id="categoriaHabilitacao" name="categoriaHabilitacao" onChange={(e) => setCategoriaHabilitacao(e.target.value)}>
                    <option value="" disabled selected>Selecione</option>
                    <option value="A">A - Motocicletas</option>
                    <option value="B">B - Veículos de passeio</option>
                    <option value="C">C - Veículos de carga (acima de 3500kg)</option>
                    <option value="D">D - Veículos de transporte de passageiros (acima de 8 assentos)</option>
                    <option value="E">E - Veículos com reboque acoplado (acima de 6000kg)</option>
                    <option value="AB">AB - Motocicletas e Veículos de passeio</option>
                    <option value="AC">AC - Categoria A e Veículos de carga (acima de 3500kg)</option>
                    <option value="AD">AD - Categoria A e Veículos de transporte de passageiros (acima de 8 assentos)</option>
                    <option value="AE">AE - Categoria A e Veículos com reboque acoplado (acima de 6000kg)</option>
                  </select>

                </div>

                <div className={style.cnpj}>
                  <Input type={"date"} text={"Validade"}
                    onChange={(e) => setValidadeHabilitacao(e.target.value)}
                  />
                </div>
              </div>

              <div className={style.lineseven}>
                <div className={style.cnpj}>
                  <Input type={"text"} text={"CTPS (*)"}
                    onChange={(e) => setCtps(e.target.value.toUpperCase())}
                  />
                </div>

                <div className={style.cnpj}>
                  <Input type={"text"} text={"Série (*)"}
                    onChange={(e) => setSerieCtps(e.target.value.toUpperCase())}
                  />
                </div>

                <div className={style.cnpj}>
                  <Input type={"text"} text={"Dígito"}
                    onChange={(e) => setDigitoCtps(e.target.value.toUpperCase())}
                  />
                </div>

                <div className={style.cnpj}>
                  <Input type={"text"} text={"Banco (*)"}
                    onChange={(e) => setBanco(e.target.value.toUpperCase())}
                  />
                </div>

                <div className={style.cnpj}>
                  <Input type={"text"} text={"Agência (*)"}
                    onChange={(e) => setAgencia(e.target.value)}
                  />
                </div>

                <div className={style.cnpj}>
                  <Input type={"text"} text={"Conta (*)"}
                    onChange={(e) => setContaCorrente(e.target.value)}
                  />
                </div>

                <div className={style.cnpj}>
                  <Input type={"text"} text={"Dígito Conta (*)"}
                    onChange={(e) => setDigitoConta(e.target.value)}
                  />
                </div>

              </div>

              <div className={style.linefive}>
                <div className={style.cnpj}>
                  <Input type={"text"} text={"Carteira Reservista"}
                    onChange={(e) => setCarteiraReservista(e.target.value)}
                  />
                </div>

                <div className={style.cnpj}>
                  <Input type={"text"} text={"N° Título de Eleitor (*)"}
                    onChange={(e) => setTituloEleitor(e.target.value)}
                  />
                </div>

                <div className={style.cnpj}>
                  <Input type={"text"} text={"Zona (*)"}
                    onChange={(e) => setZonaEleitoral(e.target.value)}
                  />
                </div>

                <div className={style.cnpj}>
                  <Input type={"text"} text={"Seção (*)"}
                    onChange={(e) => setSecaoEleitoral(e.target.value)}
                  />
                </div>

                <div className={style.itens}>
                  <label for="grauInstrucao">Grau de Instrução (*):</label>
                  <select id="grauInstrucao" name="grauInstrucao" onChange={(e) => setGrauInstrucao(e.target.value)}>
                    <option value="" disabled selected>Selecione</option>
                    <option value="NENHUM">Nenhum</option>
                    <option value="FUNDAMENTAL INCOMPLETO">Ensino Fundamental Incompleto</option>
                    <option value="FUNDAMENTAL COMPLETO">Ensino Fundamental Completo</option>
                    <option value="MEDIO INCOMPLETO">Ensino Médio Incompleto</option>
                    <option value="MEDIO COMPLETO">Ensino Médio Completo</option>
                    <option value="SUPERIOR INCOMPLETO">Ensino Superior Incompleto</option>
                    <option value="SUPERIOR COMPLETO">Ensino Superior Completo</option>
                    <option value="POS-GRADUACAO INCOMPLETA">Pós-graduação Incompleta</option>
                    <option value="POS-GRADUACAO COMPLETA">Pós-graduação Completa</option>
                    <option value="MESTRADO">Mestrado</option>
                    <option value="DOUTORADO">Doutorado</option>
                    <option value="POS-DOUTORADO">Pós-doutorado</option>
                  </select>
                </div>


              </div>

              <div className={style.linetwo}>
                <div className={style.cnpj}>
                  <Input type={"text"} text={"N° do PIS (*)"}
                    onChange={(e) => setNumeroPis(e.target.value)}
                  />
                </div>

                <div className={style.itens}>
                  <label for="sangue">Estado Civil (*):</label>
                  <select id="estadoCivil" name="estadoCivil" onChange={(e) => setEstadoCivil(e.target.value)}>
                    <option value="" disabled selected>Selecione</option>
                    <option value="SOLTEIRO">Solteiro(a)</option>
                    <option value="CASADO">Casado(a)</option>
                    <option value="DIVORCIADO">Divorciado(a)</option>
                    <option value="VIUVO">Viúvo(a)</option>
                    <option value="SEPARADO">Separado(a) judicialmente</option>
                    <option value="COMPANHEIRO">Em união estável</option>
                  </select>

                </div>

              </div>

              <div className={style.linefour2}>
                <div className={style.cnpj}>
                  <Input type={"text"} text={"Endereço (*)"}
                    onChange={(e) => setEndereco(e.target.value.toUpperCase())}
                  />
                </div>

                <div className={style.cnpj}>
                  <Input type={"text"} text={"Número (*)"}
                    onChange={(e) => setNumeroEndereco(e.target.value.toUpperCase())}
                  />
                </div>

                <div className={style.cnpj}>
                  <Input type={"text"} text={"Complemento"}
                    onChange={(e) => setComplemento(e.target.value.toUpperCase())}
                  />
                </div>

                <div className={style.cnpj}>
                  <Input type={"text"} text={"Bairro (*)"}
                    onChange={(e) => setBairro(e.target.value.toUpperCase())}
                  />
                </div>

              </div>

              <div className={style.linefour2}>
                <div className={style.cnpj}>
                  <Input type={"text"} text={"Cidade (*)"}
                    onChange={(e) => setCidade(e.target.value.toUpperCase())}
                  />
                </div>

                <div className={style.itens}>
                  <label for="estado">Estado (*):</label>
                  <select id="estado" name="estado" onChange={(e) => setEstadoEndereco(e.target.value)}>
                    <option value="" disabled selected>Selecione</option>
                    <option value="AC">AC</option>
                    <option value="AL">AL</option>
                    <option value="AP">AP</option>
                    <option value="AM">AM</option>
                    <option value="BA">BA</option>
                    <option value="CE">CE</option>
                    <option value="DF">DF</option>
                    <option value="ES">ES</option>
                    <option value="GO">GO</option>
                    <option value="MA">MA</option>
                    <option value="MT">MT</option>
                    <option value="MS">MS</option>
                    <option value="MG">MG</option>
                    <option value="PA">PA</option>
                    <option value="PB">PB</option>
                    <option value="PR">PR</option>
                    <option value="PE">PE</option>
                    <option value="PI">PI</option>
                    <option value="RJ">RJ</option>
                    <option value="RN">RN</option>
                    <option value="RS">RS</option>
                    <option value="RO">RO</option>
                    <option value="RR">RR</option>
                    <option value="SC">SC</option>
                    <option value="SP">SP</option>
                    <option value="SE">SE</option>
                    <option value="TO">TO</option>
                  </select>
                </div>

                <div className={style.cnpj}>
                  <MaskedInput
                    text="CEP (*)"
                    mask="99999-999"
                    placeholder="00000-000"
                    onChange={(e) => setCep(e.target.value)}
                  />

                </div>

                <div className={style.cnpj}>
                  <MaskedInput
                    text="Telefone"
                    mask="(99) 99999-9999"
                    placeholder="(00) 00000-0000"
                    onChange={(e) => setTelefone(e.target.value)}
                  />
                </div>

              </div>

              <div className={style.linetwo}>
                <div className={style.cnpj}>
                  <Input type={"text"} text={"Endereço Eletrônico (*)"}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>

                <div className={style.cnpj}>
                  <MaskedInput
                    text="Celular (*)"
                    mask="(99) 99999-9999"
                    placeholder="(00) 00000-0000"
                    onChange={(e) => setCelular(e.target.value)}
                  />

                </div>
              </div>

              <div className={style.linethree}>
                <div className={style.cnpj}>
                  <Input type={"date"} text={"Data de Admissão (*)"}
                    onChange={(e) => setDataAdmissao(e.target.value)}
                  />
                </div>

                <div className={style.cnpj}>
                  <Input type={"text"} text={"Função (*)"}
                    onChange={(e) => setFuncao(e.target.value)}
                  />
                </div>

                <div className={style.cnpj}>
                  <Input type={"number"} text={"CBO (*)"}
                    onChange={(e) => setCbo(e.target.value)}
                  />
                </div>

              </div>

              <div className={style.linesix}>
                <div className={style.cnpj}>
                  <Input type="text" text="Salário Inicial (*)"
                    onChange={(e) => {
                      // Substitui vírgula por ponto
                      const value = e.target.value.replace(',', '.');
                      setSalarioInicial(value);
                    }}
                  />
                </div>

                <div className={style.cnpj}>
                  <label for="tipopagamento">Tipo de Pagamento (*):</label>
                  <select id="tipopagamento" name="tipopagamento" onChange={(e) => setTipoPagamento(e.target.value)}>
                    <option value="" disabled selected>Selecione</option>
                    <option value="DIARIO">Diário</option>
                    <option value="SEMANAL">Semanal</option>
                    <option value="QUINZENAL">Quinzenal</option>
                    <option value="MENSAL">Mensal</option>
                  </select>
                </div>

                <div className={style.cnpj}>
                  <Input type={"number"} text={"Insalubridade"}
                    onChange={(e) => setInsalubridade(e.target.value)}
                  />
                </div>

                <div className={style.cnpj}>
                  <Input type={"number"} text={"Periculosidade"}
                    onChange={(e) => setPericulosidade(e.target.value)}
                  />
                </div>

                <div className={style.cnpj}>
                  <Input type={"number"} text={"Comissão"}
                    onChange={(e) => setComissao(e.target.value)}
                  />
                </div>

              </div>

              <div className={style.linetwo}>
                <div className={style.cnpj}>
                  <label for="categoria">Categoria (*):</label>
                  <select id="categoria" name="categoria" onChange={(e) => setCategoria(e.target.value)}>
                    <option value="" disabled selected>Selecione</option>
                    <option value="1. EMPREGADO">1. Empregado</option>
                  </select>
                </div>

                <div className={style.cnpj}>
                  <label for="sindicato">Sindicato (*):</label>
                  <select id="sindicato" name="sindicato" onChange={(e) => setSindicato(e.target.value)}>
                    <option value="" disabled selected>Selecione</option>
                    <option value="SETTAPORT">Settaport</option>
                    <option value="SINDROD">Sindrod</option>
                    <option value="SINTESP">Sintesp</option>
                  </select>
                </div>
              </div>

              <div className={style.linetwo}>
                <div className={style.itens}>
                  <label>Centro de Custo (*):</label>
                  <select onChange={(e) => { setCentroCusto(e.target.value) }}>
                    <option disabled selected>Escolha uma opção</option>
                    {centro_de_custo?.map((centro_custo) => {
                      return (
                        <option value={centro_custo.COD_CENTROCUSTO}>{centro_custo.DESCRICAO}</option>
                      )
                    })}
                  </select>
                </div>

                <div className={style.cnpj}>
                  <label for="localizacao">Localização (*):</label>
                  <select id="localizacao" name="localizacao" onChange={(e) => setLocalizacao(e.target.value)}>
                    <option value="" disabled selected>Selecione</option>
                    <option value="SANTOS">Santos</option>
                    <option value="RIO DE JANEIRO">Rio De Janeiro</option>
                  </select>
                </div>
              </div>

              <div className={style.linetwo}>
                <div className={style.cnpj}>
                  <Input type={"text"} text={"Horário (*)"}
                    onChange={(e) => setHorario(e.target.value)}
                  />
                </div>
              </div>

              <div className={style.linefive}>
                <div className={style.cnpj}>
                  <Input type={"date"} text={"Data Rescisão"}
                    onChange={(e) => setDataRescisao(e.target.value)}
                  />
                </div>

                <div className={style.cnpj}>
                  <Input type={"date"} text={"Aviso Prévio"}
                    onChange={(e) => setAvisoPrevio(e.target.value)}
                  />
                </div>

                <div className={style.cnpj}>
                  <Input type={"number"} text={"Saldo FGTS"}
                    onChange={(e) => setSaldoFgts(e.target.value)}
                  />
                </div>

                <div className={style.cnpj}>
                  <Input type={"number"} text={"Maior Remuneração"}
                    onChange={(e) => setMaiorRemuneracao(e.target.value)}
                  />
                </div>

                <div className={style.itens}>
                  <label for="grrf">Recolheu FGTS na GRRF:</label>
                  <select id="grrf" name="grrf" onChange={(e) => setRecolheuFgts(e.target.value)}>
                    <option value="" disabled selected>Selecione</option>
                    <option value="S">Sim</option>
                    <option value="N">Não</option>
                  </select>
                </div>

              </div>

              <div className={style.lineone}>
                <div className={style.cnpj}>
                  <Input type={"text"} text={"Causa da Rescisão"}
                    onChange={(e) => setCausaRescisao(e.target.value)}
                  />
                </div>
              </div>

              <div className={style.submitButton}>
                <SubmitButton text="Cadastrar" onClick={cadastrarFuncionario} />
              </div>
            </>
          )}

        </div>
      </Container>

      <Dialog isOpen={isModalOpen} handleClose={handleCloseModal}>
        {selectedFuncionario && (
          <div className={modal.modalContent}>
            <div className={modal.header}>
              <h2>Dados do Funcionário</h2>
              <button onClick={handleCloseModal} className={modal.closeButton} aria-label="Close">&#10005;</button>
            </div>
            <div className={modal.flexContainer}>
              <div className={modal.leftColumn}>
                <h3>Informações Pessoais</h3>
                <p><strong>Nome:</strong> {selectedFuncionario.NOME}</p>
                <p><strong>CPF:</strong> {formatarCPF(selectedFuncionario.CPF)}</p>
                <p><strong>Sexo:</strong> {selectedFuncionario.SEXO}</p>
                <p><strong>Data de Nascimento:</strong> {moment(selectedFuncionario.DATA_NASCIMENTO).format('DD/MM/YYYY')}</p>
                <p><strong>Naturalidade:</strong> {selectedFuncionario.NATURALIDADE} - <strong> Nacionalidade:</strong> {selectedFuncionario.NACIONALIDADE}</p>
                <p><strong>Estado Civil:</strong> {selectedFuncionario.ESTADO_CIVIL}</p>
                <p><strong>Endereço:</strong> {selectedFuncionario.ENDERECO}, {selectedFuncionario.NUMERO_ENDERECO} {selectedFuncionario.COMPLEMENTO} - {selectedFuncionario.BAIRRO}, {selectedFuncionario.CIDADE} - {selectedFuncionario.ESTADOENDERECO}, CEP: {selectedFuncionario.CEP}</p>
                <p><strong>Email:</strong> {selectedFuncionario.EMAIL}</p>
                <p><strong>Celular:</strong> {selectedFuncionario.CELULAR ? formatCelular(selectedFuncionario.CELULAR) : 'N/A'}</p>
              </div>
              <div className={modal.rightColumn}>
                <h3>Informações de Emprego</h3>
                <p><strong>Contrato:</strong> {selectedFuncionario.CONTRATO}</p>
                <p><strong>Função:</strong> {selectedFuncionario.FUNCAO}</p>
                <p><strong>Data de Admissão:</strong> {moment(selectedFuncionario.DATA_ADMISSAO).format('DD/MM/YYYY')}</p>
                <p><strong>Salário Inicial:</strong> {selectedFuncionario.SALARIO_INICIAL ? formatSalario(selectedFuncionario.SALARIO_INICIAL) : 'N/A'}</p>
                <p><strong>Centro de Custo:</strong> {selectedFuncionario.CENTRO_CUSTO}</p>
                <p><strong>Localização:</strong> {selectedFuncionario.LOCALIZACAO}</p>
              </div>
            </div>
            <div className={modal.section}>
              <h3>Dependentes</h3>
              <div className={modal.dependentSection}>
                {Array.from({ length: 5 }).map((_, index) => {
                  const indexPadded = `0${index + 1}`; // Pad the index to match the key formatting
                  const dependenteKey = `DEPENDENTE_${indexPadded}`;
                  const dataNascKey = `DATA_NASC_DEPENDENTE_${indexPadded}`;
                  const cpfKey = `CPF_DEPENDENTE_${indexPadded}`;
                  const grauKey = `GRAU_PARENTESCO_${indexPadded}`;

                  const dependente = selectedFuncionario[dependenteKey];
                  const dataNasc = selectedFuncionario[dataNascKey];
                  const cpf = selectedFuncionario[cpfKey];
                  const grau = selectedFuncionario[grauKey];

                  if (dependente) { // Only render if the dependente name exists
                    return (
                      <div key={index} className={modal.dependentCard}>
                        <p><strong>Nome:</strong> {dependente}</p>
                        <p><strong>CPF:</strong> {cpf ? formatarCPF(cpf) : 'N/A'}</p>
                        <p><strong>Data de Nasc.:</strong> {dataNasc ? moment(dataNasc).format('DD/MM/YYYY') : 'N/A'}</p>
                        <p><strong>Parentesco:</strong> {grau}</p>
                      </div>
                    );
                  }
                  return null;
                })}
              </div>

            </div>


          </div>
        )}
      </Dialog>


    </>
  );
};

export default function IntegrationNotistack() {
  return (
    <SnackbarProvider
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      maxSnack={3}
      autoHideDuration={2500}
    >
      <FichaRegistro />
    </SnackbarProvider>
  );
}
