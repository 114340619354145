import React, { useState } from "react";
import Axios from "axios";
import moment from 'moment';
import Navbar from "../../components/Navbar";
import Background from "../../components/Background";
import Container from "../../components/Container";
import Header from "../../components/Header";
import SubmitButton from "../../components/Button";
import style from "./Administrador.module.css";
import { useSnackbar } from 'notistack';



const Administrador = () => {
    const [carregamentoId, setCarregamentoId] = useState(''); // Captura o ID digitado
    const [carregamentoInfo, setCarregamentoInfo] = useState([]); // Armazena os dados do carregamento
    const [erro, setErro] = useState('');

    const [editMode, setEditMode] = useState(null); // Armazena o ID do carregamento em edição
    const [editedData, setEditedData] = useState({});
    const [editedPesoCarregado, setEditedPesoCarregado] = useState('');
    const [editedPesoCarregadoPY, setEditedPesoCarregadoPY] = useState('');
    const [editModePesoCarregadoPY, setEditModePesoCarregadoPY] = useState(null); // Estado para modo de edição de PESO_CARREGADO_PY
    const [editModePesoBruto, setEditModePesoBruto] = useState(null); // Estado para modo de edição de PESO_BRUTO
    const [editModeTicket01, setEditModeTicket01] = useState(null); // Estado para modo de edição de PESO_BRUTO
    const [editModeTicket02, setEditModeTicket02] = useState(null); // Estado para modo de edição de PESO_BRUTO
    const [editedPesoBruto, setEditedPesoBruto] = useState(''); // Estado para valor editado de PESO_BRUTO
    const [editedTicket01, setEditedTicket01] = useState(''); // Estado para valor editado de PESO_BRUTO
    const [editedTicket02, setEditedTicket02] = useState(''); // Estado para valor editado de PESO_BRUTO
    const [editModePesoTara, setEditModePesoTara] = useState(null); // Estado para modo de edição de PESO_TARA
    const [editedPesoTara, setEditedPesoTara] = useState(''); // Estado para valor editado de PESO_TARA
    const [editModeDelete, setEditModeDelete] = useState(null); // Para armazenar o ID do carregamento em exclusão

    const [editModePlacaCavalo, setEditModePlacaCavalo] = useState(null); // Estado para modo de edição da Placa Cavalo
    const [editedPlacaCavalo, setEditedPlacaCavalo] = useState(''); // Estado para valor editado da Placa Cavalo


    const [editModeDataCarregado, setEditModeDataCarregado] = useState(null); // Estado para modo de edição de Data do Carregado
    const [editedDataCarregado, setEditedDataCarregado] = useState(''); // Estado para valor editado de Data do Carregado

    const [editModeDataIntegrado, setEditModeDataIntegrado] = useState(null); // Estado para modo de edição de Data Integrado
    const [editedDataIntegrado, setEditedDataIntegrado] = useState(''); // Estado para valor editado de Data Integrado

    const [editModePlacaCarreta, setEditModePlacaCarreta] = useState(null); // Estado para modo de edição da Placa Carreta
    const [editedPlacaCarreta, setEditedPlacaCarreta] = useState(''); // Estado para valor editado da Placa Carreta

    const [editModePlacaCarreta2, setEditModePlacaCarreta2] = useState(null); // Estado para modo de edição da Placa Carreta
    const [editedPlacaCarreta2, setEditedPlacaCarreta2] = useState(''); // Estado para valor editado da Placa Carreta

    const [editModePlacaCarreta3, setEditModePlacaCarreta3] = useState(null); // Estado para modo de edição da Placa Carreta
    const [editedPlacaCarreta3, setEditedPlacaCarreta3] = useState(''); // Estado para valor editado da Placa Carreta


    const ShowAlert = (message) => {
        alert(message);
    };


    const buscarCarregamento = () => {
        console.log('ID do carregamento buscado:', carregamentoId); // Verifica o ID digitado
        Axios.get(`https://hml.operacao.orion-op.com.br/api/administrador/carregamento/${carregamentoId}`)
            .then(response => {
                console.log('Resposta da API:', response.data); // Verifica a resposta da API
                setCarregamentoInfo(response.data); // Atualiza os dados do carregamento
                setErro('');
            })
            .catch(error => {
                console.error('Erro ao buscar carregamento', error);
                setErro('Carregamento não encontrado ou erro na consulta');
                setCarregamentoInfo([]); // Limpa os dados caso não encontre o carregamento
            });
    };

    const handleEdit = (rowData) => {
        setEditedPesoCarregado(rowData.PESO_CARREGADO.toString());
        setEditMode(rowData.ID_CARREGAMENTO);
        setEditedData({ ...rowData });
    };

    const handleCancelEdit = () => {
        setEditMode(null);
        setEditedData({});
        setEditedPesoCarregado('');
    };

    const handleSave = () => {
        // Certifique-se de que os valores estão corretamente formatados, especialmente se espera-se um número
        const updatedData = { PESO_CARREGADO: parseFloat(editedPesoCarregado) };

        Axios.put(`https://hml.operacao.orion-op.com.br/api/administrador/updatecarregado/${carregamentoId}`, updatedData)
            .then(response => {
                buscarCarregamento(); // Recarrega os dados após a atualização
                setEditMode(null);
                setEditedData({});
                setEditedPesoCarregado('');
                console.log('Peso do carregamento atualizado com sucesso');
                ShowAlert('Peso alterado com sucesso!');
            })
            .catch(error => {
                console.error('Erro ao salvar carregamento', error);
            });
    };

    const handleEditPesoCarregadoPY = (rowData) => {
        // Verifica se o valor é nulo ou indefinido antes de converter para string
        const pesoCarregadoPY = rowData.PESO_CARREGADO_PY != null ? rowData.PESO_CARREGADO_PY.toString() : '';

        setEditedPesoCarregadoPY(pesoCarregadoPY);
        setEditModePesoCarregadoPY(rowData.ID_CARREGAMENTO);
        setEditedData({ ...rowData });
    };


    const handleCancelEditPesoCarregadoPY = () => {
        setEditModePesoCarregadoPY(null);
        setEditedData({});
        setEditedPesoCarregadoPY('');
    };

    const handleSavePesoCarregadoPY = () => {
        const updatedDataPY = { PESO_CARREGADO_PY: parseFloat(editedPesoCarregadoPY) };

        Axios.put(`https://hml.operacao.orion-op.com.br/api/administrador/updatecarregadopy/${carregamentoId}`, updatedDataPY)
            .then(response => {
                buscarCarregamento(); // Recarrega os dados após a atualização
                setEditModePesoCarregadoPY(null);
                setEditedData({});
                setEditedPesoCarregadoPY('');
                console.log('Peso Carregado PY atualizado com sucesso');
                ShowAlert('Peso Carregado PY alterado com sucesso!');
            })
            .catch(error => {
                console.error('Erro ao salvar Peso Carregado PY', error);
            });
    };

    const handleCancelEditPesoBruto = () => {
        setEditModePesoBruto(null);
        setEditedData({});
        setEditedPesoBruto('');
    };


    const handleEditPesoBruto = (rowData) => {
        setEditedPesoBruto(rowData.PESO_BRUTO.toString());
        setEditModePesoBruto(rowData.ID_CARREGAMENTO);
        setEditedData({ ...rowData });
    };

    const handleSavePesoBruto = () => {
        const updatedDataBruto = { PESO_BRUTO: parseFloat(editedPesoBruto) };

        Axios.put(`https://hml.operacao.orion-op.com.br/api/administrador/updatepesobruto/${carregamentoId}`, updatedDataBruto)
            .then(response => {
                buscarCarregamento(); // Recarrega os dados após a atualização
                setEditModePesoBruto(null);
                setEditedData({});
                setEditedPesoBruto('');
                console.log('Peso Bruto atualizado com sucesso');
                ShowAlert('Peso Bruto alterado com sucesso!');
            })
            .catch(error => {
                console.error('Erro ao salvar Peso Bruto', error);
            });
    };

    const handleCancelEditTicket01 = () => {
        setEditModeTicket01(null);
        setEditedData({});
        setEditedTicket01('');
    };

    const handleEditTicket01 = (rowData) => {
        // Verifica se TICKET_CHAVE_01 é null e atribui uma string vazia nesse caso.
        const ticket01 = rowData.TICKET_CHAVE_01 != null ? rowData.TICKET_CHAVE_01.toString() : '';
        setEditedTicket01(ticket01);
        setEditModeTicket01(rowData.ID_CARREGAMENTO);
    };

    const handleSaveTicket01 = () => {
        const updatedTicket01 = { TICKET_CHAVE_01: parseFloat(editedTicket01) };

        Axios.put(`https://hml.operacao.orion-op.com.br/api/administrador/updateticket01/${carregamentoId}`, updatedTicket01)
            .then(response => {
                buscarCarregamento(); // Recarrega os dados após a atualização
                setEditModeTicket01(null);
                setEditedData({});
                setEditedTicket01('');
                console.log('Ticket 01 atualizado com sucesso');
                ShowAlert('Ticket 01 alterado com sucesso!');
            })
            .catch(error => {
                console.error('Erro ao salvar Ticket 01', error);
            });
    };

    const handleCancelEditTicket02 = () => {
        setEditModeTicket02(null);
        setEditedData({});
        setEditedTicket02('');
    };

    const handleEditTicket02 = (rowData) => {
        // Verifica se TICKET_CHAVE_02 é null e atribui uma string vazia nesse caso.
        const ticket02 = rowData.TICKET_CHAVE_02 != null ? rowData.TICKET_CHAVE_02.toString() : '';
        setEditedTicket02(ticket02);
        setEditModeTicket02(rowData.ID_CARREGAMENTO);
    };

    const handleSaveTicket02 = () => {
        const updatedTicket02 = { TICKET_CHAVE_02: parseFloat(editedTicket02) };

        Axios.put(`https://hml.operacao.orion-op.com.br/api/administrador/updateticket02/${carregamentoId}`, updatedTicket02)
            .then(response => {
                buscarCarregamento(); // Recarrega os dados após a atualização
                setEditModeTicket02(null);
                setEditedData({});
                setEditedTicket02('');
                console.log('Ticket 02 atualizado com sucesso');
                ShowAlert('Ticket 02 alterado com sucesso!');
            })
            .catch(error => {
                console.error('Erro ao salvar Ticket 02', error);
            });
    };

    const handleEditPesoTara = (rowData) => {
        setEditedPesoTara(rowData.PESO_TARA.toString());
        setEditModePesoTara(rowData.ID_CARREGAMENTO);
    };

    const handleCancelEditPesoTara = () => {
        setEditModePesoTara(null);
        setEditedPesoTara('');
    };

    const handleSavePesoTara = () => {
        const updatedDataTara = { PESO_TARA: parseFloat(editedPesoTara) };

        Axios.put(`https://hml.operacao.orion-op.com.br/api/administrador/updatepesotara/${carregamentoId}`, updatedDataTara)
            .then(response => {
                buscarCarregamento(); // Recarrega os dados após a atualização
                setEditModePesoTara(null);
                setEditedPesoTara('');
                console.log('Peso Tara atualizado com sucesso');
                ShowAlert('Peso Tara alterado com sucesso!');
            })
            .catch(error => {
                console.error('Erro ao salvar Peso Tara', error);
            });
    };

    const handleEditDataCarregado = (rowData) => {
        setEditedDataCarregado(moment(rowData.DATA_CARREGAMENTO).format('YYYY-MM-DDTHH:mm')); // Use ISO string for input type="datetime-local"
        setEditModeDataCarregado(rowData.ID_CARREGAMENTO);
    };

    const handleCancelEditDataCarregado = () => {
        setEditModeDataCarregado(null);
        setEditedDataCarregado('');
    };

    const handleSaveDataCarregado = () => {
        const updatedDataCarregado = { DATA_CARREGAMENTO: new Date(editedDataCarregado).toISOString() };

        Axios.put(`https://hml.operacao.orion-op.com.br/api/administrador/updatedatacarregado/${carregamentoId}`, updatedDataCarregado)
            .then(response => {
                buscarCarregamento(); // Recarrega os dados após a atualização
                setEditModeDataCarregado(null);
                setEditedDataCarregado('');
                console.log('Data do Carregado atualizada com sucesso');
                ShowAlert('Data do Carregado alterada com sucesso!');
            })
            .catch(error => {
                console.error('Erro ao salvar Data do Carregado', error);
            });
    };

    const handleEditDataIntegrado = (rowData) => {
        setEditedDataIntegrado(moment(rowData.DATA_CARREGAMENTO_PY).format('YYYY-MM-DDTHH:mm')); // Use ISO string for input type="datetime-local"
        setEditModeDataIntegrado(rowData.ID_CARREGAMENTO);
    };

    const handleCancelEditDataIntegrado = () => {
        setEditModeDataIntegrado(null);
        setEditedDataIntegrado('');
    };

    const handleSaveDataIntegrado = () => {
        const updatedDataIntegrado = { DATA_CARREGAMENTO_PY: new Date(editedDataIntegrado).toISOString() };

        Axios.put(`https://hml.operacao.orion-op.com.br/api/administrador/updatedataintegrado/${carregamentoId}`, updatedDataIntegrado)
            .then(response => {
                buscarCarregamento(); // Recarrega os dados após a atualização
                setEditModeDataIntegrado(null);
                setEditedDataIntegrado('');
                console.log('Data Integrado atualizada com sucesso');
                ShowAlert('Data Integrado alterada com sucesso!');
            })
            .catch(error => {
                console.error('Erro ao salvar Data Integrado', error);
            });
    };

    const handleEditPlacaCavalo = (rowData) => {
        setEditedPlacaCavalo(rowData.PLACA_CAVALO);
        setEditModePlacaCavalo(rowData.ID_CARREGAMENTO);
    };

    const handleCancelEditPlacaCavalo = () => {
        setEditModePlacaCavalo(null);
        setEditedPlacaCavalo('');
    };

    const handleSavePlacaCavalo = () => {
        const updatedPlacaCavalo = { PLACA_CAVALO: editedPlacaCavalo };

        Axios.put(`https://hml.operacao.orion-op.com.br/api/administrador/updateplacacavalo/${carregamentoId}`, updatedPlacaCavalo)
            .then(response => {
                buscarCarregamento(); // Recarrega os dados após a atualização
                setEditModePlacaCavalo(null);
                setEditedPlacaCavalo('');
                console.log('Placa Cavalo atualizada com sucesso');
                ShowAlert('Placa Cavalo alterada com sucesso!');
            })
            .catch(error => {
                console.error('Erro ao salvar Placa Cavalo', error);
            });
    };

    const handleEditPlacaCarreta = (rowData) => {
        setEditedPlacaCarreta(rowData.PLACA_CARRETA);
        setEditModePlacaCarreta(rowData.ID_CARREGAMENTO);
    };

    const handleCancelEditPlacaCarreta = () => {
        setEditModePlacaCarreta(null);
        setEditedPlacaCarreta('');
    };

    const handleSavePlacaCarreta = () => {
        const updatedPlacaCarreta = { PLACA_CARRETA: editedPlacaCarreta };

        Axios.put(`https://hml.operacao.orion-op.com.br/api/administrador/updateplacacarreta/${carregamentoId}`, updatedPlacaCarreta)
            .then(response => {
                buscarCarregamento(); // Atualiza a lista de carregamentos após a edição
                setEditModePlacaCarreta(null);
                setEditedPlacaCarreta('');
                alert('Placa da carreta atualizada com sucesso!');
            })
            .catch(error => {
                console.error('Erro ao atualizar a placa da carreta', error);
                alert('Erro ao atualizar a placa da carreta');
            });
    };


    const handleEditPlacaCarreta2 = (rowData) => {
        setEditedPlacaCarreta2(rowData.PLACA_CARRETA2);
        setEditModePlacaCarreta2(rowData.ID_CARREGAMENTO);
    };

    const handleCancelEditPlacaCarreta2 = () => {
        setEditModePlacaCarreta2(null);
        setEditedPlacaCarreta2('');
    };

    const handleSavePlacaCarreta2 = () => {
        const updatedPlacaCarreta2 = { PLACA_CARRETA2: editedPlacaCarreta2 };

        Axios.put(`https://hml.operacao.orion-op.com.br/api/administrador/updateplacacarreta2/${carregamentoId}`, updatedPlacaCarreta2)
            .then(response => {
                buscarCarregamento(); // Atualiza a lista de carregamentos após a edição
                setEditModePlacaCarreta2(null);
                setEditedPlacaCarreta2('');
                alert('Placa da carreta 2 atualizada com sucesso!');
            })
            .catch(error => {
                console.error('Erro ao atualizar a placa da carreta 2', error);
                alert('Erro ao atualizar a placa da carreta 2');
            });
    };

    const handleEditPlacaCarreta3 = (rowData) => {
        setEditedPlacaCarreta3(rowData.PLACA_CARRETA3);
        setEditModePlacaCarreta3(rowData.ID_CARREGAMENTO);
    };

    const handleCancelEditPlacaCarreta3 = () => {
        setEditModePlacaCarreta3(null);
        setEditedPlacaCarreta3('');
    };

    const handleSavePlacaCarreta3 = () => {
        const updatedPlacaCarreta3 = { PLACA_CARRETA3: editedPlacaCarreta3 };

        Axios.put(`https://hml.operacao.orion-op.com.br/api/administrador/updateplacacarreta3/${carregamentoId}`, updatedPlacaCarreta3)
            .then(response => {
                buscarCarregamento(); // Atualiza a lista de carregamentos após a edição
                setEditModePlacaCarreta3(null);
                setEditedPlacaCarreta3('');
                alert('Placa da carreta 3 atualizada com sucesso!');
            })
            .catch(error => {
                console.error('Erro ao atualizar a placa da carreta 3', error);
                alert('Erro ao atualizar a placa da carreta 3');
            });
    };

    const handleDelete = () => {
        console.log('ID do Carregamento para excluir:', carregamentoId);
        Axios.put(`https://hml.operacao.orion-op.com.br/api/administrador/updatestatuscarreg/${carregamentoId}`)
            .then(response => {
                buscarCarregamento(); // Atualiza os dados após a exclusão
                console.log('Carregamento excluído com sucesso');
                alert('Carregamento excluído com sucesso!');
            })
            .catch(error => {
                console.error('Erro ao excluir carregamento', error);
                alert('Erro ao excluir carregamento!');
            });
    };

    return (
        <>
            <Navbar />
            <Header />
            <Background />
            <Container>
                <div className={style.content}>

                    <div className={style.leftColumn2}>
                        <div className={style.leftColumn}>
                            <div className={style.nav}>
                                <div className={style.active}>Administrador</div>
                            </div>
                            <input
                                type="text"
                                placeholder="Digite o ID do Carregamento"
                                value={carregamentoId}
                                onChange={(e) => setCarregamentoId(e.target.value)}
                                className={style.inputField}
                            />
                            <SubmitButton text="Buscar" onClick={buscarCarregamento} />
                            {erro && <p className={style.error}>{erro}</p>}
                        </div>
                        {/* Início do alerta */}
                        <div className={style.alertaManual}>
                            <strong>Atenção:</strong> l, é necessário inserir corretamente os dados de <em>Peso Integrado</em> e <em>Data Integrada</em>. Após isso, acesse a tela de <em>Viagem do Navio</em> e clique no botão <strong>"Integração"</strong> para registrar. As informações precisas são essenciais para a emissão correta da nota.
                        </div>
                        {/* Fim do alerta */}
                    </div>

                    <div className={style.rightColumn}>
                        {carregamentoInfo.length > 0 && carregamentoInfo.map((info, index) => (
                            <div key={index} className={`${style.card} ${info.STATUS_NOTA_MIC === 7 ? style.cardRed : ''}`}>
                            <div className={style.historico}>Histórico do Motorista</div>
                                <div className={style.cardItem}><strong>DI/BL:</strong> {info.REFERENCIA}</div>
                                <div className={style.cardItem}><strong>Navio:</strong> {info.NOME_NAVIO}</div>
                                <div className={style.cardItem}><strong>Motorista:</strong> {info.NOME_MOTORISTA}</div>

                                <div className={style.cardItem}>
                                    <strong>Placa Cavalo:</strong>
                                    {editModePlacaCavalo === info.ID_CARREGAMENTO ? (
                                        <input
                                            type="text"
                                            value={editedPlacaCavalo}
                                            onChange={(e) => setEditedPlacaCavalo(e.target.value)}
                                        />
                                    ) : (
                                        info.PLACA_CAVALO
                                    )}

                                    {editModePlacaCavalo === info.ID_CARREGAMENTO ? (
                                        <>
                                            <span onClick={handleSavePlacaCavalo}>✅</span>
                                            <span onClick={handleCancelEditPlacaCavalo}>❌</span>
                                        </>
                                    ) : (
                                        <span onClick={() => handleEditPlacaCavalo(info)}>✏️</span>
                                    )}
                                </div>

                                <div className={style.cardItem}>
                                    <strong>Placa Carreta 1:</strong>
                                    {editModePlacaCarreta === info.ID_CARREGAMENTO ? (
                                        <input
                                            type="text"
                                            value={editedPlacaCarreta}
                                            onChange={(e) => setEditedPlacaCarreta(e.target.value)}
                                        />
                                    ) : (
                                        info.PLACA_CARRETA
                                    )}

                                    {editModePlacaCarreta === info.ID_CARREGAMENTO ? (
                                        <>
                                            <span onClick={handleSavePlacaCarreta}>✅</span>
                                            <span onClick={handleCancelEditPlacaCarreta}>❌</span>
                                        </>
                                    ) : (
                                        <span onClick={() => handleEditPlacaCarreta(info)}>✏️</span>
                                    )}
                                </div>

                                <div className={style.cardItem}>
                                    <strong>Placa Carreta 2:</strong>
                                    {editModePlacaCarreta2 === info.ID_CARREGAMENTO ? (
                                        <input
                                            type="text"
                                            value={editedPlacaCarreta2}
                                            onChange={(e) => setEditedPlacaCarreta2(e.target.value)}
                                        />
                                    ) : (
                                        info.PLACA_CARRETA2
                                    )}

                                    {editModePlacaCarreta2 === info.ID_CARREGAMENTO ? (
                                        <>
                                            <span onClick={handleSavePlacaCarreta2}>✅</span>
                                            <span onClick={handleCancelEditPlacaCarreta2}>❌</span>
                                        </>
                                    ) : (
                                        <span onClick={() => handleEditPlacaCarreta2(info)}>✏️</span>
                                    )}
                                </div>

                                <div className={style.cardItem}>
                                    <strong>Placa Carreta 3:</strong>
                                    {editModePlacaCarreta3 === info.ID_CARREGAMENTO ? (
                                        <input
                                            type="text"
                                            value={editedPlacaCarreta3}
                                            onChange={(e) => setEditedPlacaCarreta3(e.target.value)}
                                        />
                                    ) : (
                                        info.PLACA_CARRETA3
                                    )}

                                    {editModePlacaCarreta3 === info.ID_CARREGAMENTO ? (
                                        <>
                                            <span onClick={handleSavePlacaCarreta3}>✅</span>
                                            <span onClick={handleCancelEditPlacaCarreta3}>❌</span>
                                        </>
                                    ) : (
                                        <span onClick={() => handleEditPlacaCarreta3(info)}>✏️</span>
                                    )}
                                </div>

                                <div className={style.cardItem}><strong>Transportadora:</strong> {info.NOME_REDUZIDO}</div>
                                <div className={style.cardItem}><strong>Pedido:</strong> {info.PEDIDO_MIC}</div>

                                <div className={style.cardItem}><strong>1° Peso (Tara):</strong>
                                    {editModePesoTara === info.ID_CARREGAMENTO ? (
                                        <input
                                            type="text"
                                            value={editedPesoTara}
                                            onChange={(e) => setEditedPesoTara(e.target.value)}
                                        />
                                    ) : (
                                        `${Number(info.PESO_TARA).toLocaleString(undefined, { maximumFractionDigits: 3 })} KG`
                                    )}

                                    {editModePesoTara === info.ID_CARREGAMENTO ? (
                                        <>
                                            <span onClick={handleSavePesoTara}>✅</span>
                                            <span onClick={handleCancelEditPesoTara}>❌</span>
                                        </>
                                    ) : (
                                        <span onClick={() => handleEditPesoTara(info)}>✏️</span>
                                    )}
                                </div>

                                <div className={style.cardItem}><strong>2° Peso (Integrado):</strong>
                                    {editModePesoCarregadoPY === info.ID_CARREGAMENTO ? (
                                        <input
                                            type="text"
                                            value={editedPesoCarregadoPY}
                                            onChange={(e) => setEditedPesoCarregadoPY(e.target.value)}
                                        />
                                    ) : (
                                        `${Number(info.PESO_CARREGADO_PY).toLocaleString(undefined, { maximumFractionDigits: 3 })} KG`
                                    )}

                                    {editModePesoCarregadoPY === info.ID_CARREGAMENTO ? (
                                        <>
                                            <span onClick={handleSavePesoCarregadoPY}>✅</span>
                                            <span onClick={handleCancelEditPesoCarregadoPY}>❌</span>
                                        </>
                                    ) : (
                                        <span onClick={() => handleEditPesoCarregadoPY(info)}>✏️</span>
                                    )}
                                </div>

                                <div className={style.cardItem}><strong>Data Integrado:</strong>
                                    {editModeDataIntegrado === info.ID_CARREGAMENTO ? (
                                        <input
                                            type="datetime-local"
                                            value={editedDataIntegrado}
                                            onChange={(e) => setEditedDataIntegrado(e.target.value)}
                                        />
                                    ) : (
                                        moment(info.DATA_CARREGAMENTO_PY).format('DD/MM - HH:mm')
                                    )}

                                    {editModeDataIntegrado === info.ID_CARREGAMENTO ? (
                                        <>
                                            <span onClick={handleSaveDataIntegrado}>✅</span>
                                            <span onClick={handleCancelEditDataIntegrado}>❌</span>
                                        </>
                                    ) : (
                                        <span onClick={() => handleEditDataIntegrado(info)}>✏️</span>
                                    )}
                                </div>

                                <div className={style.cardItem}><strong>2° Peso (Carregado):</strong>
                                    {editMode === info.ID_CARREGAMENTO ? (
                                        <input
                                            type="text"
                                            value={editedPesoCarregado}
                                            onChange={(e) => setEditedPesoCarregado(e.target.value)}
                                        />
                                    ) : (
                                        `${Number(info.PESO_CARREGADO).toLocaleString(undefined, { maximumFractionDigits: 3 })} KG`
                                    )}

                                    {editMode === info.ID_CARREGAMENTO ? (
                                        <>
                                            <span onClick={handleSave}>✅</span>
                                            <span onClick={handleCancelEdit}>❌</span>
                                        </>
                                    ) : (
                                        <span onClick={() => handleEdit(info)}>✏️</span>
                                    )}
                                </div>

                                <div className={style.cardItem}><strong>Data do Carregado:</strong>
                                    {editModeDataCarregado === info.ID_CARREGAMENTO ? (
                                        <input
                                            type="datetime-local"
                                            value={editedDataCarregado}
                                            onChange={(e) => setEditedDataCarregado(e.target.value)}
                                        />
                                    ) : (
                                        moment(info.DATA_CARREGAMENTO).format('DD/MM - HH:mm')
                                    )}

                                    {editModeDataCarregado === info.ID_CARREGAMENTO ? (
                                        <>
                                            <span onClick={handleSaveDataCarregado}>✅</span>
                                            <span onClick={handleCancelEditDataCarregado}>❌</span>
                                        </>
                                    ) : (
                                        <span onClick={() => handleEditDataCarregado(info)}>✏️</span>
                                    )}
                                </div>
                                <div className={style.cardItem}><strong>3° Peso (Bruto):</strong>
                                    {editModePesoBruto === info.ID_CARREGAMENTO ? (
                                        <input
                                            type="text"
                                            value={editedPesoBruto}
                                            onChange={(e) => setEditedPesoBruto(e.target.value)}
                                        />
                                    ) : (
                                        `${Number(info.PESO_BRUTO).toLocaleString(undefined, { maximumFractionDigits: 3 })} KG`
                                    )}

                                    {editModePesoBruto === info.ID_CARREGAMENTO ? (
                                        <>
                                            <span onClick={handleSavePesoBruto}>✅</span>
                                            <span onClick={handleCancelEditPesoBruto}>❌</span>
                                        </>
                                    ) : (
                                        <span onClick={() => handleEditPesoBruto(info)}>✏️</span>
                                    )}
                                </div>

                                <div className={style.cardItem}><strong>1° Tiquete:</strong>
                                    {editModeTicket01 === info.ID_CARREGAMENTO ? (
                                        <input
                                            type="text"
                                            value={editedTicket01}
                                            onChange={(e) => setEditedTicket01(e.target.value)}
                                        />
                                    ) : (
                                        `${info.TICKET_CHAVE_01}`
                                    )}


                                    {editModeTicket01 === info.ID_CARREGAMENTO ? (
                                        <>
                                            <span onClick={handleSaveTicket01}>✅</span>
                                            <span onClick={handleCancelEditTicket01}>❌</span>
                                        </>
                                    ) : (
                                        <span onClick={() => handleEditTicket01(info)}>✏️</span>
                                    )}
                                </div>

                                <div className={style.cardItem}><strong>2° Tiquete:</strong>
                                    {editModeTicket02 === info.ID_CARREGAMENTO ? (
                                        <input
                                            type="text"
                                            value={editedTicket02}
                                            onChange={(e) => setEditedTicket02(e.target.value)}
                                        />
                                    ) : (
                                        `${info.TICKET_CHAVE_02}`
                                    )}


                                    {editModeTicket02 === info.ID_CARREGAMENTO ? (
                                        <>
                                            <span onClick={handleSaveTicket02}>✅</span>
                                            <span onClick={handleCancelEditTicket02}>❌</span>
                                        </>
                                    ) : (
                                        <span onClick={() => handleEditTicket02(info)}>✏️</span>
                                    )}
                                </div>

                                {info.STATUS_NOTA_MIC !== 7 && (
    <div className={style.cardItem}>
        {console.log("ID_CARREGAMENTO:", info.ID_CARREGAMENTO)} {/* Verifica se o ID está presente */}
        <SubmitButton text="Excluir Carregamento" onClick={() => handleDelete(info.ID_CARREGAMENTO)} />
    </div>
)}



                            </div>
                        ))}
                    </div>
                </div>
            </Container>
        </>
    );
};

export default Administrador;